import {useLocation} from "react-router-dom";
import {useSelector} from "react-redux";

//import constants
import {navigationTitles} from "../../constants/constants";

//import components
import CurrentResponses from "./Navigation/CurrentResponses";
import SerialResponses from "./Navigation/SerialResponses";

//import selectors
import {responseSelector} from "../../reducers/selectors";

const DisplayResponses = () => {
    const location = useLocation()

    const {game, correction, display} = useSelector(responseSelector)

    const {pathname} = location

    const title = navigationTitles[pathname]
    const blackList = ["poursuite", "empan", "anti-saccade", "saccade", "lecture"]
    const whiteList = ["mémoire", "lecture", "balayage", "comparaison"]

    return (
        <div className="navigation-left-top">
            <div className="navigation-left-title">{title}</div>

            {game && (correction && !blackList.includes(title) || whiteList.includes(title)) && display && <CurrentResponses location={title}/>}
            {game && <SerialResponses location={title}/>}
        </div>
    )
}

export default DisplayResponses

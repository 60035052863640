import {useDispatch, useSelector} from "react-redux";
import {useRef, useEffect, useState} from "react";

//import selectors
import {navigationSelector} from "../../../reducers/selectors";

//import actions
import {navigationActions} from "../../../actions/navigationActions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

//import components
import SwitchGroup from "../../home/SwitchGroup";
import NavigationLeftFields from "./NavigationLeftFields";
import DisplayResponses from "../DisplayResponses";

//import services
import {getStyleParameters} from "../../../constants/localStorage";
import {responseActions} from "../../../actions/responseActions";

const NavigationLeft = () => {
    const dispatch = useDispatch()

    const {displayLeft, open} = useSelector(navigationSelector)

    const [parameters, setParameters] = useState(getStyleParameters("oRtHoTrAiNeMeNtStOrAgE"))

    const navigationRef = useRef(null);

    useEffect(() => {
        open && document.addEventListener("mousedown", handleClickOutside);

        return () => document.removeEventListener("mousedown", handleClickOutside)
    }, [open]);

    const handleClickOutside = event => (
        navigationRef.current && !navigationRef.current.contains(event.target) && navigationActions.close(dispatch)
    )

    const onChange = (parameter, value) => {
        const newParameters = {...parameters, [parameter]: value};
        const jsonParameters = JSON.stringify(newParameters);
        localStorage.setItem("oRtHoTrAiNeMeNtStOrAgE", jsonParameters);
        setParameters(newParameters)

        if (parameter === "correction") {
            responseActions.switchCorrection(dispatch)
        }

        if (parameter === "shadow") {
            navigationActions.switchShadow(dispatch)
        }
    }

    return (
        <div className={displayLeft ? open ? "navigation-left navigation-left-open" : "navigation-left" : "navigation-hide"} ref={navigationRef}>
            <button
                className="navigation-left-btn"
                onClick={() => open ? navigationActions.close(dispatch) : navigationActions.open(dispatch)}
            >
                <FontAwesomeIcon className="navigation-left-icon" icon="chevron-right"/>
            </button>
            <div className="navigation-left-content">
                <DisplayResponses/>
                <div className="navigation-left-bottom">
                    <NavigationLeftFields parameters={parameters} onChange={onChange}/>
                    <SwitchGroup onChange={onChange}/>
                </div>
            </div>
        </div>
    )
}

export default NavigationLeft

//import components
import InitGame from "../commun/InitGame";
import ScanForm from "./ScanForm"
import ScanGame from "./ScanGame"

function Reading() {
    return (
        <InitGame
            form={<ScanForm/>}
            game={<ScanGame/>}
        />
    )
}

export default Reading

export const empanTitle = "Cet exercice fait travailler l’empan visuo-attentionnel, c’est-à-dire la capacité de mémorisation d’un " +
    "ensemble d’éléments par l’œil sur un temps très bref. Celui-ci peut se réaliser avec des enfants de maternelle " +
    "(chiffres), aider les élèves CP dans l’apprentissage des syllabes ainsi qu’entraîner des adultes. Ce jeu offre la " +
    "possibilité de choisir le nombre de caractères ainsi que le temps d’apparition afin de faire varier la difficulté. " +
    "Un temps supérieur à 600ms, engendre un mouvement de saccade et fausse le principe du test, mais sera utile pour " +
    "l’entraînement d’un enfant avec des faiblesses. Une héminégligence droite ou gauche (pathologique ou juste induite " +
    "par le biais attentionnel naturel de lecture à gauche), peut être travaillée en changeant le paramètre de direction " +
    "(centre, gauche, droite)."

export const antiJerkTitle = "Cet exercice fait travailler le mouvement anti-saccade en faisant apparaître un caractère " +
    "à l’opposé d’un distracteur (une étoile). Le sujet doit s'opposer à la distraction. Ainsi, dans le cas où l'étoile " +
    "apparaît à gauche, le sujet doit lutter en regardant du côté opposé, c'est à dire à droite. Cela l'oblige à faire appel " +
    "à son système inhibiteur. Afin de faire varier la difficulté, il est possible de modifier la distance entre le caractère et le distracteur, ainsi " +
    "que le temps d’apparition. Les différentes directions augmentent le dynamisme et réduisent l’anticipation du sujet."

export const jerkTitle = "Cet exercice fait travailler les saccades visuelles, c’est-à-dire, les mouvements rapides " +
    "effectués d’un élément à un autre (fréquemment utiliser, notamment à la lecture). Afin de faire varier la difficulté, " +
    "il est possible de modifier la distance entre les caractères, ainsi que le temps d’apparition. Les différentes " +
    "directions augmentent le dynamisme et réduisent l’anticipation du sujet."

export const comparisonTitle = "Cet exercice fait travailler les saccades horizontales, verticales et obliques en " +
    "demandant au sujet de comparer différentes séquences de lettres ou de chiffres. Afin de faire varier la difficulté, " +
    "il est possible de modifier la distance entre les caractères. Il est également possible d’ajouter une grille, " +
    "facilitant les mouvements oculaires du sujet. Une correction en temps réel est disponible au clic sur les " +
    "différents caractères (vert : une différence est présente, rouge : aucune différence)."

export const readingTitle = "Cet exercice consiste à faire reconnaître au sujet des syllabes ou des mots. " +
    "Afin de faire varier la difficulté, il est possible de changer de police (scripte ou cursive), d’ajouter du mouvement " +
    ". Une correction en temps réel est disponible au clic sur les " +
    "différents caractères (vert : syllabes/mots identiques, rouge : syllabes/mots différents)."

export const scanTitle = "Ce jeu fait travailler le balayage visuel, c'est-à-dire l'exploration du regard avec une " +
    "recherche visuelle. Deux supports sont proposés, organisé et désorganisé, afin de faire travailler la stratégie " +
    "de recherche visuelle conventionnelle au sens de lecture (de gauche à droite avec des retours à la ligne). Il est " +
    "possible de modifier la densité des caractères sur chaque support. Ce jeu peut également faire travailler la " +
    "coordination oculo-manuelle si on laisse le patient sélectionner lui-même les caractères. Une aide avec le nombre " +
    "de caractères disséminés dans la page est disponible au survol du i."

export const pursuitTitle = "Ce jeu fait travailler la poursuite oculaire, c'est-à-dire la capacité de suivre des yeux " +
    "une cible en mouvement. Le but est de rendre la poursuite visuelle fluide et régulière pour éviter la fatigue " +
    "visuelle. Il est possible de choisir différentes trajectoires afin de faire travailler l'ensemble des muscles " +
    "oculaires.  Il est également possible de régler la vitesse de défilement afin de faire varier la difficulté."

export const memoryTitle = "Ce jeu fait travailler la mémoire visuelle à l'aide de symboles quasiment identiques. Il est " +
    "important de porter l'attention sur les détails des images. Afin de faire varier la difficulté, il est possible de " +
    "régler la taille, le contraste et le temps d'apparition des symboles. Il est également possible d’ajouter du " +
    "mouvement afin de réduire l’anticipation."



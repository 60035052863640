import {isExist} from "../../../services/isExistServices";

export const validations = {
    required,
    email,
    number,
    noSpecialCharacters,
    identicalPassword,
    unique,
    iban
}

function required(value) {
    return !value && 'Le champ est obligatoire.'
}

function email(value) {
    return value && !value.match(/([a-zA-Z0-9-_.&]+)(@[a-zA-Z0-9-_]+)(\.[a-z]+)$/) && 'L\'email est incorrect.'
}

function number(value, length) {
    const pattern = new RegExp('^[0-9]{' + length + '}$')
    return value && !value.toString().match(pattern) && 'Le champ doit contenir ' + length + ' chiffres.'
}

function noSpecialCharacters(value) {
    const pattern = new RegExp('^[a-zA-Z0-9éèêëàùç-]*$')
    return value && !value.match(pattern) && `Le champ ne doit pas contenir de caractères sépciaux.`
}

function identicalPassword(values) {
    return values.password && values.confirmPassword && values.password !== values.confirmPassword && `Les mots de passes doivent être identiques.`
}

async function unique(value, type) {
    return value && await isExist({[type]: value}) && "Cette donnée existe déjà"
}

function iban(value) {
    const pattern = new RegExp('^[a-zA-Z0-9]{27}$')
    return value && !value.match(pattern) && `L'IBAN est incorrect.`
}
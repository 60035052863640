import {fieldsType} from "../commun/Form/RadioData";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export const fieldsTypeScan = [
    ...fieldsType.slice(0, 2),
    {name: "type", value: "3", label: "Mire : dessin", display: <span className="radio-icon"><FontAwesomeIcon icon="icons"/></span>},
    {name: "type", value: "2", label: "Mire : mot", display: "MOT"},
]

export const fieldsSetup = [
    {name: "setup", value: "0", label: "Mires ordonnées", display: <span className="radio-icon"><FontAwesomeIcon icon="bars"/></span>},
    {name: "setup", value: "1", label: "Mires désordonnées", display: <span className="radio-icon"><FontAwesomeIcon icon="water"/></span>},
]

export const fieldsDensity = [
    {name: "density", value: "0", label: "Mires nombreuses et serrées", display: <span className="radio-icon"><FontAwesomeIcon icon="down-left-and-up-right-to-center"/></span>},
    {name: "density", value: "1", label: "Mires peu nombreuses et espacées", display: <span className="radio-icon"><FontAwesomeIcon icon="up-right-and-down-left-from-center"/></span>},
]

import React, {useEffect} from "react";
import {BrowserRouter, Routes, Route, Navigate} from "react-router-dom";
import moment from "moment";
import WebFont from 'webfontloader';

//import components
import Svg from "./commun/Svg";
import Empan from "./empan/Empan";
import AntiJerk from "./antiJerk/AntiJerk";
import Home from "./home/Home";
import Navigation from "./commun/Navigation/Navigation";
import Jerk from './jerk/Jerk'
import Mobile from "./commun/Mobile";
import Comparison from "./comparison/Comparison";
import Reading from "./reading/Reading"
import Login from "./login/Login";
import ToastComponent from "./commun/ToastComponent";
import Profile from "./profile/Profile";
import Upgrade from "./upgrade/Upgrade";
import SubscribePage from "./subscribe/SubscribePage";
import SubscribePageForm from "./subscribe/SubscribePageForm";
import Pursuit from "./pursuit/Pursuit";
import Scan from "./scan/Scan";
import Memory from "./memory/Memory";
import NavigationLeft from "./commun/Navigation/NavigationLeft";

//import store
import {store} from "../config/redux";

const isAuth = () => {
    const {user} = store.getState()
    return Object.keys(user.credentials).length && user.data.hasChangePassword
        && user.credentials.date === moment().format("MM-DD-YYYY")
}

const PrivateRoute = ({children}) => {
    const auth = isAuth()
    return auth ? children : <Navigate to="/"/>
}

const PrivateRouteHasPaid = ({children}) => {
    const {user} = store.getState()
    const auth = isAuth()

    if (auth && (user.data.hasPaid || user.data.tryPeriod)) return children
    if (!user.data.tryPeriod) return <Navigate to="/profil"/>
    if (!user.data.hasPaid || !auth) return <Navigate to="/"/>
}

const PrivateRouteHasNotPaid = ({children}) => {
    const {user} = store.getState()
    return !user.data.hasPaid ? children : <Navigate to="/empan"/>
}

function Router () {
    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Roboto', 'parisienne']
            }
        });
    }, []);

    return (
        <BrowserRouter>
            <div>
                <Svg/>
                <ToastComponent/>

                <div className="main-container">
                    <Navigation/>
                    <NavigationLeft/>

                    <Routes>
                        <Route path="/" element={<Login/>}/>

                        <Route path="/profil" element={<PrivateRoute><Profile/></PrivateRoute>}/>
                        <Route path="/abonnement" element={<PrivateRouteHasNotPaid><SubscribePage/></PrivateRouteHasNotPaid>}/>
                        <Route path="/abonnement/creer" element={<PrivateRouteHasNotPaid><SubscribePageForm/></PrivateRouteHasNotPaid>}/>
                        <Route path="/ameliorer" element={<PrivateRoute><Upgrade/></PrivateRoute>}/>
                        <Route path="/empan" element={<PrivateRouteHasPaid><Empan/></PrivateRouteHasPaid>}/>
                        <Route path="/antiSaccade" element={<PrivateRouteHasPaid><AntiJerk/></PrivateRouteHasPaid>}/>
                        <Route path="/saccade" element={<PrivateRouteHasPaid><Jerk/></PrivateRouteHasPaid>}/>
                        <Route path="/comparaison" element={<PrivateRouteHasPaid><Comparison/></PrivateRouteHasPaid>}/>
                        <Route path="/lecture" element={<PrivateRouteHasPaid><Reading/></PrivateRouteHasPaid>}/>
                        <Route path="/balayage" element={<PrivateRouteHasPaid><Scan/></PrivateRouteHasPaid>}/>
                        <Route path="/poursuite" element={<PrivateRouteHasPaid><Pursuit/></PrivateRouteHasPaid>}/>
                        <Route path="/memoire" element={<PrivateRouteHasPaid><Memory/></PrivateRouteHasPaid>}/>
                        <Route path="*" element={<PrivateRoute><Home/></PrivateRoute>}/>

                        <Route path="/mobile" element={<Mobile/>}/>
                    </Routes>
                </div>
            </div>
        </BrowserRouter>
    )
}

export default Router;

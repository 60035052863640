import styled, {css, keyframes} from "styled-components"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {movementAtZ, movementDtU, movementLtR, movementRtL, movementUtD, movementZtA} from "./animation";

//import constants
import {generateRandomNumber} from "../../constants/getRandomNumber";
import {getRandomLetter} from "../../constants/getRandomLetter";
import {getStyle} from "../../constants/localStorage";
import {getColor} from "../../constants/constants";
import {memoryDataGroup} from "../memory/series";

const getElement = (fontSize, type, color) => {
    const getIcon = () => {
        const data = [...memoryDataGroup].flat()
        return <FontAwesomeIcon style={{fontSize: fontSize, color: `${color}!important`}} icon={data[generateRandomNumber(0, data.length)]}/>
    }

    const element = {
        "0": generateRandomNumber(0, 10),
        "1": getRandomLetter(),
        "2": <FontAwesomeIcon style={{fontSize: fontSize, color: `${color}!important`}} icon="circle"/>,
        "3": getIcon()
    }

    return element[type]
}

const getDirection = (allDirection, direction) => (
    direction === "aleatoire" ? allDirection[generateRandomNumber(0, 2)] : direction
)

const displayPattern = (launch, getAnimation, setDisplay) => {
    const {time, type, orientation, colored} = launch

    const inc = orientation === "0" ? 8 : 9

    const DIV = styled.div`
      ${(props) => css`
        display: flex;
        align-items: center;
        justify-content: center;
        animation: ${getAnimation} ${time}ms ${props.delay}ms linear;
        position: absolute;
        animation-fill-mode: forwards;
        opacity: 0;
        height: 4.7rem;
        ${props.margin}
      `};
    `

    setDisplay(
        Array(inc).fill("").map((_, index) => {
            const style = getStyle()
            const color = colored === "1" ? getColor() : 'var(--dark)'
            const element = getElement(style.fontSize, type, color)
            const margin = orientation === "0" ? `top: ${(index + 1) * 9.5}svh` : `left: ${(index + 1) * 9.8}svw`
            return (<DIV key={index} margin={margin} delay={index * time + 50}>
                <span style={{...style, padding: 0, letterSpacing: 0, paddingRight: 0, color: color}}>{element}</span>
            </DIV>)
        })
    )
}

const horizontal = (launch, setDisplay) => {
    const {direction} = launch

    const getHorizontalDirection = () => {
        const theDirection = getDirection(["RtL", "LtR"], direction)
        return theDirection === "RtL" ? movementRtL : movementLtR
    }

    displayPattern(launch, getHorizontalDirection, setDisplay)
}

const vertical = (launch, setDisplay) => {
    const {directionV} = launch

    const getVerticalDirection = () => {
        const theDirection = getDirection(["UtD", "DtU"], directionV)
        return theDirection === "DtU" ? movementDtU : movementUtD
    }

    displayPattern(launch, getVerticalDirection, setDisplay)
}

const zigzag = (launch, setDisplay) => {
    const {time, type, direction, colored} = launch

    const getZigZagDirection = (props) => {
        const theDirection = getDirection(["RtL", "LtR"], direction)
        return theDirection === "RtL" ? movementAtZ(props) : movementZtA(props)
    }

    const DIV = styled.div`
      ${(props) => css`
        animation: ${(props) => getZigZagDirection(props)} ${time*5}ms linear;
        position: absolute;
        animation-fill-mode: forwards;
        opacity: 0;
        height: 4.7rem;
      `};
    `

    const colors = Array(6).fill("").map((_, index) => colored === "1" ? getColor() : 'var(--dark)')

    const style = getStyle()
    const element = getElement(style.fontSize, type)

    setDisplay(
        <DIV colors={colors}>
            <span style={{...style, padding: 0, letterSpacing: 0, paddingRight: 0}}>{element}</span>
        </DIV>
    )
}

const box = (launch, setDisplay) => {
    const {time, type, colored} = launch

    const getMovement = () => {
        let movement = ""
        const edges = [-43, 43, 34, -47]
        let previousEdge = 0

        for (let i = 0; i < 11; i++) {
            const percent = 10*i
            const newEdges = [...edges].filter(element => element !== previousEdge)
            const randomEdge = newEdges[generateRandomNumber(0, newEdges.length)]
            const isWidth = 43 === Math.abs(randomEdge)
            const color = colored === "1" ? getColor() : 'var(--dark)'

            if (isWidth) {
                const randomNumber = generateRandomNumber(-47, 34)
                movement = `${movement} ${percent}% {transform: translate(${randomEdge}svw, ${randomNumber}svh); color: ${color}}`
            } else {
                const randomNumber = generateRandomNumber(-43, 43)
                movement = `${movement} ${percent}% {transform: translate(${randomNumber}svw, ${randomEdge}svh); color: ${color}}`
            }

            previousEdge = randomEdge
        }

        return movement
    }

    const movementBox = props => keyframes`
        1% {opacity: 1;}
        99% {opacity: 1;}
        ${props.movement}
    `

    const DIV = styled.div`
      ${(props) => css`
        animation: ${(props) => movementBox(props)} ${time*10}ms linear;
        position: absolute;
        animation-fill-mode: forwards;
        opacity: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 4.7rem;
      `};
    `

    const style = getStyle()
    const element = getElement(style.fontSize, type)

    const movement = getMovement()

    setDisplay(
        <DIV movement={movement}>
            <span style={{...style, padding: 0, letterSpacing: 0, paddingRight: 0}}>{element}</span>
        </DIV>
    )
}

export const pursuitDisplay = (setDisplay, launch) => {
    const {orientation} = launch

    const display = {
        "0": () => horizontal(launch, setDisplay),
        "1": () => vertical(launch, setDisplay),
        "2": () => zigzag(launch, setDisplay),
        "3": () => box(launch, setDisplay),
    }

    display[orientation]()
}

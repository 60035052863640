import {Fragment, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

//import component
import Results from "../commun/Results";
import {CustomTooltip} from "./customTooltip";

//import constants
import {getIdParameter} from "../../constants/localStorage";

//import actions
import {responseActions} from "../../actions/responseActions";
import {navigationActions} from "../../actions/navigationActions";

//Import selectors
import {responseSelector} from "../../reducers/selectors";

function BottomNavWithoutTime({launch, setLaunch, gameDisplay, title, setDisplay}) {
    const [results, setResults] = useState(false)

    const dispatch = useDispatch()

    const {display, correction, trueResponses, falseResponses} = useSelector(responseSelector)

    const resetId = () => {
        const id = getIdParameter()

        if (id) {
            clearInterval(id)
        }
    }

    const getGame = () => {
        resetId()

        responseActions.responseTrueCurrent(dispatch)
        responseActions.responseFalseCurrent(dispatch)
        responseActions.serial(dispatch)

        const content = document.getElementsByClassName('content')

        for (let i = 0; i < content.length; i++) {
            content[i].classList.remove('true')
            content[i].classList.remove('false')
        }

        gameDisplay()
    }

    useEffect(() => {
        navigationActions.displayLeft(dispatch)
    }, [])

    useEffect(() => {
        getGame()
    }, [])

    useEffect(() => {
        document.addEventListener('keydown', keyEvents)
        return () => document.removeEventListener('keydown', keyEvents);
    }, [])

    const keyEvents = event => {
        if (event?.srcElement.tagName === "BUTTON") return

        event.stopPropagation()
        event.code === 'Space' && getGame()
        event.code === 'Escape' && exit()
    }

    const exit = () => {
        resetId()

        if (correction && (title === "balayage" || title === "comparaison")) {
            if (trueResponses.current !== 0 || falseResponses.current !== 0) {
                responseActions.serial(dispatch)
            } else {
                responseActions.responseMissedDecSerial(dispatch)
            }
        }

        if (correction
            && title !== "poursuite"
            && title !== "lecture"
            && !(title === "saccade" && launch.position === "movement")
            && !(title === "saccade" && launch.stopwatch === "false")
        ) {
            setResults(true)
            document.removeEventListener('keydown', keyEvents)
        } else {
            setLaunch({})
        }
    }

    return (
        <Fragment>
            <CustomTooltip title={(display && correction) ? "Correction" : "Retour"} placement="right">
                <button className="btn-nav btn-first" onClick={exit}>
                    <FontAwesomeIcon className="icon" icon="circle-left"/>
                </button>
            </CustomTooltip>

            <CustomTooltip title="Suivant" placement="left">
                <button className="btn-nav btn-second" onClick={getGame}>
                    <FontAwesomeIcon className="icon" icon="circle-right"/>
                </button>
            </CustomTooltip>

            {results && <Results
                title={title}
                setLaunch={setLaunch}
                launch={launch}
                setResults={setResults}
                setDisplay={setDisplay}
                gameDisplay={getGame}
            />}
        </Fragment>
    )
}

export default BottomNavWithoutTime

//import config
import * as DT from "../config/dispatchTypes";

export const navigationActions = {
    open,
    close,
    hide,
    display,
    displayLeft,
    switchShadow,
    raz
}

function open(dispatch) {
    dispatch({type: DT.NAVIGATION_OPEN})
}

function close(dispatch) {
    dispatch({type: DT.NAVIGATION_CLOSE})
}

function hide(dispatch) {
    dispatch({type: DT.NAVIGATION_HIDE})
}

function display(dispatch) {
    dispatch({type: DT.NAVIGATION_DISPLAY})
}

function displayLeft(dispatch) {
    dispatch({type: DT.NAVIGATION_DISPLAY_LEFT})
}

function switchShadow(dispatch) {
    dispatch({type: DT.NAVIGATION_SWITCH_SHADOW})
}

function raz(dispatch) {
    dispatch({type: DT.NAVIGATION_RAZ})
}

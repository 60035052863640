//import services
import {request} from './request';
import {notifications} from "../components/commun/Notifications";

export const mollieServices = {
    createMandate
}

function createMandate(values, setLoading) {
    const rejectedCallback = () => {
        setLoading(false)
        notifications.warning()
    }

    const errorCallback = () => {
        setLoading(false)
        notifications.error()
    }

    return request(
        'POST',
        `/api/mollie/create-mandate`,
        response => response,
        rejectedCallback,
        errorCallback,
        values
    )
}

export const getRandomNumber = length => {
    const number = [0, 10, 100, 1000, 10000, 100000, 1000000]

    const min = number[length - 1];
    const max = number[length];

    return generateRandomNumber(min, max);
}

export const generateRandomNumber = (min, max) => {
    return Math.floor(Math.random() * (max - min) + min)
}

export const generateRandomUniqueNumbers = (length) => {
    const randomNumbers = []

    while (randomNumbers.length < length) {
        const randomNumber = generateRandomNumber(0, 10)
        !randomNumbers.includes(randomNumber) && randomNumbers.push(randomNumber)
    }

    return randomNumbers
}

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export const fieldsPosition = [
    {name: "type", value: "static", label: "Mire statique", display: <span className="radio-icon"><FontAwesomeIcon icon="person"/></span>},
    {name: "type", value: "movement", label: "Mire en mouvement", display: <span className="radio-icon"><FontAwesomeIcon icon="person-running"/></span>},
    {name: "type", value: "appearance", label: "Mire en apparition", display: <span className="radio-icon"><FontAwesomeIcon icon="person-rays"/></span>},
]

export const fieldsTextType = [
    {name: "textType", value: "0", label: "Écriture scripte", display: "SCRIPTE"},
    {name: "textType", value: "1", label: "Écriture cursive", display: <span className="cursive" style={{fontFamily: "parisienne"}}>cursive</span>},
]

const fieldsWordType = [
    {name: "wordType", value: "0", label: "Syllabe", display: "SYLLABE"},
    {name: "wordType", value: "1", label: "Double syllabe", display: "LOGATOME"},
    {name: "wordType", value: "2", label: "Mot", display: "MOT"},
]

export const readingFieldsTimeStatic = [
    {value: 200, label: '200ms'},
    {value: 300, label: ''},
    {value: 400, label: '400ms'},
    {value: 500, label: ''},
    {value: 600, label: '600ms'},
    {value: 700, label: ''},
    {value: 800, label: '800ms'},
    {value: 900, label: ''},
    {value: 1000, label: '1000ms'},
    {value: 1100, label: ''},
    {value: 1200, label: '1200ms'},
];

export const readingFieldsTimeMovement = [
    {value: 1000, label: '1000ms'},
    {value: 1500, label: ''},
    {value: 2000, label: '2000ms'},
    {value: 2500, label: ''},
    {value: 3000, label: '3000ms'},
    {value: 3500, label: ''},
    {value: 4000, label: '4000ms'},
];

export const readingFieldsTimeAppearance = [
    {value: 500, label: '500ms'},
    {value: 1000, label: ''},
    {value: 1500, label: '1500ms'},
    {value: 2000, label: ''},
    {value: 2500, label: '2500ms'},
];

export const readingTimeConfig = {
    "static": {marks: readingFieldsTimeStatic, defaultTime: 400},
    "movement": {marks: readingFieldsTimeMovement, defaultTime: 2500},
    "appearance": {marks: readingFieldsTimeAppearance, defaultTime: 1000},
}

export const readingFormDataDirection = [
    {name: "direction", value: "RtL", label: "De droite à gauche", display: <span className="radio-icon"><FontAwesomeIcon icon="arrow-left"/>|</span>},
    {name: "direction", value: "LtR", label: "De gauche à droite", display: <span className="radio-icon">|<FontAwesomeIcon icon="arrow-right"/></span>},
]

export const fieldsText = [
    {name: "readText", value: "0", label: "Texte classique", display: "CLASSIQUE"},
    {name: "readText", value: "1", label: "Texte coloré", display: <span className="rText">COLORÉ</span>},
    {name: "readText", value: "2", label: "Texte espacé", display: "ESPACÉ"}
]

export const fieldsTextColored = [
    {name: "textColored", value: "0", label: "Un mot sur deux est coloré", display: <span className="rText">TEXTE <span className="rText rColored">COLORÉ</span></span>},
    {name: "textColored", value: "1", label: "Une syllabe sur deux est colorée", display: <span className="rText">TEX<span className="rText rColored">TE</span> CO<span className="rText rColored">LO</span>RÉ</span>},
]

export const staticData = [
    [...fieldsWordType, {name: "wordType", value: "3", label: "Texte", display: "TEXTE"}],
    fieldsTextType
]

export const movementData = [
    readingFormDataDirection,
    fieldsWordType,
]

export const appearanceDataWordType = [...
    [...fieldsWordType].splice(2,3),
    {name: "wordType", value: "3", label: "Texte", display: "TEXTE"},
    {name: "wordType", value: "4", label: "Chiffre", display: "CHIFFRE"}
]

import * as DT from '../config/dispatchTypes';

const initialState = {
    data: {},
    credentials: {}
}

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case DT.USER_FIND:
            return {
                ...state,
                data: action.data
            }

        case DT.USER_CREDENTIALS:
            return {
                ...state,
                credentials: action.credentials
            }

        case DT.USER_EDIT:
            return {
                ...state,
                data: {
                    ...state.data,
                    ...action.data
                }
            }

        case DT.USER_RAZ:
            return initialState

        default:
            return state;
    }
}

export default userReducer;
import {useState} from "react";

//import constants
import {comparisonDisplay} from "./comparisonDisplay";
import {useDispatch} from "react-redux";

//import components
import BottomNavWithoutTime from "../commun/BottomNavWithoutTime";

function ComparisonGame({launch, setLaunch}) {
    const [display, setDisplay] = useState()

    const dispatch = useDispatch()

    const {difficulty, orientation} = launch
    const grid = orientation === "2" ? "noGrid" : launch.grid

    const gameDisplay = () => {
        comparisonDisplay(setDisplay, launch, dispatch)
    }

    return (
        <main>
            <div className="comparison-game">
                <div className={`comparison-game-display orientation-${orientation} ${difficulty} ${grid}`}>
                    {display}
                </div>

                <BottomNavWithoutTime
                    title={"comparaison"}
                    launch={launch}
                    setLaunch={setLaunch}
                    gameDisplay={gameDisplay}
                    setDisplay={setDisplay}
                />
            </div>
        </main>
    )
}

export default ComparisonGame

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

//import components
import {CustomTooltip} from "../customTooltip";

function Submit({condition}) {
    return (
        <button className="btn-nav btn-second" type="submit" disabled={condition}>
            <CustomTooltip title={condition ? "Formulaire non complet" : "Lancer le jeu"} placement="left">
                <FontAwesomeIcon className="icon" icon="circle-right"/>
            </CustomTooltip>
        </button>
    )
}

export default Submit

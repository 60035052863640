import {Fragment} from "react";
import {Form} from "react-final-form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";

//import components
import {fields} from "../commun/Form/fields";

//import selector
import {userSelector} from "../../reducers/selectors";

//import services
import {userServices} from "../../services/userServices";
import {encrypt} from "../../constants/crypto";

const CreatePassword = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const user = useSelector(userSelector)

    const submit = (values) => {
        const valuesToSubmit = {
            password: encrypt(values.password),
            confirmPassword: encrypt(values.confirmPassword)
        }

        userServices.updatePassword(user.data._id, valuesToSubmit).then(response => (
            response.status === 200 && userServices.find(user.data._id, dispatch, navigate)
        ))
    }

    return (
        <Fragment>
            <h1>Définir son mot de passe</h1>
            <Form
                onSubmit={submit}
                render={({handleSubmit, hasValidationErrors}) => (
                    <form onSubmit={handleSubmit} className="create-password">
                        {fields.password(false, null,false, "Mot de passe*", "password", true)}
                        {fields.password(false, null, false, "Confirmation mot de passe*", "confirmPassword", true)}
                        <button disabled={hasValidationErrors} className="btn-nav btn-second" type="submit">
                            <FontAwesomeIcon className="icon" icon="circle-right"/>
                        </button>
                    </form>
                )}
            />
        </Fragment>
    )
}

export default CreatePassword
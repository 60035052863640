import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

//import constants
import {getRandomLetter} from "../../constants/getRandomLetter";
import {generateRandomNumber} from "../../constants/getRandomNumber";
import {getStyle} from "../../constants/localStorage";

//import actions
import {responseActions} from "../../actions/responseActions";
import {smallReadingData} from "../reading/Dictionnary";
import {newMemoryData} from "../memory/series";

const getClick = (event) => {
    let click = event.target
    if (click.tagName === "svg") return click.parentNode
    if (click.tagName === "path") return click.parentNode.parentNode
    return click
}

const getCompare = (click) => {
    if (click.textContent) {
        return click.textContent
    } else {
        let compare = click?.firstChild?.classList?.value
        if (compare) return compare.replace(/(?:svg-inline--fa|fa-|\s+)/g, '')
    }
}

const getToFind = (characterToFind) => {
    if (typeof characterToFind === "string" || typeof characterToFind === "number") return characterToFind.toString()
    if (Array.isArray(characterToFind)) return characterToFind.map(character => `${character.props.icon}${character.props.className}`).join("")
    return `${characterToFind.name}${characterToFind.custom}`
}

const check = (event, characterToFind, dispatch) => {
    const click = getClick(event)
    const compare = getCompare(click)
    const toFind = getToFind(characterToFind)

    if (compare === toFind) {
        click.classList.add("true")
        responseActions.responseTrue(dispatch)
        responseActions.responseMissedDec(dispatch)
    } else {
        click.classList.add("false")
        responseActions.responseFalse(dispatch)
    }
}

const getNewStyle = (setup) => {
    const style = getStyle()

    let newStyle = {
        ...style,
        whiteSpace: "nowrap",
        textTransform: style.textTransform === "lowercase" ? "none" : "upperCase",
    }

    if (setup === "0") {
        newStyle = {...newStyle, paddingRight: style.letterSpacing, paddingLeft: 0}
    }

    if (setup === "1") {
        const marginType = ["marginTop", "marginBottom", "marginRight", "marginLeft"]
        const margin = generateRandomNumber(0, 90)
        newStyle = {...newStyle, [marginType[generateRandomNumber(0, 4)]]: `${margin}px`, padding: 0}
    }

    return newStyle
}

const getIndex = (setup, type) => {
    const index = {
        "0": type === "2" ? 40 : 200,
        "1" : type === "2" ? 15 : 50,
    }

    return index[setup]
}

const getElement = (type, words = [], icons = []) => {
    const element = {
        "0": generateRandomNumber(0, 10),
        "1": getRandomLetter(),
        "2": words[generateRandomNumber(0, words.length)],
        "3": icons[generateRandomNumber(0, icons.length)],
    }

    return element[type]
}

const getElementToFind = (elementsToDisplay) => {
    const elements = elementsToDisplay.filter(element => element !== null)
    return elements[generateRandomNumber(0, elements.length)]
}

const getNoGroupData = (type, setup, density, icons, dispatch) => {
    const words = type === "2" ? smallReadingData[generateRandomNumber(0, smallReadingData.length)] : []
    let numberElements = 0
    const index = getIndex(setup, type)

    const elementsToDisplay = Array(index).fill('').map(() => {
        const flag = density === "1" ? generateRandomNumber(0, 3) : 0
        return flag === 0 ? getElement(type, words, icons) : null
    })

    const elementToFind = getElementToFind(elementsToDisplay)

    const elements = elementsToDisplay.map((element, i) => {
        if (element === elementToFind) numberElements++
        const style = getNewStyle(setup)
        return (
            <div
                className='grid content'
                key={i}
                style={style}
                onClick={(event) => check(event, elementToFind, dispatch)}
            >
                {type === "3" ?
                    element ? <FontAwesomeIcon className={element?.custom} icon={element?.name}/> : null
                    :
                    element
                }
            </div>
        )
    })

    return {
        elements,
        numberElements,
        elementToFind : type === "3" ? <FontAwesomeIcon className={elementToFind.custom} icon={elementToFind.name}/> : elementToFind
    }
}

const checkGroup = (event, length, characterToFind, dispatch) => {
    const click = getClick(event)
    const siblings = [click];
    let compare = getCompare(click)
    let sibling = click.nextElementSibling;
    const toFind = getToFind(characterToFind)

    for (let i = 0; i < (length-1) && sibling; i++) {
        compare += getCompare(sibling)
        siblings.push(sibling);
        sibling = sibling.nextElementSibling;
    }

    if (compare === toFind) {
        siblings.forEach(element => element.classList.add("true"))
        responseActions.responseTrue(dispatch)
        responseActions.responseMissedDec(dispatch)
    } else {
        siblings[0].classList.add("false")
        responseActions.responseFalse(dispatch)
    }
}

const getGroupData = (launch, icons, dispatch) => {
    const {type, density, setup, numberChar} = launch

    const intNumberChar = parseInt(numberChar)
    const index = getIndex(setup, type)
    const inc = Math.floor(index / intNumberChar)
    const rest = index % intNumberChar
    let numberElements = 0

    const getElements = (empty = false) => Array(intNumberChar).fill('').map(() =>
        getElement(empty ? "4" : type, [], icons)
    )

    const toFind = getElements(false)

    const incElements = Array(inc).fill('').map(() => {
        const randomToFind = generateRandomNumber(0, 3)
        const flag = density === "1" ? generateRandomNumber(0, 3) : 1

        if (flag === 0) {
            return getElements(true)
        }

        if (randomToFind === 0) {
            numberElements++
            return toFind
        }

        return getElements()
    }).flat()

    const restElements = Array(rest).fill('').map((_, i) =>
        getElement(type, [], icons)
    ).flat()

    const elements = [...incElements, ...restElements].map((element, index) => {
        const style = getNewStyle(setup)
        return <div key={`e-${index}`} className='grid content' style={style} onClick={(event) => checkGroup(event, intNumberChar, elementToFind, dispatch)}>
            {type === "3" ?
                element ? <FontAwesomeIcon className={element?.custom} icon={element?.name}/> : null
                :
                element
            }
        </div>
    })

    const elementToFind = type === "3" ?
        toFind.map((element, index) => <FontAwesomeIcon key={`etf-${index}`} className={element?.custom} icon={element?.name}/>)
        : toFind.map(element => element).join("")

    return {elements: elements, elementToFind, numberElements}
}

export const scanDisplay = (launch, setDisplay, dispatch) => {
    const {type, density, setup, group} = launch
    let icons = []

    if (type === "3") {
        while (icons.length < 26) {
            const icon = newMemoryData[generateRandomNumber(0, newMemoryData.length)]
            const isExist = icons.some(element => JSON.stringify(element) === JSON.stringify(icon))

            if (!isExist) {
                icons.push(icon)
            }
        }
    }

    const {elements, elementToFind, numberElements} = (group === "group" && setup !== "1") ?
        getGroupData(launch, icons, dispatch) : getNoGroupData(type, setup, density, icons, dispatch)

    responseActions.responseMissed(dispatch, numberElements)
    setDisplay({elements, elementToFind, numberElements})
}

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

//import constants
import {fieldsType} from "../commun/Form/RadioData";
import {fieldsDifficulty, fieldsDirection} from "../jerk/pattern/JerkRadioData";

export const fieldsTypeComparison = [
    ...fieldsType.slice(0, 2),
    {name: "type", value: "3", label: "Mire : dessin", display: <span className="radio-icon"><FontAwesomeIcon icon="icons"/></span>},
    {name: "type", value: "2", label: "Aléatoire", display: "ALÉATOIRE"},
]

export const fieldsGrid = [
    {name: "grid", value: "withGrid", label: "Avec grille", display: <span className="radio-icon"><FontAwesomeIcon icon="border-all"/></span>},
    {name: "grid", value: "noGrid", label: "Sans grille", display: <span className="radio-icon"><FontAwesomeIcon icon="border-none"/></span>},
]

export const fieldsNumberChar = (difficulty) => {
    const numberChar = [
        {name: "numberChar", value: "1", label: "Nombre mire : 1", display: "1"},
        {name: "numberChar", value: "2", label: "Nombre mire : 2", display: "2"},
        {name: "numberChar", value: "3", label: "Nombre mire : 3", display: "3"},
        {name: "numberChar", value: "4", label: "Nombre mire : 4", display: "4", disabled: difficulty === "easy"},
        {name: "numberChar", value: "5", label: "Nombre mire : 5", display: "5", disabled: difficulty === "easy"},
        {name: "numberChar", value: "6", label: "Nombre mire : 6", display: "6", disabled: difficulty === "easy"},
    ]

    //return difficulty === "easy" ? numberChar.splice(0, 3): numberChar
    return numberChar
}

export const fieldsGroup = [
    {name: "group", value: "noGroup", label: "Comparer seul", display: <span className="radio-icon"><FontAwesomeIcon icon="dice-five"/></span>},
    {name: "group", value: "group", label: "Comparer par groupe", display: <span className="radio-icon"><FontAwesomeIcon icon="dice"/></span>}
]

export const comparisonFormData = [fieldsDifficulty, fieldsDirection.slice(0, 4)]

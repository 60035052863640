import {Field} from "react-final-form";

//import components
import {CustomTooltip} from "../customTooltip";

function CommonRadio({index, step, setStep, fields, defaultHandleClick = undefined}) {
    const required = value => (value ? undefined : 'Required')

    const handleClick = () => {
        index >= step && setStep(index + 1)
    }

    return (
        <div className={index > step ? "game-radio-container hide" : "game-radio-container"}>
            <div className="game-radio-content">
                {fields.map((field, i) =>
                    <CustomTooltip key={i} title={field.label}>
                        <div key={i} className="game-radio">
                            <Field
                                name={field.name}
                                component="input"
                                type="radio"
                                value={field.value}
                                id={field.label}
                                validate={required}
                                onClick={defaultHandleClick ? defaultHandleClick : handleClick}
                                disabled={field.disabled ? field.disabled : false}
                            />
                            <label htmlFor={field.label} className={field.name}>
                                {field.display}
                            </label>
                        </div>
                    </CustomTooltip>
                )}
            </div>
        </div>
    )
}

export default CommonRadio

import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux'
import axios from "axios";
import {PersistGate} from "redux-persist/integration/react";

//import style
import "./styles/styles.scss"

//import fontAwesome
import "./config/FontAwesome"

//import components
import Router from "./components/Router";

//import config
import {persistor, store} from "./config/redux";

//config url
axios.defaults.baseURL = process.env.REACT_APP_API_HOST

const container = document.getElementById('root')
const root = createRoot(container)

root.render(
    <Provider store={store}>
        <PersistGate persistor={persistor}>
            <Router/>
        </PersistGate>
    </Provider>
)
import {Fragment} from "react";
import styled, {keyframes, css} from "styled-components"

//import constants
import {generateRandomNumber} from "../../constants/getRandomNumber";
import {
    originalTextsData,
    readingAppearanceData,
    readingData,
    smallTextData,
    textsData
} from "./Dictionnary";
import {getRandomSyllable} from "../../constants/getRandomSyllable";
import {getStyle, setIdParameter} from "../../constants/localStorage";

//import actions
import {responseActions} from "../../actions/responseActions";

const readingSyllable = (type) => {
    const randomNumber = generateRandomNumber(0, 4)
    const allSyllables = []

    for (let i = 0; i < 4; i++) {
        allSyllables.push(getRandomSyllable(type))
    }

    return {real: allSyllables[randomNumber], fake: allSyllables}
}

const readingWord = () => {
    const randomNumber = generateRandomNumber(0, readingData.length)
    const allWordsToDisplay = [...readingData[randomNumber]].sort(() => 0.5 - Math.random())
    return {real: allWordsToDisplay[generateRandomNumber(0, 4)], fake: allWordsToDisplay}
}

export const readingDisplay = (launch, setDisplay, setStart, dispatch) => {
    const {type, direction, wordType, textType, time} = launch

    let allToDisplay

    if (wordType === "0") {
        allToDisplay = readingSyllable(2)
    } else if (wordType === "1") {
        allToDisplay = readingSyllable(4)
    } else {
        allToDisplay = readingWord()
    }

    const check = (event, index) => {
        if (allToDisplay.fake[index] === allToDisplay.real) {
            event.target.classList.add("true")
            responseActions.responseTrue(dispatch)
        } else {
            event.target.classList.add("false")
            responseActions.responseFalse(dispatch)
        }
    }

    const movementRtL = keyframes`
      0% {transform: translateX(32vw)}
      100% {transform: translateX(-32vw)}
    `;

    const movementLtR = keyframes`
      0% {transform: translateX(-32vw)}
      100% {transform: translateX(32vw)}
    `;

    const DIVRtL = styled.div`
      ${(props) => props.movement && css`
        animation: ${movementRtL} ${time + 50}ms linear
      `};
    `

    const DIVLtR = styled.div`
      ${(props) => props.movement && css`
        animation: ${movementLtR} ${time + 50}ms linear
      `};
      ;
    `

    let style = getStyle()

    if (textType === "1") {
        style = {...style, fontFamily: "parisienne"}
    }

    const animate = (rwClassName) => (
        <div className="reading-animate-container">
            <div className="blank blank-left"></div>
            {direction === "RtL" ?
                <DIVRtL movement={type === "movement" && rwClassName !== "hide"} className={"rgd-real-word " + rwClassName}>
                    <span style={style}>{allToDisplay.real}</span>
                </DIVRtL>
            :
                <DIVLtR movement={type === "movement" && rwClassName !== "hide"} className={"rgd-real-word " + rwClassName}>
                    <span style={style}>{allToDisplay.real}</span>
                </DIVLtR>
            }
            <div className="blank blank-right"></div>
        </div>
    )

    const divToDisplay = (rwClassName, fwClassName) => {
        return (
            <Fragment>
                {type === "movement" && rwClassName !== "hide" ?
                    animate(rwClassName)
                    :
                    <div className={`rgd-real-word ${rwClassName} reading-noAnimate`}>
                        <span style={style}>{allToDisplay.real}</span>
                    </div>
                }
                <div className={`rgd-fake-word-container ${fwClassName}`}>
                    {allToDisplay.fake.map((word, index) =>
                        <div className={`rgd-fake-word-content ${fwClassName}`} key={index} style={{...style, padding: "3rem", fontFamily: "roboto", opacity: 1}}
                             onClick={(event) => check(event, index)}>
                            {word}
                        </div>
                    )}
                </div>
            </Fragment>
        )
    }

    setDisplay(divToDisplay("", "hide"))
    setTimeout(() => setDisplay(divToDisplay("hide", "")), time)
    setTimeout(() => setStart(true), time)
}

export const readingTextDisplay = (launch, setDisplay) => {
    const {textType, readText, textColored} = launch

    const readTextData = {
        "0": originalTextsData,
        "1": textColored === "0" ? originalTextsData : textsData,
        "2": smallTextData
    }

    const texts = readTextData[readText]
    const random = generateRandomNumber(0, texts.length)
    let textToDisplay = texts[random]

    if (textColored === "0") {
        let newTextToDisplay = ""

        textToDisplay.split(" ").forEach((element, index) => {
            newTextToDisplay = index % 2 !== 0 ? `${newTextToDisplay} <span>${element}</span>` : `${newTextToDisplay} ${element}`
        })

        textToDisplay = newTextToDisplay
    }

    if (readText === "2") {
        let newTextToDisplay = ""

        textToDisplay.split(" ").forEach((element, index) => {
            const style = `margin: 0 ${generateRandomNumber(0, 280)}px;`;
            newTextToDisplay = `${newTextToDisplay} <span style="${style}">${element}</span>`
        })

        textToDisplay = newTextToDisplay
    }

    const style = getStyle()

    const newStyle = {
        ...style,
        textTransform: style.textTransform === "lowercase" ? "none" : "upperCase",
        fontFamily: textType === "1" ? "parisienne" : "inherit",
    }

    const display = <div
        className={`reading-text tc-${readText === "1" ? textColored : ""}`}
        style={newStyle}
        dangerouslySetInnerHTML={{__html: textToDisplay}}
    ></div>

    setDisplay(display)
}

const readingAppearanceType2 = (difficulty) => {
    const index = {
        "easy": [5, 9, 20, 24, 35, 39, 50, 54, 65, 69, 80, 84, 95, 99, 110, 114, 125, 129, 140, 144, 155, 159, 170, 174, 185, 189, 200, 204, 215, 219],
        "medium": [3, 11, 18, 26, 33, 41, 48, 56, 63, 71, 78, 86, 93, 101, 108, 116, 123, 131, 138, 146, 153, 161, 168, 176, 183, 191, 198, 206, 213, 221],
        "difficult": [0, 14, 15, 29, 30, 44, 45, 59, 60, 74, 75, 89, 90, 104, 105, 119, 120, 134, 135, 149, 150, 164, 165, 179, 180, 194, 195, 209, 210, 224]
    }

    const value = Array(15).fill('').map((_, i) => {
        const word = readingAppearanceData[generateRandomNumber(0, readingAppearanceData.length)]
        const split = Math.floor(word.length / 2)
        return [word.substring(0, split), word.substring(split)]
    }).flat()

    return {index: index[difficulty], value}
}

const readingAppearanceType3 = () => {
    const textIndex = (values) => {
        let down = 0
        const index = []

        const max = 82
        const length = values.length
        const inc = Math.floor(max / length)
        const rest = max % length

        const getRandomIndex = (down, up, currentIndex) => {
            const value = values[currentIndex]
            let randomIndex = generateRandomNumber(down, up)

            if (currentIndex > 0) {
                const prevValue = values[currentIndex - 1]
                if (prevValue.length > 5) randomIndex++
                if (prevValue.length > 12) randomIndex++
            }

            if (value.length > 6 && randomIndex % 6 === 5) randomIndex++

            return randomIndex
        }

        while (index.length < length) {
            const currentIndex = index.length
            const up = inc * (currentIndex + 1) + rest
            const randomIndex = getRandomIndex(down, up, currentIndex)
            down = randomIndex + 1
            index.push(randomIndex)
        }

        return index
    }

    const text = smallTextData[generateRandomNumber(0, smallTextData.length)]
    const value = text.split(" ")
    const index = textIndex(value)
    return {index, value}
}

const readingAppearanceType4 = () => {
    const uniqueArrayDigit = (size) => {
        const index = []
        while (index.length < size) {
            const randomIndex = generateRandomNumber(0, 225)
            !index.includes(randomIndex) && index.push(randomIndex)
        }
        return index.sort((a, b) => a - b)
    }

    const value = Array(75).fill('').map((_, i) => generateRandomNumber(0, 10))
    const index = uniqueArrayDigit(value.length)
    return {index, value}
}

export const readingAppearanceDisplay = (launch, setDisplay) => {
    const {time, wordType, textType, difficulty} = launch

    const getData = () => {
        if (wordType === "2") return readingAppearanceType2(difficulty)
        if (wordType === "3") return readingAppearanceType3()
        if (wordType === "4") return readingAppearanceType4()
    }

    let inc = 0
    const {index, value} = getData()

    const arrayToDisplay = Array(wordType === "3" ? 84 : 225).fill('').map((_, i) => (
        <div className='grid' key={i}></div>
    ));

    setDisplay(arrayToDisplay)

    const style = getStyle()

    const newStyle = {
        ...style,
        padding: "0",
        whiteSpace: "nowrap",
        textTransform: style.textTransform === "lowercase" ? "none" : "upperCase",
        fontFamily: (textType === "1" && wordType !== "4") ? "parisienne" : "inherit",
    }

    const intervalId = setInterval(() => {
        arrayToDisplay[index[inc]] = <div className='grid' key={index[inc]}><span style={newStyle}>{value[inc]}</span></div>
        setDisplay([...arrayToDisplay])
        inc++;

        if (inc >= index.length) {
            clearInterval(intervalId);
        }
    }, time);

    setIdParameter(intervalId)
}

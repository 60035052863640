export const
    NAVIGATION_OPEN = "NAVIGATION_OPEN",
    NAVIGATION_CLOSE = "NAVIGATION_CLOSE",
    NAVIGATION_HIDE = "NAVIGATION_HIDE",
    NAVIGATION_DISPLAY = "NAVIGATION_DISPLAY",
    NAVIGATION_DISPLAY_LEFT = "NAVIGATION_DISPLAY_LEFT",
    NAVIGATION_SWITCH_SHADOW = "NAVIGATION_SWITCH_SHADOW",
    NAVIGATION_RAZ = "NAVIGATION_RAZ";

export const
    USER_FIND = "USER_FIND",
    USER_CREDENTIALS = "USER_CREDENTIALS",
    USER_EDIT = "USER_EDIT",
    USER_RAZ = "USER_RAZ";

export const
    RESPONSE_TRUE = "RESPONSE_TRUE",
    RESPONSE_TRUE_CURRENT = "RESPONSE_TRUE_CURRENT",
    RESPONSE_FALSE = "RESPONSE_FALSE",
    RESPONSE_FALSE_CURRENT = "RESPONSE_FALSE_CURRENT",
    RESPONSE_SWITCH_CORRECTION = "RESPONSE_SWITCH_CORRECTION",
    RESPONSE_DISPLAY = "RESPONSE_DISPLAY",
    RESPONSE_RAZ = "RESPONSE_RAZ",
    RESPONSE_MISSED = "RESPONSE_MISSED",
    RESPONSE_MISSED_INC_SERIAL = "RESPONSE_MISSED_INC_SERIAL",
    RESPONSE_MISSED_DEC = "RESPONSE_MISSED_DEC",
    RESPONSE_MISSED_DEC_SERIAL = "RESPONSE_MISSED_DEC_SERIAL",
    RESPONSE_SERIAL = "RESPONSE_SERIAL",
    RESPONSE_SERIAL_DEC = "RESPONSE_SERIAL_DEC";

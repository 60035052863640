import {
    faSquare,
    faCircle,
    faStar,
    faHeart,
    faComment,
    faFaceSmile,
    faBell,
    faLemon,
    faSun,
    faFlag,
    faMoon,
    faCompass,
    faHandshake,
    faCopy,
    faFileWord
} from '@fortawesome/free-regular-svg-icons'

export const memoryData = [
    [{name: 'eye', custom: ''}, {name: 'eye', custom: 'fh'}, {name: 'eye', custom: 'fv'}, {name: 'eye', custom: 'r180'}],
    [{name: 'eye-slash', custom: ''}, {name: 'eye-slash', custom: 'fh'}, {name: 'eye-slash', custom: 'fv'}, {name: 'eye-slash', custom: 'r180'}],
    [{name: 'eye-low-vision', custom: ''}, {name: 'eye-low-vision', custom: 'fh'}, {name: 'eye-low-vision', custom: 'fv'}, {name: 'eye-low-vision', custom: 'r180'}],
    [{name: 'magnifying-glass', custom: ''}, {name: 'magnifying-glass', custom: 'fh'}, {name: 'magnifying-glass', custom: 'fv'}, {name: 'magnifying-glass', custom: 'r180'}],
    [{name: 'magnifying-glass-plus', custom: ''}, {name: 'magnifying-glass-minus', custom: ''}, {name: 'magnifying-glass-location', custom: ''}, {name: 'magnifying-glass-chart', custom: ''}],
    [{name: 'magnifying-glass-plus', custom: ''}, {name: 'magnifying-glass-plus', custom: 'fh'}, {name: 'magnifying-glass-plus', custom: 'fv'}, {name: 'magnifying-glass-plus', custom: 'r180'}],
    [{name: 'magnifying-glass-minus', custom: ''}, {name: 'magnifying-glass-minus', custom: 'fh'}, {name: 'magnifying-glass-minus', custom: 'fv'}, {name: 'magnifying-glass-minus', custom: 'r180'}],
    [{name: 'magnifying-glass-location', custom: ''}, {name: 'magnifying-glass-location', custom: 'fh'}, {name: 'magnifying-glass-location', custom: 'fv'}, {name: 'magnifying-glass-location', custom: 'r180'}],
    [{name: 'magnifying-glass-chart', custom: ''}, {name: 'magnifying-glass-chart', custom: 'fh'}, {name: 'magnifying-glass-chart', custom: 'fv'}, {name: 'magnifying-glass-chart', custom: 'r180'}],
    [{name: 'magnifying-glass-arrow-right', custom: ''}, {name: 'magnifying-glass-arrow-right', custom: 'fh'}, {name: 'magnifying-glass-arrow-right', custom: 'fv'}, {name: 'magnifying-glass-arrow-right', custom: 'r180'}],
    [{name: 'image', custom: ''}, {name: 'image', custom: 'fh'}, {name: 'image', custom: 'fv'}, {name: 'image', custom: 'r180'}],
    [{name: 'phone', custom: ''}, {name: 'phone', custom: 'fh'}, {name: 'phone', custom: 'fv'}, {name: 'phone', custom: 'r180'}],
    [{name: 'music', custom: ''}, {name: 'music', custom: 'fh'}, {name: 'music', custom: 'fv'}, {name: 'music', custom: 'r180'}],
    [{name: 'wand-magic-sparkles', custom: ''}, {name: 'wand-magic-sparkles', custom: 'fh'}, {name: 'wand-magic-sparkles', custom: 'fv'}, {name: 'wand-magic-sparkles', custom: 'r180'}],
    [{name: 'bomb', custom: ''}, {name: 'bomb', custom: 'fh'}, {name: 'bomb', custom: 'fv'}, {name: 'bomb', custom: 'r180'}],
    [{name: 'camera-retro', custom: ''}, {name: 'camera-retro', custom: 'fh'}, {name: 'camera-retro', custom: 'fv'}, {name: 'camera-retro', custom: 'r180'}],
    [{name: 'file', custom: ''}, {name: 'file', custom: 'fh'}, {name: 'file', custom: 'fv'}, {name: 'file', custom: 'r180'}],
    [{name: 'mug-hot', custom: ''}, {name: 'mug-hot', custom: 'fh'}, {name: 'mug-hot', custom: 'fv'}, {name: 'mug-hot', custom: 'r180'}],
    [{name: 'umbrella', custom: ''}, {name: 'umbrella', custom: 'fh'}, {name: 'umbrella', custom: 'fv'}, {name: 'umbrella', custom: 'r180'}],
    [{name: 'hand', custom: ''}, {name: 'hand', custom: 'fh'}, {name: 'hand', custom: 'fv'}, {name: 'hand', custom: 'r180'}],
    [{name: 'thumbs-up', custom: ''}, {name: 'thumbs-up', custom: 'fh'}, {name: 'thumbs-up', custom: 'fv'}, {name: 'thumbs-up', custom: 'r180'}],
    [{name: 'key', custom: ''}, {name: 'key', custom: 'fh'}, {name: 'key', custom: 'fv'}, {name: 'key', custom: 'r180'}],
    [{name: 'bicycle', custom: ''}, {name: 'bicycle', custom: 'fh'}, {name: 'bicycle', custom: 'fv'}, {name: 'bicycle', custom: 'r180'}],
    [{name: 'feather', custom: ''}, {name: 'feather', custom: 'fh'}, {name: 'feather', custom: 'fv'}, {name: 'feather', custom: 'r180'}],
    [{name: 'mug-saucer', custom: ''}, {name: 'mug-saucer', custom: 'fh'}, {name: 'mug-saucer', custom: 'fv'}, {name: 'mug-saucer', custom: 'r180'}],
    [{name: 'stethoscope', custom: ''}, {name: 'stethoscope', custom: 'fh'}, {name: 'stethoscope', custom: 'fv'}, {name: 'stethoscope', custom: 'r180'}],
    [{name: 'mountain-sun', custom: ''}, {name: 'mountain-sun', custom: 'fh'}, {name: 'mountain-sun', custom: 'fv'}, {name: 'mountain-sun', custom: 'r180'}],
    [{name: 'copy', custom: ''}, {name: 'copy', custom: 'fh'}, {name: 'copy', custom: 'fv'}, {name: 'copy', custom: 'r180'}],
    [{name: 'hand-point-up', custom: ''}, {name: 'hand-point-up', custom: 'fh'}, {name: 'hand-point-up', custom: 'fv'}, {name: 'hand-point-up', custom: 'r180'}],
    [{name: 'flag', custom: ''}, {name: 'flag', custom: 'fh'}, {name: 'flag', custom: 'fv'}, {name: 'flag', custom: 'r180'}],
    [{name: 'rocket', custom: ''}, {name: 'rocket', custom: 'fh'}, {name: 'rocket', custom: 'fv'}, {name: 'rocket', custom: 'r180'}],
    [{name: 'moon', custom: ''}, {name: 'moon', custom: 'fh'}, {name: 'moon', custom: 'fv'}, {name: 'moon', custom: 'r180'}],
    [{name: 'hammer', custom: ''}, {name: 'hammer', custom: 'fh'}, {name: 'hammer', custom: 'fv'}, {name: 'hammer', custom: 'r180'}],
    [{name: 'cube', custom: ''}, {name: 'cube', custom: 'fh'}, {name: 'cube', custom: 'fv'}, {name: 'cube', custom: 'r180'}],
    [{name: 'clone', custom: ''}, {name: 'clone', custom: 'fh'}, {name: 'clone', custom: 'fv'}, {name: 'clone', custom: 'r180'}],
    [{name: 'dice', custom: ''}, {name: 'dice', custom: 'fh'}, {name: 'dice', custom: 'fv'}, {name: 'dice', custom: 'r180'}],
    [{name: 'not-equal', custom: ''}, {name: 'not-equal', custom: 'fh'}, {name: 'equals', custom: 'fv'}, {name: 'bars', custom: ''}],
    [{name: 'otter', custom: ''}, {name: 'otter', custom: 'fh'}, {name: 'otter', custom: 'fv'}, {name: 'otter', custom: 'r180'}],
    [{name: 'table-tennis-paddle-ball', custom: ''}, {name: 'table-tennis-paddle-ball', custom: 'fh'}, {name: 'table-tennis-paddle-ball', custom: 'fv'}, {name: 'table-tennis-paddle-ball', custom: 'r180'}],
    [{name: 'shapes', custom: ''}, {name: 'shapes', custom: 'fh'}, {name: 'shapes', custom: 'fv'}, {name: 'shapes', custom: 'r180'}],
    [{name: 'pizza-slice', custom: ''}, {name: 'pizza-slice', custom: 'fh'}, {name: 'pizza-slice', custom: 'fv'}, {name: 'pizza-slice', custom: 'r180'}],
    [{name: 'house-chimney', custom: ''}, {name: 'house-chimney', custom: 'fh'}, {name: 'house-chimney', custom: 'fv'}, {name: 'house-chimney', custom: 'r180'}],
    [{name: 'hill-rockslide', custom: ''}, {name: 'hill-rockslide', custom: 'fh'}, {name: 'hill-rockslide', custom: 'fv'}, {name: 'hill-rockslide', custom: 'r180'}],
    [{name: 'guitar', custom: ''}, {name: 'guitar', custom: 'fh'}, {name: 'guitar', custom: 'fv'}, {name: 'guitar', custom: 'r180'}],
    [{name: 'football', custom: ''}, {name: 'football', custom: 'r90'}, {name: 'football', custom: 'r45'}, {name: 'football', custom: 'r135'}],
    [{name: 'carrot', custom: ''}, {name: 'carrot', custom: 'fh'}, {name: 'carrot', custom: 'fv'}, {name: 'carrot', custom: 'r180'}],
    [{name: 'person-arrow-up-from-line', custom: ''}, {name: 'person-arrow-up-from-line', custom: 'fh'}, {name: 'person-arrow-down-to-line', custom: ''}, {name: 'person-arrow-down-to-line', custom: 'fh'}],
    [{name: 'hill-rockslide', custom: ''}, {name: 'hill-rockslide', custom: 'fh'}, {name: 'hill-avalanche', custom: ''}, {name: 'hill-avalanche', custom: 'fh'}],
    [{name: 'head-side-mask', custom: ''}, {name: 'head-side-mask', custom: 'fh'}, {name: 'head-side-cough', custom: ''}, {name: 'head-side-cough', custom: 'fh'}],
    [{name: 'head-side-cough-slash', custom: ''}, {name: 'head-side-cough-slash', custom: 'fh'}, {name: 'head-side-cough', custom: ''}, {name: 'head-side-cough', custom: 'fh'}],
    [{name: 'hand', custom: ''}, {name: 'hand', custom: 'fh'}, {name: 'hand-dots', custom: ''}, {name: 'hand-dots', custom: 'fh'}],
    [{name: 'crop', custom: ''}, {name: 'crop', custom: 'fh'}, {name: 'crop-simple', custom: ''}, {name: 'crop-simple', custom: 'fh'}],
    [{name: 'cloud-sun-rain', custom: ''}, {name: 'cloud-sun-rain', custom: 'fh'}, {name: 'cloud-sun', custom: ''}, {name: 'cloud-sun', custom: 'fh'}],
    [{name: 'cloud-moon-rain', custom: ''}, {name: 'cloud-moon-rain', custom: 'fh'}, {name: 'cloud-moon', custom: ''}, {name: 'cloud-moon', custom: 'fh'}],
    [{name: 'circle-play', custom: ''}, {name: 'circle-play', custom: 'fh'}, {name: 'circle-right', custom: ''}, {name: 'circle-right', custom: 'fh'}],
    [{name: 'arrow-right', custom: ''}, {name: 'arrow-right', custom: 'r90'}, {name: 'arrow-right', custom: 'r180'}, {name: 'arrow-right', custom: 'r270'}],
    [{name: 'circle-right', custom: ''}, {name: 'circle-right', custom: 'r90'}, {name: 'circle-right', custom: 'r180'}, {name: 'circle-right', custom: 'r270'}],
    [{name: 'circle-play', custom: ''}, {name: 'circle-play', custom: 'r90'}, {name: 'circle-play', custom: 'r180'}, {name: 'circle-play', custom: 'r270'}],
    [{name: 'circle-half-stroke', custom: ''}, {name: 'circle-half-stroke', custom: 'r90'}, {name: 'circle-half-stroke', custom: 'r180'}, {name: 'circle-half-stroke', custom: 'r270'}],
    [{name: 'plane', custom: ''}, {name: 'plane', custom: 'r90'}, {name: 'plane', custom: 'r180'}, {name: 'plane', custom: 'r270'}],
    [{name: 'magnet', custom: ''}, {name: 'magnet', custom: 'r90'}, {name: 'magnet', custom: 'r180'}, {name: 'magnet', custom: 'r270'}],
    [{name: 'fish', custom: ''}, {name: 'fish', custom: 'r90'}, {name: 'fish', custom: 'r180'}, {name: 'fish', custom: 'r270'}],
    [{name: 'bug', custom: ''}, {name: 'bug', custom: 'r90'}, {name: 'bug', custom: 'r180'}, {name: 'bug', custom: 'r270'}],
    [{name: 'gauge', custom: ''}, {name: 'gauge', custom: 'r90'}, {name: 'gauge', custom: 'r180'}, {name: 'gauge', custom: 'r270'}],
    [{name: 'clock', custom: ''}, {name: 'clock', custom: 'r90'}, {name: 'clock', custom: 'r180'}, {name: 'clock', custom: 'r270'}],
    [{name: 'play', custom: ''}, {name: 'play', custom: 'r90'}, {name: 'play', custom: 'r180'}, {name: 'play', custom: 'r270'}],
    [{name: 'backward', custom: ''}, {name: 'backward', custom: 'r90'}, {name: 'backward', custom: 'r180'}, {name: 'backward', custom: 'r270'}],
    [{name: 'chevron-right', custom: ''}, {name: 'chevron-right', custom: 'r90'}, {name: 'chevron-right', custom: 'r180'}, {name: 'chevron-right', custom: 'r270'}],
    [{name: 'hourglass-start', custom: ''}, {name: 'hourglass-start', custom: 'r90'}, {name: 'hourglass-start', custom: 'r180'}, {name: 'hourglass-start', custom: 'r270'}],
    [{name: 'power-off', custom: ''}, {name: 'power-off', custom: 'r90'}, {name: 'power-off', custom: 'r180'}, {name: 'power-off', custom: 'r270'}],
    [{name: 'plug', custom: ''}, {name: 'plug', custom: 'r90'}, {name: 'plug', custom: 'r180'}, {name: 'plug', custom: 'r270'}],
    [{name: 'yin-yang', custom: ''}, {name: 'yin-yang', custom: 'r90'}, {name: 'yin-yang', custom: 'r180'}, {name: 'yin-yang', custom: 'r270'}],
    [{name: 'volume-xmark', custom: ''}, {name: 'volume-xmark', custom: 'r90'}, {name: 'volume-xmark', custom: 'r180'}, {name: 'volume-xmark', custom: 'r270'}],
    [{name: 'venus', custom: ''}, {name: 'venus', custom: 'r90'}, {name: 'venus', custom: 'r180'}, {name: 'venus', custom: 'r270'}],
    [{name: 'star-half-stroke', custom: ''}, {name: 'star-half-stroke', custom: 'r90'}, {name: 'star-half-stroke', custom: 'r180'}, {name: 'star-half-stroke', custom: 'r270'}],
    [{name: 'signs-post', custom: ''}, {name: 'signs-post', custom: 'r90'}, {name: 'signs-post', custom: 'r180'}, {name: 'signs-post', custom: 'r270'}],
    [{name: 'shoe-prints', custom: ''}, {name: 'shoe-prints', custom: 'r90'}, {name: 'shoe-prints', custom: 'r180'}, {name: 'shoe-prints', custom: 'r270'}],
    [{name: 'peace', custom: ''}, {name: 'peace', custom: 'r90'}, {name: 'peace', custom: 'r180'}, {name: 'peace', custom: 'r270'}],
    [{name: 'hand-point-right', custom: ''}, {name: 'hand-point-right', custom: 'r90'}, {name: 'hand-point-right', custom: 'r180'}, {name: 'hand-point-right', custom: 'r270'}],
    [{name: 'angle-up', custom: ''}, {name: 'angle-up', custom: 'r90'}, {name: 'angle-up', custom: 'r180'}, {name: 'angle-up', custom: 'r270'}],
    [{name: 'house', custom: ''}, {name: 'house-chimney-window', custom: ''}, {name: 'house-chimney', custom: ''}, {name: 'house-medical', custom: ''}],
    [{name: 'house-chimney-user', custom: ''}, {name: 'house-chimney-medical', custom: ''}, {name: 'house-chimney', custom: ''}, {name: 'house-chimney-window', custom: ''}],
    [{name: 'user', custom: ''}, {name: 'user-group', custom: ''}, {name: 'users', custom: ''}, {name: 'user-tie', custom: ''}],
    [{name: 'users-viewfinder', custom: ''}, {name: 'users-rectangle', custom: ''}, {name: 'users-line', custom: ''}, {name: 'users-between-lines', custom: ''}],
    [{name: 'user-nurse', custom: ''}, {name: 'user-doctor', custom: ''}, {name: 'user-graduate', custom: ''}, {name: 'user', custom: ''}],
    [{name: 'person', custom: ''}, {name: 'person-walking', custom: ''}, {name: 'person-walking-with-cane', custom: ''}, {name: 'person-walking-luggage', custom: ''}],
    [{name: 'person-skiing-nordic', custom: ''}, {name: 'person-skiing', custom: ''}, {name: 'person-skating', custom: ''}, {name: 'person-running', custom: ''}],
    [{name: 'image', custom: ''}, {name: 'images', custom: ''}, {name: 'file-image', custom: ''}, {name: 'photo-film', custom: ''}],
    [{name: 'pager', custom: ''}, {name: 'mobile-screen-button', custom: ''}, {name: 'mobile-button', custom: ''}, {name: 'mobile-retro', custom: ''}],
    [{name: 'microphone', custom: ''}, {name: 'microphone-slash', custom: ''}, {name: 'microphone-lines', custom: ''}, {name: 'microphone-lines-slash', custom: ''}],
    [{name: 'person-swimming', custom: ''}, {name: 'person-swimming', custom: 'fh'}, {name: 'person-drowning', custom: ''}, {name: 'person-dots-from-line', custom: ''}],
    [{name: 'phone-volume', custom: ''}, {name: 'phone-slash', custom: ''}, {name: 'phone', custom: ''}, {name: 'phone', custom: 'fh'}],
    [{name: 'ruler', custom: ''}, {name: 'ruler', custom: 'fh'}, {name: 'ruler-vertical', custom: ''}, {name: 'ruler-vertical', custom: 'r90'}],
    [{name: 'faucet-drip', custom: ''}, {name: 'faucet-drip', custom: 'fh'}, {name: 'faucet', custom: ''}, {name: 'faucet', custom: 'fh'}],
    [{name: 'temperature-arrow-up', custom: ''}, {name: 'temperature-arrow-up', custom: 'fh'}, {name: 'temperature-arrow-down', custom: ''}, {name: 'temperature-arrow-down', custom: 'fh'}],
    [{name: 'horse-head', custom: ''}, {name: 'horse-head', custom: 'fh'}, {name: 'horse', custom: ''}, {name: 'horse', custom: 'fh'}],
    [{name: 'circle-plus', custom: ''}, {name: 'circle-minus', custom: ''}, {name: 'circle-check', custom: ''}, {name: 'circle-xmark', custom: ''}],
    [{name: 'circle-chevron-right', custom: ''}, {name: 'circle-play', custom: ''}, {name: 'circle-pause', custom: ''}, {name: 'circle-stop', custom: ''}],
    [{name: 'school-circle-xmark', custom: ''}, {name: 'school-circle-exclamation', custom: ''}, {name: 'school', custom: ''}, {name: 'school-circle-check', custom: ''}],
    [{name: 'plug-circle-xmark', custom: ''}, {name: 'plug-circle-plus', custom: ''}, {name: 'plug-circle-minus', custom: ''}, {name: 'plug-circle-check', custom: ''}],
    [{name: 'anchor', custom: ''}, {name: 'anchor-circle-xmark', custom: ''}, {name: 'anchor-circle-check', custom: ''}, {name: 'anchor-circle-exclamation', custom: ''}],
    [{name: 'camera', custom: ''}, {name: 'camera-retro', custom: ''}, {name: 'camera-retro', custom: 'fh'}, {name: 'camera-rotate', custom: ''}],
    [{name: 'location-dot', custom: ''}, {name: 'location-pin', custom: ''}, {name: 'map-location-dot', custom: ''}, {name: 'map-location', custom: ''}],
    [{name: 'chart-simple', custom: ''}, {name: 'square-poll-vertical', custom: ''}, {name: 'square-poll-horizontal', custom: ''}, {name: 'magnifying-glass-chart', custom: ''}],
    [{name: 'landmark', custom: ''}, {name: 'landmark-flag', custom: ''}, {name: 'landmark-flag', custom: 'fh'}, {name: 'landmark-dome', custom: ''}],
    [{name: 'circle-notch', custom: ''}, {name: 'bullseye', custom: ''}, {name: 'crosshairs', custom: 'fh'}, {name: 'circle-dot', custom: ''}],
    [{name: 'cloud', custom: ''}, {name: 'cloud-showers-heavy', custom: ''}, {name: 'cloud-rain', custom: ''}, {name: 'cloud-showers-water', custom: ''}],
    [{name: 'comment', custom: ''}, {name: 'comments', custom: ''}, {name: 'comment-slash', custom: ''}, {name: 'comment-dots', custom: ''}],
    [{name: 'truck', custom: ''}, {name: 'truck-fast', custom: ''}, {name: 'truck-medical', custom: ''}, {name: 'truck-droplet', custom: ''}],
    [{name: 'truck-monster', custom: ''}, {name: 'truck-pickup', custom: ''}, {name: 'car-side', custom: ''}, {name: 'van-shuttle', custom: ''}],
    [{name: 'car', custom: ''}, {name: 'taxi', custom: ''}, {name: 'car-rear', custom: ''}, {name: 'bus-simple', custom: ''}],
    [{name: 'gauge-simple', custom: ''}, {name: 'gauge-simple', custom: 'r90'}, {name: 'gauge', custom: ''}, {name: 'gauge', custom: 'r90'}],
    [{name: 'maximize', custom: ''}, {name: 'maximize', custom: 'r45'}, {name: 'minimize', custom: ''}, {name: 'minimize', custom: 'r45'}],
    [{name: 'arrows-to-dot', custom: ''}, {name: 'arrows-to-circle', custom: ''}, {name: 'minimize', custom: ''}, {name: 'minimize', custom: 'r45'}],
    [{name: 'shield-heart', custom: ''}, {name: 'shield-dog', custom: ''}, {name: 'shield-virus', custom: ''}, {name: 'shield-cat', custom: ''}],
    [{name: 'shield', custom: ''}, {name: 'shield', custom: 'r180'}, {name: 'shield-halved', custom: ''}, {name: 'shield-halved', custom: 'fh'}],
    [{name: 'face-smile', custom: ''}, {name: 'face-smile-wink', custom: ''}, {name: 'face-meh', custom: ''}, {name: 'face-smile-beam', custom: ''}],
    [{name: 'face-laugh-beam', custom: ''}, {name: 'face-smile-wink', custom: ''}, {name: 'face-laugh-wink', custom: ''}, {name: 'face-smile-beam', custom: ''}],
    [{name: 'face-laugh-beam', custom: ''}, {name: 'face-laugh-squint', custom: ''}, {name: 'face-laugh-wink', custom: ''}, {name: 'face-laugh', custom: ''}],
    [{name: 'face-grin-wide', custom: ''}, {name: 'face-grin-stars', custom: ''}, {name: 'face-grin-beam-sweat', custom: ''}, {name: 'face-grin-beam', custom: ''}],
    [{name: 'face-grin-tongue', custom: ''}, {name: 'face-grin-tongue-wink', custom: ''}, {name: 'face-grin-tongue-squint', custom: ''}, {name: 'face-grin-squint', custom: ''}],
    [{name: 'face-grin-beam', custom: ''}, {name: 'face-grin-hearts', custom: ''}, {name: 'face-grin-stars', custom: ''}, {name: 'face-grin-squint', custom: ''}],
    [{name: 'face-grin-tears', custom: ''}, {name: 'face-grin-squint-tears', custom: ''}, {name: 'face-grin-squint-tears', custom: 'fh'}, {name: 'face-grin-tears', custom: 'r45'}],
    [{name: 'face-kiss', custom: ''}, {name: 'face-kiss-beam', custom: ''}, {name: 'face-kiss-wink-heart', custom: ''}, {name: 'face-kiss-wink-heart', custom: 'fh'}],
    [{name: 'face-angry', custom: ''}, {name: 'face-frown', custom: ''}, {name: 'face-frown-open', custom: ''}, {name: 'face-meh', custom: ''}],
    [{name: 'dice-two', custom: ''}, {name: 'dice-two', custom: 'fh'}, {name: 'dice-three', custom: ''}, {name: 'dice-three', custom: 'fh'}],
    [{name: 'dice-one', custom: ''}, {name: 'dice-four', custom: ''}, {name: 'dice-five', custom: ''}, {name: 'dice-six', custom: ''}],
    [{name: 'table', custom: ''}, {name: 'table-columns', custom: ''}, {name: 'table-list', custom: ''}, {name: 'table-cells', custom: ''}],
    [{name: 'diagram-successor', custom: ''}, {name: 'diagram-successor', custom: 'fh'}, {name: 'diagram-predecessor', custom: ''}, {name: 'diagram-predecessor', custom: 'fh'}],
    [{name: 'scale-balanced', custom: ''}, {name: 'scale-unbalanced', custom: ''}, {name: 'scale-unbalanced-flip', custom: ''}, {name: 'scale-balanced', custom: 'r45'}],
    [{name: 'mug-hot', custom: ''}, {name: 'mug-hot', custom: 'fh'}, {name: 'mug-saucer', custom: ''}, {name: 'mug-saucer', custom: 'fh'}],
    [{name: 'bag-shopping', custom: ''}, {name: 'briefcase', custom: ''}, {name: 'suitcase-rolling', custom: ''}, {name: 'suitcase', custom: ''}],
    [{name: 'calendar', custom: ''}, {name: 'calendar-week', custom: ''}, {name: 'calendar-minus', custom: ''}, {name: 'calendar-days', custom: ''}],
    [{name: 'chart-gantt', custom: ''}, {name: 'chart-column', custom: ''}, {name: 'chart-bar', custom: ''}, {name: 'chart-line', custom: ''}],
    [{name: 'umbrella', custom: ''}, {name: 'umbrella', custom: 'fh'}, {name: 'umbrella-beach', custom: ''}, {name: 'umbrella-beach', custom: 'fh'}],
    [{name: 'hands-holding', custom: ''}, {name: 'hands-holding-child', custom: ''}, {name: 'hands-holding-circle', custom: ''}, {name: 'hand-holding-hand', custom: ''}],
    [{name: 'bars', custom: ''}, {name: 'bars-staggered', custom: ''}, {name: 'list', custom: ''}, {name: 'list-ul', custom: ''}],
    [{name: 'gear', custom: ''}, {name: 'gears', custom: ''}, {name: 'user-gear', custom: ''}, {name: 'users-gear', custom: ''}],
    [{name: 'earth-americas', custom: ''}, {name: 'earth-oceania', custom: ''}, {name: 'earth-africa', custom: ''}, {name: 'earth-asia', custom: ''}],
    [{name: 'martini-glass', custom: ''}, {name: 'martini-glass-empty', custom: ''}, {name: 'martini-glass-citrus', custom: ''}, {name: 'martini-glass-citrus', custom: 'fh'}],
    [{name: 'hourglass-end', custom: ''}, {name: 'hourglass-start', custom: ''}, {name: 'hourglass-half', custom: ''}, {name: 'hourglass', custom: ''}],
    [{name: 'lock', custom: ''}, {name: 'lock-open', custom: ''}, {name: 'unlock', custom: ''}, {name: 'unlock-keyhole', custom: ''}],
    [{name: 'align-right', custom: ''}, {name: 'align-center', custom: ''}, {name: 'align-justify', custom: ''}, {name: 'align-left', custom: ''}],
    [{name: 'folder', custom: ''}, {name: 'folder-open', custom: ''}, {name: 'folder-minus', custom: ''}, {name: 'folder-closed', custom: ''}],
    [{name: 'plane-arrival', custom: ''}, {name: 'plane-arrival', custom: 'fh'}, {name: 'plane-departure', custom: ''}, {name: 'plane-departure', custom: 'fh'}],
    [{name: 'pepper-hot', custom: ''}, {name: 'pepper-hot', custom: 'fh'}, {name: 'carrot', custom: ''}, {name: 'carrot', custom: 'fh'}],
    [{name: 'money-bill', custom: ''}, {name: 'money-bill-wave', custom: ''}, {name: 'money-bill-1-wave', custom: ''}, {name: 'money-bill-1', custom: ''}],
    [{name: 'virus', custom: ''}, {name: 'viruses', custom: ''}, {name: 'disease', custom: ''}, {name: 'gear', custom: ''}],
    [{name: 'virus', custom: ''}, {name: 'virus-slash', custom: ''}, {name: 'virus-covid', custom: ''}, {name: 'virus-covid-slash', custom: ''}],
    [{name: 'square', custom: ''}, {name: 'circle', custom: ''}, {name: 'play', custom: 'r270'}, {name: 'square', custom: 'r45'}],
    [{name: 'flag', custom: ''}, {name: 'flag-checkered', custom: ''}, {name: 'flag-usa', custom: ''}, {name: 'font-awesome', custom: ''}],
    [{name: 'flag', custom: ''}, {name: 'flag', custom: 'fh'}, {name: 'font-awesome', custom: ''}, {name: 'font-awesome', custom: 'fh'}],
    [{name: 'motorcycle', custom: ''}, {name: 'motorcycle', custom: 'fh'}, {name: 'bicycle', custom: ''}, {name: 'bicycle', custom: 'fh'}],
    [{name: 'bowling-ball', custom: ''}, {name: 'volleyball', custom: ''}, {name: 'basketball', custom: ''}, {name: 'baseball', custom: ''}],
    [{name: 'battery-empty', custom: ''}, {name: 'battery-full', custom: ''}, {name: 'battery-quarter', custom: ''}, {name: 'battery-half', custom: ''}],
    [{name: 'bullseye', custom: ''}, {name: 'bowling-ball', custom: ''}, {name: 'compact-disc', custom: ''}, {name: 'record-vinyl', custom: ''}],
    [{name: 'stroopwafel', custom: ''}, {name: 'cookie', custom: ''}, {name: 'cookie-bite', custom: ''}, {name: 'bowling-ball', custom: ''}],
    [{name: 'wheelchair', custom: ''}, {name: 'wheelchair', custom: 'fh'}, {name: 'wheelchair-move', custom: ''}, {name: 'wheelchair-move', custom: 'fh'}],
    [{name: 'wand-magic', custom: ''}, {name: 'wand-magic', custom: 'fh'}, {name: 'wand-sparkles', custom: ''}, {name: 'wand-sparkles', custom: 'fh'}],
    [{name: 'volume-off', custom: ''}, {name: 'volume-off', custom: 'fh'}, {name: 'volume-xmark', custom: ''}, {name: 'volume-xmark', custom: 'fh'}],
    [{name: 'volume-high', custom: ''}, {name: 'volume-high', custom: 'fh'}, {name: 'volume-low', custom: ''}, {name: 'volume-low', custom: 'fh'}],
    [{name: 'venus', custom: ''}, {name: 'venus', custom: 'fv'}, {name: 'venus-double', custom: ''}, {name: 'venus-double', custom: 'fv'}],
    [{name: 'vest', custom: ''}, {name: 'vest', custom: 'r90'}, {name: 'vest-patches', custom: ''}, {name: 'vest-patches', custom: 'r90'}],
    [{name: 'border-all', custom: ''}, {name: 'border-none', custom: ''}, {name: 'border-top-left', custom: ''}, {name: 'border-top-left', custom: 'fh'}],
    [{name: 'arrow-up-wide-short', custom: ''}, {name: 'arrow-up-wide-short', custom: 'fh'}, {name: 'arrow-up-short-wide', custom: ''}, {name: 'arrow-up-short-wide', custom: 'fh'}],
    [{name: 'arrow-down-short-wide', custom: ''}, {name: 'arrow-down-short-wide', custom: 'fh'}, {name: 'arrow-down-wide-short', custom: ''}, {name: 'arrow-down-wide-short', custom: 'fh'}],
    [{name: 'tower-observation', custom: ''}, {name: 'vihara', custom: ''}, {name: 'monument', custom: ''}, {name: 'tower-cell', custom: ''}],
    [{name: 'tents', custom: ''}, {name: 'tents', custom: 'fh'}, {name: 'tent', custom: ''}, {name: 'tent', custom: 'fh'}],
    [{name: 'spray-can', custom: ''}, {name: 'spray-can', custom: 'fh'}, {name: 'spray-can-sparkles', custom: ''}, {name: 'spray-can-sparkles', custom: 'fh'}],
    [{name: 'mountain', custom: ''}, {name: 'volcano', custom: ''}, {name: 'mountain-sun', custom: ''}, {name: 'mountain-sun', custom: 'fh'}],
    [{name: 'pump-soap', custom: ''}, {name: 'pump-soap', custom: 'fh'}, {name: 'pump-medical', custom: ''}, {name: 'pump-medical', custom: 'fh'}],
    [{name: 'people-group', custom: ''}, {name: 'people-roof', custom: ''}, {name: 'people-line', custom: ''}, {name: 'arrows-down-to-people', custom: ''}],
    [{name: 'prescription-bottle', custom: ''}, {name: 'prescription-bottle-medical', custom: ''}, {name: 'trash', custom: ''}, {name: 'trash-arrow-up', custom: ''}],
    [{name: 'plus-minus', custom: ''}, {name: 'plus-minus', custom: 'r180'}, {name: 'plus', custom: ''}, {name: 'minus', custom: ''}],
    [{name: 'grip-lines', custom: ''}, {name: 'grip-lines', custom: 'r45'}, {name: 'grip-lines-vertical', custom: ''}, {name: 'grip-lines', custom: 'r135'}],
    [{name: 'bacterium', custom: ''}, {name: 'bacteria', custom: ''}, {name: 'virus-covid', custom: ''}, {name: 'virus', custom: ''}],
    [{name: 'fire', custom: ''}, {name: 'fire-flame-simple', custom: ''}, {name: 'fire-flame-curved', custom: ''}, {name: 'fire-flame-curved', custom: 'fh'}],
    [{name: 'wine-glass', custom: ''}, {name: 'champagne-glasses', custom: ''}, {name: 'wine-glass-empty', custom: ''}, {name: 'capsules', custom: ''}],
    [{name: 'bowl-rice', custom: ''}, {name: 'bowl-food', custom: ''}, {name: 'burger', custom: ''}, {name: 'plate-wheat', custom: ''}],
    [{name: 'asterisk', custom: ''}, {name: 'star-of-life', custom: ''}, {name: 'plus', custom: ''}, {name: 'xmark', custom: ''}],
    [{name: 'splotch', custom: ''}, {name: 'star', custom: ''}, {name: 'certificate', custom: ''}, {name: 'gear', custom: ''}],
    [{name: 'star', custom: ''}, {name: 'star-half', custom: 'fh'}, {name: 'star-half', custom: ''}, {name: 'star-half-stroke', custom: ''}],
    [{name: 'person-walking-with-cane', custom: ''}, {name: 'person-walking-with-cane', custom: 'fh'}, {name: 'person-walking-luggage', custom: ''}, {name: 'person-walking-luggage', custom: 'fh'}],
    [{name: 'person-falling', custom: ''}, {name: 'person-falling', custom: 'fh'}, {name: 'person-walking', custom: ''}, {name: 'person-walking', custom: 'fh'}],
    [{name: 'user-clock', custom: ''}, {name: 'user-clock', custom: 'fh'}, {name: 'user-gear', custom: ''}, {name: 'user-gear', custom: 'fh'}],
    [{name: 'user-xmark', custom: ''}, {name: 'user-xmark', custom: 'fh'}, {name: 'user-plus', custom: ''}, {name: 'user-plus', custom: 'fh'}],
    [{name: 'house-crack', custom: ''}, {name: 'house-chimney-crack', custom: ''}, {name: 'house-chimney-crack', custom: 'fh'}, {name: 'house-chimney', custom: ''}],
    [{name: faCompass, custom: ''}, {name: faCompass, custom: 'fh'}, {name: 'compass', custom: 'fh'}, {name: 'compass', custom: ''}],
    [{name: faHandshake, custom: ''}, {name: faHandshake, custom: 'fv'}, {name: 'handshake', custom: 'fv'}, {name: 'handshake', custom: ''}],
    [{name: faCopy, custom: ''}, {name: faCopy, custom: 'fv'}, {name: 'copy', custom: 'fv'}, {name: 'copy', custom: ''}],
    [{name: faFileWord, custom: ''}, {name: faFileWord, custom: 'fh'}, {name: 'file-word', custom: 'fh'}, {name: 'file-word', custom: ''}],
    [{name: 'jet-fighter', custom: ''}, {name: 'jet-fighter', custom: 'r90'}, {name: 'jet-fighter', custom: 'r180'}, {name: 'jet-fighter', custom: 'r270'}],
    [{name: 'list', custom: ''}, {name: 'list-check', custom: ''}, {name: 'list-ul', custom: ''}, {name: 'list-ol', custom: ''}],
]

export const memoryDataGroup = [
    ["square", faSquare, "circle", faCircle, "plus", "star", faStar, "heart", faHeart, "cloud", "play", "diamond",
        "certificate", "location-pin", "crown", "comment", faComment, "face-smile", faFaceSmile, "bell", faBell,
        "lemon", faLemon, "sun", faSun, "flag", faFlag, "moon", faMoon
    ],
    ["hippo", "otter", "dog", "cow", "fish", "dragon", "spider", "mosquito", "horse", "frog", "crow", "cat"]
]

const cleanArray = (array) => {
    const newArray = new Set(array.map(JSON.stringify))
    return Array.from(newArray, JSON.parse);
}

export const newMemoryData = cleanArray([...memoryData].flat())

import {useSelector} from "react-redux";

//import selectors
import {responseSelector} from "../../../reducers/selectors";

//import components
import Responses from "./Responses";

const SerialResponses = ({location}) => {
    const {trueResponses, falseResponses, missedResponse, serial} = useSelector(responseSelector)

    return (
        <Responses
            title={`Série : ${serial}`}
            location={location}
            trueResponses={trueResponses.serial}
            falseResponses={falseResponses.serial}
            missedResponse={missedResponse.serial}
        />
    )
}

export default SerialResponses

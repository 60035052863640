import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

//import constants
import {generateRandomNumber, getRandomNumber} from "../../../constants/getRandomNumber";
import {getRandomLetter, getRandomLetters} from "../../../constants/getRandomLetter";
import {getStyle, setIdParameter} from "../../../constants/localStorage";
import {memoryDataGroup} from "../../memory/series";

export const jerkDisplay = [
    horizontale,
    verticale,
    slash,
    backSlash,
    left,
    right,
    all,
]

function horizontale(setDisplay, setStart, launch) {
    const {difficulty} = launch
    const box = {easy: [65, 69], medium: [63, 71], difficult: [60, 74]}
    return display(setDisplay, setStart, launch, getPairs(box[difficulty]))
}

function verticale(setDisplay, setStart, launch) {
    const {difficulty} = launch
    const box = {easy: [52, 82], medium: [37, 97], difficult: [7, 127]}
    return display(setDisplay, setStart, launch, getPairs(box[difficulty]))
}

function slash(setDisplay, setStart, launch) {
    const {difficulty} = launch
    const box = {easy: [53, 81], medium: [26, 93], difficult: [14, 105]}
    return display(setDisplay, setStart, launch, getPairs(box[difficulty]))
}

function backSlash(setDisplay, setStart, launch) {
    const {difficulty} = launch
    const box = {easy: [51, 83], medium: [18, 101], difficult: [0, 119]}
    return display(setDisplay, setStart, launch, getPairs(box[difficulty]))
}

function all(setDisplay, setStart, launch) {
    const randomNumber = generateRandomNumber(0, 6)
    jerkDisplay[randomNumber](setDisplay, setStart, launch)
}

function left(setDisplay, setStart, launch) {
    const {difficulty} = launch
    const box = {easy: [45, 75], medium: [30, 90], difficult: [0, 105]}
    return display(setDisplay, setStart, launch, getPairs(box[difficulty]))
}

function right (setDisplay, setStart, launch) {
    const {difficulty} = launch
    const box = {easy: [59, 89], medium: [44, 104], difficult: [14, 119]}
    return display(setDisplay, setStart, launch, getPairs(box[difficulty]))
}

const getPairs = (box) => {
    const randomNumber = generateRandomNumber(0, box.length)
    const first = box[randomNumber]
    const last = box.filter((item) => item !== first)[0]
    return {first: first, last: last}
}

const getRandomIcons = (icons, length) => {
    return Array(length).fill("").map(() => {
        const icon = icons[generateRandomNumber(0, icons.length)]
        return <FontAwesomeIcon icon={icon}/>
    })
}

const display = (setDisplay, setStart, launch, pair) => {
    const {type, time, jerk, stopwatch} = launch
    const number = parseInt(launch.number) || 1
    const characterToDisplay = []

    for (let i = 0; i < jerk; i++) {
        switch (type) {
            case "0":
                characterToDisplay.push(getRandomNumber(number))
                break

            case "1":
                characterToDisplay.push(getRandomLetters(number))
                break

            case "3":
                const icons = [...memoryDataGroup].flat()
                characterToDisplay.push(getRandomIcons(icons, number))
                break
        }
    }

    (jerk === 1 || stopwatch) && setDisplay(firstDisplay())

    if (jerk === 2) {
        !stopwatch ?
            setTimeout(() => setDisplay(thirdDisplay([pair.first, pair.last], characterToDisplay), 2000))
        :
            displayJerk(setDisplay, setStart, time, pair, characterToDisplay)
    } else {
        displayAntiJerk(setDisplay, setStart, time, pair, characterToDisplay)
    }
}

const displayJerk = (setDisplay, setStart, time, pair, characterToDisplay) => {
    setTimeout(() => setDisplay(thirdDisplay([pair.first, pair.last], characterToDisplay)), 2000)
    setTimeout(() => setDisplay(lastDisplay([pair.first, pair.last], characterToDisplay)), 2000 + time)
    setTimeout(() => setStart(true), 2000 + time)
}

const displayAntiJerk = (setDisplay, setStart, time, pair, characterToDisplay) => {
    setTimeout(() => setDisplay(secondDisplay([pair.first])), 2000)
    setTimeout(() => setDisplay(thirdDisplay([pair.last], characterToDisplay)), 2300)
    setTimeout(() => setDisplay(lastDisplay([pair.last], characterToDisplay)), 2300 + time)
    setTimeout(() => setStart(true), 2100 + time)
}

const firstDisplay = () => generateToDisplay([67], [<FontAwesomeIcon className="point" icon="circle"/>])
const secondDisplay = (index) => generateToDisplay(index, [<FontAwesomeIcon className="star" icon="star-of-life"/>])
const thirdDisplay = (index, characterToDisplay) => generateToDisplay(index, characterToDisplay, '')
const lastDisplay = (index, characterToDisplay) => generateToDisplay(index, characterToDisplay, 'hide')

const generateToDisplay = (index, characterToDisplay, className) => {
    const arrayToDisplay = []
    let style = getStyle()

    if(characterToDisplay[0]?.props?.className === "point") {
        style = {...style, fontSize: `calc(${style.fontSize} / 2)`}
    }

    if(characterToDisplay[0]?.props?.className === "start") {
        style = {...style, fontSize: `calc(${style.fontSize} / 1.5)`}
    }

    if(characterToDisplay[0].type !== "div" && characterToDisplay[0].type !== undefined) style = {...style, padding: 0}

    for (let i = 0; i < 135; i++) {
        arrayToDisplay.push(<div className='grid' key={i}></div>)
    }

    const displayIcons = (characterToDisplay) => {
        return characterToDisplay.map((character, index) => (
             {
                 ...character,
                 key: {index},
                 props: {...character.props, style: {padding: `.3rem ${style.letterSpacing}`, fontSize: style.fontSize}}
             }
        ))
    }

    for (let i = 0; i < index.length; i++) {
        const element = characterToDisplay[i]

        arrayToDisplay.fill(
            <div className="grid" key={index[i]}>
                <div className={`content ${className}`}>
                    {Array.isArray(element) ?
                        displayIcons(characterToDisplay[i]) :
                        <span style={style}>{characterToDisplay[i]}</span>
                    }
                </div>
            </div>, index[i], index[i] + 1)
    }

    return arrayToDisplay
}

export const jerkDisplayStatic = (setDisplay, setStart, launch) => {
    const {type, time} = launch
    const style = getStyle()

    let inc = 0

    const getElement = () => {
        if (type === "0") return generateRandomNumber(0, 10)
        if (type === "1") return getRandomLetter()
        if (type === "3") {
            const icons = [...memoryDataGroup].flat()
            const icon = icons[generateRandomNumber(0, icons.length)]
            return <FontAwesomeIcon icon={icon}/>
        }
    }

    const index = []

    while (index.length < 10) {
        const tempIndex = generateRandomNumber(0, 135)
        index[index.length - 1] !== tempIndex && index.push(tempIndex)
    }

    const intervalId = setInterval(() => {
        const arrayToDisplay = Array(135).fill('').map((_, i) => (
            <div className='grid' key={i}></div>
        ));

        arrayToDisplay[index[inc]] = <div className='grid' key={index[inc]}><span style={style}>{getElement()}</span></div>

        setDisplay(arrayToDisplay)
        inc++;

        if (inc >= index.length) {
            clearInterval(intervalId);
            setDisplay()
        }
    }, time);

    setIdParameter(intervalId)
}

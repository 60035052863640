import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

//import constants
import {readingAppearanceDisplay, readingDisplay, readingTextDisplay} from "./ReadingDisplay";

//import components
import BottomNav from "../commun/BottomNav";
import BottomNavWithoutTime from "../commun/BottomNavWithoutTime";

//import actions
import {responseActions} from "../../actions/responseActions";

//import selector
import {responseSelector} from "../../reducers/selectors";

function ReadingGame({launch, setLaunch}) {
    const [display, setDisplay] = useState()
    const [start, setStart] = useState(false)

    const {trueResponses, serial} = useSelector(responseSelector)

    const dispatch = useDispatch()

    const {type, wordType, grid} = launch

    useEffect(() => {
        if (((type === "static" && wordType !== "3") || type === "movement") && !start) {
            if (serial !== 0 && trueResponses.current === 0) {
                responseActions.responseMissedIncSerial(dispatch)
            }

            responseActions.responseTrueCurrent(dispatch)
            responseActions.responseFalseCurrent(dispatch)
            setDisplay()
            setTimeout(() => readingDisplay(launch, setDisplay, setStart, dispatch), 1000)
        }
    }, [start])

    const getGameDisplay = () => {
        responseActions.displayResponse(dispatch, false)

        type === "appearance" ?
            readingAppearanceDisplay(launch, setDisplay, dispatch)
            :
            readingTextDisplay(launch, setDisplay)
    }

    return (
        <main className={!start && ((type === "static" && wordType !== "3") || type === "movement") ? "cursor-hide" : undefined}>
            <div className="reading-game">
                <div className={`reading-game-display ${type} wordType-${wordType} ${grid}`}>{display}</div>
                {start && ((type === "static" && wordType !== "3") || type === "movement") &&
                    <BottomNav
                        title="lecture"
                        start={start}
                        setStart={setStart}
                        setLaunch={setLaunch}
                        launch={launch}
                        setDisplay={setDisplay}
                    />
                }

                {((type === "static" && wordType === "3") || type === "appearance") &&
                    <BottomNavWithoutTime
                        title={"lecture"}
                        launch={launch}
                        setLaunch={setLaunch}
                        gameDisplay={getGameDisplay}
                        setDisplay={setDisplay}
                    />
                }
            </div>
        </main>
    )
}

export default ReadingGame

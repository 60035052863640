import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function UpgradeBlock({icon, text, setDisplay}) {
    return (
        <div className="upgrade-block" onClick={() => setDisplay(icon)}>
            <div className="upgrade-block-icon">
                <FontAwesomeIcon className="upgrade-icon" icon={icon}/>
            </div>

            <div className="upgrade-block-text">
                {text}
            </div>
        </div>
    )
}

export default UpgradeBlock
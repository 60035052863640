import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Form} from "react-final-form";
import {useSelector} from "react-redux";
import {Field} from "react-final-form";

//import components
import {validations} from "../commun/Form/validations";
import {CustomTooltip} from "../commun/customTooltip";

//import selectors
import {userSelector} from "../../reducers/selectors";

//import services
import {upgradeServices} from "../../services/upgradeServices";

function UpgradeText({setDisplay}) {
    const user = useSelector(userSelector)

    const submit = values => {
        upgradeServices.submit(user.data._id, values).then(() => setDisplay(""))
    }

    return (
        <Form
            onSubmit={submit}
            render={({handleSubmit, hasValidationErrors}) => (
                <form onSubmit={handleSubmit}>
                    <Field
                        name="text"
                        component="textarea"
                        className="upgrade-text"
                        placeholder="Vos remarques, vos idées, vos questions, ..."
                        validate={validations.required}
                    />

                    <div className="upgrade-third">
                        <CustomTooltip title="Annuler" onClick={() => setDisplay("")}>
                            <div className="profile-icon">
                                <FontAwesomeIcon className="icon" icon="xmark"/>
                            </div>
                        </CustomTooltip>

                        <CustomTooltip title="Envoyer">
                            <button disabled={hasValidationErrors} type="submit" className={hasValidationErrors ? "profile-icon profile-icon-error" : "profile-icon"}>
                                <FontAwesomeIcon className="icon" icon="check"/>
                            </button>
                        </CustomTooltip>
                    </div>
                </form>
            )}
        />
    )
}

export default UpgradeText
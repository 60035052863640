export const fieldsTime = [
    {value: 100, label: '100ms'},
    {value: 200, label: ''},
    {value: 250, label: ''},
    {value: 300, label: '300ms'},
    {value: 400, label: ''},
    {value: 500, label: '500ms'},
    {value: 600, label: ''},
    {value: 700, label: '700ms'},
    {value: 800, label: ''},
    {value: 900, label: '900ms'},
];

export const extrafieldsTime = [
    {value: 1000, label: ''},
    {value: 1100, label: '1100ms'},
    {value: 1200, label: ''},
    {value: 1300, label: '1300ms'},
    {value: 1400, label: ''},
    {value: 1500, label: '1500ms'},
];

import {Form} from "react-final-form";
import {useEffect, useState} from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {useDispatch} from "react-redux";

//import constants
import {antiJerkFormData, jerkFieldsType} from "../jerk/pattern/JerkRadioData";
import {antiJerkTitle} from "../../constants/titleText";
import {fieldsTime} from "../commun/Form/SliderData";

//import components
import {formMount} from "../commun/Form/formFunction";
import {CustomTextTooltip} from "../commun/customTooltip";
import FirstRadio from "../commun/Form/FirstRadio";
import CommonRadio from "../commun/Form/CommonRadio";
import Submit from "../commun/Form/Submit";
import SliderTimeField from "../commun/Form/SliderTimeField";
import {submitForm} from "../commun/Form/formFunction";

//import actions
import {responseActions} from "../../actions/responseActions";

function AntiJerkForm({setLaunch, initialValues, setInitialValues}) {
    const dispatch = useDispatch()

    const [step, setStep] = useState(0)

    const submit = values => submitForm(values, dispatch, setInitialValues, setLaunch)

    useEffect(() => {
        formMount(dispatch, initialValues, setStep)
        responseActions.raz(dispatch)
    }, [])

    return (
        <main className="antiJerk">
            <div className="title">
                <CustomTextTooltip title={antiJerkTitle}>
                    <div><FontAwesomeIcon icon="fa-circle-question"/></div>
                </CustomTextTooltip>
            </div>
            <div className="game-form antiJerk-form">
                <Form
                    onSubmit={submit}
                    initialValues={initialValues}
                    render={({handleSubmit, values, form}) => (
                        <form onSubmit={handleSubmit}>
                            <FirstRadio
                                step={step}
                                setStep={setStep}
                                fields={jerkFieldsType}
                                form={form}
                                setInitialValues={setInitialValues}
                            />

                            {antiJerkFormData.map((radio, index) => (
                                <CommonRadio key={index} index={index + 1} step={step} setStep={setStep} fields={radio}/>
                            ))}

                            {step > antiJerkFormData.length && <SliderTimeField initialValue={initialValues.time} marks={fieldsTime}/>}

                            <Submit condition={3 > step}/>
                        </form>
                    )}
                />
            </div>
        </main>
    )
}

export default AntiJerkForm

import {Form} from "react-final-form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useSelector} from "react-redux";

//import components
import {CustomTooltip} from "../commun/customTooltip";
import PasswordForm from "./PasswordForm";

//import services
import {userServices} from "../../services/userServices";

//import selectors
import {userSelector} from "../../reducers/selectors";
import {encrypt} from "../../constants/crypto";

const UpdatePassword = ({setForm}) => {
    const user = useSelector(userSelector)

    const submit = (values) => {
        const valuesToSubmit = {
            password: encrypt(values.password),
            confirmPassword: encrypt(values.confirmPassword)
        }

        userServices.updatePassword(user.data._id, valuesToSubmit).then(() => setForm(true))
    }

    return (
        <Form
            onSubmit={submit}
            render={({handleSubmit, hasValidationErrors}) => (
                <form onSubmit={handleSubmit}>
                    <PasswordForm/>

                    <div className="profile-icon-container">
                        <CustomTooltip title="Annuler" onClick={() => setForm(true)}>
                            <div className="profile-icon">
                                <FontAwesomeIcon className="icon" icon="xmark"/>
                            </div>
                        </CustomTooltip>
                        <CustomTooltip title={hasValidationErrors ? "Veuillez corriger les erreurs pour confirmer" : "Confirmer les modifications"}>
                            <button type="submit" disabled={hasValidationErrors} className={hasValidationErrors ? "profile-icon profile-icon-error" : "profile-icon"}>
                                <FontAwesomeIcon className="icon" icon="check"/>
                            </button>
                        </CustomTooltip>
                    </div>
                </form>
            )}
        />
    )
}

export default UpdatePassword
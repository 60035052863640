import {useSelector} from "react-redux";
import {Fragment, useState} from "react";

//Import components
import CreatePassword from "./CreatePassword";
import ConnectionForm from "./ConnectionForm";
import ForgotForm from "./ForgotForm";

//import selectors
import {userSelector} from "../../reducers/selectors";

function LoginForm({setForm}) {
    const user = useSelector(userSelector)

    const [forgot, setForgot] = useState(true)

    return (
        !Object.keys(user.data).length ?
                forgot ?
                    <Fragment>
                        <ConnectionForm forgot={forgot} setForgot={setForgot}/>
                        <div className="login-signup" onClick={() => setForm(false)}>Pas de compte ? <span>S'incrire !</span></div>
                    </Fragment>
                :
                    <Fragment>
                        <ForgotForm setForgot={setForgot}/>
                        <div className="login-form-forgot">
                            <div className="login-signup" onClick={() => setForgot(true)}>Je me souviens ! <span>Se connecter !</span></div>
                            <div className="login-signup" onClick={() => setForm(false)}>Pas de compte ? <span>S'incrire !</span></div>
                        </div>
                    </Fragment>
            :
            <CreatePassword/>
    )
}

export default LoginForm
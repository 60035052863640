import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useSelector} from "react-redux";

//import selectors
import {CustomTooltip} from "../customTooltip";

//import selectors
import {responseSelector} from "../../../reducers/selectors";

const Responses = ({title, trueResponses, falseResponses, missedResponse, location}) => {
    const {correction, display} = useSelector(responseSelector)

    const whiteList = ["mémoire", "lecture", "balayage", "comparaison"]

    return (
        <div className="navigation-left-top-current">
            <div className="navigation-left-subtitle">{title}</div>

            {((correction || whiteList.includes(location)) && display) &&
                <div className="navigation-left-top-container">
                    <div className="navigation-left-top-content">
                        <CustomTooltip title="Bonnes réponses">
                            <FontAwesomeIcon className="icon icon-true" icon="circle-check"/>
                        </CustomTooltip>
                        {trueResponses}
                    </div>

                    <div className="navigation-left-top-content">
                        <CustomTooltip title="Réponses fausses">
                            <FontAwesomeIcon className="icon icon-false" icon="circle-xmark"/>
                        </CustomTooltip>
                        {falseResponses}
                    </div>

                    <div className="navigation-left-top-content">
                        <CustomTooltip title="Réponses manquées / passées">
                            <FontAwesomeIcon className="icon" icon="circle-exclamation"/>
                        </CustomTooltip>
                        {missedResponse}
                    </div>
                </div>
            }
        </div>
    )
}

export default Responses

//import services
import {request} from "./request";

//import actions
import {userActions} from "../actions/userActions";
import {notifications} from "../components/commun/Notifications";

export const userServices = {
    find,
    edit,
    updatePassword,
    register,
}

function find(uuid, dispatch, navigate) {
    const successCallback = async response => {
        await userActions.find(dispatch, response.data)
        navigate && response.data.hasChangePassword && navigate("/empan")
        return response
    }

    return request(
        'GET',
        `/api/users/find/${uuid}`,
        successCallback
    )
}

function edit(uuid, values, dispatch) {
    const successCallback = async response => {
        await userServices.find(uuid, dispatch)
        return response
    }

    return request(
        'PUT',
        `/api/users/${uuid}`,
        successCallback,
        null,
        null,
        values
    )
}

function updatePassword(uuid, values) {
    const successCallback = response => {
        notifications.success('Votre mot de passe a été modifié !')
        return response
    }

    return request(
        'PUT',
        `/api/users/update-password/${uuid}`,
        successCallback,
        null,
        null,
        values
    )
}

function register(values) {
    const successCallback = response => {
        notifications.success('Un email vous a été envoyé !')
        return response
    }

    return request(
        'POST',
        `/api/auth/register`,
        successCallback,
        null,
        null,
        values
    )
}

import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {useLocation} from "react-router-dom";
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {isMobile, isShadow} from "../../constants/constants";

const ToastComponent = () => {
    const location = useLocation()
    const navigate = useNavigate()

    useEffect(() => {
        isMobile() && navigate("/mobile")
        isShadow()
    }, [location])

    return (
        <ToastContainer
            position="bottom-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
        />
    )
}

export default ToastComponent;

import {fieldsGroup} from "../comparison/ComparisonRadioData";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const fieldsTargetMemory = [
    {name: "target", value: "0", label: "Mire : dessin", display: <span className="radio-icon"><FontAwesomeIcon icon="icons"/></span>},
    {name: "target", value: "1", label: "Mire : chiffre", display: "0 ... 9"},
    {name: "target", value: "2", label: "Mire : lettre", display: "A ... Z"},
    {name: "target", value: "3", label: "Aléatoire", display: "ALÉATOIRE"},
]

export const fieldsTypeComparison = [
    {
        name: "typeComparison",
        value: "0",
        label: "Comparer par couleur",
        display: <span className="rText">
            <span style={{color: "var(--dark)"}}>C</span>
            <span style={{color: "#FFDB58"}}>O</span>
            <span style={{color: "#8A2BE2"}}>U</span>
            <span style={{color: "#FF6700"}}>L</span>
            <span style={{color: "#3D0C02"}}>E</span>
            <span style={{color: "#A9A9A9"}}>U</span>
            <span style={{color: "#32CD32"}}>R</span>
        </span>
    },
    {name: "typeComparison", value: "1", label: "Comparer par forme", display: "FORME"},
]

export const memoryFormData = [
    fieldsTargetMemory,
    fieldsGroup
]

import {useSelector} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useState} from "react";
import {MoonLoader} from "react-spinners";
import {useNavigate} from "react-router-dom";

//import selector
import {userSelector} from "../../reducers/selectors";

//import components
import SubscribeForm from "./SubscribeForm";

//import constants
import {encrypt} from "../../constants/crypto";

//import services
import {mollieServices} from "../../services/mollieServices";

function SubscribePageForm() {
    const user = useSelector(userSelector)

    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)

    const submit = async values => {
        setLoading(true)

        const valuesToSubmit = {
            [encrypt("customerId")]: encrypt(user.data.customer),
            [encrypt("iban")]: encrypt(values.iban),
        }

        return !user.data.hasPaid && mollieServices.createMandate(valuesToSubmit, setLoading).then(response => {
            setLoading(false)
            response && navigate("/profil?hasPaid=true")
        })
    }

    return (
        <main>
            <div className="upgrade">
                <div className="upgrade-first">
                    <FontAwesomeIcon className="icon" icon="fa-credit-card"/>
                    <div className="upgrade-first-text">
                        <p>Création d'un mandat pour un abonnement à Orthotraînement de <span>7 euros</span> par mois.</p>
                        <SubscribeForm submit={submit} loading={loading}/>
                    </div>
                </div>

                {loading && <MoonLoader className="subscribe-loader" color="#0D0D0D" size={30.5} speedMultiplier={.5}/>}
            </div>
        </main>
    )
}

export default SubscribePageForm

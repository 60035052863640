import {useState} from "react";
import {useDispatch} from "react-redux";

//import components
import {pursuitDisplay} from "./PursuitDisplay";
import BottomNavWithoutTime from "../commun/BottomNavWithoutTime";

//import actions
import {responseActions} from "../../actions/responseActions";

function ReadingGame({launch, setLaunch}) {
    const [display, setDisplay] = useState()

    const {orientation} = launch

    const dispatch = useDispatch()

    const getGameDisplay = () => {
        responseActions.displayResponse(dispatch, false)
        return pursuitDisplay(setDisplay, launch)
    }

    return (
        <main>
            <div className="pursuit-game">
                <div className="pursuit-display">
                    <div className={`blank-${orientation} blank-${orientation}-first`}></div>
                    {orientation === "3" && <div className={`blank-${orientation} blank-${orientation}-left`}></div>}
                        {display}
                    <div className={`blank-${orientation} blank-${orientation}-last`}></div>
                    {orientation === "3" && <div className={`blank-${orientation} blank-${orientation}-right`}></div>}
                </div>

                <BottomNavWithoutTime
                    title="poursuite"
                    launch={launch}
                    setLaunch={setLaunch}
                    gameDisplay={getGameDisplay}
                    setDisplay={setDisplay}
                />
            </div>
        </main>
    )
}

export default ReadingGame

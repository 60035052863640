export const smallReadingData = [
    ['bouche', 'louche', 'couche', 'douche'],
    ['banane', 'danone', 'nanane', 'paname'],
    ['salle', 'selle', 'malle', 'pelle'],
    ['balle', 'dalle', 'malle', 'pelle'],
    ['poule', 'boule', 'coule', 'foule'],
    ['poisson', 'poison', 'boisson', 'chanson'],
    ['amener', 'emmener', 'animer', 'arriver'],
    ['niche', 'biche', 'miche', 'quiche'],
    ['salade', 'tablier', 'table', 'saladier'],
    ['barbe', 'barde', 'tarte', 'darde'],
    ['terre', 'serre', 'pere', 'requerre'],
    ['tapis', 'tapas', 'tapos', 'pitas'],
    ['souris', 'sourir', 'sourie', 'soutis'],
    ['route', 'croute', 'roule', 'proute'],
    ['lampe', 'jambe', 'rampe', 'vampe'],
    ['île', 'pile', 'quille', 'bille'],
    ['pomme', 'somme', 'comme', 'tomme'],
    ['langue', 'lanque', 'tangue', 'tongue'],
    ['vache', 'bache', 'tache', 'lache'],
    ['lion', 'lyon', 'rion', 'pion'],
    ['soir', 'foire', 'voir', 'poire'],
    ['branche', 'planche', 'drenche', 'tranche'],
    ['sable', 'table', 'cable', 'fable'],
    ['mignon', 'pignon', 'chignon', 'quignon'],
    ['crayon', 'canyon', 'croyons', 'embryons'],
    ['presse', 'tresse', 'messe', 'princesse'],
    ['enfant', 'griffant', 'fanfan', 'bouffant'],
    ['canard', 'panard', 'magnard', 'coquard'],
    ['dard', 'pard', 'bard', 'fard'],
    ['eau', 'beau', 'peau', 'veau'],
    ['seau', 'sceau', 'veau', 'beau'],
    ['chapeau', 'chateau', 'cerveau', 'chameau'],
    ['adresse', 'caresse', 'paresse', 'ogresse'],
    ['poussin', 'coussin', 'bousin', 'cousin'],
    ['saut', 'taut', 'paut', 'vaut'],
    ['pareil', 'appareil', 'abeille', 'paille'],
    ['model', 'rondel', 'bordel', 'modele'],
    ['crime', 'prime', 'rime', 'frime'],
    ['mimer', 'mener', 'rimer', 'nommer'],
    ['limer', 'ramer', 'trimer', 'reanimer'],
    ['carte', 'tarte', 'farte', 'jarte'],
    ['stade', 'stage', 'tague', 'stable'],
    ['enfuir', 'enfouir', 'enduire', 'ecrouir'],
    ['bijou', 'genou', 'hibou', 'joujou'],
    ['collier', 'coller', 'relier', 'soulier'],
    ['diable', 'fable', 'double', 'fiable'],
    ['comble', 'tombe', 'decomble', 'omble'],
    ['jambe', 'gambe', 'flambe', 'enjambe'],
    ['ombre', 'nombre', 'hombre', 'sombre'],
    ['arome', 'atome', 'drome', 'chrome'],
    ['hiver', 'hier', 'haver', 'pivert'],
    ['camion', 'acromion', 'canyon', 'cation'],
    ['coupé', 'couté', 'coulé', 'loupé'],
    ['boisson', 'poisson', 'moisson', 'toison'],
    ['prison', 'saison', 'moison', 'frison'],
    ['angle', 'ongle', 'oncle', 'ange'],
    ['tarte', 'tante', 'tourte', 'carte'],
    ['carré', 'cassé', 'corré', 'capré'],
    ['nourrir', 'mourir', 'pourrir', 'rounir'],
    ['épique', 'époque', 'poque', 'pique'],
    ['suite', 'fuite', 'cuite', 'ensuite'],
    ['nature', 'facture', 'couture', 'sculpture'],
    ['écaille', 'école', 'écale', 'caille'],
    ['écharde', 'écharpe', 'escarpe', 'agrafe'],
    ['couler', 'coûter', 'couper', 'couver'],
    ['effiler', 'affiler', 'filler', 'enfiller'],
    ['harpe', 'carpe', 'marpe', 'escarpe'],
    ['justesse', 'justice', 'juste', 'justicier'],
    ['lacune', 'lagune', 'dune', 'lune'],
    ['accès', 'excès', 'succès', 'excel'],
    ['égaler', 'égaliser', 'égale', 'régale'],
    ['éminent', 'imminent', 'immanent', 'émanent'],
    ['recouvrer', 'recouvrir', 'couvrir', 'couvrer'],
    ['brise', 'bise', 'brosse', 'brasse'],
    ['gourmand', 'gourmet', 'gourmandise', 'gourme'],
    ['rivage', 'visage', 'sage', 'tirage'],
    ['dirige', 'digère', 'prestige', 'vestige'],
    ['abstrait', 'abstrus', 'distrait', 'intrus'],
    ['acquis', 'acquit', 'acquire', 'acquitter'],
    ['ver', 'verre', 'vert', 'vers'],
    ['vis', 'vice', 'voie', 'voix'],
    ['lait', 'laid', 'laide', 'laitier'],
    ['censé', 'sensé', 'sensu', 'sangsue'],
    ['mer', 'mère', 'maire', 'ère'],
    ['fée', 'fait', 'lait', 'thé'],
    ['cotte', 'côte', 'pote', 'botte'],
    ['motte', 'botte', 'cotte', 'sotte'],
    ['vain', 'vin', 'vaine', 'veine'],
    ['vanter', 'venter', 'hanter', 'tenter'],
    ['tain', 'teint', 'thym', 'vain'],
    ['tante', 'tente', 'pente', 'rente'],
    ['serein', 'serin', 'serment', 'serrement'],
    ['rouer', 'rouet', 'roue', 'loue'],
    ['reine', 'rêne', 'renne', 'rêve'],
    ['repaire', 'repère', 'répartir', 'repartir'],
    ['lait', 'plait', 'relais', 'lais'],
    ['chat', 'plat', 'chatte', 'plate'],
    ['pain', 'sain', 'train', 'demain'],
    ['clef', 'chef', 'relief', 'gief'],
    ['fort', 'port', 'record', 'abord'],
    ['tête', 'tempête', 'requête', 'répète'],
    ['bois', 'fois', 'lois', 'pois'],
    ['trois', 'mois', 'crois', 'étois'],
    ['mer', 'amer', 'aimer', 'humer'],
    ['noyer', 'loyer', 'ployer', 'employer'],
    ['manger', 'ranger', 'changer', 'démanger'],
    ['pouvoir', 'voir', 'devoir', 'savoir'],
    ['valeur', 'labeur', 'peur', 'chaleur'],
    ['blesser', 'blaser', 'presser', 'dresser'],
    ['main', 'faim', 'sain', 'pain'],
    ['plage', 'rage', 'courage', 'lissage'],
    ['chien', 'bien', 'rien', 'vien'],
    ['indien', 'vivien', 'italien', 'parisien'],
    ['feu', 'teu', 'leu', 'peu'],
    ['sol', 'bol', 'col', 'lol'],
    ['nuit', 'fruit', 'cuit', 'suit'],
    ['part', 'tart', 'fart', 'gart'],
    ['roue', 'joue', 'boue', 'soue'],
    ['lais', 'mais', 'pais', 'vais'],
    ['fait', 'nait', 'sait', 'vait'],
    ['fable', 'faible', 'diable', 'stable'],
    ['pareil', 'soleil', 'conseil', 'sommeil'],
    ['pense', 'danse', 'dispense', 'dépense'],
    ['rive', 'arrive', 'survive', 'vive'],
    ['château', 'manteau', 'cadeau', 'peau'],
    ["sage", "sale", "sable", "salle"],
    ["chat", "chut", "chic", "chiot"],
    ["chic", "choc", "cher", "chez"],
    ["code", "mode", "come", "coge"],
    ["aller", "ailes", "allée", "allez"],
    ["coller", "caller", "couler", "ciller"],
    ["renard", "regard", "retard", "revard"],
    ["livre", "libre", "litre", "vivre"],
    ["porte", "torte", "poste", "potte"],
    ["forte", "morte", "porte", "sorte"],
    ["sorte", "soute", "sotte", "sorbe"],
    ["pense", "panse", "pence", "pente"],
    ["pente", "lente", "rente", "fente"],
    ["taule", "talle", "fable", "table"],
    ["tire", "tare", "tore", "tiré"],
    ["début", "débat", "débit", "rébut"],
    ["chaire", "blaire", "flaire", "braire"],
    ["claire", "plaire", "praire", "traire"],
    ["monte", "monde", "monta", "monts"],
    ["guide", "fluide", "guida", "liquide"],
    ["interne", "interna", "interro", "interim"],
    ["tirade", "tirage", "tiret", "tirer"],
    ["faxe", "fixe", "foxe", "faxa"],
    ["flac", "floc", "flic", "fluc"],
    ["bébé", "dédé", "pépé", "kéké"],
    ["dandy", "bandy", "danby", "daudy"],
    ["décidé", "bécidé", "déssidé", "bécibé"],
    ["bonbon", "pompon", "dondon", "doudou"],
    ["balise", "balisse", "belise", "dalise"],
    ["bambou", "dambou", "dombou", "banbou"],
    ["mien", "mieu", "mein", "niem"],
    ["août", "aout", "aûot", "oaût"],
    ["projet", "prajet", "projef", "priet"],
    ["automne", "autonme", "antomne", "autonne"],
    ["déjà", "déjâ", "dêjà", "dàjé"],
    ["furet", "fôret", "fleuret", "foret"],
    ["bouton", "mouton", "montou", "bontou"],
    ["chateau", "château", "chapeau", "châpeau"],
    ["bateau", "rateau", "bâteau", "râteau"],
    ['éminent', 'imminent', 'minent', 'élément'],
    ['potable', 'portable', 'notable', 'volable'],
    ['camarade', 'parade', 'campagne', 'camargue'],
    ['entamer', 'estimer', 'enflamer', 'entrainer'],
    ['apporter', 'emporter', 'porter', 'reporter'],
    ["rentrer", "rentier", "rentrée", "rentrez"],
    ['spacieux', 'spécieux', 'spéciaux', 'spatiaux'],
    ['répulsion', 'révulsion', 'pulsion', 'vulsion'],
    ['porte', 'transporte', 'rapporte', 'comporte'],
    ['chocolat', 'chacolat', 'cachalot', 'chacalot'],
    ["toujours", "tanjours", "toujour", "tonjours"],
    ['émigrant', 'immigrant', 'migrant', 'respirant'],
    ['différent', 'déférent', 'errant', 'aberrant'],
    ['prodige', 'prodigue', 'produire', 'produit'],
    ['fusion', 'infusion', 'intrusion', 'extrusion'],
    ['accident', 'incident', 'indécent', 'exigent'],
    ['confirmer', 'infirmer', 'affirmer', 'réformer'],
    ['papa', 'pape', 'tata', 'tati'],
    ['calmat', 'climat', 'format', 'clamat'],
    ['karmas', 'calmas', "cramas", 'germas'], 
    ['tramas', 'tremas', 'trimas', 'thomas'],
    ['gammas', 'gemmas', 'germas', 'gommas'],
    ['érable', 'étable', 'stable', 'viable'],
    ['agneau', 'anneau', 'arceau', 'asseau'],
    ['biseau', 'bureau', 'cadeau','caveau'],
    ['copeau', 'coteau', 'couteau', 'ceteau'],
    ['cochon', 'cocon', 'cochet', 'cocher'],
]

export const bigReadingData = [
    ['éruption', 'érection', 'irruption', 'corruption'],
    ['affection', 'infection', 'influence', 'affluence'],
    ["blanquide", "blonquide", "blanguide", "blonguide"],
    ["meilleure", "mielleure", "meilluere", "meulleure"],
    ['rudement', 'rudiment', 'condiment', 'rendement'],
    ['raisonner', 'résonner', 'maisonner', 'emprisonner'],
    ['survoler', 'survolter', 'surmonter', 'surcharger'],
    ['proposition', 'préposition', 'exception', 'conception'],
    ['éruption', 'irruption', 'corruption', 'interruption'],
    ['exception', 'exemption', 'réception', 'perception'],
    ['perpétrer', 'perpétuer', 'récupérer', 'proliférer'],
    ['acception', 'acceptation', 'accepter', 'accepté'],
    ['promettre', 'admettre', 'soumettre', 'promettre'],
    ['mission', 'confusion', 'permission', 'émission'],
    ['consommer', 'consumer', 'contacter', 'contracter'],
    ['allocation', 'allocution', 'attention', 'illusion'],
    ['collision', 'collusion', 'commision', 'confusion'],
    ['conjecture', 'conjoncture', 'structure', 'littérature'],
]

export const readingData = [...smallReadingData, ...bigReadingData]

export const readingAppearanceData = [
    "angle", "armoire", "bureau", "cabinets", "carreau", "chaise", "classe", "couloir", "exigent", "mission",
    "dossier", "écoles", "écriture", "entrée", "escalier", "étagère", "étude", "extérieur", "fenêtre", "châpeau", "château",
    "intérieur", "lavabo", "lecture", "marche", "matelas", "maternelle", "meuble", "mousse",
    "peluche", "placard", "plafond", "porte", "poubelle", "radiateur", "rampe", "récréation",
    "rentrée", "rideau", "robinet", "salle", "savon", "serrure", "serviette", "siège", "sieste", "silence",
    "sommeil", "sonnette", "sortie", "table", "tableau", "tabouret", "tapis", "tiroir", "toilette", "vitre",
    "aller", "amener", "apporter", "appuyer", "attendre", "bâiller", "bosser", "dormir", "éclairer", "écrire",
    "emmener", "emporter", "entrer", "étudier", "fermer", "frapper", "lever", "lire", "ouvrir", "presser", "réchauffer",
    "rentrer", "reposer", "rester", "réveiller", "sonner", "sortir", "tricher", "venir", "absent", "assis", "maman",
    "couché", "présent", "debout", "dedans", "dehors", "face", "harche", "taper", "zapper", "papa", "pape", "tata",
    "avant", "contre", "dans", "derrière", "devant", "crayon", "stylo", "feutre", "pointe", "mine", "gomme",
    "dessin", "coloriage", "rayure", "peinture", "pinceau", "couleur", "papier", "feuille", "cahier",
    "carnet", "carton", "ciseaux", "découpage", "pliage", "colle", "affaire", "boîte", "casier", "caisse", "trousse",
    "cartable", "jouet", "pion", "domino", "puzzle", "cube", "perle", "chose", "forme", "carré", "pâte", "tatie", "papy",
    "tampon", "livre", "histoire", "image", "album", "titre", "bande", "dessinée", "conte", "bavé", "kiwi", "coma", 
    "magazine", "catalogue", "gage", "juge", "cuve", "dune", "ligne", "enveloppe", "étiquette", "carte", "appel", "affiche", "alphabet",
    "appareil", "caméscope", "cassettes", "chaînes", "chansons", "chiffre", "contraire", "différence", "doigt", "écran",
    "écriture", "film", "fois", "idée", "instruments", "intrus", "lettre", "liste", "main", "micro", "modèle",
    "musique", "nombre", "orchestre", "ordinateur", "photo", "point", "poster", "pouce", "prénom", "question", "radio", "sens",
    "tambour", "téléphone", "télévision", "trait", "trompette", "voix", "zéro", "chanter", "chercher", "choisir",
    "chuchoter", "coller", "colorier", "commencer", "comparer", "compter", "construire", "continuer", "copier", "couper",
    "déchirer", "décoller", "décorer", "découper", "demander", "démolir", "dépêcher", "dessiner", "dire", "discuter",
    "écouter", "écrire", "effacer", "entendre", "entourer", "envoyer", "faire", "finir", "fouiller", "goûter", "imiter",
    "laisser", "lire", "mettre", "montrer", "ouvrir", "livre", "parler", "peindre", "plier", "poser", "prendre", "préparer",
    "ranger", "réciter", "recommencer", "regarder", "remettre", "répéter", "répondre", "sentir", "souligner", "tailler",
    "taire", "tenir", "terminer", "toucher", "travailler", "trier", "attention", "camarade", "colère", "copain", "dame",
    "directeur", "directrice", "droit", "effort", "élève", "enfant", "fatigue", "faute", "fille", "garçon", "gardien",
    "madame", "maître", "maîtresse", "mensonge", "ordre", "personne", "retard", "sourire", "travail", "aider", "tige", "page",
    "défendre", "désobéir", "distribuer", "échanger", "excuser", "expliquer", "fâcher", "gronder", "obéir", "obliger",
    "partager", "prêter", "priver", "promettre", "punir", "quitter", "raconter", "refuser", "séparer", "calme", "curieux",
    "différent", "énervé", "gentil", "grand", "handicapé", "inséparable", "jaloux", "moyen", "nouveau", "petit", "poli",
    "propre", "sage", "rage", "sale", "sérieux", "tranquille",  "arrosoir", "assiette", "balle", "bateau", "boîte", "bouchon",
    "bouteille", "bulles", "canard", "casserole", "cuillère", "cuvette", "douche", "entonnoir", "gouttes", "litre", "moulin",
    "poisson", "pont", "plastique", "saladier", "seau", "tablier", "tasse", "verre", "verbes", "agiter", "mare", "male", "mère",
    "amuser", "arroser", "attraper", "avancer", "baigner", "barboter", "boucher", "bouger", "déborder", "doucher", "éclabousser",
    "essuyer", "envoyer", "flotter", "gonfler", "inonder", "jouer", "laver", "mélanger", "mouiller", "nager", "patauger", "pleuvoir",
    "plonger", "pousser", "pouvoir", "presser", "recevoir", "remplir", "renverser", "sécher", "serrer", "souffler", "tirer",
    "tourner", "tremper", "verser", "vider", "vouloir", "balle", "gâteau", "manteau", "oiseau", "plante", "soleil", "tigre",
    "bicyclette", "éléphant", "ordinateur", "université", "avocat", "chocolat", "camionnette", "alligator", "parapluie", "écureuil",
    "crocodile", "aquarium", "cafetière", "pharmacie", "ardu", "acacia", "acte", "lune", "moto", "rire", "vide", "bonjour",
    "chanson", "jardin", "pommes", "héros", "avocat", "joueur", "amical", "sauter", "banque", "forger", "souris", "chaleur", "poivre",
    "rondin", "danseur", "tranches", "fraises", "goulot", "jungle", "lierre", "plantes", "rondel", "chemin", "heureux", "délicieux", "kiosque",
    "dynamite", "épouvant", "fonction", "guerrier", "inconnue", "débattre", "généreux", "haricots", "jubilant", "medecine", "traction",
    "code", "mode", "rêvé", "rêve", "père", "mure", "rare", "tare", "gare", "taxe", "tiré", "tapé", "pate", "mémo", "sono",
    "déjà", "projet", "double", "ânerie", "vite", "cité", "jugement", "longue", "pauser", "bouton", "mouton", "fermes",
    "question", "ruiner", "routes", "volé", "perles", "coffre", "chaque", "reçu", "connue", "étouffer", "villes", "buse", "cêpe",
    "chenil", "quartier", "hâte", "théâtre", "noël", "maison", "beaucoup", "géré", "fête", "joli", "hâte", "inné", "bébé", 
    "actu", "arme", "ardu", "anse", "ceci", "cage", "badges", "côté", "cire", "coco", "café", "cave", "dome", "dure", "cure", 
    "dira", "cube", "tube", "Inde", "lave", "jury", "fumé", "lame", "bête", "bonbon", "aube", "auto", "poison", "bison", "baton", 
    "beton", "bidon", "carie", "cadre", "aigle", "barre", "banal", "astre", "arche", "aérée", "Alpe", "botte", "canal", "capot", 
    "aimer", "année", "autre", "arrêt", "appui", "accru", "canoë", "cajou", "bijou", "hibou", "bonus", "bocal", "bisou", "bémol", 
    "caler", "bacon", "abatre", "abattu", "adorer", "agiter", 
]

export const originalTextsData = [
    "Le lion ne savait pas écrire. Mais ça lui était bien égal, car il savait rugir et montrer les crocs. Et pour un lion, c’est bien suffisant. Un jour, le lion rencontra une lionne. Elle lisait un livre et elle était très belle. Le lion s’approcha pour l’embrasser. Mais il s’arrêta net, et réfléchit. Une lionne qui lit, c’est une dame. Et à une dame, on écrit des lettres. Avant de l’embrasser. Cela, il l’avait appris d’un missionnaire qu’il avait dévoré. Mais le lion ne savait pas écrire.",
    "Cela se passait en plein hiver et les flocons de neige tombaient du ciel comme un duvet léger. Une reine était assise à sa fenêtre encadrée de bois d'ébène et cousait. Tout en tirant l'aiguille, elle regardait voler les blancs flocons. Elle se piqua au doigt et trois gouttes de sang tombèrent sur la neige. Ce rouge sur ce blanc faisait si bel effet qu'elle se dit: Si seulement j'avais un enfant aussi blanc que la neige, aussi rose que le sang, aussi noir que le bois de ma fenêtre!",
    "Il était une fois une fillette de village, la plus jolie qu'on eût su voir; sa mère en était folle, et sa mère-grand plus folle encore. Cette bonne femme lui fit faire un petit chaperon rouge, qui lui seyait si bien, que partout on l'appelait le petit chaperon rouge. Un jour sa mère, ayant cuit et fait des galettes, lui dit: «Va voir comment se porte ta mère-grand, car on m'a dit qu'elle était malade, porte-lui une galette et ce petit pot de beurre.»",
    "Il était une fois, dans une forêt enchantée, un petit lapin nommé Flocon, qui rêvait de devenir le plus rapide de tous les animaux. Un jour, alors qu'il explorait la clairière voisine, Flocon rencontra une chouette sage nommée Luna, qui lui confia un mystérieux secret. Guidé par sa curiosité et sa détermination, Flocon entreprit un voyage périlleux à travers les bois sombres pour découvrir la vérité cachée derrière les étoiles. Avec l'aide de ses nouveaux amis, Flocon surmonta de nombreux obstacles.",
    "Dans une forêt dense et mystérieuse, vivaient trois amis inséparables: Léo, un jeune renard rusé, Luna, une chouette sage et observatrice, et Timmy, un lapin espiègle mais courageux. Un jour, alors qu'ils explorent un sentier inconnu, ils découvrent une ancienne carte au trésor cachée sous un vieux chêne. Intrigués et excités par cette trouvaille, ils décident de suivre les indications de la carte pour trouver le trésor légendaire qui promet des richesses incroyables.",
    "Au fil de leur périple, ils affrontent de nombreux défis et obstacles, mais leur amitié et leur détermination les aident à surmonter chaque épreuve. Ensemble, ils traversent des rivières tumultueuses, gravissent des montagnes escarpées et affrontent même une horde de gobelins farceurs. Malgré les dangers qui les entourent, leur confiance mutuelle ne faiblit jamais, et ils continuent leur quête avec courage et espoir.",
    "Dans un petit village niché au creux des montagnes, vivait une jeune fille nommée Elara. Elle était connue pour sa curiosité sans limite et son esprit aventureux. Un jour, alors qu'elle explorait la forêt voisine, elle découvrit une étrange clairière cachée derrière un rideau de lianes. Intriguée, elle s'aventura plus loin et découvrit un arbre ancien au tronc noueux, dont les branches semblaient murmurer des secrets oubliés depuis longtemps.",
    "Fascinée, Elara s'approcha timidement et tendit la main pour toucher l'écorce rugueuse de l'arbre mystérieux. Soudain, une lueur dorée jaillit de l'arbre, enveloppant Elara dans une douce lumière. Éblouie mais curieuse, elle se laissa emporter par cette étrange magie. Lorsque la lumière se dissipa, Elara se retrouva dans un autre monde, bien loin de chez elle.",
    "Devant elle s'étendait un paysage féérique, où les arbres chantaient et les rivières dansaient au rythme du vent. Intriguée mais déterminée, Elara décida d'explorer ce monde merveilleux et de percer les mystères qui l'entouraient. Ainsi commença l'aventure extraordinaire de la jeune Elara dans ce monde magique et enchanté.",
    "Pendant des heures, Emma travailla sans relâche, nettoyant, dépoussiérant et organisant chaque recoin de sa chambre. Par moments, elle se sentait découragée devant le chaos apparent, mais elle se rappela pourquoi elle avait commencé: elle voulait retrouver un espace de vie agréable et confortable.",
    "Dans une petite ville paisible, au bord d'une rivière étincelante, vivait une jeune fille nommée Luna. Luna était connue pour sa joie de vivre contagieuse et sa passion pour la musique. Chaque matin, elle se levait tôt pour arpenter les rues, accompagnant les premiers rayons du soleil de mélodies enjouées jouées sur son violon. Les habitants se réveillaient au son de sa musique, et bientôt, toute la ville était bercée par ses airs entraînants.",
    "Un jour, alors qu'elle se promenait près du vieux moulin abandonné, Luna découvrit un piano poussiéreux caché sous une bâche. Son cœur bondit de joie à la vue de cet instrument oublié, et elle décida de le restaurer. Avec l'aide de ses amis, Luna nettoya le piano et l'accorda avec soin. Bientôt, le son envoûtant du piano résonna à nouveau dans la ville, ajoutant une nouvelle dimension à la musique de Luna.",
    "Les habitants se rassemblaient désormais chaque soir pour écouter les duos envoûtants de Luna et du mystérieux pianiste, qui avait révélé son identité: un ancien compositeur renommé qui avait trouvé refuge dans la tranquillité de la petite ville. Ensemble, Luna et le pianiste enchantèrent la ville entière avec leur musique, répandant la joie et l'harmonie partout où ils allaient.",
    "Dans un petit village au cœur de la campagne, vivait une jeune fille nommée Élodie. Émerveillée par la nature qui l'entourait, Élodie passait ses journées à explorer les bois et les champs, à la recherche de trésors cachés. Un jour, alors qu'elle se promenait dans la forêt, Élodie découvrit un vieux livre poussiéreux abandonné sous un arbre.",
    "Intriguée, elle l'ouvrit et découvrit qu'il s'agissait d'un livre de jardinage magique. Les pages étaient remplies de sorts et d'incantations pour faire pousser des plantes extraordinaires aux couleurs chatoyantes et aux parfums envoûtants. Élodie décida de mettre ses nouvelles connaissances en pratique et se mit à cultiver un jardin extraordinaire.",
    "Bientôt, le jardin d’Élodie se transforma en un véritable paradis enchanté. Des fleurs éclatantes de toutes les couleurs dansaient au gré du vent, des arbres fruitiers géants offraient des fruits juteux toute l'année, et des papillons magiques voletaient autour, répandant la joie partout où ils allaient.",
    "Les habitants du village étaient émerveillés par le jardin d’Élodie et venaient de loin pour en admirer la beauté. Élodie partageait généreusement les fruits et les fleurs de son jardin avec tous ceux qui en avaient besoin, apportant réconfort et bonheur à tous ceux qui croisaient son chemin.",
    "Grâce à son jardin enchanté, Élodie avait transformé sa petite partie du monde en un endroit magique où règnent la paix, la joie et l'harmonie. Et chaque jour, elle continuait d'embellir le monde avec son amour pour la nature et sa générosité infinie.",
    "Il était une fois, en Afrique, il y a très longtemps, un serpent python. Il vivait dans un pays qui s'appelle le Cameroun et une région qu'on appelle le Bamiléké. C'était un serpent python mangeur d'hommes et tout le monde avait peur de lui.",
    "Plus personne ne le trouvait petit. On l'acclama, on le porta en triomphe et une grande fête fut organisée en son honneur. Débarrassée du serpent python, la tribu des Bamilékés put enfin goûter le bonheur de longues et paisibles siestes.",
    "On raconte qu’elles étaient trois filles d’un même père. La plus jeune était douce et gentille. Les deux aînées la jalousaient et lui voulaient du mal. Un jour qu’elles cueillaient des morelles dans les bois, les deux méchantes filles s’enfuirent en abandonnant leur petite sœur.",
    "On raconte que le père fut rempli dejoie quand il retrouva sa fille chérie mais que sa colère fut terrible lorsqu’il apprit que ses deux aînées avaient abandonné leur petite sœur dans la forêt. Il allait les punir.",
    "Dandine est heureuse dans son pré, car l'herbe y est douce et sucrée... et puis si fraiche, si belle, si verte, qu'elle ne pourrait plus s'en priver. Mais quand elle voit passer le train, elle voudrait bien partir au loin.",
    "Quel grand voyage on vient de faire! Je vais pouvoir me reposer en ruminant mon herbe verte. Mais lorsque je voudrai rêver, promets-moi que tu reviendras, car toutes ces couleurs si belles, toi tu les portes sur tes ailes.",
    "J'ai compris que j'étais vieux le jour où je me suis retrouvé dans la vitrine d'un antiquaire. J'ai été fabriqué en Allemagne. Mes tout premiers souvenirs sont assez douloureux.J'étais dans un atelier et l'on me cousait les bras et les jambes pour m'assembler.",
    "Quand mes yeux furent cousus à leur tour, j'eus mon premier aperçu d'un être humain. Une femme souriante me tenait dans ses mains. Puis je fus emballé et mis dans une boîte.",
    "Le second visage dont je me souvienne est celui d'un petit garçon qui sourit en me serrant contre lui. Je compris ensuite que ce garçon s'appelait David, que c'était son anniversaire et que j'étais son cadeau.",
    "Oscar, le meilleur ami de David, habitait sur le même palier. Ils passaient la plupart de leur temps ensemble, à jouer et à échanger des histoires et des blagues. Ils me baptisèrent Otto. Un jour, ils se mirent en tête de m'apprendre à écrire.",
    "Mais avec mes pattes maladroites je renversai l'encrier et m'éclaboussai la figure d'encre violette. J'allais garder cette tache le restant de ma vie. Comme cette tentative était un échec, les garçons allèrent chercher la machine à écrire, qui était plus facile à manier.",
    "On s'amusait bien. J'étais utile aux garçons pour toutes sortes de bonnes blagues. Ils me déguisaient en fantôme, me suspendaient à une corde et me promenaient devant la fenêtre de la vieille dame du dessous.",
    "Ce fut un jour atrocement triste lorsque des hommes en manteau de cuir noir et d'autres en uniforme vinrent chercher David et ses parents. Juste avant d'être emmené, David me donna à son meilleur ami, Oscar.",
    "Elle arriva à la petite maison des trois ours. elle jeta alors un coup d'œil à l'intérieur, par la porte restée ouverte. Comme il n'y avait personne, elle entra, ce qui n'était pas bien du tout. Elle vit trois bols sur la table, et comme elle avait faim, elle goûta la purée de très grand bol. Mais c'était bien trop chaud!",
    "Dans un lointain royaume vivaient un roi et une reine qui avaient tout pour être heureux: un beau château, un peuple aimant, des serviteurs, des courtisans... Tout, sauf le principal: ils n'avaient pas d'enfant. Le roi bâillait et la reine soupirait: «Mon dieu, que les journées sont longues!» Même Barnabé, le chien s'ennuyait. Car un château, sans une fille ou un garçon pour faire les polissons, est plus triste qu'une prison.",
    "La petite voiture s'élance aussitôt. Vroum ! Une promenade à la ferme de Mr Paille est toujours une grande fête. L'auto dépasse une grande poupée blonde. Tut ! Tut ! Fait-elle en guise de salut. Tiens, voici Bouboule, l'ourson en peluche. Le taxi jaune est si joyeux, ce matin, qu'il salue même le gendarme aux grosses moustaches.",
    "Un coup de sifflet. Les portières claquent. On démarre, on prend de la vitesse. Les parents de Martine ont disparu dans la foule. Mais que se passe-t-il sur le quai ? C'est un voyageur qui court après le train, cravate au vent, l'imperméable sur le bras. Tant pis! Il faudra qu'il attende le prochain départ. Vous savez, on ne monte jamais dans un train en marche.",
    "Un violent orage s'abat sur la campagne, détruisant de sa puissante foudre les arbres desséchés qui ne sont plus que flammes. Le ciel devient lugubre. Plus un seul chant d'oiseau. Le vieux saule n'entend plus le clapotis de l'eau au bord du ruisseau. Les roseaux se couchent désespérément sur les orties qui s'envolent en lanières brûlantes. Et soudain le calme, la nature s'apaise et au loin s'aperçoit la plaine ondoyante des blés épargnés.",
    "Paulette n'était pas si folle qu'on le disait. Bien sûr qu'elle reconnaissait les jours puisqu'elle n'avait plus que ça à faire désormais. Les compter, les attendre et les oublier. Elle savait très bien que c'était mercredi aujourd'hui. D'ailleurs elle était prête ! Elle avait mis son manteau, pris son panier et réuni ses coupons de réductions. Elle avait même entendu la voiture d'Yvonne au loin.",
    "Son chat était devant la porte, il avait faim et c'est en se penchant pour reposer son bol qu'elle était tombée en se cognant la tête contre la première marche de l'escalier. Paulette tombait souvent, mais c'était son secret. Il ne fallait pas en parler. Il fallait se relever lentement, attendre que les objets redeviennent normaux, se frictionner avec de la crème et cacher ces maudits bleus.",
    "Quand j’arrive à la gare de l‘Est, j’espère toujours secrètement qu’il y aura quelqu’un pour m’attendre. C’est bête. J’ai beau savoir que ma mère est encore au boulot à cette heure-là et que Marc n’est pas du genre à traverser la banlieue pour porter mon sac, j’ai toujours cet espoir idiot.",
    "Quand il était petit, Pierre ne voulait pas manger sa soupe et ça faisait des tas d’histoires. Ça faisait des histoires avec sa mère, sa grand-mère. Mais avec son père, ça faisait des histoires encore pires qu’avec le reste de la famille. En général, Pierre se retrouvait au lit l’estomac vide.",
    "Une nuit, il se passa quelque chose de bizarre, oui, de vraiment bizarre ! Dans la chambre de Pierre, la porte de l’armoire s’entrouvrit avec un grincement terrible. Pierre alluma sa lampe de chevet. Un jouet était tombé de l'armoire. Ouf, ce que j'ai eu peur, dit Pierre.",
    "Cette jeune fille allongée là près d'un bel arbre centenaire rêvait en lisant son livre de contes. Elle songea alors à de beaux paysages peuplés de mille créatures aux couleurs flamboyantes comme des bijoux. Sa pensée prit la liberté de vagabonder parmi ces êtres qui l'attiraient. Quand elle feuilletait cet ouvrage, elle était toujours emportée dans ce monde où les fantaisies étaient reines.",
    "À n'en pas douter, le prince de Motordu menait la belle vie. Il habitait un chapeau magnifique au dessus duquel, le dimanche, flottaient les crapauds bleu blanc rouge qu'on pouvait voir de loin. Le prince de Motordu ne s'ennuyait jamais. Lorsque venait l'hiver, il faisait d'extraordinaires batailles de poules de neige. Le soir, il restait bien au chaud à jouer aux tartes avec ses coussins.",
    "Le prince vivait à la campagne. Un jour, on le voyait mener paître son troupeau de boutons. Le lendemain, on pouvait l'admirer filant comme le vent sur son râteau à voiles. Et quand le dimanche arrivait, il invitait ses amis à déjeuner. Le prince se montra sensible à ces arguments et prit la ferme résolution de se marier bientôt.",
    "Il ferma donc son chapeau à clé, rentra son troupeau de boutons dans les tables, puis monta dans sa toiture de course pour se mettre en quête d'une fiancée. Hélas, en cours de route, un pneu de sa toiture creva. Il n'y avait pas beaucoup d'élèves dans l'école de la princesse et on n'eut aucun mal à trouver une table libre pour le prince de Motordu, le nouveau de la classe.",
    "Dès qu'il commença à répondre aux questions qu'on lui posait, le prince déclencha l'hilarité parmi ses nouveaux camarades. Quant à son cahier, il était, à chaque ligne, plein de taches et de ratures, on eût dit un véritable torchon. Peu à peu, le prince de Motordu, grâce aux efforts constants de son institutrice, commença à faire des progrès.",
    "Au bout de quelques semaines, il parvint à parler normalement, mais ses camarades le trouvaient beaucoup moins drôle depuis qu'il ne tordait plus les mots. À la fin de l'année, cependant, il obtint le prix de camaraderie car, comme il était riche, il achetait chaque jour des kilos de bonbons qu'il distribuait sans compter.",
    "Lorsqu'il revint chez lui, après avoir passé une année en classe, le prince de Motordu avait complètement oublié de se marier. Mais quelques jours plus tard, il reçut une lettre qui lui rafraîchit la mémoire. Et c'est ainsi que le prince de Motordu épousa la maîtresse.",
    "Il était une fois trois vilains brigands, avec de grands manteaux noirs et de hauts chapeaux noirs. La nuit, au clair de lune, ils se tenaient cachés au bord de la route. Ils faisaient peur à tout le monde. Leur cachette était une caverne en haut de la montagne. C’est là qu’ils transportaient ce qu’ils avaient volé. Ils avaient des coffres pleins d’or, pleins de perles, de bijoux et de pierres précieuses.",
    "Chaque enfant reçut un manteau et un chapeau comme ceux des brigands, mais rouges. Naturellement, cette histoire fut vite connue dans toute la région, et, chaque jour, de nouveaux orphelins étaient abandonnés à la porte du château. Ils étaient tous recueillis et vivaient là jusqu’à ce qu’ils soient en âge de se marier. Alors, ils se construisirent des maisons dans le voisinage.",
    "Bientôt, cela fit toute une petite ville. Tous ses habitants portaient un manteau et un chapeau rouges. À la fin, ils bâtirent une muraille tout autour de la ville avec trois tours imposantes, une pour chaque brigand, pour les remercier.",
    "L'obtention de mon baccalauréat à l'âge de dix-huit ans ne se fit pas sans mal. Je dus mon diplôme qu'à une séance de rattrapage. Pendant que tout le monde fêtait leur résultat, je fus assigné aux révisions au vu des rattrapages qui auront lieu trois jours plus tard.",
    "Mon père est passé tout à l'heure chez moi sans prévenir. On a parlé de choses et d'autres et il a habillement glissé dans le fil de notre conversation l'histoire de mon permis de conduire. Il souhaite que je passe mon permis mais pas moi.",
    "On a beau faire, compatir, essayer de comprendre, on ne peut jamais vraiment ressentir la douleur qu'éprouve l'autre, ni dans sa tête ni dans son corps. Et sans doute que c'est mieux ainsi.",
    "Le train a stoppé le long du quai. Une gare sans guichet. Philippe m'attendait. Son badge de garde forestier brillait au revers de sa veste. Il avait pris des rides, les cheveux en broussaille, une barbe de trois jours et des kilos en trop. Philippe est mon frère.",
    "On était trois semaines avant Noël. J'étais arrivée au Val par le seul train possible, celui de onze heures. Tous les autres arrêts avaient été supprimés pour gagner quelques minutes. Il a longé le chenil. Je me suis plaqué le front à la vitre, j'ai aperçu les grillages, les niches, les chiens.",
    "J'ai choisi une orange dans le filet et je l'ai pelée avec les dents. J'ai toujours aimé faire ça. Arracher la peau à la sauvage et me rendre la bouche amère. J'ai posé les épluchures sur le dessus brûlant du radiateur électrique. En chauffant, les pelures d'orange devenaient rousses, presque noires, elles ne brûlaient pas mais diffusaient un doux parfum d'agrume.",
    "Je me déplace avant même que mon cerveau enregistre le mouvement et je me retrouve agenouillée sur le sol dur à côté de Rex, sans savoir comment j'y suis arrivée. Mon visage à quelques centimètres du sien. Je pose une main sur le sofa à côté de sa tête pour retrouver l'équilibre. Rex est un chien.",
    "Trois femmes vivaient dans un village. La première était méchante, la deuxième était menteuse, la troisième était égoïste. Elles possédaient pourtant un point commun, un secret en quelque sorte: toutes les trois rêvaient de fuir.",
    "Ils sont venus me voir. Elle était en robe tailleur, on aurait dit Kate Middleton. Lui avait mit un costume. Ici , si tu croises quelqu'un en costume-cravate, tu te dis qu'il va à un mariage, pas qu'il revienne du boulot. Ça a dû lui faire bizarre de venir dans notre quartier.",
    "Ceux qui l'avait croisé disait qu'il était grand. Enfin, ceux qui disaient ça, c'étaient ceux qui pouvaient encore parler. Parce que, malheureusement, la plupart de ceux qui l'avaient croisé étaient revenus complètement patraques. Et bien, oui, forcément, quand on est un géant, grand comme une maison à deux étages, avec d'aussi grands bras.",
    "Et bien, oui, forcément, quand on est un géant, grand comme une maison à deux étages, avec d'aussi grands bras, d'aussi grandes jambes et surtout d'aussi grands pieds, forcément, dés qu'on remue un petit peu, on écrabouille les gens qui sont venus vous rendre visite pour prendre le thé. Vous n'imaginez pas le nombre de personnes, qu'il avait écrabouillées et transformées en crêpes, sans le faire exprès.",
    "Souvent, la nuit, pendant que tout le village dormait à poings fermés, le géant pleurait et soupirait car il se rendait bien compte qu'il avait de moins en moins d'amis. Il aimerait se faire de nouveau ami mais c'est difficile. Surtout que les personnes ont souvent peur de moi à cause de ma si grande taille.",
    "Cette catastrophe faisait partout un grand bruit pendant quelques heures. Candice et Martin, en retournant à la petite métairie, rencontrèrent un bon vieillard qui prenait le frais à sa porte sous un berceau d'orangers. Martin, qui était aussi curieux que raisonneur, lui demanda des conseils.",
    "J'ignore absolument l'aventure dont vous me parlez. Je présume qu'en général ceux qui se mêlent des affaires publiques périssent quelques fois misérablement. Je m'informe jamais de ce qu'on fait sur la place du village. Je me contente d'y envoyer vendre les fruits du jardin que je cultive.",
    "Il fit entrer les étrangers dans sa maison. Ses deux filles et ses deux fils leur présentèrent plusieurs sortes de sorbets qu'ils faisaient eux-mêmes. Il y a des sorbets aux citrons, aux oranges, aux ananas, à la pistache et au café Moka. Ils se régalèrent tous et toutes. Les étrangers repartent le ventre plein.",
    "Je l’ai vu arriver de loin. Je ne sais pas, sa démarche peut-être, un peu nonchalante ou les pans de son manteau qui prenaient de l’aisance devant lui. Bref, j’étais à vingt mètres de lui et je savais déjà que je ne le raterai pas. Ça n’a pas loupé, arrivé à ma hauteur, je le vois me regarder. Je lui décoche un sourire, genre flèche de Cupidon mais en plus réservé.",
    "Il me sourit aussi. En passant mon chemin, je continue de sourire, je repense à lui. Je marche moins vite car j’essaye de me souvenir. Et pendant ce temps-là, je sens son regard toujours dans mon dos. Je n'ose pas me retourner.",
    "Elle était, comme souvent, étendue sur son lit en mezzanine entourée de coussins multicolores, un livre entre les mains. Elle avait les cheveux noirs et raides comme des baguettes. Elle les avait attachés avec une barrette car la chaleur se faisait sentir en cette soirée estivale.",
    "Depuis le CM1 elle était fascinée par le monde des sorciers et surtout par la série «Harry Potter» dont elle avait lu les sept tomes et dont elle avait vu les films plusieurs fois, jusqu’à en connaître certaines répliques par cœur. Elle s’identifiait au personnage d’Hermione dont elle partageait la première lettre du prénom.",
    "En cette veille de week-end, elle était rentrée du collège le plus vite possible. Elle avait hâte de retrouver Harry et ses camarades et de suivre leurs aventures. La jeune fille tournait les pages frénétiquement. Elle ne s’était pas rendu compte du temps passé quand son frère entra en trombe dans sa chambre pour l’aider à mettre la table.",
    "Sans attendre sa réponse, il claqua la porte si violemment que l’affiche d’Harry Potter qu’elle avait scotchée menaça de se détacher. Elle en était au chapitre 22 quand sa mère à son tour lui demanda de venir pour le dîner car il était prêt. ",
    "Ce matin-là, Alma avait été réveillée trop tôt à son goût par son père. Comme tous les matins, elle avait avalé son bol de céréales en vitesse. Elle avait enfilé à toute allure un short en jean et son T-shirt jaune vif pour aller au collège afin d’avoir un peu de temps pour discuter avec ses meilleurs copains, Antoine, Sarah et Aminata.",
    "C’était censé être une journée tout à fait ordinaire. Quelle ne fut pas sa surprise quand Madame Zhang leur annonça qu’ils devaient préparer des chansons. Ils les interpréteraient à la fête de la musique, prévue dans un mois. Alma sentit son coeur battre la chamade. ",
    "Et ce n’était rien en comparaison de ce qu’elle ressentit quand en fin de matinée, Monsieur Rythmo, le professeur de musique leur expliqua que certains élèves devraient jouer de la musique pour accompagner les chansons. Chanter et jouer de la musique? Mais ce n’est pas possible pensait-elle.",
    "Tout le monde va s’apercevoir que je chante faux et qu’à part «Au clair de la lune » à la flûte, je ne sais jouer d’aucun instrument. J’aime bien écrire des textes de chansons pour m’amuser mais chanter. La dernière fois qu’elle avait chanté pour l’anniversaire de sa grand-mère, Clément, son grand frère, lui avait dit «je crois qu’il va pleuvoir»!",
    "Elle aimait bien regarder tous les samedis soir l’émission de télévision avec des candidats qui chantent devant un jury. Mais elle ne se sentait pas ce talent. Antoine faisait de la batterie, Aminata et Sarah chantaient tous les mercredis après-midi en duo à la Maison de quartier ; elle, c’était plutôt le rugby sa spécialité.",
    "Quand elle rentra chez elle après les cours, Alma trouva Clément affalé dans le canapé en train de manger la dernière gaufre au chocolat du paquet. Affamée et agacée, elle commença à lui exposer combien elle n’en pouvait plus qu’il mange régulièrement tous les paquets de biscuits sans penser à elle.",
    "Léo est champion de vélo. L’année dernière il a gagné beaucoup de courses organisées pour les plus jeunes, mais cette année il va courir avec les grands. Pour le féliciter et l’encourager, ses parents lui ont acheté un super vélo. Aujourd’hui c’est la première course de la nouvelle saison.",
    "Léo est tout fier et tout content de pouvoir courir avec le beau maillot bleu et blanc du club cycliste de sa ville. Avec son nouveau vélo aux couleurs du maillot, si beau et si léger, il est convaincu qu’il va encore gagner. Pour cela Léo a fait tout le nécessaire. Il s’est soigneusement entraîné pendant les derniers jours de l’hiver avec ses copains et, ce matin, il a bien vérifié tout son matériel.",
    "Il s’est ensuite préparé un goûter qu’il a fourré dans son petit sac pour le ravitaillement. Mais, avant de se rendre sur la ligne de départ, il faut signer son nom sur la liste des participants. Léo appuie son vélo contre un arbre et se dirige vers la table des commissaires de course. Léo se retourne, sifflotant. Mais là, consternation, son vélo a disparu!",
    "Marcel se réveille tout joyeux et excité. C’est Noël. Il se précipite vers le salon où il a décoré un joli sapin avec maman. Il ne fait même pas attention aux guirlandes lumineuses qui clignotent. Cela ne l’intéresse pas et il espère que le père Noël est passé.",
    "Cet été il a appris à pédaler sans roulettes sur le vélo de sa cousine. Il était tout fier. Que ce fut long d’attendre l’hiver et la neige! Marcel a essayé d’être sage et il a travaillé de son mieux pour être irréprochable. Son travail lui a d’ailleurs permis d’écrire la fameuse lettre au Père Noël pour demander le superbe vélo rouge qu’il avait vu dans le magasin.",
    "Les derniers jours avaient passé vite mais hier soir les adultes semblaient prendre un malin plaisir à faire durer le réveillon de Noël. Alors que Marcel n’avait qu’une hâte, inhabituelle: aller se coucher. Pourtant une fois dans son lit impossible de trouver le sommeil: il s’imaginait déjà pédalant de toutes ses forces dans les allées du parc.",
    "Un loup vit un agneau qui buvait à un cours d'eau et prétendit se couvrir d'un motif raisonnable pour le dévorer. Aussi, bien qu'il se tînt lui-même en amont, l'accusa-t-il de troubler l'eau et de l'empêcher de boire. L'autre répondit qu'il ne buvait que du bout des lèvres et que d'ailleurs il lui était impossible, en aval, de rien faire à l'eau qui coulait au-dessus de lui.",
]

export const textsData = [
    "Le <span>li</span>on <span>ne</span> sa<span>vait</span> pas <span>é</span>cri<span>re</span>. <span>Mais</span> ça <span>lui</span> é<span>tait</span> bi<span>en</span> é<span>gal</span>, <span>car</span> il <span>sa</span>vait <span>ru</span>gir <span>et</span> mon<span>trer</span> les <span>crocs</span>. <span>Et</span> pour <span>un</span> li<span>on</span>, <span>c’est</span> bi<span>en</span> suf<span>fi</span>sant. Un <span>jour</span>, <span>le</span> li<span>on</span> ren<span>con</span>tra <span>u</span>ne <span>li</span>on<span>ne</span>. <span>El</span>le <span>li</span>sait <span>un</span> li<span>vre</span> et <span>el</span>le <span>é</span>tait <span>très</span> bel<span>le</span>. <span>Le</span> li<span>on</span> s’ap<span>pro</span>cha <span>pour</span> l’em<span>bras</span>ser. Mais <span>il</span> s’ar<span>rê</span>ta <span>net</span>, <span>et</span> ré<span>flé</span>chit. U<span>ne</span> li<span>on</span>ne <span>qui</span> lit, c’est <span>u</span>ne <span>da</span>me. <span>Et</span> à <span>u</span>ne <span>da</span>me, on <span>é</span>crit <span>des</span> let<span>tres</span>. <span>A</span>vant <span>de</span> l’em<span>bras</span>ser.",
    "Ce<span>la</span> se <span>pas</span>sait <span>en</span> plein <span>hi</span>ver <span>et</span> les <span>flo</span>cons <span>de</span> nei<span>ge</span> tom<span>baient</span> du <span>ci</span>el <span>com</span>me <span>un</span> duv<span>et</span> lé<span>ger</span>. <span>U</span>ne <span>rei</span>ne <span>é</span>tait <span>as</span>si<span>se</span> à <span>sa</span> fe<span>nê</span>tre <span>en</span>ca<span>drée</span> de <span>bois</span> d'é<span>bè</span>ne <span>et</span> cou<span>sait</span>. <span>Tout</span> en <span>ti</span>rant <span>l'ai</span>gui<span>lle</span>, <span>el</span>le <span>re</span>gar<span>dait</span> vo<span>ler</span> les <span>blancs</span> flo<span>cons</span>. <span>El</span>le <span>se</span> pi<span>qua</span> au <span>doigt</span> et <span>trois</span> gout<span>tes</span> de <span>sang</span> tom<span>bè</span>rent <span>sur</span> la <span>nei</span>ge. Ce <span>rou</span>ge <span>sur</span> ce <span>blanc</span> fai<span>sait</span> si <span>bel</span> ef<span>fet</span> qu'el<span>le</span> se <span>dit</span>: Si <span>seu</span>le<span>ment</span> j'a<span>vais</span> un <span>en</span>fant <span>aus</span>si <span>blanc</span> que <span>la</span> nei<span>ge</span>, <span>aus</span>si <span>ro</span>se <span>que</span> le <span>sang</span>.",
    "Il <span>é</span>tait <span>u</span>ne <span>fois</span> u<span>ne</span> fi<span>llet</span>te <span>de</span> vil<span>la</span>ge, la <span>plus</span> jo<span>lie</span> qu'on <span>eût</span> su <span>voir</span>; sa <span>mè</span>re <span>en</span> é<span>tait</span> fol<span>le</span>, et <span>sa</span> mè<span>re</span>-grand <span>plus</span> fol<span>le</span> en<span>co</span>re. <span>Cet</span>te <span>bon</span>ne <span>fem</span>me <span>lui</span> fit <span>fai</span>re <span>un</span> pe<span>tit</span> cha<span>pe</span>ron <span>rou</span>ge, <span>qui</span> lui <span>se</span>yait <span>si</span> bi<span>en</span>, que <span>par</span>tout <span>on</span> l'ap<span>pe</span>lait <span>le</span> pe<span>tit</span> cha<span>pe</span>ron <span>rou</span>ge. <span>Un</span> jour <span>sa</span> mè<span>re</span>, a<span>yant</span> cuit <span>et</span> fait <span>des</span> ga<span>let</span>tes, <span>lui</span> dit: «<span>Va</span> voir <span>com</span>ment <span>se</span> por<span>te</span> ta <span>mè</span>re-<span>grand</span>, car <span>on</span> m'a <span>dit</span> qu'el<span>le</span> é<span>tait</span> ma<span>la</span>de, <span>por</span>te-<span>lui</span> u<span>ne</span> ga<span>let</span>te <span>et</span> ce <span>pe</span>tit <span>pot</span> de <span>beur</span>re.»",
    "Il <span>é</span>tait <span>u</span>ne <span>fois</span>, dans <span>u</span>ne <span>fo</span>rêt <span>en</span>chan<span>tée</span>, un <span>pe</span>tit <span>la</span>pin <span>nom</span>mé <span>Flo</span>con, <span>qui</span> rê<span>vait</span> de <span>de</span>ve<span>nir</span> le <span>plus</span> ra<span>pi</span>de <span>de</span> tous <span>les</span> a<span>ni</span>maux. <span>Un</span> jour, <span>a</span>lors <span>qu'il</span> ex<span>plo</span>rait <span>la</span> clai<span>ri</span>è<span>re</span> voi<span>si</span>ne, <span>Flo</span>con <span>ren</span>con<span>tra</span> une <span>chou</span>et<span>te</span> sa<span>ge</span> nom<span>mée</span> Lu<span>na</span>, qui <span>lui</span> con<span>fia</span> un <span>mys</span>té<span>ri</span>eux <span>se</span>cret. <span>Gui</span>dé <span>par</span> sa <span>cu</span>rio<span>si</span>té <span>et</span> sa <span>dé</span>ter<span>mi</span>na<span>tion</span>, Flo<span>con</span> en<span>tre</span>prit <span>un</span> vo<span>ya</span>ge <span>pé</span>ril<span>leux</span> à <span>tra</span>vers <span>les</span> bois <span>som</span>bres <span>pour</span> dé<span>cou</span>vrir <span>la</span> vé<span>ri</span>té <span>ca</span>chée <span>der</span>ri<span>è</span>re <span>les</span> é<span>toi</span>les.",
    "Dans <span>u</span>ne <span>fo</span>rêt <span>den</span>se <span>et</span> mys<span>té</span>ri<span>eu</span>se, <span>vi</span>vaient <span>trois</span> a<span>mis</span> in<span>sé</span>pa<span>ra</span>bles: <span>Léo</span>, un <span>jeu</span>ne <span>re</span>nard <span>ru</span>sé, <span>Lu</span>na, <span>u</span>ne <span>chou</span>et<span>te</span> sa<span>ge</span> et <span>ob</span>ser<span>va</span>tri<span>ce</span>, et <span>Tim</span>my, <span>un</span> la<span>pin</span> es<span>pi</span>è<span>gle</span> mais <span>cou</span>ra<span>geux</span>. Un <span>jour</span>, a<span>lors</span> qu'ils <span>ex</span>plo<span>rent</span> un <span>sen</span>tier <span>in</span>con<span>nu</span>, ils <span>dé</span>cou<span>vrent</span> u<span>ne</span> an<span>ci</span>en<span>ne</span> car<span>te</span> au <span>tré</span>sor <span>ca</span>chée <span>sous</span> un <span>vieux</span> chê<span>ne</span>. In<span>tri</span>gués <span>et</span> ex<span>ci</span>tés <span>par</span> cet<span>te</span> trou<span>va</span>ille, <span>ils</span> dé<span>ci</span>dent <span>de</span> sui<span>vre</span> les <span>in</span>di<span>ca</span>tions <span>de</span> la <span>car</span>te <span>pour</span> trou<span>ver</span> le <span>tré</span>sor.",
    "Au <span>fil</span> de <span>leur</span> pé<span>ri</span>ple, <span>ils</span> af<span>fron</span>tent <span>de</span> nom<span>breux</span> dé<span>fis</span> et <span>ob</span>sta<span>cles</span>, mais <span>leur</span> a<span>mi</span>tié <span>et</span> leur <span>dé</span>ter<span>mi</span>na<span>tion</span> les <span>ai</span>dent <span>à</span> sur<span>mon</span>ter <span>cha</span>que <span>é</span>preu<span>ve</span>. En<span>sem</span>ble, <span>ils</span> tra<span>ver</span>sent <span>des</span> ri<span>vi</span>è<span>res</span> tu<span>mul</span>tu<span>eu</span>ses, <span>gra</span>vis<span>sent</span> des <span>mon</span>ta<span>gnes</span> es<span>car</span>pées <span>et</span> af<span>fron</span>tent <span>mê</span>me <span>u</span>ne <span>hor</span>de <span>de</span> go<span>be</span>lins <span>far</span>ceurs. <span>Mal</span>gré <span>les</span> dan<span>gers</span> qui <span>les</span> en<span>tou</span>rent, <span>leur</span> con<span>fi</span>an<span>ce</span> mu<span>tu</span>el<span>le</span> ne <span>fai</span>blit <span>ja</span>mais, <span>et</span> ils <span>con</span>ti<span>nuent</span> leur <span>quê</span>te <span>a</span>vec <span>cou</span>ra<span>ge</span> et <span>es</span>poir.",
    "<span>Dans</span> un <span>pe</span>tit <span>vil</span>la<span>ge</span> ni<span>ché</span> au <span>creux</span> des <span>mon</span>ta<span>gnes</span>, vi<span>vait</span> une <span>jeu</span>ne <span>fil</span>le <span>nom</span>mée <span>E</span>la<span>ra</span>. El<span>le</span> é<span>tait</span> con<span>nue</span> pour <span>sa</span> cu<span>rio</span>si<span>té</span> sans <span>li</span>mi<span>te</span> et <span>son</span> es<span>prit</span> a<span>ven</span>tu<span>reux</span>. Un <span>jour</span>, a<span>lors</span> qu'el<span>le</span> ex<span>plo</span>rait <span>la</span> fo<span>rêt</span> voi<span>si</span>ne, <span>el</span>le <span>dé</span>cou<span>vrit</span> u<span>ne</span> é<span>tran</span>ge <span>clai</span>ri<span>è</span>re <span>ca</span>chée <span>der</span>ri<span>è</span>re <span>un</span> ri<span>deau</span> de <span>lia</span>nes. <span>In</span>tri<span>guée</span>, el<span>le</span> s'a<span>ven</span>tu<span>ra</span> plus <span>loin</span> et <span>dé</span>cou<span>vrit</span> un <span>ar</span>bre <span>an</span>cien <span>au</span> tronc <span>nou</span>eux, <span>dont</span> les <span>bran</span>ches <span>sem</span>blaient <span>mur</span>mu<span>rer</span> des <span>se</span>crets <span>ou</span>bli<span>és</span>.",
    "<span>De</span>vant <span>el</span>le <span>s'é</span>ten<span>dait</span> un <span>pay</span>sa<span>ge</span> fé<span>é</span>ri<span>que</span>, où <span>les</span> ar<span>bres</span> chan<span>taient</span> et <span>les</span> ri<span>vi</span>è<span>res</span> dan<span>saient</span> au <span>ryth</span>me <span>du</span> vent. <span>In</span>tri<span>guée</span> mais <span>dé</span>ter<span>mi</span>née, <span>E</span>la<span>ra</span> dé<span>ci</span>da <span>d'ex</span>plo<span>rer</span> ce <span>mon</span>de <span>mer</span>veil<span>leux</span> et <span>de</span> per<span>cer</span> les <span>mys</span>tè<span>res</span> qui <span>l'en</span>tou<span>raient</span>. Ain<span>si</span> com<span>men</span>ça <span>l'a</span>ven<span>tu</span>re <span>ex</span>tra<span>or</span>di<span>nai</span>re <span>de</span> la <span>jeu</span>ne <span>E</span>la<span>ra</span> dans <span>ce</span> mon<span>de</span> ma<span>gi</span>que <span>et</span> en<span>chan</span>té.",
    "<span>Pen</span>dant <span>des</span> heu<span>res</span>, Em<span>ma</span> tra<span>va</span>illa <span>sans</span> re<span>lâ</span>che, <span>net</span>to<span>yant</span>, dé<span>pou</span>ssi<span>é</span>rant <span>et</span> or<span>ga</span>ni<span>sant</span> cha<span>que</span> re<span>coin</span> de <span>sa</span> cham<span>bre</span>. Par <span>mo</span>ments, <span>el</span>le <span>se</span> sen<span>tait</span> dé<span>cou</span>ra<span>gée</span> de<span>vant</span> le <span>cha</span>os <span>a</span>ppa<span>rent</span>, mais <span>el</span>le <span>se</span> ra<span>ppe</span>la <span>pour</span>quoi <span>el</span>le <span>a</span>vait <span>com</span>men<span>cé</span>: el<span>le</span> vou<span>lait</span> re<span>trou</span>ver <span>un</span> es<span>pa</span>ce <span>de</span> vie <span>a</span>gré<span>a</span>ble <span>et</span> con<span>for</span>ta<span>ble</span>.",
    "Un <span>jour</span>, a<span>lors</span> qu'el<span>le</span> se <span>pro</span>me<span>nait</span> près <span>du</span> vi<span>eux</span> mou<span>lin</span> a<span>ban</span>do<span>nné</span>, Lu<span>na</span> dé<span>cou</span>vrit <span>un</span> pi<span>a</span>no <span>pou</span>ssi<span>é</span>reux <span>ca</span>ché <span>sous</span> u<span>ne</span> bâ<span>che</span>. Son <span>cœur</span> bon<span>dit</span> de <span>joie</span> à <span>la</span> vue <span>de</span> cet <span>ins</span>tru<span>ment</span> ou<span>bli</span>é, <span>et</span> el<span>le</span> dé<span>ci</span>da <span>de</span> le <span>res</span>tau<span>rer</span>. A<span>vec</span> l'ai<span>de</span> de <span>ses</span> a<span>mis</span>, Lu<span>na</span> ne<span>ttoy</span>a <span>le</span> pi<span>a</span>no <span>et</span> l'a<span>ccor</span>da <span>a</span>vec <span>soin</span>. Bien<span>tôt</span>, le <span>son</span> en<span>voû</span>tant <span>du</span> pi<span>a</span>no <span>ré</span>son<span>na</span> à <span>nou</span>veau <span>dans</span> la <span>vil</span>le, <span>a</span>jou<span>tant</span> u<span>ne</span> nou<span>vel</span>le <span>di</span>men<span>si</span>on <span>à</span> la <span>mu</span>si<span>que</span> de <span>Lu</span>na.",
    "Dans <span>un</span> pe<span>tit</span> vil<span>la</span>ge <span>au</span> cœur <span>de</span> la <span>cam</span>pa<span>gne</span>, vi<span>vait</span> u<span>ne</span> jeu<span>ne</span> fil<span>le</span> nom<span>mée</span> É<span>lo</span>die. <span>É</span>mer<span>veil</span>lée <span>par</span> la <span>na</span>tu<span>re</span> qui <span>l'en</span>tou<span>rait</span>, É<span>lo</span>die <span>pas</span>sait <span>ses</span> jour<span>nées</span> à <span>ex</span>plo<span>rer</span> les <span>bois</span> et <span>les</span> champs, <span>à</span> la <span>re</span>cher<span>che</span> de <span>tré</span>sors <span>ca</span>chés. <span>Un</span> jour, <span>a</span>lors <span>qu'el</span>le <span>se</span> pro<span>me</span>nait <span>dans</span> la <span>fo</span>rêt, <span>É</span>lo<span>die</span> dé<span>cou</span>vrit <span>un</span> vi<span>eux</span> li<span>vre</span> pou<span>ssi</span>é<span>reux</span> a<span>ban</span>do<span>nné</span> sous <span>un</span> ar<span>bre</span>.",
    "<span>In</span>tri<span>guée</span>, el<span>le</span> l'ou<span>vrit</span> et <span>dé</span>cou<span>vrit</span> qu'il <span>s'a</span>gi<span>ssait</span> d'un <span>li</span>vre <span>de</span> jar<span>di</span>na<span>ge</span> ma<span>gi</span>que. <span>Les</span> pa<span>ges</span> é<span>taient</span> rem<span>plies</span> de <span>sorts</span> et <span>d'in</span>can<span>ta</span>tions <span>pour</span> fai<span>re</span> pous<span>ser</span> des <span>plan</span>tes <span>ex</span>tra<span>or</span>di<span>nai</span>res <span>aux</span> cou<span>leurs</span> cha<span>toy</span>an<span>tes</span> et <span>aux</span> par<span>fums</span> en<span>voû</span>tants. <span>É</span>lo<span>die</span> dé<span>ci</span>da <span>de</span> met<span>tre</span> ses <span>nou</span>vel<span>les</span> co<span>nnai</span>ssan<span>ces</span> en <span>pra</span>ti<span>que</span> et <span>se</span> mit <span>à</span> cul<span>ti</span>ver <span>un</span> jar<span>din</span> ex<span>tra</span>or<span>di</span>nai<span>re</span>.",
    "<span>Bien</span>tôt, <span>le</span> jar<span>din</span> d’É<span>lo</span>die <span>se</span> trans<span>for</span>ma <span>en</span> un <span>vé</span>ri<span>ta</span>ble <span>pa</span>ra<span>dis</span> en<span>chan</span>té. <span>Des</span> fleurs <span>é</span>cla<span>tan</span>tes <span>de</span> tou<span>tes</span> les <span>cou</span>leurs <span>dan</span>saient <span>au</span> gré <span>du</span> vent, <span>des</span> ar<span>bres</span> frui<span>tiers</span> gé<span>ants</span> of<span>fraient</span> des <span>fruits</span> ju<span>teux</span> tou<span>te</span> l'an<span>née</span>, et <span>des</span> pa<span>pi</span>llons <span>ma</span>gi<span>ques</span> vo<span>le</span>taient <span>au</span>tour, <span>ré</span>pan<span>dant</span> la <span>joie</span> par<span>tout</span> où <span>ils</span> al<span>laient</span>.",
    "Les <span>ha</span>bi<span>tants</span> du <span>vi</span>lla<span>ge</span> é<span>taient</span> é<span>mer</span>vei<span>llés</span> par <span>le</span> jar<span>din</span> d’É<span>lo</span>die <span>et</span> ve<span>naient</span> de <span>loin</span> pour <span>en</span> ad<span>mi</span>rer <span>la</span> beau<span>té</span>. É<span>lo</span>die <span>par</span>ta<span>geait</span> gé<span>né</span>reu<span>se</span>ment <span>les</span> fruits <span>et</span> les <span>fleurs</span> de <span>son</span> jar<span>din</span> a<span>vec</span> tous <span>ceux</span> qui <span>en</span> a<span>vaient</span> be<span>soin</span>, a<span>ppor</span>tant <span>ré</span>con<span>fort</span> et <span>bon</span>heur <span>à</span> tous <span>ceux</span> qui <span>croi</span>saient <span>son</span> che<span>min</span>.",
    "Grâ<span>ce</span> à <span>son</span> jar<span>din</span> en<span>chan</span>té, <span>É</span>lo<span>die</span> a<span>vait</span> trans<span>for</span>mé <span>sa</span> pe<span>ti</span>te <span>par</span>tie <span>du</span> mon<span>de</span> en <span>un</span> en<span>droit</span> ma<span>gi</span>que <span>où</span> rè<span>gnent</span> la <span>paix</span>, la <span>joie</span> et <span>l'har</span>mo<span>nie</span>. Et <span>cha</span>que <span>jour</span>, el<span>le</span> con<span>ti</span>nu<span>ait</span> d'em<span>be</span>llir <span>le</span> mon<span>de</span> a<span>vec</span> son <span>a</span>mour <span>pour</span> la <span>na</span>tu<span>re</span> et <span>sa</span> gé<span>né</span>ro<span>si</span>té <span>in</span>fi<span>nie</span>.",
    "Il <span>é</span>tait <span>u</span>ne <span>fois</span>, en <span>A</span>fri<span>que</span>, il <span>y</span> a <span>très</span> long<span>temps</span>, un <span>ser</span>pent <span>py</span>thon. <span>Il</span> vi<span>vait</span> dans <span>un</span> pays <span>qui</span> s'a<span>ppe</span>lle <span>le</span> Ca<span>me</span>roun <span>et</span> u<span>ne</span> ré<span>gion</span> qu'on <span>a</span>ppe<span>lle</span> le <span>Ba</span>mi<span>lé</span>ké. <span>C'é</span>tait <span>un</span> ser<span>pent</span> py<span>thon</span> man<span>geur</span> d'ho<span>mmes</span> et <span>tout</span> le <span>mon</span>de <span>a</span>vait <span>peur</span> de <span>lui</span>.",
    "<span>Plus</span> per<span>so</span>nne <span>ne</span> le <span>trou</span>vait <span>pe</span>tit. <span>On</span> l'a<span>ccla</span>ma, <span>on</span> le <span>por</span>ta <span>en</span> tri<span>om</span>phe <span>et</span> u<span>ne</span> gran<span>de</span> fê<span>te</span> fut <span>or</span>ga<span>ni</span>sée <span>en</span> son <span>ho</span>nneur. <span>Dé</span>ba<span>rra</span>ssée <span>du</span> ser<span>pent</span> py<span>thon</span>, la <span>tri</span>bu <span>des</span> Ba<span>mi</span>lé<span>kés</span> put <span>en</span>fin <span>goû</span>ter <span>le</span> bo<span>nheur</span> de <span>lon</span>gues <span>et</span> pai<span>si</span>bles <span>si</span>es<span>tes</span>.",
    "On <span>ra</span>con<span>te</span> qu’el<span>les</span> é<span>taient</span> trois <span>fi</span>lles <span>d’un</span> mê<span>me</span> pè<span>re</span>. La <span>plus</span> jeu<span>ne</span> é<span>tait</span> dou<span>ce</span> et <span>gen</span>ti<span>lle</span>. Les <span>deux</span> aî<span>nées</span> la <span>ja</span>lou<span>saient</span> et <span>lui</span> vou<span>laient</span> du <span>mal</span>. Un <span>jour</span> qu’el<span>les</span> cueil<span>laient</span> des <span>mo</span>re<span>lles</span> dans <span>les</span> bois, <span>les</span> deux <span>mé</span>chan<span>tes</span> fi<span>lles</span> s’en<span>fui</span>rent <span>en</span> a<span>ban</span>do<span>nnant</span> leur <span>pe</span>ti<span>te</span> sœur.",
    "On <span>ra</span>con<span>te</span> que <span>le</span> pè‌<span>re</span> fut <span>rem</span>pli <span>de</span> joie <span>quand</span> il <span>re</span>trou<span>va</span> sa <span>fil</span>le <span>ché‌</span>rie <span>mais</span> que <span>sa</span> co<span>lè‌</span>re <span>fut</span> te<span>rri</span>ble <span>lors</span>qu’il <span>a</span>pprit <span>que</span> ses <span>deux</span> aî‌<span>nées</span> a<span>vaient</span> a<span>ban</span>do<span>nné‌</span> leur <span>pe</span>ti<span>te</span> sœur <span>dans</span> la <span>fo</span>rêt. <span>Il</span> a<span>llait</span> les <span>pu</span>nir.",
    "Dan<span>di</span>ne <span>est</span> heu<span>reu</span>se <span>dans</span> son <span>pré‌</span>, car <span>l'her</span>be <span>y</span> est <span>dou</span>ce <span>et</span> su<span>crée</span>... et <span>puis</span> si <span>frai</span>che, <span>si</span> bel<span>le</span>, si <span>ver</span>te, <span>qu'el</span>le <span>ne</span> pour<span>rait</span> plus <span>s'en</span> pri<span>ver</span>. Mais <span>quand</span> el<span>le</span> voit <span>pas</span>ser <span>le</span> train, <span>el</span>le <span>vou</span>drait <span>bien</span> par<span>tir</span> au <span>loin</span>.",
    "Quel <span>grand</span> vo<span>ya</span>ge <span>on</span> vient <span>de</span> fai<span>re</span>! Je <span>vais</span> pou<span>voir</span> me <span>re</span>po<span>ser</span> en <span>ru</span>mi<span>nant</span> mon <span>her</span>be <span>ver</span>te. <span>Mais</span> lors<span>que</span> je <span>vou</span>drai <span>rê‌</span>ver, <span>pro</span>mets-<span>moi</span> que <span>tu</span> re<span>vien</span>dras, <span>car</span> tou<span>tes</span> ces <span>cou</span>leurs <span>si</span> bel<span>les</span>, toi <span>tu</span> les <span>por</span>tes <span>sur</span> tes <span>ai</span>les.",
    "J'ai <span>comp</span>ris <span>que</span> j'é‌<span>tais</span> vi<span>eux</span> le <span>jour</span> où <span>je</span> me <span>suis</span> re<span>trou</span>vé <span>dans</span> la <span>vi</span>tri<span>ne</span> d'un <span>an</span>ti<span>quai</span>re. <span>J'ai</span> é‌<span>té‌</span> fa<span>bri</span>qué <span>en</span> Al<span>le</span>ma<span>gne</span>. Mes <span>tout</span> pre<span>mi</span>ers <span>sou</span>ve<span>nirs</span> sont <span>a</span>ssez <span>dou</span>lou<span>reux</span>. J'é‌<span>tais</span> dans <span>un</span> a<span>te</span>li<span>er</span> et <span>l'on</span> me <span>cou</span>sait <span>les</span> bras <span>et</span> les <span>jam</span>bes <span>pour</span> m'a<span>ssem</span>bler.",
    "Le <span>se</span>cond <span>vi</span>sa<span>ge</span> dont <span>je</span> me <span>sou</span>vi<span>en</span>ne <span>est</span> ce<span>lui</span> d'un <span>pe</span>tit <span>gar</span>çon <span>qui</span> sou<span>rit</span> en <span>me</span> ser<span>rant</span> con<span>tre</span> lui. <span>Je</span> com<span>pris</span> en<span>sui</span>te <span>que</span> ce <span>gar</span>çon <span>s'ap</span>pel<span>lait</span> Da<span>vid</span>, que <span>c'é‌</span>tait <span>son</span> an<span>ni</span>ver<span>sai</span>re <span>et</span> que <span>j'é‌</span>tais <span>son</span> ca<span>deau</span>.",
    "Os<span>car</span>, le <span>meil</span>leur <span>a</span>mi <span>de</span> Da<span>vid</span>, ha<span>bi</span>tait <span>sur</span> le <span>mê‌</span>me <span>pa</span>li<span>er</span>. Ils <span>pas</span>saient <span>la</span> plu<span>part</span> de <span>leur</span> temps <span>en</span>sem<span>ble</span>, à <span>jou</span>er <span>et</span> à <span>é‌</span>chan<span>ger</span> des <span>his</span>toi<span>res</span> et <span>des</span> bla<span>gues</span>. Ils <span>me</span> bap<span>ti</span>sè‌<span>rent</span> Ot<span>to</span>. Un <span>jour</span>, ils <span>se</span> mi<span>rent</span> en <span>tê‌</span>te <span>de</span> m'ap<span>pren</span>dre <span>à‌</span> é‌<span>cri</span>re.",
    "Mais <span>a</span>vec <span>mes</span> pat<span>tes</span> ma<span>la</span>droi<span>tes</span> je <span>ren</span>ver<span>sai</span> l'en<span>cri</span>er <span>et</span> m'é‌<span>cla</span>bous<span>sai</span> la <span>fi</span>gu<span>re</span> d'en<span>cre</span> vi<span>o</span>let<span>te</span>. J'al<span>lais</span> gar<span>der</span> cet<span>te</span> ta<span>che</span> le <span>res</span>tant <span>de</span> ma <span>vie</span>. Com<span>me</span> cet<span>te</span> ten<span>ta</span>ti<span>ve</span> é‌<span>tait</span> un <span>é‌</span>chec, <span>les</span> gar<span>çons</span> al<span>lè‌</span>rent <span>cher</span>cher <span>la</span> ma<span>chi</span>ne <span>à‌</span> é‌<span>cri</span>re, <span>qui</span> é‌<span>tait</span> plus <span>fa</span>ci<span>le</span> à <span>ma</span>ni<span>er</span>.",
    "On <span>s'a</span>mu<span>sait</span> bi<span>en</span>. J'é‌<span>tais</span> u<span>ti</span>le <span>aux</span> gar<span>çons</span> pour <span>tou</span>tes <span>sor</span>tes <span>de</span> bon<span>nes</span> bla<span>gues</span>. Ils <span>me</span> dé‌<span>gui</span>saient <span>en</span> fan<span>tô‌</span>me, <span>me</span> sus<span>pen</span>daient <span>à‌</span> u<span>ne</span> cor<span>de</span> et <span>me</span> pro<span>me</span>naient <span>de</span>vant <span>la</span> fe<span>nê‌</span>tre <span>de</span> la <span>vi</span>eil<span>le</span> da<span>me</span> du <span>des</span>sous.",
    "Ce <span>fut</span> un <span>jour</span> a<span>tro</span>ce<span>ment</span> tris<span>te</span> lors<span>que</span> des <span>hom</span>mes <span>en</span> man<span>teau</span> de <span>cuir</span> noir <span>et</span> d'au<span>tres</span> en <span>u</span>ni<span>for</span>me <span>vin</span>rent <span>cher</span>cher <span>Da</span>vid <span>et</span> ses <span>pa</span>rents. <span>Jus</span>te <span>a</span>vant <span>d'ê‌</span>tre <span>em</span>me<span>né‌</span>, Da<span>vid</span> me <span>don</span>na <span>à‌</span> son <span>meil</span>leur <span>a</span>mi, <span>Os</span>car.",
    "El<span>le</span> a<span>rri</span>va <span>à</span> la <span>pe</span>ti<span>te</span> mai<span>son</span> des <span>trois</span> ours. <span>El</span>le <span>je</span>ta <span>a</span>lors <span>un</span> coup <span>d'œil</span> à <span>l'in</span>té<span>ri</span>eur, <span>par</span> la <span>por</span>te <span>res</span>tée <span>ou</span>ver<span>te</span>. Com<span>me</span> il <span>n'y</span> a<span>vait</span> per<span>son</span>ne, <span>el</span>le <span>en</span>tra, <span>ce</span> qui <span>n'é</span>tait <span>pas</span> bien <span>du</span> tout. <span>El</span>le <span>vit</span> trois <span>bols</span> sur <span>la</span> ta<span>ble</span>, et <span>com</span>me <span>el</span>le <span>a</span>vait <span>faim</span>, el<span>le</span> goû<span>ta</span> la <span>pu</span>rée <span>dU</span> très <span>grand</span> bol. <span>Mais</span> c'é<span>tait</span> bien <span>trop</span> chaud!",
    "<span>Dans</span> un <span>loin</span>tain <span>roy</span>au<span>me</span> vi<span>vaient</span> un <span>roi</span> et <span>u</span>ne <span>rei</span>ne <span>qui</span> a<span>vaient</span> tout <span>pour</span> ê<span>tre</span> heu<span>reux</span>: un <span>beau</span> châ<span>teau</span>, un <span>peu</span>ple <span>ai</span>mant, <span>des</span> ser<span>vi</span>teurs, <span>des</span> cour<span>ti</span>sans... <span>Tout</span>, sauf <span>le</span> prin<span>ci</span>pal: <span>ils</span> n'a<span>vaient</span> pas <span>d'en</span>fant. <span>Le</span> roi <span>bâ</span>ill<span>ait</span> et <span>la</span> rei<span>ne</span> sou<span>pi</span>rait: «<span>Mon</span> dieu, <span>que</span> les <span>jour</span>nées <span>sont</span> lon<span>gues</span>!» Mê<span>me</span> Bar<span>na</span>bé, <span>le</span> chi<span>en</span> s'en<span>nuy</span>ait. <span>Car</span> un <span>châ</span>teau, <span>sans</span> u<span>ne</span> fil<span>le</span> ou <span>un</span> gar<span>çon</span> pour <span>fai</span>re <span>les</span> po<span>li</span>ssons, <span>est</span> plus <span>tris</span>te <span>qu'u</span>ne <span>pri</span>son.",
    "La <span>pe</span>ti<span>te</span> voi<span>tu</span>re <span>s'é</span>lan<span>ce</span> au<span>ssi</span>tôt. <span>Vroum</span> ! U<span>ne</span> pro<span>me</span>na<span>de</span> à <span>la</span> fer<span>me</span> de <span>Mr</span> Pa<span>ille</span> est <span>tou</span>jours <span>u</span>ne <span>gran</span>de <span>fê</span>te. <span>L'au</span>to <span>dé</span>pa<span>sse</span> u<span>ne</span> gran<span>de</span> pou<span>pée</span> blon<span>de</span>. Tut! <span>Tut</span>! Fait-<span>el</span>le <span>en</span> gui<span>se</span> de <span>sa</span>lut. <span>Tiens</span>, voi<span>ci</span> Bou<span>bou</span>le, <span>l'our</span>son <span>en</span> pe<span>lu</span>che. <span>Le</span> ta<span>xi</span> jau<span>ne</span> est <span>si</span> joy<span>eux</span>, ce <span>ma</span>tin, <span>qu'il</span> sa<span>lue</span> mê<span>me</span> le <span>gen</span>dar<span>me</span> aux <span>gro</span>sses <span>mous</span>ta<span>ches</span>.",
    "<span>Un</span> coup <span>de</span> si<span>fflet</span>. Les <span>por</span>ti<span>è</span>res <span>cla</span>quent. <span>On</span> dé<span>ma</span>rre, <span>on</span> prend <span>de</span> la <span>vi</span>te<span>sse</span>. Les <span>pa</span>rents <span>de</span> Mar<span>ti</span>ne <span>ont</span> dis<span>pa</span>ru <span>dans</span> la <span>fou</span>le. <span>Mais</span> que <span>se</span> pas<span>se</span>-t-il <span>sur</span> le <span>quai</span>? C'est <span>un</span> vo<span>ya</span>geur <span>qui</span> court <span>a</span>près <span>le</span> train, <span>cra</span>va<span>te</span> au <span>vent</span>, l'im<span>per</span>mé<span>a</span>ble <span>sur</span> le <span>bras</span>. Tant <span>pis</span>! Il <span>fau</span>dra <span>qu'il</span> at<span>ten</span>de <span>le</span> pro<span>chain</span> dé<span>part</span>. Vous <span>sa</span>vez, <span>on</span> ne <span>mon</span>te <span>ja</span>mais <span>dans</span> un <span>train</span> en <span>mar</span>che.",
    "<span>Un</span> vi<span>o</span>lent <span>o</span>ra<span>ge</span> s'a<span>bat</span> sur <span>la</span> cam<span>pa</span>gne, <span>dé</span>trui<span>sant</span> de <span>sa</span> pui<span>ssan</span>te <span>fou</span>dre <span>les</span> ar<span>bres</span> de<span>ssé</span>chés <span>qui</span> ne <span>sont</span> plus <span>que</span> fla<span>mmes</span>. Le <span>ci</span>el <span>de</span>vient <span>lu</span>gu<span>bre</span>. Plus <span>un</span> seul <span>chant</span> d'oi<span>seau</span>. Le <span>vi</span>eux <span>sau</span>le <span>n'en</span>tend <span>plus</span> le <span>cla</span>po<span>tis</span> de <span>l'eau</span> au <span>bord</span> du <span>rui</span>sseau. <span>Les</span> ro<span>seaux</span> se <span>cou</span>chent <span>dé</span>ses<span>pé</span>ré<span>ment</span> sur <span>les</span> or<span>ties</span> qui <span>s'en</span>vo<span>lent</span> en <span>la</span>ni<span>è</span>res <span>brû</span>lan<span>tes</span>.",
    "Pau<span>le</span>tte <span>n'é</span>tait <span>pas</span> si <span>fol</span>le <span>qu'on</span> le <span>di</span>sait. <span>Bien</span> sûr <span>qu'el</span>le <span>re</span>co<span>nnai</span>ssait <span>les</span> jours <span>puis</span>qu'el<span>le</span> n'a<span>vait</span> plus <span>que</span> ça <span>à</span> fai<span>re</span> dé<span>sor</span>mais. <span>Les</span> comp<span>ter</span>, les <span>a</span>tten<span>dre</span> et <span>les</span> ou<span>bli</span>er. <span>El</span>le <span>sa</span>vait <span>très</span> bien <span>que</span> c'é<span>tait</span> mer<span>cre</span>di <span>au</span>jour<span>d'hui</span>. D'a<span>illeurs</span> el<span>le</span> é<span>tait</span> prê<span>te</span> ! El<span>le</span> a<span>vait</span> mis <span>son</span> man<span>teau</span>, pris <span>son</span> pa<span>nier</span> et <span>ré</span>u<span>ni</span> ses <span>cou</span>pons <span>de</span> ré<span>duc</span>tions. <span>El</span>le <span>a</span>vait <span>mê</span>me <span>en</span>ten<span>du</span> la <span>voi</span>tu<span>re</span> d'Y<span>vo</span>nne <span>au</span> loin.",
    "<span>Son</span> chat <span>é</span>tait <span>de</span>vant <span>la</span> por<span>te</span>, il <span>a</span>vait <span>faim</span> et <span>c'est</span> en <span>se</span> pen<span>chant</span> pour <span>re</span>po<span>ser</span> son <span>bol</span> qu'el<span>le</span> é<span>tait</span> tom<span>bée</span> en <span>se</span> co<span>gnant</span> la <span>tê</span>te <span>con</span>tre <span>la</span> pre<span>mi</span>è<span>re</span> mar<span>che</span> de <span>l'es</span>ca<span>lier</span>. Pau<span>le</span>tte <span>tom</span>bait <span>sou</span>vent, mais <span>c'é</span>tait <span>son</span> se<span>cret</span>. Il <span>ne</span> fa<span>llait</span> pas <span>en</span> par<span>ler</span>. Il <span>fa</span>llait <span>se</span> re<span>le</span>ver <span>len</span>te<span>ment</span>, a<span>tten</span>dre <span>que</span> les <span>o</span>bjets <span>re</span>de<span>vien</span>nent <span>nor</span>maux, <span>se</span> fric<span>ti</span>o<span>nner</span> a<span>vec</span> de <span>la</span> crè<span>me</span> et <span>ca</span>cher <span>ces</span> mau<span>dits</span> bleus.",
    "Quand <span>j’a</span>rri<span>ve</span> à <span>la</span> ga<span>re</span> de <span>l‘Est</span>, j’es<span>pè‌</span>re <span>tou</span>jours <span>se</span>crè‌<span>te</span>ment <span>qu’il</span> y <span>au</span>ra <span>quel</span>qu’un <span>pour</span> m’a<span>tten</span>dre. <span>C’est</span> bê‌<span>te</span>. J’ai <span>beau</span> sa<span>voir</span> que <span>ma</span> mè‌<span>re</span> est <span>en</span>co<span>re</span> au <span>bou</span>lot <span>à‌</span> cet<span>te</span> heu<span>re</span>-là <span>et</span> que <span>Marc</span> n’est <span>pas</span> du <span>gen</span>re <span>à‌</span> tra<span>ver</span>ser <span>la</span> ban<span>li</span>eue <span>pour</span> por<span>ter</span> mon <span>sac</span>, j’ai <span>tou</span>jours <span>cet</span> es<span>poir</span> i<span>di</span>ot.",
    "Quand <span>il</span> é<span>tait</span> pe<span>tit</span>, Pi<span>er</span>re <span>ne</span> vou<span>lait</span> pas <span>man</span>ger <span>sa</span> sou<span>pe</span> et <span>ça</span> fai<span>sait</span> des <span>tas</span> d’his<span>toi</span>res. <span>Ça</span> fai<span>sait</span> des <span>his</span>toi<span>res</span> a<span>vec</span> sa <span>mè</span>re, <span>sa</span> grand-<span>mè</span>re. <span>Mais</span> a<span>vec</span> son <span>pè</span>re, <span>ça</span> fai<span>sait</span> des <span>his</span>toi<span>res</span> en<span>co</span>re <span>pi</span>res <span>qu’a</span>vec <span>le</span> res<span>te</span> de <span>la</span> fa<span>mil</span>le. <span>En</span> gé<span>né</span>ral, <span>Pi</span>er<span>re</span> se <span>re</span>trou<span>vait</span> au <span>lit</span> l’es<span>to</span>mac <span>vi</span>de.",
    "<span>Les</span> ha<span>bi</span>tants <span>se</span> ras<span>sem</span>blaient <span>dé</span>sor<span>mais</span> cha<span>que</span> soir <span>pour</span> é<span>cou</span>ter <span>les</span> du<span>os</span> en<span>voû</span>tants <span>de</span> Lu<span>na</span> et <span>du</span> mys<span>té</span>ri<span>eux</span> pi<span>a</span>nis<span>te</span>, qui <span>a</span>vait <span>ré</span>vé<span>lé</span> son <span>i</span>den<span>ti</span>té: <span>un</span> an<span>cien</span> com<span>po</span>si<span>teur</span> re<span>no</span>mmé <span>qui</span> a<span>vait</span> trou<span>vé</span> re<span>fu</span>ge <span>dans</span> la <span>tran</span>qui<span>lli</span>té <span>de</span> la <span>pe</span>ti<span>te</span> vil<span>le</span>. En<span>sem</span>ble, <span>Lu</span>na <span>et</span> le <span>pi</span>a<span>nis</span>te <span>en</span>chan<span>tè</span>rent <span>la</span> vil<span>le</span> en<span>ti</span>è<span>re</span> a<span>vec</span> leur <span>mu</span>si<span>que</span>, ré<span>pan</span>dant <span>la</span> joie <span>et</span> l'har<span>mo</span>nie <span>par</span>tout <span>où</span> ils <span>al</span>laient.",
    "<span>Fa</span>sci<span>née</span>, E<span>la</span>ra <span>s'ap</span>pro<span>cha</span> ti<span>mi</span>de<span>ment</span> et <span>ten</span>dit <span>la</span> main <span>pour</span> tou<span>cher</span> l'é<span>cor</span>ce <span>ru</span>gueu<span>se</span> de <span>l'ar</span>bre <span>mys</span>té<span>rieux</span>. Sou<span>dain</span>, u<span>ne</span> lu<span>eur</span> do<span>rée</span> jail<span>lit</span> de <span>l'ar</span>bre, <span>en</span>ve<span>lo</span>ppant <span>E</span>la<span>ra</span> dans <span>u</span>ne <span>dou</span>ce <span>lu</span>mi<span>è</span>re. <span>É</span>blou<span>ie</span> mais <span>cu</span>ri<span>eu</span>se, <span>el</span>le <span>se</span> lais<span>sa</span> em<span>por</span>ter <span>par</span> cet<span>te</span> é<span>tran</span>ge <span>ma</span>gie. <span>Lors</span>que <span>la</span> lu<span>mi</span>è<span>re</span> se <span>di</span>ssi<span>pa</span>, E<span>la</span>ra <span>se</span> re<span>trou</span>va <span>dans</span> un <span>au</span>tre <span>mon</span>de, <span>bien</span> loin <span>de</span> chez <span>el</span>le.",
    "<span>Quand</span> mes <span>yeux</span> fu<span>rent</span> cou<span>sus</span> à <span>leur</span> tour, <span>j'eus</span> mon <span>pre</span>mi<span>er</span> a<span>per</span>çu <span>d'un</span> ê‌<span>tre</span> hu<span>main</span>. U<span>ne</span> fem<span>me</span> sou<span>ri</span>an<span>te</span> me <span>te</span>nait <span>dans</span> ses <span>mains</span>. Puis <span>je</span> fus <span>em</span>bal<span>lé‌</span> et <span>mis</span> dans <span>u</span>ne <span>boî‌</span>te.",
    "<span>Dans</span> u<span>ne</span> pe<span>ti</span>te </span>vil</span>le <span>pai</span>si<span>ble</span>, au <span>bord</span> d'u<span>ne</span> ri<span>vi</span>è<span>re</span> é<span>tin</span>ce<span>lan</span>te, <span>vi</span>vait <span>u</span>ne <span>jeu</span>ne <span>fil</span>le <span>nom</span>mée <span>Lu</span>na. <span>Lu</span>na <span>é</span>tait <span>con</span>nue <span>pour</span> sa <span>joie</span> de <span>vi</span>vre <span>con</span>ta<span>gi</span>eu<span>se</span> et <span>sa</span> pas<span>sion</span> pour <span>la</span> mu<span>si</span>que. <span>Cha</span>que <span>ma</span>tin, <span>el</span>le <span>se</span> le<span>vait</span> tôt <span>pour</span> ar<span>pen</span>ter <span>les</span> rues, <span>ac</span>com<span>pa</span>gnant <span>les</span> pre<span>mi</span>ers <span>ray</span>ons <span>du</span> so<span>leil</span> de <span>mé</span>lo<span>dies</span> en<span>jou</span>ées <span>jou</span>ées <span>sur</span> son <span>vi</span>o<span>lon</span>. Les <span>ha</span>bi<span>tants</span> se <span>ré</span>veil<span>laient</span> au <span>son</span> de <span>sa</span> mu<span>si</span>que, <span>et</span> bien<span>tôt</span>, tou<span>te</span> la <span>vil</span>le <span>é</span>tait <span>ber</span>cée <span>par</span> ses <span>airs</span> en<span>traî</span>nants.",
    "U<span>ne</span> nuit, <span>il</span> se <span>pas</span>sa <span>quel</span>que <span>cho</span>se <span>de</span> bi<span>zar</span>re, <span>oui</span>, de <span>vrai</span>ment <span>bi</span>zar<span>re</span> ! Dans <span>la</span> cham<span>bre</span> de <span>Pi</span>er<span>re</span>, la <span>por</span>te <span>de</span> l’ar<span>moi</span>re <span>s’en</span>trou<span>vrit</span> a<span>vec</span> un <span>grin</span>ce<span>ment</span> ter<span>ri</span>ble. <span>Pi</span>er<span>re</span> al<span>lu</span>ma <span>sa</span> lam<span>pe</span> de <span>che</span>vet. <span>Un</span> jou<span>et</span> é<span>tait</span> tom<span>bé</span> de <span>l'ar</span>moi<span>re</span>. Ouf, <span>ce</span> que <span>j'ai</span> eu <span>peur</span>, dit <span>Pi</span>er<span>re</span>.",
    "Cet<span>te</span> jeu<span>ne</span> fil<span>le</span> al<span>lon</span>gée <span>là</span> près <span>d'un</span> bel <span>ar</span>bre <span>cen</span>te<span>nai</span>re <span>rê</span>vait <span>en</span> li<span>sant</span> son <span>li</span>vre <span>de</span> con<span>tes</span>. El<span>le</span> son<span>gea</span> a<span>lors</span> à <span>de</span> beaux <span>pay</span>sa<span>ges</span> peu<span>plés</span> de <span>mil</span>le <span>cré</span>a<span>tu</span>res <span>aux</span> cou<span>leurs</span> flam<span>boy</span>an<span>tes</span> com<span>me</span> des <span>bi</span>joux. <span>Sa</span> pen<span>sée</span> prit <span>la</span> li<span>ber</span>té <span>de</span> va<span>ga</span>bon<span>der</span> par<span>mi</span> ces <span>ê</span>tres <span>qui</span> l'at<span>ti</span>raient. <span>Quand</span> el<span>le</span> feu<span>ille</span>tait <span>cet</span> ou<span>vra</span>ge, <span>el</span>le <span>é</span>tait <span>tou</span>jours <span>em</span>por<span>tée</span> dans <span>ce</span> mon<span>de</span> où <span>les</span> fan<span>tai</span>sies <span>é</span>taient <span>rei</span>nes.",
    "À <span>n'en</span> pas <span>dou</span>ter, <span>le</span> prin<span>ce</span> de <span>Mo</span>tor<span>du</span> me<span>nait</span> la <span>bel</span>le <span>vie</span>. Il <span>ha</span>bi<span>tait</span> un <span>cha</span>peau <span>ma</span>gni<span>fi</span>que <span>au</span> des<span>sus</span> du<span>quel</span>, le <span>di</span>man<span>che</span>, flot<span>taient</span> les <span>cra</span>pauds <span>bleu</span> blanc <span>rou</span>ge <span>qu'on</span> pou<span>vait</span> voir <span>de</span> loin. <span>Le</span> prin<span>ce</span> de <span>Mo</span>tor<span>du</span> ne <span>s'en</span>nuy<span>ait</span> ja<span>mais</span>. Lors<span>que</span> ve<span>nait</span> l'hi<span>ver</span>, il <span>fai</span>sait <span>d'ex</span>tra<span>or</span>di<span>nai</span>res <span>ba</span>tail<span>les</span> de <span>pou</span>les <span>de</span> nei<span>ge</span>. Le <span>soir</span>, il <span>res</span>tait <span>bien</span> au <span>chaud</span> à <span>jouer</span> aux <span>tar</span>tes <span>a</span>vec <span>ses</span> cous<span>sins</span>.",
    "Le <span>prin</span>ce <span>vi</span>vait <span>à</span> la <span>cam</span>pa<span>gne</span>. Un <span>jour</span>, on <span>le</span> voy<span>ait</span> me<span>ner</span> paî<span>tre</span> son <span>trou</span>peau <span>de</span> bou<span>tons</span>. Le <span>len</span>de<span>main</span>, on <span>pou</span>vait <span>l'ad</span>mi<span>rer</span> fi<span>lant</span> com<span>me</span> le <span>vent</span> sur <span>son</span> râ<span>teau</span> à <span>voi</span>les. <span>Et</span> quand <span>le</span> di<span>man</span>che <span>ar</span>ri<span>vait</span>, il <span>in</span>vi<span>tait</span> ses <span>a</span>mis <span>à</span> dé<span>jeu</span>ner. <span>Le</span> prin<span>ce</span> se <span>mon</span>tra <span>sen</span>si<span>ble</span> à <span>ces</span> ar<span>gu</span>ments <span>et</span> prit <span>la</span> fer<span>me</span> ré<span>so</span>lu<span>tion</span> de <span>se</span> ma<span>ri</span>er <span>bien</span>tôt.",
    "Il <span>fer</span>ma <span>donc</span> son <span>cha</span>peau <span>à</span> clé, <span>ren</span>tra <span>son</span> trou<span>peau</span> de <span>bou</span>tons <span>dans</span> les <span>ta</span>bles, <span>puis</span> mon<span>ta</span> dans <span>sa</span> toi<span>tu</span>re <span>de</span> cour<span>se</span> pour <span>se</span> met<span>tre</span> en <span>quê</span>te <span>d'u</span>ne <span>fi</span>an<span>cée</span>. Hé<span>las</span>, en <span>cours</span> de <span>rou</span>te, <span>un</span> pneu <span>de</span> sa <span>toi</span>tu<span>re</span> cre<span>va</span>. Il <span>n'y</span> a<span>vait</span> pas <span>beau</span>coup <span>d'é</span>lè<span>ves</span> dans <span>l'é</span>co<span>le</span> de <span>la</span> prin<span>ces</span>se <span>et</span> on <span>n'eut</span> au<span>cun</span> mal <span>à</span> trou<span>ver</span> u<span>ne</span> ta<span>ble</span> li<span>bre</span> pour <span>le</span> prin<span>ce</span> de <span>Mo</span>tor<span>du</span>, le <span>nou</span>veau <span>de</span> la <span>clas</span>se.",
    "Dès <span>qu'il</span> com<span>men</span>ça <span>à</span> ré<span>pon</span>dre <span>aux</span> ques<span>tions</span> qu'on <span>lui</span> po<span>sait</span>, le <span>prin</span>ce <span>dé</span>clen<span>cha</span> l'hi<span>la</span>ri<span>té</span> par<span>mi</span> ses <span>nou</span>veaux <span>ca</span>ma<span>ra</span>des. <span>Quant</span> à <span>son</span> ca<span>hier</span>, il <span>é</span>tait, <span>à</span> cha<span>que</span> li<span>gne</span>, plein <span>de</span> ta<span>ches</span> et <span>de</span> ra<span>tu</span>res, <span>on</span> eût <span>dit</span> un <span>vé</span>ri<span>ta</span>ble <span>tor</span>chon. <span>Peu</span> à <span>peu</span>, le <span>prin</span>ce <span>de</span> Mo<span>tor</span>du, <span>grâ</span>ce <span>aux</span> ef<span>forts</span> cons<span>tants</span> de <span>son</span> ins<span>ti</span>tu<span>tri</span>ce, <span>com</span>men<span>ça</span> à <span>fai</span>re <span>des</span> pro<span>grès</span>.",
    "Au <span>bout</span> de <span>quel</span>ques <span>se</span>mai<span>nes</span>, il <span>par</span>vint <span>à</span> par<span>ler</span> nor<span>ma</span>le<span>ment</span>, mais <span>ses</span> ca<span>ma</span>ra<span>des</span> le <span>trou</span>vaient <span>beau</span>coup <span>moins</span> drô<span>le</span> de<span>puis</span> qu'il <span>ne</span> tor<span>dait</span> plus <span>les</span> mots. <span>À</span> la <span>fin</span> de <span>l'an</span>née, <span>ce</span>pen<span>dant</span>, il <span>ob</span>tint <span>le</span> prix <span>de</span> ca<span>ma</span>ra<span>de</span>rie <span>car</span>, com<span>me</span> il <span>é</span>tait <span>ri</span>che, <span>il</span> a<span>che</span>tait <span>cha</span>que <span>jour</span> des <span>ki</span>los <span>de</span> bon<span>bons</span> qu'il <span>dis</span>tri<span>bu</span>ait <span>sans</span> comp<span>ter</span>.",
    "Lors<span>qu'il</span> re<span>vint</span> chez <span>lui</span>, a<span>près</span> a<span>voir</span> pas<span>sé</span> u<span>ne</span> an<span>née</span> en <span>clas</span>se, <span>le</span> prin<span>ce</span> de <span>Mo</span>tor<span>du</span> a<span>vait</span> com<span>plè</span>te<span>ment</span> ou<span>bli</span>é <span>de</span> se <span>ma</span>ri<span>er</span>. Mais <span>quel</span>ques <span>jours</span> plus <span>tard</span>, il <span>re</span>çut <span>u</span>ne <span>let</span>tre <span>qui</span> lui <span>ra</span>fraî<span>chit</span> la <span>mé</span>moi<span>re</span>. Et <span>c'est</span> ain<span>si</span> que <span>le</span> prin<span>ce</span> de <span>Mo</span>tor<span>du</span> é<span>pou</span>sa <span>la</span> maî<span>tres</span>se.",
    "Il <span>é</span>tait <span>u</span>ne <span>fois</span> trois <span>vi</span>lains <span>bri</span>gands, <span>a</span>vec <span>de</span> grands <span>man</span>teaux <span>noirs</span> et <span>de</span> hauts <span>cha</span>peaux <span>noirs</span>. La <span>nuit</span>, au <span>clair</span> de <span>lu</span>ne, <span>ils</span> se <span>te</span>naient <span>ca</span>chés <span>au</span> bord <span>de</span> la <span>rou</span>te. <span>Ils</span> fai<span>saient</span> peur <span>à</span> tout <span>le</span> mon<span>de</span>. Leur <span>ca</span>chet<span>te</span> é<span>tait</span> u<span>ne</span> ca<span>ver</span>ne <span>en</span> haut <span>de</span> la <span>mon</span>ta<span>gne</span>. C’est <span>là</span> qu’ils <span>trans</span>por<span>taient</span> ce <span>qu’ils</span> a<span>vaient</span> vo<span>lé</span>. Ils <span>a</span>vaient <span>des</span> cof<span>fres</span> pleins <span>d’or</span>, pleins <span>de</span> per<span>les</span>, de <span>bi</span>joux <span>et</span> de <span>pi</span>er<span>res</span> pré<span>ci</span>eu<span>ses</span>.",
    "Cha<span>que</span> en<span>fant</span> re<span>çut</span> un <span>man</span>teau <span>et</span> un <span>cha</span>peau <span>com</span>me <span>ceux</span> des <span>bri</span>gands, <span>mais</span> rou<span>ges</span>. Na<span>tu</span>rel<span>le</span>ment, <span>cet</span>te <span>his</span>toi<span>re</span> fut <span>vi</span>te <span>con</span>nue <span>dans</span> tou<span>te</span> la <span>ré</span>gi<span>on</span>, et, <span>cha</span>que <span>jour</span>, de <span>nou</span>veaux <span>or</span>phe<span>lins</span> é<span>taient</span> a<span>ban</span>don<span>nés</span> à <span>la</span> por<span>te</span> du <span>châ</span>teau. <span>Ils</span> é<span>taient</span> tous <span>re</span>cueil<span>lis</span> et <span>vi</span>vaient <span>là</span> jus<span>qu’à</span> ce <span>qu’ils</span> soient <span>en</span> â<span>ge</span> de <span>se</span> ma<span>ri</span>er. <span>A</span>lors, <span>ils</span> se <span>cons</span>trui<span>si</span>rent <span>des</span> mai<span>sons</span> dans <span>le</span> voi<span>si</span>na<span>ge</span>.",
    "Bien<span>tôt</span>, ce<span>la</span> fit <span>tou</span>te <span>u</span>ne <span>pe</span>ti<span>te</span> vil<span>le</span>. Tous <span>ses</span> ha<span>bi</span>tants <span>por</span>taient <span>un</span> man<span>teau</span> et <span>un</span> cha<span>peau</span> rou<span>ges</span>. À <span>la</span> fin, <span>ils</span> bâ<span>ti</span>rent <span>u</span>ne <span>mu</span>rail<span>le</span> tout <span>au</span>tour <span>de</span> la <span>vil</span>le <span>a</span>vec <span>trois</span> tours <span>im</span>po<span>san</span>tes, <span>u</span>ne <span>pour</span> cha<span>que</span> bri<span>gand</span>, pour <span>les</span> re<span>mer</span>ci<span>er</span>.",
    "L'ob<span>ten</span>ti<span>on</span> de <span>mon</span> bac<span>ca</span>lau<span>ré</span>at <span>à</span> l'â<span>ge</span> de <span>dix</span>-huit <span>ans</span> ne <span>se</span> fit <span>pas</span> sans <span>mal</span>. Je <span>dus</span> mon <span>di</span>plô<span>me</span> qu'à <span>u</span>ne <span>sé</span>an<span>ce</span> de <span>rat</span>tra<span>pa</span>ge. <span>Pen</span>dant <span>que</span> tout <span>le</span> mon<span>de</span> fê<span>tait</span> leur <span>ré</span>sul<span>tat</span>, je <span>fus</span> as<span>si</span>gné <span>aux</span> ré<span>vi</span>si<span>ons</span> au <span>vu</span> des <span>rat</span>tra<span>pa</span>ges <span>qui</span> au<span>ront</span> lieu <span>trois</span> jours <span>plus</span> tard.",
    "Mon <span>pè</span>re <span>est</span> pas<span>sé</span> tout <span>à</span> l'heu<span>re</span> chez <span>moi</span> sans <span>pré</span>ve<span>nir</span>. On <span>a</span> par<span>lé</span> de <span>cho</span>ses <span>et</span> d'au<span>tres</span> et <span>il</span> a <span>ha</span>bil<span>le</span>ment <span>glis</span>sé <span>dans</span> le <span>fil</span> de <span>no</span>tre <span>con</span>ver<span>sa</span>ti<span>on</span> l'his<span>toi</span>re <span>de</span> mon <span>per</span>mis <span>de</span> con<span>dui</span>re. <span>Il</span> sou<span>hai</span>te <span>que</span> je <span>pas</span>se <span>mon</span> per<span>mis</span> mais <span>pas</span> moi.",
    "Le <span>train</span> a <span>stop</span>pé <span>le</span> long <span>du</span> quai. <span>U</span>ne <span>ga</span>re <span>sans</span> gui<span>chet</span>. Phi<span>lip</span>pe <span>m'at</span>ten<span>dait</span>. Son <span>bad</span>ge <span>de</span> gar<span>de</span> fo<span>res</span>ti<span>er</span> bril<span>lait</span> au <span>re</span>vers <span>de</span> sa <span>ves</span>te. <span>Il</span> a<span>vait</span> pris <span>des</span> ri<span>des</span>, les <span>che</span>veux <span>en</span> brous<span>sa</span>ille, <span>u</span>ne <span>bar</span>be <span>de</span> trois <span>jours</span> et <span>des</span> ki<span>los</span> en <span>trop</span>. Phi<span>lip</span>pe <span>est</span> mon <span>frè‌</span>re.",
    "On <span>é‌</span>tait <span>trois</span> se<span>mai</span>nes <span>a</span>vant <span>No</span>ël. <span>J'é‌</span>tais <span>ar</span>ri<span>vée</span> au <span>Val</span> par <span>le</span> seul <span>train</span> pos<span>si</span>ble, <span>ce</span>lui <span>de</span> on<span>ze</span> heu<span>res</span>. Tous <span>les</span> au<span>tres</span> ar<span>rêts</span> a<span>vaient</span> é‌<span>té‌</span> sup<span>pri</span>més <span>pour</span> ga<span>gner</span> quel<span>ques</span> mi<span>nu</span>tes. <span>Il</span> a <span>lon</span>gé <span>le</span> che<span>nil</span>. Je <span>me</span> suis <span>pla</span>qué <span>le</span> front <span>à‌</span> la <span>vi</span>tre, <span>j'ai</span> a<span>per</span>çu <span>les</span> gril<span>la</span>ges, <span>les</span> ni<span>ches</span>, les <span>chiens</span>.",
    "J'ai <span>choi</span>si <span>u</span>ne <span>o</span>ran<span>ge</span> dans <span>le</span> fi<span>let</span> et <span>je</span> l'ai <span>pe</span>lée <span>a</span>vec <span>les</span> dents. <span>J'ai</span> tou<span>jours</span> ai<span>mé‌</span> fai<span>re</span> ça. <span>Ar</span>ra<span>cher</span> la <span>peau</span> à <span>la</span> sau<span>va</span>ge <span>et</span> me <span>ren</span>dre <span>la</span> bou<span>che</span> a<span>mè‌</span>re. <span>J'ai</span> po<span>sé‌</span> les <span>é‌</span>plu<span>chu</span>res <span>sur</span> le <span>des</span>sus <span>brû‌</span>lant <span>du</span> ra<span>di</span>a<span>teur</span> é‌<span>lec</span>tri<span>que</span>. En <span>chauf</span>fant, <span>les</span> pe<span>lu</span>res <span>d'o</span>ran<span>ge</span> de<span>ve</span>naient <span>rous</span>ses, <span>pres</span>que <span>noi</span>res, <span>el</span>les <span>ne</span> brû‌<span>laient</span> pas <span>mais</span> dif<span>fu</span>saient <span>un</span> doux <span>par</span>fum <span>d'a</span>gru<span>me</span>.",
    "Je <span>me</span> dé‌<span>pla</span>ce <span>a</span>vant <span>mê‌</span>me <span>que</span> mon <span>cer</span>veau <span>en</span>re<span>gis</span>tre <span>le</span> mou<span>ve</span>ment <span>et</span> je <span>me</span> re<span>trou</span>ve <span>a</span>ge<span>nou</span>illée <span>sur</span> le <span>sol</span> dur <span>à‌</span> cô‌<span>té‌</span> de <span>Rex</span>, sans <span>sa</span>voir <span>com</span>ment <span>j'y</span> suis <span>ar</span>ri<span>vée</span>. Mon <span>vi</span>sa<span>ge</span> à <span>quel</span>ques <span>cen</span>ti<span>mè‌</span>tres <span>du</span> sien. <span>Je</span> po<span>se</span> u<span>ne</span> main <span>sur</span> le <span>so</span>fa <span>à‌</span> cô‌<span>té‌</span> de <span>sa</span> tê‌<span>te</span> pour <span>re</span>trou<span>ver</span> l'é‌<span>qui</span>li<span>bre</span>. Rex <span>est</span> mon <span>chi</span>en.",
    "Trois <span>fem</span>mes <span>vi</span>vaient <span>dans</span> un <span>vil</span>la<span>ge</span>. La <span>pre</span>mi<span>è‌</span>re <span>é‌</span>tait <span>mé‌</span>chan<span>te</span>, la <span>deu</span>xi<span>è‌</span>me <span>é‌</span>tait <span>men</span>teu<span>se</span>, la <span>troi</span>si<span>è‌</span>me <span>é‌</span>tait <span>é‌</span>go<span>ïs</span>te. <span>El</span>les <span>pos</span>sé‌<span>daient</span> pour<span>tant</span> un <span>point</span> com<span>mun</span>, un <span>se</span>cret <span>en</span> quel<span>que</span> sor<span>te</span>: tou<span>tes</span> les <span>trois</span> rê‌<span>vaient</span> de <span>fuir</span>.",
    "Ils <span>sont</span> ve<span>nus</span> me <span>voir</span>. El<span>le</span> é‌<span>tait</span> en <span>ro</span>be<span> ta</span>illeur, <span>on</span> au<span>rait</span> dit <span>Ka</span>te <span>Mi</span>ddle<span>ton</span>. Lui <span>a</span>vait <span>mit</span> un <span>cos</span>tu<span>me</span>. I<span>ci</span>, si <span>tu</span> croi<span>ses</span> quel<span>qu'un</span> en <span>cos</span>tu<span>me</span>-cra<span>va</span>te, <span>tu</span> te <span>dis</span> qu'il <span>va</span> à <span>un</span> ma<span>ri</span>a<span>ge</span>, pas <span>qu'il</span> re<span>vi</span>en<span>ne</span> du <span>bou</span>lot. <span>Ça</span> a <span>dû‌</span> lui <span>fai</span>re <span>bi</span>zar<span>re</span> de <span>ve</span>nir <span>dans</span> no<span>tre</span> quar<span>ti</span>er.",
    "Ceux <span>qui</span> l'a<span>vait</span> croi<span>sé‌</span> di<span>sait</span> qu'il <span>é‌</span>tait <span>grand</span>. En<span>fin</span>, ceux <span>qui</span> di<span>saient</span> ça, <span>c'é‌</span>taient <span>ceux</span> qui <span>pou</span>vaient <span>en</span>co<span>re</span> par<span>ler</span>. Par<span>ce</span> que, <span>ma</span>lheu<span>reu</span>se<span>ment</span>, la <span>plu</span>part <span>de</span> ceux <span>qui</span> l'a<span>vaient</span> croi<span>sé‌</span> é‌<span>taient</span> re<span>ve</span>nus <span>com</span>plè‌<span>te</span>ment <span>pa</span>tra<span>ques</span>. Et <span>bien</span>, oui, <span>for</span>cé‌<span>ment</span>, quand <span>on</span> est <span>un</span> gé‌<span>ant</span>, grand <span>com</span>me <span>u</span>ne <span>mai</span>son <span>à‌</span> deux <span>é‌</span>ta<span>ges</span>, a<span>vec</span> d'aus<span>si</span> grands <span>bras</span>.",
    "Et <span>bien</span>, oui, <span>for</span>cé‌<span>ment</span>, quand <span>on</span> est <span>un</span> gé‌<span>ant</span>, grand <span>com</span>me <span>u</span>ne <span>mai</span>son <span>à‌</span> deux <span>é‌</span>ta<span>ges</span>, a<span>vec</span> d'aus<span>si</span> grands <span>bras</span>, d'aus<span>si</span> gran<span>des</span> jam<span>bes</span> et <span>sur</span>tout <span>d'aus</span>si <span>grands</span> pi<span>eds</span>, for<span>cé‌</span>ment, <span>dés</span> qu'on <span>re</span>mue <span>un</span> pe<span>tit</span> peu, <span>on</span> é‌<span>cra</span>bou<span>ille</span> les <span>gens</span> qui <span>sont</span> ve<span>nus</span> vous <span>ren</span>dre <span>vi</span>si<span>te</span> pour <span>pren</span>dre <span>le</span> thé. <span>Vous</span> n'i<span>ma</span>gi<span>nez</span> pas <span>le</span> nom<span>bre</span> de <span>per</span>son<span>nes</span>, qu'il <span>a</span>vait <span>é‌</span>cra<span>bou</span>illées <span>et</span> trans<span>for</span>mées <span>en</span> crê‌<span>pes</span>, sans <span>le</span> fai<span>re</span> ex<span>près</span>.",
    "Sou<span>vent</span>, la <span>nuit</span>, pen<span>dant</span> que <span>tout</span> le <span>vil</span>la<span>ge</span> dor<span>mait</span> à <span>poings</span> fer<span>més</span>, le <span>gé</span>ant <span>pleu</span>rait <span>et</span> sou<span>pi</span>rait <span>car</span> il <span>se</span> ren<span>dait</span> bien <span>com</span>pte <span>qu'il</span> a<span>vait</span> de <span>moins</span> en <span>moins</span> d'a<span>mis</span>. Il <span>ai</span>me<span>rait</span> se <span>fai</span>re <span>de</span> nou<span>veau</span> ami <span>mais</span> c'est <span>dif</span>fi<span>ci</span>le. <span>Sur</span>tout <span>que</span> les <span>per</span>son<span>nes</span> ont <span>sou</span>vent <span>peur</span> de <span>moi</span> à <span>cau</span>se <span>de</span> ma <span>si</span> gran<span>de</span> ta<span>ille</span>.",
    "Cet<span>te</span> ca<span>tas</span>tro<span>phe</span> fai<span>sait</span> par<span>tout</span> un <span>grand</span> bruit <span>pen</span>dant <span>quel</span>ques <span>heu</span>res. <span>Can</span>di<span>ce</span> et <span>Mar</span>tin, <span>en</span> re<span>tour</span>nant <span>à</span> la <span>pe</span>ti<span>te</span> mé<span>tai</span>rie, <span>ren</span>con<span>trè</span>rent <span>un</span> bon <span>vi</span>eil<span>lard</span> qui <span>pre</span>nait <span>le</span> frais <span>à</span> sa <span>por</span>te <span>sous</span> un <span>ber</span>ceau <span>d'o</span>ran<span>gers</span>. Mar<span>tin</span>, qui <span>é</span>tait <span>aus</span>si <span>cu</span>ri<span>eux</span> que <span>rai</span>son<span>neur</span>, lui <span>de</span>man<span>da</span> des <span>con</span>seils.",
    "J'i<span>gno</span>re <span>ab</span>so<span>lu</span>ment <span>l'a</span>ven<span>tu</span>re <span>dont</span> vous <span>me</span> par<span>lez</span>. Je <span>pré</span>su<span>me</span> qu'en <span>gé</span>né<span>ral</span> ceux <span>qui</span> se <span>mê</span>lent <span>des</span> af<span>fai</span>res <span>pu</span>bli<span>ques</span> pé<span>ris</span>sent <span>quel</span>ques <span>fois</span> mi<span>sé</span>ra<span>ble</span>ment. <span>Je</span> m'in<span>for</span>me <span>ja</span>mais <span>de</span> ce <span>qu'on</span> fait <span>sur</span> la <span>pla</span>ce <span>du</span> vil<span>la</span>ge. <span>Je</span> me <span>con</span>ten<span>te</span> d'y <span>en</span>voy<span>er</span> ven<span>dre</span> les <span>fruits</span> du <span>jar</span>din <span>que</span> je <span>cul</span>ti<span>ve</span>.",
    "Il <span>fit</span> en<span>trer</span> les <span>é</span>tran<span>gers</span> dans <span>sa</span> mai<span>son</span>. Ses <span>deux</span> fil<span>les</span> et <span>ses</span> deux <span>fils</span> leur <span>pré</span>sen<span>tè</span>rent <span>plu</span>si<span>eurs</span> sor<span>tes</span> de <span>sor</span>bets <span>qu'ils</span> fai<span>saient</span> eux-<span>mê</span>mes. <span>Il</span> y <span>a</span> des <span>sor</span>bets <span>aux</span> ci<span>trons</span>, aux <span>o</span>ran<span>ges</span>, aux <span>a</span>na<span>nas</span>, à <span>la</span> pis<span>ta</span>che <span>et</span> au <span>ca</span>fé <span>Mo</span>ka. <span>Ils</span> se <span>ré</span>ga<span>lè</span>rent <span>tous</span> et <span>tou</span>tes. <span>Les</span> é<span>tran</span>gers <span>re</span>par<span>tent</span> le <span>ven</span>tre <span>plein</span>.",
    "On <span>a</span> beau <span>fai</span>re, <span>com</span>pa<span>tir</span>, es<span>say</span>er <span>de</span> com<span>pren</span>dre, <span>on</span> ne <span>peut</span> ja<span>mais</span> vrai<span>ment</span> res<span>sen</span>tir <span>la</span> dou<span>leur</span> qu'é<span>prou</span>ve <span>l'au</span>tre, <span>ni</span> dans <span>sa</span> tê<span>te</span> ni <span>dans</span> son <span>corps</span>. Et <span>sans</span> dou<span>te</span> que <span>c'est</span> mi<span>eux</span> ain<span>si</span>.",
    "Je <span>l’ai</span> vu <span>ar</span>ri<span>ver</span> de <span>loin</span>. Je <span>ne</span> sais <span>pas</span>, sa <span>dé</span>mar<span>che</span> peut-<span>ê</span>tre, <span>un</span> peu <span>non</span>cha<span>lan</span>te <span>ou</span> les <span>pans</span> de <span>son</span> man<span>teau</span>  qui <span>pre</span>naient <span>de</span> l’ai<span>san</span>ce <span>de</span>vant <span>lui</span>. Bref, <span>j’é</span>tais <span>à</span> vingt <span>mè</span>tres <span>de</span> lui <span>et</span> je <span>sa</span>vais <span>dé</span>jà <span>que</span> je <span>ne</span> le <span>ra</span>te<span>rai</span> pas. <span>Ça</span> n’a <span>pas</span> lou<span>pé</span>, ar<span>ri</span>vé <span>à</span> ma <span>hau</span>teur, <span>je</span> le <span>vois</span> me <span>re</span>gar<span>der</span>. Je <span>lui</span> dé<span>co</span>che <span>un</span> sou<span>ri</span>re, <span>gen</span>re <span>flè</span>che <span>de</span> Cu<span>pi</span>don <span>mais</span> en <span>plus</span> ré<span>ser</span>vé.",
    "Il <span>me</span> sou<span>rit</span> aus<span>si</span>. En <span>pas</span>sant <span>mon</span> che<span>min</span>, <span>je</span> con<span>ti</span>nue <span>de</span> sou<span>ri</span>re, <span>je</span> re<span>pen</span>se <span>à</span> lui. <span>Je</span> mar<span>che</span> moins <span>vi</span>te <span>car</span> j’es<span>sa</span>ye <span>de</span> me <span>sou</span>ve<span>nir</span>. Et <span>pen</span>dant <span>ce</span> temps-<span>là</span>, je <span>sens</span> son <span>re</span>gard <span>tou</span>jours <span>dans</span> mon <span>dos</span>. Je <span>n'o</span>se <span>pas</span> me <span>re</span>tour<span>ner</span>.",
    "<span>Mar</span>cel <span>se</span> ré<span>veil</span>le <span>tout</span> joy<span>eux</span> et <span>ex</span>ci<span>té</span>. C’est <span>No</span>ël. <span>Il</span> se <span>pré</span>ci<span>pi</span>te <span>vers</span> le <span>sa</span>lon <span>où</span> il <span>a</span> dé<span>co</span>ré <span>un</span> jo<span>li</span> sa<span>pin</span> a<span>vec</span> ma<span>man</span>. Il <span>ne</span> fait <span>mê</span>me <span>pas</span> at<span>ten</span>ti<span>on</span> aux <span>guir</span>lan<span>des</span> lu<span>mi</span>neu<span>ses</span> qui <span>cli</span>gno<span>tent</span>. Ce<span>la</span> ne <span>l’in</span>té<span>res</span>se <span>pas</span> et <span>il</span> es<span>pè</span>re <span>que</span> le <span>pè</span>re <span>No</span>ël <span>est</span> pas<span>sé</span>.",
    "El<span>le</span> é<span>tait</span>, com<span>me</span> sou<span>vent</span>, é<span>ten</span>due <span>sur</span> son <span>lit</span> en <span>mez</span>za<span>ni</span>ne <span>en</span>tou<span>rée</span> de <span>cous</span>sins <span>mul</span>ti<span>co</span>lo<span>res</span>, un <span>li</span>vre <span>en</span>tre <span>les</span> mains. <span>El</span>le <span>a</span>vait <span>les</span> che<span>veux</span> noirs <span>et</span> rai<span>des</span> com<span>me</span> des <span>ba</span>guet<span>tes</span>. El<span>le</span> les <span>a</span>vait <span>at</span>ta<span>chés</span> a<span>vec</span> u<span>ne</span> bar<span>ret</span>te <span>car</span> la <span>cha</span>leur <span>se</span> fai<span>sait</span> sen<span>tir</span> en <span>cet</span>te <span>soi</span>rée <span>es</span>ti<span>va</span>le.",
    "De<span>puis</span> le <span>C</span>M<span>1</span> el<span>le</span> é<span>tait</span> fas<span>ci</span>née <span>par</span> le <span>mon</span>de <span>des</span> sor<span>ci</span>ers <span>et</span> sur<span>tout</span> par <span>la</span> sé<span>rie</span> «Har<span>ry</span> Pot<span>ter</span>» dont <span>el</span>le <span>a</span>vait <span>lu</span> les <span>sept</span> to<span>mes</span> et <span>dont</span> el<span>le</span> a<span>vait</span> vu <span>les</span> films <span>plu</span>si<span>eurs</span> fois, <span>jus</span>qu’à <span>en</span> con<span>naî</span>tre <span>cer</span>tai<span>nes</span> ré<span>pli</span>ques <span>par</span> cœur. <span>El</span>le <span>s’i</span>den<span>ti</span>fi<span>ait</span> au <span>per</span>son<span>na</span>ge <span>d’Her</span>mi<span>o</span>ne <span>dont</span> el<span>le</span> par<span>ta</span>geait <span>la</span> pre<span>mi</span>è<span>re</span> let<span>tre</span> du <span>pré</span>nom.",
    "En <span>cet</span>te <span>veil</span>le <span>de</span> week-<span>end</span>, el<span>le</span> é<span>tait</span> ren<span>trée</span> du <span>col</span>lè<span>ge</span> le <span>plus</span> vi<span>te</span> pos<span>si</span>ble. <span>El</span>le <span>a</span>vait <span>hâ</span>te <span>de</span> re<span>trou</span>ver <span>Har</span>ry <span>et</span> ses <span>ca</span>ma<span>ra</span>des <span>et</span> de <span>sui</span>vre <span>leurs</span> a<span>ven</span>tu<span>res</span>. La <span>jeu</span>ne <span>fil</span>le <span>tour</span>nait <span>les</span> pa<span>ges</span> fré<span>né</span>ti<span>que</span>ment. <span>El</span>le <span>ne</span> s’é<span>tait</span> pas <span>ren</span>du <span>comp</span>te <span>du</span> temps <span>pas</span>sé <span>quand</span> son <span>frè</span>re <span>en</span>tra <span>en</span> trom<span>be</span> dans <span>sa</span> cham<span>bre</span> pour <span>l’ai</span>der <span>à</span> met<span>tre</span> la <span>ta</span>ble.",
    "Sans <span>at</span>ten<span>dre</span> sa <span>ré</span>pon<span>se</span>, il <span>cla</span>qua <span>la</span> por<span>te</span> si <span>vio</span>le<span>mment</span> que <span>l’af</span>fi<span>che</span> d’Har<span>ry</span> Pot<span>ter</span> qu’el<span>le</span> a<span>vait</span> scot<span>chée</span> me<span>na</span>ça <span>de</span> se <span>dé</span>ta<span>cher</span>. El<span>le</span> en <span>é</span>tait <span>au</span> cha<span>pi</span>tre <span>22</span> quand <span>sa</span> mè<span>re</span> à <span>son</span> tour <span>lui</span> de<span>man</span>da <span>de</span> ve<span>nir</span> pour <span>le</span> dî<span>ner</span> car <span>il</span> é<span>tait</span> prêt.",
    "<span>Ce</span> ma<span>tin</span>-là, <span>Al</span>ma <span>a</span>vait <span>é</span>té <span>ré</span>veil<span>lée</span> trop <span>tôt</span> à <span>son</span> goût <span>par</span> son <span>pè</span>re. <span>Com</span>me <span>tous</span> les <span>ma</span>tins, <span>el</span>le <span>a</span>vait <span>a</span>va<span>lé</span> son <span>bol</span> de <span>cé</span>ré<span>a</span>les <span>en</span> vi<span>tes</span>se. <span>El</span>le <span>a</span>vait <span>en</span>fi<span>lé</span> à <span>tou</span>te <span>al</span>lu<span>re</span> un <span>short</span> en <span>jean</span> et <span>son</span> T-<span>shirt</span> jau<span>ne</span> vif <span>pour</span> al<span>ler</span> au <span>col</span>lè<span>ge</span> a<span>fin</span> d’a<span>voir</span> un <span>peu</span> de <span>temps</span> pour <span>dis</span>cu<span>ter</span> a<span>vec</span> ses <span>meil</span>leurs <span>co</span>pains, <span>An</span>toi<span>ne</span>, Sa<span>rah</span> et <span>A</span>mi<span>na</span>ta.",
    "C’é<span>tait</span> cen<span>sé</span> ê<span>tre</span> u<span>ne</span> jour<span>née</span> tout <span>à</span> fait <span>or</span>di<span>nai</span>re. <span>Quel</span>le <span>ne</span> fut <span>pas</span> sa <span>sur</span>pri<span>se</span> quand <span>Ma</span>da<span>me</span> Zhang <span>leur</span> an<span>non</span>ça <span>qu’ils</span> de<span>vaient</span> <span>pré</span>pa<span>rer</span> des <span>chan</span>sons. <span>Ils</span> les <span>in</span>ter<span>pré</span>te<span>raient</span> à <span>la</span> fê<span>te</span> de <span>la</span> mu<span>si</span>que, <span>pré</span>vue <span>dans</span> un <span>mois</span>. Al<span>ma</span> sen<span>tit</span> son <span>cœur</span> bat<span>tre</span> la <span>cha</span>ma<span>de</span>.",
    "Et <span>ce</span> n’é<span>tait</span> rien <span>en</span> com<span>pa</span>rai<span>son</span> de <span>ce</span> qu’el<span>le</span> res<span>sen</span>tit <span>quand</span> en <span>fin</span> de <span>ma</span>ti<span>née</span>, Mon<span>si</span>eur <span>Ryth</span>mo, <span>le</span> pro<span>fes</span>seur <span>de</span> mu<span>si</span>que <span>leur</span> ex<span>pli</span>qua <span>que</span> cer<span>tains</span> é<span>lè</span>ves <span>de</span>vraient <span>jou</span>er <span>de</span> la <span>mu</span>si<span>que</span> pour <span>ac</span>com<span>pa</span>gner <span>les</span> chan<span>sons</span>. Chan<span>ter</span> et <span>jou</span>er <span>de</span> la <span>mu</span>si<span>que</span>? Mais <span>ce</span> n’est <span>pas</span> pos<span>si</span>ble <span>pen</span>sait-<span>el</span>le.",
    "Tout <span>le</span> mon<span>de</span> va <span>s’a</span>per<span>ce</span>voir <span>que</span> je <span>chan</span>te <span>faux</span> et <span>qu’à</span> part «<span>Au</span> clair <span>de</span> la <span>lu</span>ne» <span>à</span> la <span>flû</span>te, <span>je</span> ne <span>sais</span> jou<span>er</span> d’au<span>cun</span> ins<span>tru</span>ment. <span>J’ai</span>me <span>bien</span> é<span>cri</span>re <span>des</span> tex<span>tes</span> de <span>chan</span>sons <span>pour</span> m’a<span>mu</span>ser <span>mais</span> chan<span>ter</span>. La <span>der</span>ni<span>è</span>re <span>fois</span> qu’el<span>le</span> a<span>vait</span> chan<span>té</span> pour <span>l’an</span>ni<span>ver</span>sai<span>re</span> de <span>sa</span> grand-<span>mè</span>re, <span>Clé</span>ment, <span>son</span> grand <span>frè</span>re, <span>lui</span> a<span>vait</span> dit «<span>je</span> crois <span>qu’il</span> va <span>pleu</span>voir»!",
    "El<span>le</span> ai<span>mait</span> bien <span>re</span>gar<span>der</span> tous <span>les</span> sa<span>me</span>dis <span>soir</span> l’é<span>mis</span>si<span>on</span> de <span>té</span>lé<span>vi</span>si<span>on</span> a<span>vec</span> des <span>can</span>di<span>dats</span> qui <span>chan</span>tent <span>de</span>vant <span>un</span> ju<span>ry</span>. Mais <span>el</span>le <span>ne</span> se <span>sen</span>tait <span>pas</span> ce <span>ta</span>lent. <span>An</span>toi<span>ne</span> fai<span>sait</span> de <span>la</span> bat<span>te</span>rie, <span>A</span>mi<span>na</span>ta <span>et</span> Sa<span>rah</span> chan<span>taient</span> tous <span>les</span> mer<span>cre</span>dis <span>a</span>près-<span>mi</span>di <span>en</span> du<span>o</span> à <span>la</span> Mai<span>son</span> de <span>quar</span>ti<span>er</span>; el<span>le</span>, c’é<span>tait</span> plu<span>tôt</span> le <span>rug</span>by <span>sa</span> spé<span>ci</span>a<span>li</span>té.",
    "Quand <span>el</span>le <span>ren</span>tra <span>chez</span> el<span>le</span> a<span>près</span> les <span>cours</span>, Al<span>ma</span> trou<span>va</span> Clé<span>ment</span> af<span>fa</span>lé <span>dans</span> le <span>ca</span>na<span>pé</span> en <span>train</span> de <span>man</span>ger <span>la</span> der<span>ni</span>è<span>re</span> gau<span>fre</span> au <span>cho</span>co<span>lat</span> du <span>pa</span>quet. <span>Af</span>fa<span>mée</span> et <span>a</span>ga<span>cée</span>, el<span>le</span> com<span>men</span>ça <span>à</span> lui <span>ex</span>po<span>ser</span> com<span>bien</span> el<span>le</span> n’en <span>pou</span>vait <span>plus</span> qu’il <span>man</span>ge <span>ré</span>gu<span>li</span>è<span>re</span>ment <span>tous</span> les <span>pa</span>quets <span>de</span> bis<span>cuits</span> sans <span>pen</span>ser <span>à</span> el<span>le</span>.",
    "Lé<span>o</span> est <span>cham</span>pi<span>on</span> de <span>vé</span>lo. <span>L’an</span>née <span>der</span>ni<span>è</span>re <span>il</span> a <span>ga</span>gné <span>beau</span>coup <span>de</span> cour<span>ses</span> or<span>ga</span>ni<span>sées</span> pour <span>les</span> plus <span>jeu</span>nes, <span>mais</span> cet<span>te</span> an<span>née</span> il <span>va</span> cou<span>rir</span> a<span>vec</span> les <span>grands</span>. Pour <span>le</span> fé<span>li</span>ci<span>ter</span> et <span>l’en</span>cou<span>ra</span>ger, <span>ses</span> pa<span>rents</span> lui <span>ont</span> a<span>che</span>té <span>un</span> su<span>per</span> vé<span>lo</span>. Au<span>jour</span>d’hui <span>c’est</span> la <span>pre</span>mi<span>è</span>re <span>cour</span>se <span>de</span> la <span>nou</span>vel<span>le</span> sai<span>son</span>.",
    "Lé<span>o</span> est <span>tout</span> fi<span>er</span> et <span>tout</span> con<span>tent</span> de <span>pou</span>voir <span>cou</span>rir <span>a</span>vec <span>le</span> beau <span>mail</span>lot <span>bleu</span> et <span>blanc</span> du <span>club</span> cy<span>clis</span>te <span>de</span> sa <span>vil</span>le. <span>A</span>vec <span>son</span> nou<span>veau</span> vé<span>lo</span> aux <span>cou</span>leurs <span>du</span> mail<span>lot</span>, si <span>beau</span> et <span>si</span> lé<span>ger</span>, il <span>est</span> con<span>vain</span>cu <span>qu’il</span> va <span>en</span>co<span>re</span> ga<span>gner</span>. Pour <span>ce</span>la <span>Lé</span>o <span>a</span> fait <span>tout</span> le <span>né</span>ces<span>sai</span>re. <span>Il</span> s’est <span>soi</span>gneu<span>se</span>ment <span>en</span>traî<span>né</span> pen<span>dant</span> les <span>der</span>ni<span>ers</span> jours <span>de</span> l’hi<span>ver</span> a<span>vec</span> ses <span>co</span>pains <span>et</span>, ce <span>ma</span>tin, <span>il</span> a <span>bien</span> vé<span>ri</span>fi<span>é</span> tout <span>son</span> ma<span>té</span>ri<span>el</span>.",
    "Il <span>s’est</span> en<span>sui</span>te <span>pré</span>pa<span>ré</span> un <span>goû</span>ter <span>qu’il</span> a <span>four</span>ré <span>dans</span> son <span>pe</span>tit <span>sac</span> pour <span>le</span> ra<span>vi</span>tail<span>le</span>ment. <span>Mais</span>, a<span>vant</span> de <span>se</span> ren<span>dre</span> sur <span>la</span> li<span>gne</span> de <span>dé</span>part <span>il</span> faut <span>si</span>gner <span>son</span> nom <span>sur</span> la <span>lis</span>te <span>des</span> par <span>ti</span>ci<span>pants</span>. Lé<span>o</span> ap<span>puie</span> son <span>vé</span>lo <span>con</span>tre <span>un</span> ar<span>bre</span> et <span>se</span> di<span>ri</span>ge <span>vers</span> la <span>ta</span>ble <span>des</span> com<span>mi</span>ssai<span>res</span> de <span>cour</span>se. <span>Lé</span>o <span>se</span> re<span>tour</span>ne,<span>sif</span>flo<span>tant</span>. Mais<span>là</span>, cons<span>ter</span>na<span>ti</span>on <span>son</span> vé<span>lo</span> a <span>dis</span>pa<span>ru</span>!",
    "Cet <span>é</span>té <span>il</span> a <span>ap</span>pris <span>à</span> pé<span>da</span>ler <span>sans</span> rou<span>let</span> tes <span>sur</span> le <span>vé</span>lo <span>de</span> sa <span>cou</span>si<span>ne</span>. Il <span>é</span>tait <span>tout</span> fi<span>er</span>. Que <span>ce</span> fut <span>long</span> d’at<span>ten</span>dre <span>l’hi</span>ver <span>et</span> la <span>nei</span>ge! <span>Mar</span>cel <span>a</span> es<span>sa</span>yé <span>d’ê</span>tre <span>sa</span>ge <span>et</span> il <span>a</span> tra<span>va</span>il<span>lé</span> de <span>son</span> mi<span>eux</span> pour <span>ê</span>tre <span>ir</span>ré<span>pro</span>cha<span>ble</span>. Son <span>tra</span>vail <span>lui</span> a <span>d’ail</span>leurs <span>per</span>mis <span>d’é</span>cri<span>re</span> la <span>fa</span>meu<span>se</span> let<span>tre</span> au <span>Pè</span>re <span>No</span>ël <span>pour</span> de<span>man</span>der <span>le</span> su<span>per</span>be <span>vé</span>lo <span>rou</span>ge <span>qu’il</span> a<span>vait</span> vu <span>dans</span> le <span>ma</span>ga<span>sin</span>.",
    "Les <span>der</span>ni<span>ers</span> jours <span>a</span>vaient <span>pas</span>sé <span>vi</span>te <span>mais</span> hi<span>er</span> soir <span>les</span> a<span>dul</span>tes <span>sem</span>blaient <span>pren</span>dre <span>un</span> ma<span>lin</span> plai<span>sir</span> à <span>fai</span>re <span>du</span>rer <span>le</span> ré<span>veil</span>lon <span>de</span> No<span>ël</span>. A<span>lors</span> que <span>Mar</span>cel <span>n’a</span>vait <span>qu’u</span>ne <span>hâ</span>te, i<span>nha</span>bi<span>tu</span>el<span>le</span>: al<span>ler</span> se <span>cou</span>cher. <span>Pour</span>tant <span>u</span>ne <span>fois</span> dans <span>son</span> lit <span>im</span>pos<span>si</span>ble <span>de</span> trou<span>ver</span> le <span>som</span>meil: <span>il</span> s’i<span>ma</span>gi<span>nait</span> dé<span>jà</span> pé<span>da</span>lant <span>de</span> tou<span>tes</span> ses <span>for</span>ces <span>dans</span> les <span>al</span>lées <span>du</span> parc.",
    "Un <span>loup</span> vit <span>un</span> a<span>gneau</span> qui <span>bu</span>vait <span>à</span> un <span>cours</span> d'eau <span>et</span> pré<span>ten</span>dit <span>se</span> cou<span>vrir</span> d'un <span>mo</span>tif <span>rai</span>son<span>na</span>ble <span>pour</span> le <span>dé</span>vo<span>rer</span>. Aus<span>si</span>, bien <span>qu'il</span> se <span>tînt</span> lui-<span>mê</span>me <span>en</span> a<span>mont</span>, l'ac<span>cu</span>sa-<span>t-il</span> de <span>trou</span>bler <span>l'eau</span> et <span>de</span> l'em<span>pê</span>cher <span>de</span> boi<span>re</span>. L'au<span>tre</span> ré<span>pon</span>dit <span>qu'il</span> ne <span>bu</span>vait <span>que</span> du <span>bout</span> des <span>lè</span>vres <span>et</span> que <span>d'ail</span>leurs <span>il</span> lui <span>é</span>tait <span>im</span>pos<span>si</span>ble, <span>en</span> a<span>val</span>, de <span>rien</span> fai<span>re</span> à <span>l'eau</span> qui <span>cou</span>lait <span>au</span>-des<span>sus</span> de <span>lui</span>.",
]

export const smallTextData = [
    "Dans une forêt dense, un renard rusé déjoua les plans d'un loup affamé. La leçon apprise: la ruse triomphe souvent de la force brute.",
    "Au sommet de la montagne, un corbeau malin vola un fromage brillant. Le renard rusé l'encouragea à chanter, le corbeau perdit son repas.",
    "Dans un jardin secret, une fleur solitaire se languissait de la lumière. Un papillon, porteur d'espoir, lui offrit la douce caresse du soleil.",
    "Dans une bibliothèque ancienne, un livre oublié révéla les secrets d'un monde oublié. Le lecteur intrépide découvrit la clé de son destin.",
    "Dans l'obscurité de la nuit, une étoile solitaire brilla d'un éclat incomparable, illuminant les cœurs perdus en quête de réconfort.",
    "Dans une forêt enchantée, un lapin curieux découvrit un trésor scintillant. Avec l'aide de ses amis, il le partagea, semant joie et amitié.",
    "Un vieil arbre enseigna aux habitants l'importance de l'unité. Ensemble, ils affrontèrent les tempêtes, prouvant que la force réside dans la solidarité.",
    "Un porteur d’eau indien avait deux grandes jarres, suspendues aux deux extrémités d’une pièce de bois qui épousait la forme de ses épaules.",
    "Le fils de la voisine avait treize ans. Il ne se croyait pas beau. Il était même tellement complexé qu’il refusait de sortir de la maison.",
    "En plein cœur de la Sibérie, au nord de la très grande Russie, vivait une vieille dame qui connaissait le secret du bonheur.",
    "Il était une fois une petite fille qui ne trouvait jamais les mots pour dire ce qu’elle ressentait. Les mots semblaient courir plus vite que sa pensée.",
    "Bufalo était un cheval d’une très grande beauté, mais aucun cavalier ne pouvait le monter. Il se cabrait et finissait par faire tomber le cavalier.",
    "Le courage n'est pas l'absence de peur, mais la capacité de vaincre ce qui fait peur.",
    "Les habitants du village étaient émerveillés par le jardin d’Élodie et venaient de loin pour en admirer la beauté.",
    "Dans une prairie verdoyante, un lièvre arrogant perdit une course contre une tortue persévérante.",
    "Sur une montagne escarpée, un jeune renard apprit la valeur du travail acharné en observant les fourmis.",
    "Sous un ciel étoilé, un petit oiseau apprit l'importance de l'humilité en voyant l'aigle se courber pour passer sous un pont.",
    "Au cœur d'une clairière enchantée, une luciole modeste éclaira le chemin pour une chenille perdue. Chaque acte de gentillesse illumine le monde.",
    "Une fleur humble offrit son nectar à un papillon épuisé. L'amour et le partage enrichissent les cœurs.",
    "Un colibri rapide apprit la patience en observant une tortue lente. La persévérance triomphe toujours sur la hâte.",
    "Un jeune arbre comprit l'importance de la patience en voyant un chêne majestueux se dresser lentement vers le ciel.",
    "Dans une prairie animée, une abeille partagea son pollen avec une fleur solitaire. Elle a pu reprendre des forces.",
    "Dans un village tranquille, une jeune fille généreuse partagea son repas avec un vagabond affamé.",
    "Sur la plage ensoleillée, un crabe astucieux sauva une étoile de mer échouée en la ramenant à l'océan.",
    "Le lion ne savait pas écrire. Mais ça lui était bien égal, car il savait rugir et montrer les crocs. Et pour un lion, c’est bien suffisant.",
    "Une reine était assise à sa fenêtre encadrée de bois d'ébène et cousait. Tout en tirant l'aiguille, elle regardait voler les blancs flocons.",
    "Il était une fois, dans une forêt enchantée, un petit lapin nommé Flocon, qui rêvait de devenir le plus rapide de tous les animaux.",
    "Elle est minuscule, pourtant elle prend déjà tellement de place.",
    "Papa. C’est le premier mot que j’ai su prononcer. Un mot tout bête, qui sort sans qu’on y pense.",
    "Un sourire coûte moins cher que l'électricité, mais il donne autant de lumière.",
    "On a deux vies. La deuxième commence quand on réalise qu’on en a qu’une.",
    "La carte semblait mener à un endroit mystérieux, évoquant des ruines oubliées et des trésors cachés.",
    "Un jour, lors d'une excursion en montagne avec sa famille, Lily se retrouva séparée du groupe.",
    "Alice passa plus de temps à l'extérieur, à explorer la nature et moins de temps sur son téléphone.",
    "Chaque nuit, il observait les étoiles depuis le toit de sa maison, rêvant de voyager dans l'espace.",
    "Un soir, alors qu'il observait le ciel étoilé, Romain aperçut une étoile filante. Il fit un vœu.",
    " Il étudia les sciences et l'astronomie à l'école, et travailla dur pour obtenir des bonnes nôtes.",
    "Zoé rêvait de devenir chanteuse et de partager sa musique avec le monde entier.",
    "La confiance en soi est la clé du succès. Crois en toi-même et tout sera possible.",
    "Excité par cette opportunité, il décida de se lancer dans l'aventure et de créer son propre jeu.",
    "Malgré les obstacles et les défis rencontrés en cours de route, il resta déterminé à atteindre son objectif.",
    "L'histoire parlait d'une jeune exploratrice courageuse naviguant à travers des mondes fantastiques pour sauver son royaume.",
    "Élodie partageait généreusement les fruits et les fleurs de son jardin avec tous ceux qui en avaient besoin.",
    "Lorsque les résultats furent annoncés, Max retint son souffle. À sa grande joie, il remporta le premier prix du concours.",
    "Le rangement peut sembler fastidieux et décourageant au début, mais une fois qu'on s'y met sérieusement, les résultats valent bien l'effort.",
    "Un samedi matin ensoleillé, Emma, se réveilla avec une détermination nouvelle: elle allait enfin ranger sa chambre.",
    "Depuis des semaines, sa chambre ressemblait à un champ de bataille, avec des vêtements au sol et des jouets éparpillés un peu partout.",
    "Armée de courage, Fanni se mit au travail. Elle commença par écrire toutes les choses qu'elle avait à faire.",
    "Elle se tint debout au milieu de la pièce, épuisée mais fière de son travail.",
    "Lucas avait tendance à négliger ses devoirs, préférant jouer aux jeux vidéos et remettre à plus tard ses leçons à apprendre.",
    "Il comprit enfin l'importance de bien faire ses devoirs et de prendre l'école au sérieux.",
    "Chaque soir, les enfants du village se rassemblaient autour d'Elise pour écouter ses histoires et apprendre de nouvelles choses.",
    "Le papillon vole joyeusement de fleur en fleur, sans jamais rester au même endroit.",
    "Le petit Chaperon Rouge avait une grand-mère qui vivait seule à l'autre bout de la forêt.",
    "Grand-mère est malade, j'ai fait ce matin des galettes que ta grand-maman aime tant, sois gentille, va donc les lui porter.",
    "La mère dit à son garçon d’aller vendre leur vache. Jack rencontra un étrange personnage qui lui achète.",
    "Elle jeta les graines par la fenêtre et envoya Jack se coucher. Le lendemain matin, une immense plante avait poussé devant la fenêtre.",
    "Il escalada le haricot géant jusqu’au ciel. Il découvrit un château qui semblait flotter sur les nuages.",
    "Il était une fois un bûcheron et une bûcheronne qui avaient sept enfants tous des garçons.",
    "Quand les parents furent endormis, il se leva, enfila ses habits, et sortit de la maison.",
    "Comme les poches de Hansel étaient pleines de cailloux, Grethel mit le pain dans son tablier. Puis, ils se mirent tous en route pour la forêt.",
    "Quand la pleine lune brilla dans le ciel, il prit sa sœur par la main et suivit les petits cailloux blancs.",
    "C'était un serpent python mangeur d'hommes et tout le monde avait peur de lui.",
    "Plus personne ne le trouvait petit. On l'acclama, on le porta en triomphe et une grande fête fut organisée en son honneur.",
    "On raconte qu’elles étaient trois filles d’un même père. La plus jeune était douce et gentille. Les deux aînées la jalousaient et lui voulaient du mal.",
    "Dandine est heureuse dans son pré, car l'herbe y est douce et sucrée... et puis si fraiche, si belle, si verte, qu'elle ne pourrait plus s'en priver.",
    "J'ai compris que j'étais vieux le jour où je me suis retrouvé dans la vitrine d'un antiquaire.",
    "Le second visage dont je me souvienne est celui d'un petit garçon qui sourit en me serrant contre lui.",
    "Ce fut un jour atrocement triste lorsque des hommes en manteau de cuir noir et d'autres en uniforme vinrent chercher David et ses parents.",
    "Elle arriva à la petite maison des trois ours. elle jeta alors un coup d'œil à l'intérieur, par la porte restée ouverte.",
    "Comme il n'y avait personne, elle entra, ce qui n'était pas bien du tout. Elle vit trois bols sur la table.",
    "Dans un lointain royaume vivaient un roi et une reine qui avaient tout pour être heureux: un beau château, un peuple aimant, des serviteurs, des courtisans.",
    "Un violent orage s'abat sur la campagne, détruisant de sa puissante foudre les arbres desséchés qui ne sont plus que flammes. Le ciel devient lugubre.",
    "Paulette n'était pas si folle qu'on le disait. Bien sûr qu'elle reconnaissait les jours. Les compter, les attendre et les oublier.",
    "Son chat était devant la porte, il avait faim et c'est en se penchant pour reposer son bol que Paulette est tombée en se cognant la tête.",
    "Paulette tombait souvent, mais c'était son secret. Il ne fallait pas en parler, à personne. Il fallait se relever lentement.",
    "Les bleus de Paulette n'étaient jamais bleus. Ils étaient jaunes, verts ou violacés et restaient longtemps sur son corps. ",
    "Quand j’arrive à la gare de l‘Est, j’espère toujours secrètement qu’il y aura quelqu’un pour m’attendre. C’est bête.",
    "Le printemps, les petits oiseaux se chamaillent dans les bourgeons des peupliers. La nuit, les chats font beaucoup de bruit, les canards coursent les canardes.",
    "Quand il était petit, Pierre ne voulait pas manger sa soupe et ça faisait des tas d’histoires. Ça faisait des histoires avec sa mère, sa grand-mère.",
    "Une nuit, il se passa quelque chose de bizarre, oui, de vraiment bizarre ! Dans la chambre de Pierre, la porte de l’armoire s’entrouvrit avec un grincement terrible.",
    "Pierre alluma sa lampe de chevet. Un jouet était tombé de l'armoire. Ouf, ce que j'ai eu peur, dit Pierre. Je peux me rendormir maintenant!",
    "Cette jeune fille allongée là près d'un bel arbre centenaire rêvait en lisant son livre de contes. Elle songea alors à de beaux paysages peuplés de mille créatures.",
    "Sa pensée prit la liberté de vagabonder parmi ces êtres qui l'attiraient. Quand elle feuilletait cet ouvrage, elle était toujours emportée dans ce monde.",
    "À n'en pas douter, le prince de Motordu menait la belle vie. Il habitait un chapeau magnifique au dessus duquel, le dimanche, flottaient les crapauds bleu.",
    "Le prince de Motordu ne s'ennuyait jamais. Lorsque venait l'hiver, il faisait de superbes batailles de poules de neige. Le soir, il restait bien au chaud à jouer aux tartes.",
    "Le prince vivait à la campagne. Un jour, on le voyait mener paître son troupeau de boutons. Le lendemain, on pouvait l'admirer filant comme le vent sur son râteau à voiles.",
    "Quand le dimanche arrivait, il invitait ses amis à déjeuner. Le prince se montra sensible à ces arguments et prit la ferme résolution de se marier bientôt.",
    "Il ferma donc son chapeau à clé, rentra son troupeau de boutons dans les tables, puis monta dans sa toiture de course pour se mettre en quête d'une fiancée.",
    "Hélas, en cours de route, un pneu de sa toiture creva. Il n'y avait pas beaucoup d'élèves dans l'école de la princesse et on n'eut aucun mal à trouver une table libre.",
    "Dès qu'il commença à répondre aux questions qu'on lui posait, le prince déclencha l'hilarité parmi ses nouveaux camarades.",
    "Quant à son cahier, il était, à chaque ligne, plein de taches et de ratures, on eût dit un véritable torchon.",
    "Peu à peu, le prince de Motordu, grâce aux efforts constants de son institutrice, commença à faire des progrès.",
    "Au bout de quelques semaines, il parvint à parler normalement, mais ses camarades le trouvaient beaucoup moins drôle depuis qu'il ne tordait plus les mots.",
    "À la fin de l'année, cependant, il obtint le prix de camaraderie car, comme il était riche, il achetait chaque jour des kilos de bonbons qu'il distribuait.",
    "Lorsqu'il revint chez lui, après avoir passé une année en classe, le prince de Motordu avait complètement oublié de se marier.",
    "Mais quelques jours plus tard, il reçut une lettre qui lui rafraîchit la mémoire. Et c'est ainsi que le prince de Motordu épousa la maîtresse.",
    "On a beau faire, compatir, essayer de comprendre, on ne peut jamais vraiment ressentir la douleur qu'éprouve l'autre, ni dans sa tête ni dans son corps.",
    "Le train a stoppé le long du quai. Une gare sans guichet. Philippe m'attendait. Son badge de garde forestier brillait au revers de sa veste.",
    "Il avait pris des rides, les cheveux en broussaille, une barbe de trois jours et des kilos en trop. Philippe est mon frère.",
    "On était trois semaines avant Noël. J'étais arrivée au Val par le seul train possible, celui de onze heures.",
    "Tous les autres arrêts de train avaient été supprimés pour gagner quelques minutes. Il a longé le chenil. J'ai aperçu les grillages, les niches, les chiens.",
    "J'ai choisi une orange dans le filet et je l'ai pelée avec les dents. J'ai toujours aimé faire ça. Arracher la peau à la sauvage et me rendre la bouche amère.",
    "J'ai posé les épluchures sur le dessus brûlant du radiateur électrique. En chauffant, elles ne brûlaient pas mais diffusaient un doux parfum d'agrume.",
    "Je me déplace avant même que mon cerveau enregistre le mouvement et je me retrouve agenouillée sur le sol dur à côté de Rex, sans savoir comment j'y suis arrivée.",
    "Mon visage à quelques centimètres du sien. Je pose une main sur le sofa à côté de sa tête pour retrouver l'équilibre.",
    "Trois femmes vivaient dans un village. La première était méchante, la deuxième était menteuse, la troisième était égoïste.",
    "Elles possédaient pourtant un point commun, un secret en quelque sorte: toutes les trois rêvaient de fuir, de partir loin.",
    "Ils sont venus me voir. Elle était en robe tailleur, on aurait dit Kate Middleton. Lui avait mit un costume.",
    "Ici, si tu croises quelqu'un en costume-cravate, tu te dis qu'il va à un mariage, pas qu'il revienne du boulot.",
    "Ceux qui l'avait croisé disait qu'il était grand. Enfin, ceux qui disaient ça, c'étaient ceux qui pouvaient encore parler.",
    "Quand on est un géant, on écrabouille sans faire exprès, les gens qui sont venus vous rendre visite pour prendre le thé.",
    "Vous n'imaginez pas le nombre de personnes, qu'il avait écrabouillées et transformées en crêpes, sans le faire exprès.",
    "Souvent, la nuit, pendant que tout le village dormait à poings fermés, le géant pleurait et soupirait.",
    "Il se rendait bien compte qu'il avait de moins en moins d'amis. Il aimerait se faire de nouveau ami mais c'est difficile.",
    "Il fit entrer les étrangers dans sa maison. Ses deux filles et ses deux fils leur présentèrent plusieurs sortes de sorbets qu'ils faisaient eux-mêmes.",
    "Je l’ai vu arriver de loin. Je ne sais pas, sa démarche peut-être, un peu nonchalante ou les pans de son manteau qui prenaient de l’aisance devant lui.",
    "J’étais à vingt mètres de lui et je savais déjà que je ne le raterai pas. Ça n’a pas loupé, arrivé à ma hauteur, je le vois me regarder.",
    "Je lui décoche un sourire, genre flèche de Cupidon mais en plus réservé. Il me sourit aussi. En passant mon chemin, je continue de sourire, je repense à lui.",
    "Je marche moins vite car j’essaye de me souvenir. Et pendant ce temps-là, je sens son regard toujours dans mon dos. Je n'ose pas me retourner.",
    "Elle était, comme souvent, étendue sur son lit en mezzanine entourée de coussins multicolores, un livre entre les mains.",
    "Elle avait les cheveux noirs et raides comme des baguettes. Elle les avait attachés avec une barrette car la chaleur se faisait sentir en cette soirée estivale.",
    "Depuis le CM1 elle était fascinée par le monde des sorciers et surtout par la série «Harry Potter» dont elle avait lu les sept tomes.",
    "Dont elle avait vu les films plusieurs fois, jusqu’à en connaître certaines répliques par cœur. Elle s’identifiait au personnage d’Hermione.",
    "En cette veille de week-end, elle était rentrée du collège le plus vite possible. Elle avait hâte de retrouver Harry et ses camarades et de suivre leurs aventures.",
    "La jeune fille tournait les pages frénétiquement. Elle ne s’était pas rendu compte du temps passé quand son frère entra en trombe dans sa chambre pour l’aider à mettre la table.",
    "Sans attendre sa réponse, il claqua la porte si violemment que l’affiche d’Harry Potter qu’elle avait scotchée menaça de se détacher.",
    "Elle en était au chapitre 22 quand sa mère à son tour lui demanda de venir pour le dîner car il était prêt.",
    "Ce matin-là, Alma avait été réveillée trop tôt à son goût par son père. Comme tous les matins, elle avait avalé son bol de céréales en vitesse.",
    "Elle avait enfilé à toute allure un short en jean et son T-shirt jaune vif pour aller au collège afin d’avoir un peu de temps pour discuter avec ses meilleurs copains.",
    "C’était censé être une journée tout à fait ordinaire. Quelle ne fut pas sa surprise quand Madame Zhang leur annonça qu’ils devaient préparer des chansons.",
    "Ils les interpréteraient à la fête de la musique, prévue dans un mois. Alma sentit son coeur battre la chamade. Et ce n’était rien en comparaison de ce qu’elle ressentit quand en fin de matinée.",
    "Monsieur Rythmo, le professeur de musique leur expliqua que certains élèves devraient jouer de la musique pour accompagner les chansons. Chanter et jouer de la musique? Mais ce n’est pas possible pensait-elle.",
    "Tout le monde va s’apercevoir que je chante faux et qu’à part «Au clair de la lune» à la flûte, je ne sais jouer d’aucun instrument.",
    "J’aime bien écrire des textes de chansons pour m’amuser mais chanter. La dernière fois qu’elle avait chanté pour l’anniversaire de sa grand-mère.",
    "Clément, son grand frère, lui avait dit «je crois qu’il va pleuvoir»! Elle aimait bien regarder tous les samedis soir l’émission de télévision avec des candidats qui chantent devant un jury.",
    "Mais elle ne se sentait pas ce talent. Antoine faisait de la batterie, Aminata et Sarah chantaient tous les mercredis après-midi en duo à la Maison de quartier ; elle, c’était plutôt le rugby sa spécialité.",
    "Quand elle rentra chez elle après les cours, Alma trouva Clément affalé dans le canapé en train de manger la dernière gaufre au chocolat du paquet.",
    "Affamée et agacée, elle commença à lui exposer combien elle n’en pouvait plus qu’il mange régulièrement tous les paquets de biscuits sans penser à elle.",
    "Léo est champion de vélo. L’année dernière il a gagné beaucoup de courses organisées pour les plus jeunes, mais cette année il va courir avec les grands.",
    "Pour le féliciter et l’encourager, ses parents lui ont acheté un super vélo. Aujourd’hui c’est la première course de la nouvelle saison.",
    "Léo est tout fier et tout content de pouvoir courir avec le beau maillot bleu et blanc du club cycliste de sa ville.",
    "Avec son nouveau vélo aux couleurs du maillot, si beau et si léger, il est convaincu qu’il va encore gagner. Pour cela Léo a fait tout le nécessaire.",
    "Il s’est soigneusement entraîné pendant les derniers jours de l’hiver avec ses copains et, ce matin, il a bien vérifié tout son matériel.",
    "Il s’est ensuite préparé un goûter qu’il a fourré dans son petit sac pour le ravitaillement. Mais, avant de se rendre sur la ligne de départ, il faut signer son nom sur la liste.",
    "Léo appuie son vélo contre un arbre et se dirige vers la table des commissaires de course. Léo se retourne, sifflotant. Mais là, consternation, son vélo a disparu!",
    "Marcel se réveille tout joyeux et excité. C’est Noël. Il se précipite vers le salon où il a décoré un joli sapin avec maman. Il ne fait même pas attention aux guirlandes lumineuses qui clignotent.",
    "Cela ne l’intéresse pas et il espère que le père Noël est passé. Cet été il a appris à pédaler sans roulettes sur le vélo de sa cousine. Il était tout fier.",
    "Que ce fut long d’attendre l’hiver et la neige! Marcel a essayé d’être sage et il a travaillé de son mieux pour être irréprochable.",
    "Son travail lui a d’ailleurs permis d’écrire la fameuse lettre au Père Noël pour demander le superbe vélo rouge qu’il avait vu dans le magasin.",
    "Les derniers jours avaient passé vite mais hier soir les adultes semblaient prendre un malin plaisir à faire durer le réveillon de Noël.",
    "Alors que Marcel n’avait qu’une hâte, inhabituelle: aller se coucher. Pourtant une fois dans son lit impossible de trouver le sommeil.",
    "Il s’imaginait déjà pédalant de toutes ses forces dans les allées du parc avec son nouveau vélo."
]

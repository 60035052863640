import {Fragment} from "react";

//import components
import {fields} from "../commun/Form/fields";
import {jobsConstants} from "../../constants/dropdownConstants";

const ProfileForm = ({disabled, initialValues = {}}) => {
    return (
        <Fragment>
            <div className="profile-form-container pfc-alone">
                {fields.email(disabled, true, initialValues.email)}
                {/*fields.dropdown("job", "Métier", disabled, jobsConstants, "Profession*")*/}
            </div>

            <div className="profile-form-container">
                {fields.text("firstName", "Prénom*", disabled)}
                {fields.text("lastName", "Nom*", disabled)}
            </div>

            <div className="profile-form-container">
                {fields.number("finess", "Numéro FINESS*", disabled, 9, true, initialValues.finess)}
                {fields.number("phoneNumber", "Téléphone*", disabled, 10, true, initialValues.phoneNumber)}
            </div>
        </Fragment>
    )
}

export default ProfileForm;

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSquare} from '@fortawesome/free-regular-svg-icons'

//import constants
import {readingFormDataDirection} from "../reading/ReadingRadioData";
import {fieldsType} from "../commun/Form/RadioData";

export const fieldsDirectionPursuit = [
    {name: "orientation", value: "0", label: "Direction : horizontale", display: <span className="radio-icon horizontal"></span>},
    {name: "orientation", value: "1", label: "Direction : verticale", display: <span className="radio-icon vertical"></span>},
    {name: "orientation", value: "2", label: "Direction : Zigzag", display: <span className="radio-icon"><FontAwesomeIcon icon="z"/></span>},
    {name: "orientation", value: "3", label: "Coincé dans une boite", display: <span className="radio-icon"><FontAwesomeIcon icon={faSquare}/></span>},
]

export const directionHorizontal = [
    ...readingFormDataDirection,
    {name: "direction", value: "aleatoire", label: "Aléatoire", display: <span className="radio-icon overlay">
            <FontAwesomeIcon icon="arrow-left" style={{marginRight: ".3rem"}}/>
            <FontAwesomeIcon icon="arrow-right" style={{marginLeft: ".3rem"}}/>
    </span>},
]

export const directionVertical = [
    {name: "directionV", value: "UtD", label: "De haut en bas", display: <span className="radio-icon" style={{rotate: '-90deg'}}><FontAwesomeIcon icon="arrow-left"/>|</span>},
    {name: "directionV", value: "DtU", label: "De bas en haut", display: <span className="radio-icon" style={{rotate: '-90deg'}}>|<FontAwesomeIcon icon="arrow-right"/></span>},
    {name: "directionV", value: "aleatoire", label: "Aléatoire", display: <span className="radio-icon overlay" style={{rotate: "90deg", marginRight: 0, marginBottom: "-.4rem"}}>
            <FontAwesomeIcon icon="arrow-left" style={{marginRight: ".3rem"}}/>
            <FontAwesomeIcon icon="arrow-right" style={{marginLeft: ".3rem"}}/>
    </span>},
]

export const scanFieldsType = [
    ...fieldsType.slice(0, 2),
    {name: "type", value: "2", label: "Mire : point", display: "●"},
    {name: "type", value: "3", label: "Mire : dessin", display: <span className="radio-icon"><FontAwesomeIcon icon="icons"/></span>}
]

export const scanFieldsColor = [
    {name: "colored", value: "0", label: "Mire : non colorée", display: <span className="rText">NON COLORÉE</span>},
    {
        name: "colored",
        value: "1",
        label: "Mire : colorée",
        display: <span className="rText">
            <span style={{color: "var(--dark)"}}>C</span>
            <span style={{color: "#FFDB58"}}>O</span>
            <span style={{color: "#8A2BE2"}}>L</span>
            <span style={{color: "#FF6700"}}>O</span>
            <span style={{color: "#3D0C02"}}>R</span>
            <span style={{color: "#A9A9A9"}}>É</span>
            <span style={{color: "#32CD32"}}>E</span>
        </span>
    }
]


export const scanFieldsTimeMovement = [
    {value: 1000, label: '1000ms'},
    {value: 2000, label: ''},
    {value: 3000, label: '3000ms'},
    {value: 4000, label: ''},
    {value: 5000, label: '5000ms'},
    {value: 6000, label: ''},
    {value: 7000, label: '7000ms'},
]

import React, {useEffect} from "react";
import {FormControlLabel, FormGroup} from "@mui/material";

//import components
import {SwitchCustom, SwitchShadowLight} from "./Switchs";

//import constants
import {getStyleParameters} from "../../constants/localStorage";
import {isShadow} from "../../constants/constants";

function SwitchGroup({onChange}) {
    const {shadow, correction} = getStyleParameters("oRtHoTrAiNeMeNtStOrAgE")

    useEffect(() => {
        isShadow()
    }, [shadow])

    return (
        <div className="switch-box">
            <FormGroup>
                <FormControlLabel
                    control={<SwitchCustom sx={{ m: 1 }} shadow={shadow ? "true" : undefined} />}
                    checked={correction}
                    label="Corrections"
                    labelPlacement="start"
                    onChange={() => onChange("correction", !correction)}
                />

                <FormControlLabel
                    control={<SwitchShadowLight sx={{ m: 1 }} shadow={shadow ? "true" : undefined} />}
                    checked={shadow}
                    label="Mode"
                    labelPlacement="start"
                    onChange={() => onChange("shadow", !shadow)}
                />
            </FormGroup>
        </div>
    )
}

export default SwitchGroup


/*
var prefersDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;

// Fonction pour basculer entre les thèmes
function toggleTheme() {
    if (prefersDarkMode) {
        document.documentElement.classList.remove('dark-theme');
        document.documentElement.classList.add('light-theme');
    } else {
        document.documentElement.classList.remove('light-theme');
        document.documentElement.classList.add('dark-theme');
    }
}

// Exemple de basculement de thème
toggleTheme(); // Choisir le thème en fonction de la préférence actuelle de l'utilisateur

// Ajouter un écouteur d'événement pour détecter les changements de préférence de couleur
window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', function(e) {
    prefersDarkMode = e.matches;
    toggleTheme(); // Actualiser le thème en fonction de la nouvelle préférence de couleur
});
 */


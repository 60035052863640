import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

//import constants
import {fieldsNumber} from '../commun/Form/RadioData'

export const fieldsDirection = [
    {name: "direction", value: "left", label: "Position : gauche", display: <span className="radio-icon"><FontAwesomeIcon icon="arrow-left"/>|</span>},
    {name: "direction", value: "center", label: "Position : centre", display: <span className="radio-icon">|</span>},
    {name: "direction", value: "right", label: "Position : droite", display: <span className="radio-icon">|<FontAwesomeIcon icon="arrow-right"/></span>},
    {name: "direction", value: "random", label: "Position : aléatoire", display: <span className="radio-icon"><FontAwesomeIcon icon="arrow-left"/>|<FontAwesomeIcon icon="arrow-right"/></span>},
]

export const getEmpanFormData = (type) => (
    type === "2" ?
        [[...fieldsNumber.slice(1, 6), {name: "number", value: "7", label: "Nombre mire : 7", display: "7"}, {name: "number", value: "8", label: "Nombre mire : 8", display: "8"}], fieldsDirection]
        :
        [fieldsNumber, fieldsDirection]
)

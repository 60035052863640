//import actions
import {navigationActions} from '../../../actions/navigationActions';

export const submitForm = (values, dispatch, setInitialValues, setLaunch) => {
    const valuesToSubmit = {
        ...values,
        time: values.time ? values.time : 250
    }

    navigationActions.hide(dispatch)
    setInitialValues(valuesToSubmit)
    setLaunch(valuesToSubmit)
}

export const formMount = (dispatch, initialValues, setStep) => {
    navigationActions.display(dispatch)
    Object.keys(initialValues).length && setStep(10)
}

import {keyframes} from "styled-components";

export const movementRtL = keyframes`
      0% {
        transform: translateX(48svw);
        opacity: 0;
      }
      
      1% {
        opacity: 1;
      }
      
      99% {
        opacity: 1;
      }
 
      100% {
        opacity: 0;
        transform: translateX(-48svw);
      }
    `
;

export const movementLtR = keyframes`
      0% {
        transform: translateX(-48svw);
        opacity: 0;
      }
      
      1% {
        opacity: 1;
      }
      
      99% {
        opacity: 1;
      }
 
      100% {
        opacity: 0;
        transform: translateX(48svw);
      }
    `
;

export const movementDtU = keyframes`
      0% {
        transform: translateY(38svh);
        opacity: 0;
      }
      
      1% {
        opacity: 1;
      }
      
      99% {
        opacity: 1;
      }
 
      100% {
        opacity: 0;
        transform: translateY(-50svh);
      }
    `
;

export const movementUtD = keyframes`
      0% {
        transform: translateY(-50svh);
        opacity: 0;
      }
      
      1% {
        opacity: 1;
      }
      
      99% {
        opacity: 1;
      }
 
      100% {
        opacity: 0;
        transform: translateY(38svh);
      }
    `
;

export const movementAtZ = props => keyframes`
      0% {
        transform: translate(43svw, -47svh);
        opacity: 0;
        color: ${props.colors[0]}
      }
      
      1% {
        opacity: 1;
      }
  
      20% { 
        transform: translate(-43svw, -47svh);
        color: ${props.colors[1]}
      }

      40% {
        transform: translate(43svw, -7svh);
        color: ${props.colors[2]}
      }

      60% {
        transform: translate(-43svw, -7svh);
        color: ${props.colors[3]}
      }

      80% {
        transform: translate(43svw, 34svh);
        color: ${props.colors[4]}
      }
      
      99% {
        opacity: 1; 
      }
 
      100% {
        opacity: 0;
        transform: translate(-43svw, 34svh);
        color: ${props.colors[5]}
      }
    `
;

export const movementZtA = props => keyframes`
      0% {
        transform: translate(-43svw, -47svh);
        opacity: 0;
        color: ${props.colors[0]}
      }
      
      1% {
        opacity: 1;
      }

      20% {
        transform: translate(43svw, -47svh);
        color: ${props.colors[1]}
      }
    
      40% {
        transform: translate(-43svw, -7svh);
        color: ${props.colors[2]}
      }
    
      60% {
        transform: translate(43svw, -7svh);
        color: ${props.colors[3]}
      }
    
      80% {
        transform: translate(-43svw, 34svh);
        color: ${props.colors[4]}
      }
      
      99% {
        opacity: 1; 
      }
 
      100% {
        opacity: 0;
        transform: translate(43svw, 34svh);
        color: ${props.colors[5]}
      }
    `
;

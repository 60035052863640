import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

//import components
import BottomNav from "../commun/BottomNav";

//import constants
import {jerkDisplay} from "../jerk/pattern/JerkPatternDisplay";

//import actions
import {responseActions} from "../../actions/responseActions";

//import selectors
import {responseSelector} from "../../reducers/selectors";

function AntiJerkGame({launch, setLaunch}) {
    const [display, setDisplay] = useState()
    const [start, setStart] = useState()

    const {difficulty} = launch
    const orientation = parseInt(launch.orientation)
    const newLaunch = {...launch, jerk: 1}

    const {trueResponses, falseResponses, serial} = useSelector(responseSelector)

    const dispatch = useDispatch()

    useEffect(() => {
        if (!start) {
            if (serial !== 0 && trueResponses.current === 0 && falseResponses.current === 0) {
                responseActions.responseMissedIncSerial(dispatch)
            }

            jerkDisplay[orientation](setDisplay, setStart, newLaunch)
        }
    }, [start])

    return (
        <main className={!start ? "cursor-hide" : undefined}>
            <div className="antiJerk-game">
                <div className={`antiJerk-game-display ${difficulty}`}>
                    {display}
                </div>
                {start && <BottomNav
                    title={"anti-saccade"}
                    start={start}
                    setStart={setStart}
                    setLaunch={setLaunch}
                    launch={launch}
                    setDisplay={setDisplay}
                />}
            </div>
        </main>
    )
}

export default AntiJerkGame

import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Select from "react-select";

function DropdownInput({options, input, meta: {touched, error}, ...rest}) {
    return (
        <div className={touched && error ? 'it-container it-container-dropdown it-dropdown-error' : 'it-container it-container-dropdown'}>
            <Select
                {...input}
                {...rest}
                classNamePrefix="dropdown"
                options={options}
            />

            {touched && error && <div className='it-error'>
                <FontAwesomeIcon icon="exclamation-circle"/>
                {error}
            </div>}
        </div>
    )
}

export default DropdownInput;
//import components
import InitGame from "../commun/InitGame";
import AntiJerkForm from "./AntiJerkForm";
import AntiJerkGame from "./AntiJerkGame";

function AntiJerk() {
    return (
        <InitGame
            form={<AntiJerkForm/>}
            game={<AntiJerkGame/>}
        />
    )
}

export default AntiJerk

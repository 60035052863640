import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

//import constants
import {generateRandomNumber} from "../../constants/getRandomNumber";
import {getRandomLetter} from "../../constants/getRandomLetter";
import {nearlyLetters} from "../../constants/nearlyLetters";
import {getStyle} from "../../constants/localStorage";
import {newMemoryData} from "../memory/series";

//import actions
import {responseActions} from "../../actions/responseActions";
import {comparisonAllIndex2, comparisonAllIndex3} from "../../constants/constants";

const getListIcons = () => {
    let icons = []
    while (icons.length < 26) {
        const icon = newMemoryData[generateRandomNumber(0, newMemoryData.length)]
        const isExist = icons.some(element => JSON.stringify(element) === JSON.stringify(icon))
        if (!isExist) icons.push(icon)
    }
    return icons
}

const random = ["0", "1"]

const getUnique = (initialElement, getType) => {
    let newElement

    do {newElement = getType()}
    while (newElement === initialElement)

    return newElement
}

const getNumber = (numbersIntoRow, flag, identical, fakeNumbersIntoRow) => {
    const randomNumber = generateRandomNumber(0, 10)
    numbersIntoRow.push(randomNumber)

    if (flag === 1 || identical === 1) {
        fakeNumbersIntoRow.push(randomNumber)
    } else {
        const newRandomNumber = getUnique(randomNumber, () => generateRandomNumber(0, 10))
        fakeNumbersIntoRow.push(newRandomNumber)
    }
}

const getLetter = (numbersIntoRow, flag, identical, fakeNumbersIntoRow, shape) => {
    const nearly = generateRandomNumber(0, 1)
    const letter = getRandomLetter()

    numbersIntoRow.push(letter)

    if (flag === 1 || identical === 1) {
        fakeNumbersIntoRow.push(letter)
    } else if (nearly === 0) {
        const findLetter = shape === "uppercase" ? letter.toUpperCase() : letter
        const nearlyLetter = nearlyLetters[findLetter]
        const nearlyNumber = generateRandomNumber(0, nearlyLetter.length)
        fakeNumbersIntoRow.push(nearlyLetter[nearlyNumber].toLowerCase())
    } else {
        const newRandomLetter = getUnique(letter, () => getRandomLetter())
        fakeNumbersIntoRow.push(newRandomLetter)
    }
}

const getIcons = (numbersIntoRow, flag, identical, fakeNumbersIntoRow, icons) => {
    const getRandomIcon = () => {
        const icon = icons[generateRandomNumber(0, icons.length)]
        return <FontAwesomeIcon icon={icon.name} className={icon.custom} />
    }

    const getUniqueIcon = (initialIcon) => {
        let newIcon

        do {newIcon = getRandomIcon()}
        while (`${newIcon?.props.icon}${newIcon?.props.className}` === `${initialIcon?.props.icon}${initialIcon?.props.className}`)

        return newIcon
    }

    const randomIcon = getRandomIcon()
    numbersIntoRow.push(randomIcon)

    if (flag === 1 || identical === 1) {
        fakeNumbersIntoRow.push(randomIcon)
    } else {
        const newRandomIcon = getUniqueIcon(randomIcon)
        fakeNumbersIntoRow.push(newRandomIcon)
    }
}

const getType = (type, numbersIntoRow, flag, identical, fakeNumbersIntoRow, icons, shape) => {
    switch (type) {
        case "0":
            getNumber(numbersIntoRow, flag, identical, fakeNumbersIntoRow)
            break;

        case "1":
            getLetter(numbersIntoRow, flag, identical, fakeNumbersIntoRow, shape)
            break

        case "3":
            getIcons(numbersIntoRow, flag, identical, fakeNumbersIntoRow, icons)
            break
    }
}

export const comparisonDisplay = (setDisplay, launch, dispatch) => {
    const {orientation, type, difficulty} = launch
    const group = launch.group || "noGroup"
    const numberChar = orientation === "0" ? parseInt(launch.numberChar) : 1
    const icons = type === "3" || type === "2" ? getListIcons() : []
    const style = getStyle()

    const arrayToDisplay = []
    let numbers = []
    let toFind = 0

    for (let i = 0; i < 10; i++) {
        const numbersIntoRow = []
        const fakeNumbersIntoRow = []
        const identical = generateRandomNumber(0, 4)
        let test = false

        for (let j = 0; j < numberChar; j++) {
            const renderType = type === "2" ? random[generateRandomNumber(0, random.length)] : type
            const flag = generateRandomNumber(0, 2)
            getType(renderType, numbersIntoRow, flag, identical, fakeNumbersIntoRow, icons, style.textTransform)
            test = group === "group" ? test : false

            if (flag !== 1 && identical !== 1 && !test) {
                toFind++
                test = true
            }
        }
        numbers.push(numbersIntoRow)
        numbers.push(fakeNumbersIntoRow)
    }

    responseActions.responseMissed(dispatch, toFind)

    const getEventElement = (event) => {
        const target = event.target
        let element = target
        if (element.tagName === "path") element = target.parentNode.parentNode
        if (element.tagName === "svg") element = target.parentNode
        if (element.tagName === "SPAN" && group === "group") element = element.parentNode
        return element
    }

    const checkSuccess = (event) => {
        const element = getEventElement(event)
        element.classList.add("true")
        responseActions.responseTrue(dispatch)
        responseActions.responseMissedDec(dispatch)
    }

    const checkFail = (event) => {
        const element = getEventElement(event)
        element.classList.add("false")
        responseActions.responseFalse(dispatch)
    }

    const check = (event, elementIndex, rowIndex) => {
        const indexToFind = orientation !== "0" ? 10 : 1

        if (numbers[rowIndex][elementIndex] === numbers[rowIndex-indexToFind][elementIndex]) {
            checkFail(event)
        } else {
            checkSuccess(event)
        }
    }

    const checkGroup = (event, i) => {
        if (JSON.stringify(numbers[i]) === JSON.stringify(numbers[i-1])) {
            checkFail(event)
        } else {
            checkSuccess(event)
        }
    }

    if (orientation !== "0") {
        const {even, odd} = numbers.reduce((acc, cur, index) => {
            index % 2 === 0 ? acc.even.push(cur) : acc.odd.push(cur);
            return acc;
        }, { even: [], odd: [] });

        numbers = [...even, ...odd];
    }

    const newStyle = {
        ...style,
        padding: 0,
        paddingRight: 0,
        letterSpacing: "auto",
        margin: `0 calc(${style.letterSpacing}/2)`
    }

    const getContent = (number, rowIndex) => {
        return number.map((element, elementIndex) => (
            <span
                key={elementIndex}
                style={newStyle}
                className="content"
                onClick={
                    (orientation === "0" && group === "noGroup") || (orientation !== "0") ?
                        (event) => check(event, elementIndex, rowIndex)
                        :
                        undefined
                }
            >
                {element}
            </span>
        ))
    }

    if (orientation !== "2" && orientation !== "3") {
        for (let i = 0; i < numbers.length; i++) {
            arrayToDisplay.push(
                <div
                    className={`grid content ${group}`}
                    key={i}
                    onClick={(orientation === "0" && group === "group") ? (event) => checkGroup(event, i) : undefined}
                >
                    {getContent(numbers[i], i)}
                </div>
            )
        }
    } else {
        const allIndex = orientation === "2" ? comparisonAllIndex2 : comparisonAllIndex3

        for (let i = 0; i < 40; i++) {
            const index = allIndex[difficulty].indexOf(i)

            arrayToDisplay.push(
                index === -1 ?
                    <div className={`grid content`} key={i}></div>
                    :
                    <div
                        className={`grid content ${group}`}
                        key={i}
                        onClick={(orientation === "0" && group === "group") ? (event) => checkGroup(event, index) : undefined}
                    >
                        {getContent(numbers[index], index)}
                    </div>
            )
        }
    }

    setDisplay(arrayToDisplay)
}

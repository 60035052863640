import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {CustomTooltip} from "../commun/customTooltip";
import {Fragment} from "react";

function UpgradeCI({setDisplay}) {
    return (
        <Fragment>
            <div className="upgrade-ci">
                <div className="upgrade-ci-container">
                    <div className="upgrade-ci-container-center"><FontAwesomeIcon icon="eye"/></div>
                    <div className="upgrade-ci-container-center"><FontAwesomeIcon icon="laptop"/></div>
                    <div className="upgrade-ci-container-center"><FontAwesomeIcon icon="at"/></div>
                </div>
                <div className="upgrade-ci-container">
                    <div>06 46 48 64 81</div>
                    <div>06 41 98 09 21</div>
                    <div>orthotrainement@gmail.com</div>
                </div>
            </div>

            <div className="upgrade-third">
                <CustomTooltip title="Retour" onClick={() => setDisplay("")}>
                    <div className="profile-icon">
                        <FontAwesomeIcon className="icon" icon="xmark"/>
                    </div>
                </CustomTooltip>
            </div>
        </Fragment>
    )
}

export default UpgradeCI
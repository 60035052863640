import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Form} from "react-final-form";
import {useNavigate} from "react-router-dom";

//import components
import ProfileForm from "./ProfileForm";
import {CustomTooltip} from "../commun/customTooltip";
import Subscribe from "../subscribe/Subscribe";

//import services
import {userServices} from "../../services/userServices";

//import constants
import {jobsConstants} from "../../constants/dropdownConstants";

//import reducer
import {userSelector} from "../../reducers/selectors";
import {notifications} from "../commun/Notifications";

const UpdateProfile = ({setForm}) => {
    const dispatch = useDispatch()

    const user = useSelector(userSelector)
    const navigate = useNavigate()

    const resetInitialValues = {
        email: user.data.email,
        lastName: user.data.lastName,
        firstName: user.data.firstName,
        job: jobsConstants.find(job => job.value === user.data.job),
        phoneNumber: user.data.phoneNumber,
        finess: user.data.finess,
    }

    const [disabled, setDisabled] = useState(true)
    const [initialValues, setInitialValues] = useState(resetInitialValues)

    const submit = (values) => {
        const submitValues = {
            ...values,
            job: values.job.value
        }

        userServices.edit(user.data._id, submitValues, dispatch).then(response => {
            if (response.status === 200) {
                setDisabled(true)
                notifications.success('Vos informations ont été modifiées !')
            }
        })
    }

    useEffect(() => {
        Object.keys(initialValues).length === 0 && setInitialValues(resetInitialValues)
    }, [initialValues])

    return (
        <Form
            onSubmit={submit}
            initialValues={initialValues}
            render={({handleSubmit, hasValidationErrors}) => (
                <form onSubmit={handleSubmit}>
                    <ProfileForm disabled={disabled} initialValues={initialValues}/>

                    <div className="profile-icon-container">
                        {disabled ? <CustomTooltip title="Modifier mon mot de passe" onClick={() => setForm(false)}>
                            <div className="profile-icon"><FontAwesomeIcon className="icon" icon="key"/>
                            </div>
                        </CustomTooltip>
                        :
                            <CustomTooltip title="Annuler" onClick={() => {
                                setDisabled(true)
                                setInitialValues({})
                            }}>
                                <div className="profile-icon">
                                    <FontAwesomeIcon className="icon" icon="xmark"/>
                                </div>
                            </CustomTooltip>
                        }

                        {disabled ?
                            <CustomTooltip title="Modifier mes informations" onClick={() => setDisabled(false)}>
                                <div className="profile-icon"><FontAwesomeIcon className="icon" icon="pen"/></div>
                            </CustomTooltip>
                            :
                            <CustomTooltip
                                title={hasValidationErrors ? "Veuillez corriger les erreurs pour confirmer" : "Confirmer les modifications"}
                            >
                                <button type="submit" disabled={hasValidationErrors} className={hasValidationErrors ? "profile-icon profile-icon-error" : "profile-icon"}>
                                    <FontAwesomeIcon className="icon" icon="check"/>
                                </button>
                            </CustomTooltip>
                        }

                        {disabled && <CustomTooltip title="Améliorons ensemble" onClick={() => navigate("/ameliorer")}>
                            <div className="profile-icon"><FontAwesomeIcon className="icon" icon="lightbulb"/></div>
                        </CustomTooltip>}

                        {disabled && <Subscribe/>}
                    </div>
                </form>
            )}
        />
    )
}

export default UpdateProfile

//import components
import CommonRadio from "./CommonRadio";

function FirstRadio({step, setStep, fields, form, setInitialValues}) {
    const handleClick = e => {
        form.reset()
        //setStep(0)
        //setTimeout(() => setStep(1), 1)
        setInitialValues({type: e.target.value})
        setStep(1)
    }

    return (
        <CommonRadio
            index={0}
            step={step}
            setStep={setStep}
            fields={fields}
            defaultHandleClick={handleClick}
        />
    )
}

export default FirstRadio

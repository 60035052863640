import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useNavigate} from "react-router-dom";

function SubscribePage() {
    const navigate = useNavigate()

    return (
        <main>
            <div className="upgrade">
                <div className="upgrade-first">
                    <FontAwesomeIcon className="icon" icon="fa-credit-card"/>
                    <div className="upgrade-first-text">
                        <p>Orthotraînement propose un abonnement de <span>7 euros</span> pour accéder à l’intégralité du contenu de la plateforme.</p>
                        <p>Pour vous abonner, il vous suffit de <span>vous munir de votre IBAN et de cliquer sur le bouton «M'abonner !»</span>. Ainsi, nous allons mettre en place un <span>prélèvement SEPA</span>. Bien évidemment, <span>le paiement est sécurisé</span>.</p>
                        <p>Une fois le mandat créé, la plateforme vous sera accessible dans son intégralité et un premier prélèvement de 7 euros aura lieu dans les jours suivants. <span>Un prélèvement récurrent aura lieu chaque mois</span>. Vous recevrez un mail pour chaque paiement effectué avec une <span>facture et la date</span> du prochain prélèvement.</p>
                    </div>
                </div>

                <div className="subscribe-bloc" onClick={() => navigate("/abonnement/creer")}>M'abonner !</div>
            </div>
        </main>
    )
}

export default SubscribePage
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Fragment, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

//import components
import Next from "./Navigation/Next";
import Results from "./Results";
import {CustomTooltip} from "./customTooltip";

//import action
import {navigationActions} from "../../actions/navigationActions";
import {responseActions} from "../../actions/responseActions";

//import selectors
import {responseSelector} from "../../reducers/selectors";

function BottomNav({start, setStart, setLaunch, launch, title, setDisplay}) {
    const [results, setResults] = useState(false)

    const {display, correction, trueResponses, falseResponses} = useSelector(responseSelector)

    const dispatch = useDispatch()

    useEffect(() => {
        (start || title === "saccade") && document.addEventListener('keydown', keyEvents)
        navigationActions.displayLeft(dispatch)
        responseActions.serial(dispatch)
        responseActions.responseTrueCurrent(dispatch)
        responseActions.responseFalseCurrent(dispatch)
        return () => document.removeEventListener('keydown', keyEvents);
    }, [start])

    const keyEvents = event => {
        event.stopPropagation()

        if (event.code === 'Space' && !results) {
            setStart(false)
        }

        event.code === 'Escape' && exit()
    }

    const exit = () => {
        if (correction && (title === "memoire" || title === "lecture")) {
            if (trueResponses.current !== 0 || falseResponses.current !== 0) {
                responseActions.serial(dispatch)
            } else {
                responseActions.responseMissedDecSerial(dispatch)
            }
        }

        if (correction && title !== "poursuite") {
             setResults(true)
             document.removeEventListener('keydown', keyEvents)
        } else {
            setLaunch({})
        }
    }

    return (
        <Fragment>
            <CustomTooltip title={(display && correction) ? "Correction" : "Retour"} placement="right">
                <button className="btn-nav btn-first" onClick={exit}>
                    <FontAwesomeIcon className="icon" icon="circle-left"/>
                </button>
            </CustomTooltip>

            <Next setStart={setStart} launch={launch} title={title} correction={correction}/>
            {results && <Results
                title={title}
                setLaunch={setLaunch}
                launch={launch}
                setResults={setResults}
                setStart={setStart}
                setDisplay={setDisplay}
            />}
        </Fragment>
    )
}

export default BottomNav

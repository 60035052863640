//import actions
import {navigationActions} from "./navigationActions";
import {userActions} from "./userActions";
import {responseActions} from "./responseActions";

const razActions = (dispatch) => {
    navigationActions.raz(dispatch)
    userActions.raz(dispatch)
    responseActions.raz(dispatch)
}

export default razActions
const initialParameters = {
    shape: "shape-1",
    contrast: 100,
    size: 20,
    spacing: 1,
}

export const getStyleParameters = (key) => {
    const storedParameters = localStorage.getItem(key);
    return storedParameters ? JSON.parse(storedParameters) : initialParameters;
}

export const getStyle = () => {
    const {contrast, size, spacing, shape} = getStyleParameters("oRtHoTrAiNeMeNtStOrAgE")

    const style = {
        opacity: `calc(${contrast}/100)`,
        fontSize: `${size / 10}rem`,
        letterSpacing: `${spacing}px`,
        textTransform: shape === "shape-1" ? "uppercase" : "lowercase",
        paddingRight: `calc(1.5rem - ${spacing}px)`,
        paddingLeft: "1.5rem"
    }

    return style
}

export const getCorrection = () => {
    const {correction} = getStyleParameters("oRtHoTrAiNeMeNtStOrAgE")
    return correction
}

export const getShadow = () => {
    const {shadow} = getStyleParameters("oRtHoTrAiNeMeNtStOrAgE")
    return shadow
}

const idKey = "readingIntervalId"

export const getIdParameter = () => {
    return localStorage.getItem(idKey)
}

export const setIdParameter = (id) => {
    localStorage.setItem(idKey, id)
}

import moment from "moment";

//import services
import {request} from './request';

//import constants
import {encrypt} from "../constants/crypto";

//import components
import {notifications} from "../components/commun/Notifications";
import {userActions} from "../actions/userActions";

export const loginServices = {
    login,
    forgotPassword
}

function login(values, navigate, dispatch) {
    const successCallback = (response) => {
        userActions.credentials(dispatch, {
            token: encrypt(response.data.accessToken),
            date: moment().format("MM-DD-YYYY")
        })

        notifications.success('Vous êtes maintenant connecté !')
        return response
    }

    return request(
        'POST',
        `/api/auth/login`,
        successCallback,
        null,
        () => notifications.warning('Les identifiants sont incorrects !'),
        values
    )
}

async function forgotPassword(values) {
    const successCallback = (response) => {
        notifications.success('Un email vous a été envoyé !')

        return response
    }

    return request(
        'POST',
        `/api/auth/forgot-password`,
        successCallback,
        () => notifications.warning('Votre email est inconnu !'),
        null,
        values
    )
}
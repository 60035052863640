//import components
import JerkForm from './JerkForm'
import InitGame from '../commun/InitGame';
import JerkGame from "./JerkGame";

function Jerk() {
    return (
        <InitGame
            form={<JerkForm/>}
            game={<JerkGame/>}
        />
    )
}

export default Jerk
import {cloneElement, useState} from "react";

function InitGame({form, game}) {
    const [launch, setLaunch] = useState({})
    const [initialValues, setInitialValues] = useState({})

    return (
        !Object.keys(launch).length ?
            cloneElement(form, {setLaunch, initialValues, setInitialValues})
            :
            cloneElement(game, {launch, setLaunch})
    )
}

export default InitGame
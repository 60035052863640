import {Slider} from "@mui/material";
import {useEffect} from "react";

function OtherSliderTime ({input, ...rest}) {
    const max = rest.marks[rest.marks.length - 1].value

    useEffect(() => {
        !rest.default && input.onChange(rest.defaultTime)
    }, [])

    return (
        <div className={'slider-time-' + max}>
            <Slider
                defaultValue={rest.default ? rest.default : rest.defaultTime}
                step={null}
                marks={rest.marks}
                min={rest.marks[0].value}
                max={max}
                valueLabelDisplay="on"
                onChange={event => input.onChange(event.target.value)}
            />
        </div>
    )
}

export default OtherSliderTime

export const blackListConsonants = ["g", "j", "k", "v", "x", "z"]

export const consonantsWithoutBlackList = ["b", "c", "d", "f", "h", "l", "m", "n", "p", "q", "r", "s", "t"]

export const allConsonants = ["b", "c", "d", "f", "g", "h", "j", "k", "l", "m", "n", "p", "q", "r", "s", "t", "v", "x", "z"]

export const allVowels = ["a", "e", "i", "o", "u", "y"]

export const allSyllable2 = [
    "ba", "be", "bu", "bi", "bo",
    "ca", "ce", "cu", "ci", "co",
    "da", "de", "du", "di", "do",
    "fa", "fe", "fu", "fi", "fo",
    "ga", "ge", "gu", "gi", "go",
    "ja", "je", "ju", "ji", "jo",
    "ka", "ke", "ku", "ki", "ko",
    "la", "le", "lu", "li", "lo",
    "ma", "me", "mu", "mi", "mo",
    "na", "ne", "nu", "ni", "no",
    "pa", "pe", "pu", "pi", "po",
    "qa", "qe", "qu", "qi", "qo",
    "ra", "ra", "ru", "ri", "ro",
    "sa", "se", "su", "si", "so",
    "ta", "te", "tu", "ti", "to",
    "va", "ve", "vu", "vi", "vo",
    "za", "ze", "zu", "zi", "zo",
]

export const allSyllable3 = [
    "ail", "air",
    "bou", "bon", "ben", "ban", "boi", "bin", "beu", "bai", "ble", "bla", "blu", "bli", "blo", "bre", "bra", "bru", "bri", "bro", "bad", "bac", "bal", "bar", "bas", "bat", "bec", "bio", "bif", "buf", "bof", "baf", "bol", "bul", "but", "bus", "bor", "bil", "bot", "bel", "bir",
    "cou", "con", "cen", "can", "coi", "cin", "ceu", "cai", "cle", "cla", "clu", "cli", "clo", "cre", "cra", "cru", "cri", "cro", "che", "cha", "chu", "chi", "cho", "cal", "caf", "car", "cap", "cas", "cep", "cer", "cet", "cil", "cir", "coq", "col", "cur",
    "dou", "don", "den", "dan", "doi", "din", "deu", "dai", "dre", "dra", "dru", "dri", "dro", "dur", "dab", "dir", "dar", "dix",
    "eau", "eux",
    "fou", "fon", "fen", "fan", "foi", "fin", "feu", "fai", "fun", "fre", "fra", "fru", "fri", "fro", "fac", "far", "fee", "fer", "fil", "fur", "fut",
    "gou", "gon", "gen", "gan", "goi", "gin", "geu", "gai", "gre", "gra", "gru", "gri", "gro", "gue", "gui", "guo", "gua", "gap", "geo", "gel", "glu", "gym",
    "jou", "jon", "jen", "jan", "joi", "jin", "jeu", "jai", "jar", "jet", "job", "jus",
    "kou", "kon", "ken", "kan", "koi", "kin", "keu", "kai",
    "lou", "lon", "len", "lan", "loi", "lin", "leu", "lai", "lac", "lar", "las", "lat", "led", "lit", "lis", "lir", "loc", "les", "lot",
    "mou", "mon", "men", "man", "moi", "min", "meu", "mai", "mur", "mac", "mal", "mar", "mas", "mat", "mer", "mes", "met", "mir", "mis", "mod", "mol", "mor", "mul", "mal", "mec", "mix",
    "nou", "non", "nen", "nan", "noi", "nin", "neu", "nai", "ner", "net", "nil", "nir", "nie", "nid", "nom", "not", "nul", "nos",
    "pou", "pon", "pen", "pan", "poi", "pin", "peu", "pai", "pre", "pra", "pru", "pri", "pro", "pur", "par", "pal", "pas", "pat", "pel", "per", "pic", "pif", "pil", "por", "pot", "pul", "pue",
    "qou", "qon", "qen", "qan", "qoi", "qin", "qeu", "qai", "qui", "que", "quo", "qua",
    "rou", "ron", "ren", "ran", "roi", "rin", "reu", "rai", "rue", "ral", "rap", "rar", "ras", "rat", "riz", "rir", "rob", "rol", "rot",
    "sou", "son", "sen", "san", "soi", "sin", "seu", "sai", "sur", "sep", "ser", "set", "sil", "sir", "sac", "sal", "sec", "sel", "sol", "sor", "sas",
    "tou", "ton", "ten", "tan", "toi", "tin", "teu", "tai", "tre", "tra", "tru", "tri", "tro", "tac", "tic", "toc", "tuc", "taf", "tar", "tas", "tec", "ter", "tes", "tir",
    "vou", "von", "ven", "van", "voi", "vin", "veu", "vai", "vre", "vra", "vru", "vri", "vro", "vac", "var", "vas", "vil", "vir", "vis", "vit", "vol", "vot", "vue", "vie",
    "zou", "zon", "zen", "zan", "zoi", "zin", "zeu", "zai", "zoo", "zut",
]

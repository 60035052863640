import React from "react";

const InputNumber = ({id, label, min, max, value, onChange}) => {
    return (
        <div className='in-container'>
            <div className='it-container' style={{marginBottom: ".5rem", padding: "0 1rem"}}>
                <input
                    className='it-input'
                    placeholder={label}
                    type="number"
                    min={min}
                    max={max}
                    value={value}
                    onBlur={(e) => {
                        let value = e.target.value
                        if (value > max) value = max
                        if (value < min) value = min
                        onChange(id, value)
                    }}
                    onChange={(e) => {
                        let value = e.target.value
                        if (value > max) value = max
                        onChange(id, value)
                    }}
                />
                <label className='it-label' htmlFor="contraste">{label}</label>
                <span className="it-border"></span>
            </div>
        </div>
    )
}

export default InputNumber

//import constants
import {generateRandomNumber} from "./getRandomNumber";
import {allSyllable2, allSyllable3} from "./dictionary";

export const getRandomSyllable = number => {
    const allCombinations = [
        [[2]],
        [[3]],
        [[2, 2]],
        [[2, 3]],
        [[3, 3], [2, 2, 2]],
        [[3, 2, 2]],
        [[3, 3, 2], [2, 2, 2, 2]],
    ]

    const combinations = allCombinations[parseInt(number) - 2]
    const selectedCombination = combinations[generateRandomNumber(0, combinations.length)].sort(() => Math.random() - 0.5)

    let syllable = ''

    for (let i = 0; i < selectedCombination.length; i++) {
        const dictionary = selectedCombination[i] === 2 ? allSyllable2 : allSyllable3
        const indexConsonant = generateRandomNumber(0, dictionary.length)
        syllable += dictionary[indexConsonant]
    }

    return syllable
}

//import config
import * as DT from "../config/dispatchTypes";

export const userActions = {
    find,
    credentials,
    edit,
    raz
}

function find(dispatch, data) {
    dispatch({type: DT.USER_FIND, data: data})
}

function credentials(dispatch, credentials) {
    dispatch({type: DT.USER_CREDENTIALS, credentials: credentials})
}

function edit(dispatch, object) {
    dispatch({type: DT.USER_EDIT, data: object})
}

function raz(dispatch) {
    dispatch({type: DT.USER_RAZ})
}
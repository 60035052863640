import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {Form} from "react-final-form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

//import components
import FirstRadio from "../commun/Form/FirstRadio";
import {
    fieldsPosition,
    fieldsText,
    fieldsTextColored,
    fieldsTextType,
    staticData,
    movementData,
    readingTimeConfig,
    appearanceDataWordType
} from "./ReadingRadioData";
import {CustomTextTooltip} from "../commun/customTooltip";
import CommonRadio from "../commun/Form/CommonRadio";
import Submit from "../commun/Form/Submit";

//import constants
import {formMount, submitForm} from "../commun/Form/formFunction";
import SliderTimeField from "../commun/Form/SliderTimeField";
import {readingTitle} from "../../constants/titleText";

//import actions
import {responseActions} from "../../actions/responseActions";
import {fieldsGrid} from "../comparison/ComparisonRadioData";
import {fieldsDifficulty} from "../jerk/pattern/JerkRadioData";

function ReadingForm({setLaunch, initialValues, setInitialValues}) {
    const dispatch = useDispatch()

    const [step, setStep] = useState(0)

    const submit = values => submitForm(values, dispatch, setInitialValues, setLaunch)

    useEffect(() => {
        formMount(dispatch, initialValues, setStep)
        responseActions.raz(dispatch)
    }, [])

    const getSlider = (type) => {
        const config = readingTimeConfig[type]

        return <SliderTimeField
            initialValue={initialValues.time}
            marks={config.marks}
            defaultTime={config.defaultTime}
            defaultSlider={false}
        />
    }

    const getStaticData = (values) => (
        <>
            {staticData.map((radio, index) => (
                <CommonRadio key={index} index={index + 1} step={step} setStep={setStep} fields={radio}/>
            ))}

            {values.wordType === "3" && <CommonRadio index={3} step={step} setStep={setStep} fields={fieldsText}/>}
            {values.wordType === "3" && values.readText === "1" && <CommonRadio index={4} step={step} setStep={setStep} fields={fieldsTextColored}/>}
            {values.wordType !== "3" && step > 2 && getSlider(values.type)}
        </>
    )

    const getMovementData = (values) => (
        <>
            {movementData.map((radio, index) => (
                <CommonRadio key={index} index={index + 1} step={step} setStep={setStep} fields={radio}/>
            ))}

            {values.wordType !== "4" && <CommonRadio index={3} step={step} setStep={setStep} fields={fieldsTextType}/>}
            {(values.wordType !== "4" && step > 3) && getSlider(values.type)}
        </>
    )

    const getAppearanceData = (values) => {
        const getData = (wordType) => {
            if (wordType === "2") {
                return (
                    <>
                        <CommonRadio index={2} step={step} setStep={setStep} fields={fieldsTextType}/>
                        {values.textType && <CommonRadio index={3} step={step} setStep={setStep} fields={fieldsDifficulty}/>}
                        {values.textType && values.difficulty && <CommonRadio index={4} step={step} setStep={setStep} fields={fieldsGrid}/>}
                        {values.textType && values.difficulty && values.grid && getSlider(values.type)}
                    </>
                )
            }

            if (wordType === "3") {
                return (
                    <>
                        <CommonRadio index={2} step={step} setStep={setStep} fields={fieldsTextType}/>
                        {values.textType && <CommonRadio index={3} step={step} setStep={setStep} fields={fieldsGrid}/>}
                        {values.textType && values.grid && getSlider(values.type)}
                    </>
                )
            }

            if (wordType === "4") {
                return (
                    <>
                        <CommonRadio index={2} step={step} setStep={setStep} fields={fieldsGrid}/>
                        {values.grid && getSlider(values.type)}
                    </>
                )
            }
        }

        return (
            <>
                <CommonRadio index={1} step={step} setStep={setStep} fields={appearanceDataWordType}/>
                {getData(values.wordType)}
            </>
        )
    }

    const getData = (values) => {
        if (values.type === "static") return getStaticData(values)
        if (values.type === "movement") return getMovementData(values)
        if (values.type === "appearance") return getAppearanceData(values)
    }

    const getCondition = (values) => {
        const {wordType, textType, difficulty, grid} = values

        const check = (checkValues) => checkValues.some(element => element === undefined)

        const conditions = {
            "static": (values.wordType !== "3" ? 3 : values.readText === "1" ? 5 : 4) > step,
            "movement": 4 > step,
            "appearance": values.wordType === "2" ?
                check([wordType, textType, difficulty, grid])
                : values.wordType === "3" ? check([wordType, textType, grid])
                    : check([wordType, grid])
        }

        const condition = conditions[values.type]

        return condition || (1 > step)
    }

    return (
        <main className="reading">
            <div className="title">
                <CustomTextTooltip title={readingTitle}>
                    <div><FontAwesomeIcon icon="fa-circle-question"/></div>
                </CustomTextTooltip>
            </div>
            <div className='game-form reading-form'>
                <Form
                    onSubmit={submit}
                    initialValues={initialValues}
                    render={({handleSubmit, values, form}) => (
                        <form onSubmit={handleSubmit}>
                            <FirstRadio
                                setStep={setStep}
                                fields={fieldsPosition}
                                values={values}
                                form={form}
                                setInitialValues={setInitialValues}
                            />

                            {values.type && getData(values)}

                            <Submit condition={getCondition(values)}/>
                        </form>
                    )}
                />
            </div>
        </main>
    )
}

export default ReadingForm

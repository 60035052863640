import {NavLink} from "react-router-dom";
import {useSelector} from "react-redux";

//import selector
import {userSelector} from "../../../reducers/selectors";

//import component
import {CustomTooltip} from "../customTooltip";

function NavBloc ({title, link}) {
    const user = useSelector(userSelector)

    const deactivate = !user.data.hasPaid && !user.data.tryPeriod

    return (
        <CustomTooltip title={deactivate ? "Abonnement non actif" : undefined}>
            <NavLink
                className={deactivate ? "navigation-link navigation-link-deactivate" : "navigation-link navigation-link-activate"}
                onClick={e => deactivate && e.preventDefault()}
                to={link}
            >
                <div>{title}</div>
            </NavLink>
        </CustomTooltip>
    )
}

export default NavBloc

//import components
import InitGame from "../commun/InitGame";
import PursuitGame from "./PursuitGame";
import PursuitForm from "./PursuitForm";

function Pursuit() {
    return (
        <InitGame
            form={<PursuitForm/>}
            game={<PursuitGame/>}
        />
    )
}

export default Pursuit

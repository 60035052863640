import {Fragment} from "react";

//import data
import {fieldsDirection} from "./EmpanRadioData";

//import constants
import {generateRandomNumber, getRandomNumber} from "../../constants/getRandomNumber";
import {getRandomLetters} from "../../constants/getRandomLetter";
import {getRandomSyllable} from "../../constants/getRandomSyllable";

export const empanDisplay = [
    numbers,
    letters,
    syllable
]

function numbers (setDisplay, setStart, time, number, direction, style) {
    const randomNumber = getRandomNumber(number)
    return returnDisplay(setDisplay, setStart, time, randomNumber, direction, style)
}

function letters (setDisplay, setStart, time, number, direction, style) {
    const randomLetter = getRandomLetters(number)
    return returnDisplay(setDisplay, setStart, time, randomLetter, direction, style)
}

function syllable (setDisplay, setStart, time, number, direction, style) {
    const randomSyllable = getRandomSyllable(number)
    return returnDisplay(setDisplay, setStart, time, randomSyllable, direction, style)
}

const returnDisplay = (setDisplay, setStart, time, randomNumber, direction, style) => {
    const newDirection = direction === "random" ? fieldsDirection[generateRandomNumber(0, 3)].value : direction

    setTimeout(() => setDisplay(display => (
        <Fragment>
            {display}
            <div className={`content ${newDirection}`}>
                <span style={style}>{randomNumber}</span>
            </div>
        </Fragment>
    )))

    setTimeout(() => setDisplay(
        <div className={`content hide ${newDirection}`}>
            <span style={style}>{randomNumber}</span>
        </div>
    ), time)

    setTimeout(() => setStart(true), time)
}

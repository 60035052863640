import {useDispatch} from "react-redux";
import {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Form} from "react-final-form";

//import constants
import {pursuitTitle} from "../../constants/titleText";
import {
    directionHorizontal,
    directionVertical,
    fieldsDirectionPursuit,
    scanFieldsColor,
    scanFieldsTimeMovement,
    scanFieldsType
} from "./PursuitRadioData";

//import components
import SliderTimeField from "../commun/Form/SliderTimeField";
import {formMount, submitForm} from "../commun/Form/formFunction";
import {CustomTextTooltip} from "../commun/customTooltip";
import CommonRadio from "../commun/Form/CommonRadio";
import Submit from "../commun/Form/Submit";
import FirstRadio from "../commun/Form/FirstRadio";

//import actions
import {responseActions} from "../../actions/responseActions";

function PursuitForm({setLaunch, initialValues, setInitialValues}) {
    const dispatch = useDispatch()

    const [step, setStep] = useState(0)

    const submit = values => submitForm(values, dispatch, setInitialValues, setLaunch)

    useEffect(() => {
        formMount(dispatch, initialValues, setStep)
        responseActions.raz(dispatch)
    }, [])

    const getCondition = (values) => {
        let condition = 3
        if (values.orientation === "0" || values.orientation === "2") return !values.direction || (4 > step)
        if (values.orientation === "1") return !values.directionV || (4 > step)
        return condition > step
    }

    return (
        <main className="scan">
            <div className="title">
                <CustomTextTooltip title={pursuitTitle}>
                    <div><FontAwesomeIcon icon="fa-circle-question"/></div>
                </CustomTextTooltip>
            </div>

            <div className={'game-form pursuit-form'}>
                <Form
                    onSubmit={submit}
                    initialValues={initialValues}
                    render={({handleSubmit, values, form, hasValidationErrors}) => (
                        <form onSubmit={handleSubmit}>
                            <FirstRadio
                                step={step}
                                setStep={setStep}
                                fields={scanFieldsType}
                                form={form}
                                setInitialValues={setInitialValues}
                            />

                            <CommonRadio index={1} step={step} setStep={setStep} fields={scanFieldsColor}/>
                            <CommonRadio index={2} step={step} setStep={setStep} fields={fieldsDirectionPursuit}/>
                            {values.orientation !== "3" && <CommonRadio index={3} step={step} setStep={setStep} fields={values.orientation === "1" ? directionVertical: directionHorizontal}/>}

                            {(step > (values.orientation !== "3" ? 3 : 2)) && <SliderTimeField initialValue={initialValues.time} marks={scanFieldsTimeMovement} defaultTime={2000} defaultSlider={false}/>}

                            <Submit condition={getCondition(values)}/>
                        </form>
                    )}
                />
            </div>
        </main>
    )
}

export default PursuitForm

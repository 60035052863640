import {Form} from "react-final-form";
import {Fragment, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

//import components
import FirstRadio from "../commun/Form/FirstRadio";
import SliderTimeField from "../commun/Form/SliderTimeField";
import {fieldsTime} from "../commun/Form/SliderData";
import {CustomTextTooltip} from "../commun/customTooltip";
import Submit from "../commun/Form/Submit"
import CommonRadio from "../commun/Form/CommonRadio";

//import constants
import {getEmpanFormData} from "./EmpanRadioData";
import {fieldsType} from '../commun/Form/RadioData'
import {submitForm, formMount} from '../commun/Form/formFunction'
import {empanTitle} from "../../constants/titleText";

//import actions
import {responseActions} from "../../actions/responseActions";

function EmpanForm({setLaunch, initialValues, setInitialValues}) {
    const dispatch = useDispatch()

    const [step, setStep] = useState(0)

    const getFormData = type => {
        const radiosToDisplay = getEmpanFormData(type)

        return (
            <Fragment>
                {radiosToDisplay.map((radio, index) => (
                    <CommonRadio key={index} index={index + 1} step={step} setStep={setStep} fields={radio}/>
                ))}
                {step > radiosToDisplay.length && <SliderTimeField initialValue={initialValues.time} marks={fieldsTime}/>}
            </Fragment>
        )
    }

    const submit = values => submitForm(values, dispatch, setInitialValues, setLaunch)

    useEffect(() => {
        formMount(dispatch, initialValues, setStep)
        responseActions.raz(dispatch)
    }, [])

    return (
        <main className="empan">
            <div className="title">
                <CustomTextTooltip title={empanTitle}>
                    <div><FontAwesomeIcon icon="fa-circle-question"/></div>
                </CustomTextTooltip>
            </div>

            <div className="game-form empan-form">
                <Form
                    onSubmit={submit}
                    initialValues={initialValues}
                    render={({handleSubmit, values, form, hasValidationErrors}) => (
                        <form onSubmit={handleSubmit}>
                            <FirstRadio
                                step={step}
                                setStep={setStep}
                                fields={fieldsType}
                                form={form}
                                setInitialValues={setInitialValues}
                            />

                            {values.type && getFormData(values.type)}

                            <Submit condition={3 > step}/>
                        </form>
                    )}
                />
            </div>
        </main>
    )
}

export default EmpanForm

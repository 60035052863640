//import constants
import {generateRandomNumber} from "./getRandomNumber";
import {allConsonants, allVowels, blackListConsonants, consonantsWithoutBlackList} from "./dictionary";

export const getRandomLetters = length => {
    const blackList = generateRandomNumber(0, 2)

    const consonants = blackList === 0 ? [...consonantsWithoutBlackList, ...blackListConsonants] : [...consonantsWithoutBlackList]
    const randomLetters = []

    for (let i = 0; i < length; i++) {
        const index = generateRandomNumber(0, consonants.length)
        const randomLetter = consonants[index]
        consonants.splice(index, 1)
        randomLetters.push(randomLetter)
    }

    return randomLetters.join("")
}

export const getRandomLetter = () => {
    const letters = [...allConsonants, ...allVowels]
    return letters[generateRandomNumber(0, letters.length)]
}

export const generateRandomUniqueLetters = (length) => {
    const randomLetters = []

    while (randomLetters.length < length) {
        const randomLetter = getRandomLetter()
        !randomLetters.includes(randomLetter) && randomLetters.push(randomLetter)
    }

    return randomLetters
}

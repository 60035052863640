export const fieldsNumber = [
    {name: "number", value: "1", label: "Nombre mire : 1", display: "1"},
    {name: "number", value: "2", label: "Nombre mire : 2", display: "2"},
    {name: "number", value: "3", label: "Nombre mire : 3", display: "3"},
    {name: "number", value: "4", label: "Nombre mire : 4", display: "4"},
    {name: "number", value: "5", label: "Nombre mire : 5", display: "5"},
    {name: "number", value: "6", label: "Nombre mire : 6", display: "6"},
]

export const fieldsType = [
    {name: "type", value: "0", label: "Mire : chiffre", display: "0 ... 9"},
    {name: "type", value: "1", label: "Mire : lettre", display: "A ... Z"},
    {name: "type", value: "2", label: "Mire : syllabe", display: "LOGATOME"}
]

export const fieldsShape = [
    {value: "shape-1", label: "MAJUSCULE", display: <span className="shape">MAJUSCULE</span>},
    {value: "shape-2", label: "minuscule", display: <span className="shape lowercase">minuscule</span>},
]

import {Form} from "react-final-form";
import {useEffect, useState} from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {useDispatch} from "react-redux";

//import components
import FirstRadio from "../commun/Form/FirstRadio";
import {CustomTextTooltip} from "../commun/customTooltip";
import CommonRadio from "../commun/Form/CommonRadio";
import Submit from "../commun/Form/Submit";

//import constants
import {formMount, submitForm} from "../commun/Form/formFunction";
import {comparisonFormData, fieldsGrid, fieldsNumberChar, fieldsTypeComparison, fieldsGroup} from "./ComparisonRadioData";
import {comparisonTitle} from "../../constants/titleText";

//import actions
import {responseActions} from "../../actions/responseActions";

function ComparisonForm({setLaunch, initialValues, setInitialValues}) {
    const dispatch = useDispatch()

    const [step, setStep] = useState(0)

    const submit = values => submitForm(values, dispatch, setInitialValues, setLaunch)

    const getCondition = (values) => {
        const {orientation, numberChar, grid, group, difficulty} = values
        let condition = 3

        if (orientation === "0") {
            if (numberChar === "1") {
                condition = 4
            } else {
                if (difficulty === "easy") {
                    return !grid || !group || numberChar > 3
                }

                return !grid || !group
            }
        }

        if (orientation === "1") condition = 4

        return condition > step
    }

    useEffect(() => {
        formMount(dispatch, initialValues, setStep)
        responseActions.raz(dispatch)
    }, [])

    return (
        <main className="comparison">
            <div className="title">
                <CustomTextTooltip title={comparisonTitle}>
                    <div><FontAwesomeIcon icon="fa-circle-question"/></div>
                </CustomTextTooltip>
            </div>
            <div className={'game-form comparison-form'}>
                <Form
                    onSubmit={submit}
                    initialValues={initialValues}
                    render={({handleSubmit, values, form}) => (
                        <form onSubmit={handleSubmit}>
                            <FirstRadio
                                setStep={setStep}
                                fields={fieldsTypeComparison}
                                values={values}
                                form={form}
                                setInitialValues={setInitialValues}
                            />

                            {step > 0 && comparisonFormData.map((radio, index) => (
                                <CommonRadio key={index} index={index + 1} step={step} setStep={setStep} fields={radio}/>
                            ))}

                            {values && values.orientation === "0" &&
                                <CommonRadio index={comparisonFormData.length} step={step} setStep={setStep} fields={fieldsNumberChar(values.difficulty)}/>
                            }

                            {values && (values.orientation === "0" && values.numberChar && values.numberChar !== "1") &&
                                <CommonRadio index={comparisonFormData.length + 1} step={step} setStep={setStep} fields={fieldsGroup}/>
                            }

                            {values && ((values.orientation === "0" && values.numberChar === "1")
                                    || (values.orientation === "0" && values.numberChar !== "1" && values.group)
                                    || values.orientation === "1")
                                && <CommonRadio
                                    index={(values.orientation === "0" && values.numberChar !== "1") ? comparisonFormData.length + 2 : comparisonFormData.length + 1}
                                    step={step}
                                    setStep={setStep}
                                    fields={fieldsGrid}
                                />
                            }

                            <Submit condition={getCondition(values)}/>
                        </form>
                    )}
                />
            </div>
        </main>
    )
}

export default ComparisonForm

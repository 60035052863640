import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";

//import components
import {CustomTooltip} from "../commun/customTooltip";

//import selectors
import {userSelector} from "../../reducers/selectors";

function Subscribe() {
    const navigate = useNavigate()

    const user = useSelector(userSelector)

    return (
        <CustomTooltip title={user.data.hasPaid ? "" : "S'abonner"} onClick={() => !user.data.hasPaid && navigate("/abonnement")}>
            <div className={user.data.hasPaid ? "profile-icon profile-icon-error" : "profile-icon"}><FontAwesomeIcon className="icon" icon="credit-card"/></div>
        </CustomTooltip>
    )
}

export default Subscribe

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";

//import selector
import {responseSelector} from "../../reducers/selectors";

//import constants
import {trad, tradCommon, tradShape} from "../../constants/trad";
import {getStyleParameters} from "../../constants/localStorage";

//import actions
import {responseActions} from "../../actions/responseActions";

//import moment
import moment from "moment";
import 'moment/locale/fr'
moment.locale('fr')

function DisplayResults({launch, title}) {
    const response = useSelector(responseSelector)

    const dispatch = useDispatch()

    useEffect(() => {
        responseActions.serialDec(dispatch)
    }, [])

    const display = () => {
        const tradTitle = trad[title](launch)

        return Object.entries(launch).map(([key, value], index) => (
            tradTitle[key] && <div className="displayResults-content" key={index}>
                <span>{tradTitle[key].title}: </span>
                {tradTitle[key].display ? tradTitle[key].display[value] : value}
                {key === "time" && " ms"}
            </div>
        ))
    }

    const displayDate = () => {
        return (
            <div className="displayResults-date">
                <div className="displayResults-content">
                    <span>Date:</span>{moment().format("dddd Do MMMM YYYY")}
                </div>
                <div className="displayResults-content">
                    <span>Heure:</span>{moment().format("HH:mm")}
                </div>
            </div>
        )
    }

    const displayCommon = () => {
        const {contrast, size, spacing, shape} = getStyleParameters("oRtHoTrAiNeMeNtStOrAgE")

        const parameters = {contrast, size, spacing, shape}

        return Object.entries(parameters).map(([key, value], index) => (
            <div className="displayResults-content" key={index}>
                <span>{tradCommon[key]}: </span>
                {key === "shape" ? tradShape[value] : value}
            </div>
        ))
    }

    return (
        <div className="displayResults-container">
            <div>
                {display()}
                {displayDate()}
                {displayCommon()}
            </div>

            <div className="displayResults-line"></div>

            <div className="displayResults-response-container">
                <div className="displayResults-response">
                    Nombre série : {response?.serial}
                </div>

                <div className="displayResults-response">
                    <FontAwesomeIcon className="icon icon-false" icon="circle-xmark"/>
                    Réponses fausses : {response?.falseResponses?.serial}
                </div>
                <div className="displayResults-response">
                    <FontAwesomeIcon className="icon icon-true" icon="circle-check"/>
                    Bonnes réponses : {response?.trueResponses?.serial}
                </div>

                <div className="displayResults-response">
                    <FontAwesomeIcon className="icon" icon="circle-exclamation"/>
                    Réponse manquées / passées : {response?.missedResponse?.serial}
                </div>
            </div>
        </div>
    )
}

export default DisplayResults

import React from "react";
import {Field} from "react-final-form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

//import validations
import {validations} from "./validations";

//Import components
import TextInput from "./TextInput";
import {CustomTooltip} from "../customTooltip";
import DropdownInput from "./DropdownInput";

export const fields = {
    email,
    password,
    text,
    number,
    dropdown,
    iban
}

function email(disabled = false, unique = false, initialValue = {}) {
    return (
        <Field
            disabled={disabled}
            name='email'
            component={TextInput}
            type="text"
            label="Email*"
            validate={(value) => validations.required(value) || validations.email(value) ||
                (unique && initialValue !== value && validations.unique(value, "email"))}
        />
    )
}

function password(forgot = false, setForgot = null, disabled = false, label, name, identical = false) {
    return (
        <div className="it-container-container">
            <Field
                disabled={disabled}
                name={name ? name : 'password'}
                component={TextInput}
                type='password'
                label={label ? label : 'Mot de passe*'}
                validate={(value, values) => validations.required(value)
                    || (identical && validations.identicalPassword(values))
                }
            />
            {forgot && <CustomTooltip title="Mot de passe oublié ?">
                <div onClick={() => setForgot(false)}
                     className="it-forgot"><FontAwesomeIcon icon="circle-question"/>
                </div>
            </CustomTooltip>}
        </div>
    )
}

function text(name, label, disabled) {
    return (
        <Field
            disabled={disabled}
            name={name}
            component={TextInput}
            type="text"
            label={label}
            validate={(value) => validations.required(value) || validations.noSpecialCharacters(value)}
        />
    )
}

function number(name, label, disabled, length, unique = false, initialValue= {}) {
    return (
        <Field
            disabled={disabled}
            name={name}
            component={TextInput}
            maxLength={length}
            type="text"
            label={label}
            validate={(value) => validations.required(value) || validations.number(value, length) ||
                (unique && initialValue !== value && validations.unique(value, name))}
        />
    )
}

function dropdown(name, label, disabled, options, placeholder) {
    return (
        <Field
            isDisabled={disabled}
            name={name}
            component={DropdownInput}
            type="text"
            label={label}
            options={options}
            validate={validations.required}
            placeholder={placeholder}
        />
    )
}

function iban() {
    return (
        <Field
            name='iban'
            component={TextInput}
            type="text"
            label="IBAN*"
            maxLength={27}
            validate={(value) => validations.required(value) || validations.noSpecialCharacters(value)
                || validations.iban(value)}
        />
    )
}
import {useState} from "react";
import {useDispatch} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

//import component
import {scanDisplay} from "./ScanDisplay";
import BottomNavWithoutTime from "../commun/BottomNavWithoutTime";
import {CustomTooltip} from "../commun/customTooltip";

//import constants
import {getStyle} from "../../constants/localStorage";

function ScanGame({launch, setLaunch}) {
    const [display, setDisplay] = useState()

    const dispatch = useDispatch()

    const {setup, type, group} = launch
    const {textTransform} = getStyle()

    const gameDisplay = () => {
        scanDisplay(launch, setDisplay, dispatch)
    }

    const getToFind = () => {
        const {elementToFind} = display
        let toFind = elementToFind
        if (typeof toFind === 'string') toFind = textTransform === "uppercase" ? toFind.toUpperCase() : toFind
        return toFind
    }

    const getTooltip = () => {
        const {numberElements} = display
        const toFind = getToFind()
        return `Il y a ${numberElements} fois ${type === "3" ? "le dessin" : `la mire « "${toFind}" »`} sur cette page`
    }

    return (
        <main>
            <div className="title">
                {display && <CustomTooltip title={getTooltip()}>
                    <div><FontAwesomeIcon icon="fa-circle-info"/></div>
                </CustomTooltip>}
            </div>

            <div className={`scan-game setup-${setup} type-${type} ${group}`}>
                <div className="scan-game-display">
                    {display?.elements}
                </div>

                {display && <div className="scan-game-elementToFind">
                    <span className="scan-game-elementToFind-search">Sélectionne <span style={{textTransform: "none"}}>{getToFind()}</span></span>
                    {(display.elementToFind.length > 1 && type !== "2") && <span className="scan-game-elementToFind-subtitle">(Pour valider, sélectionne le premier élement de la série)</span>}
                </div>}

                <BottomNavWithoutTime
                    title={"balayage"}
                    launch={launch}
                    setLaunch={setLaunch}
                    gameDisplay={gameDisplay}
                    setDisplay={setDisplay}
                />
            </div>
        </main>
    )
}

export default ScanGame

import axios from "axios";

//import components
import {notifications} from "../components/commun/Notifications";

//import config
import {store} from "../config/redux";

//import constants
import {decrypt} from "../constants/crypto";

const getHeaders = () => {
    const {user} = store.getState()
    const token = user.credentials.token && decrypt(user.credentials.token)
    return {headers: {'Authorization': `Bearer ${token}`}}
}

export const request = async (method, url, success, rejected = null, catchCallback = null, values = null, config = null) => {
    try {
        const response = await axios.request({
            url: url,
            method: method,
            data: values,
            ...getHeaders(),
            ...config
        })
        if (response.status === 200) {
            return success(response)
        } else {
            return rejected ? rejected(response) : notifications.warning()
        }
    } catch (error) {
        return catchCallback ? catchCallback() : notifications.error()
    }
}
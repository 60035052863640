import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

//import constants
import {fieldsType} from '../../commun/Form/RadioData'

export const jerkFieldsType = [
    ...fieldsType.slice(0, 2),
    {name: "type", value: "3", label: "Mire : dessin", display: <span className="radio-icon"><FontAwesomeIcon icon="icons"/></span>},
]

export const fieldsDifficulty = [
    {name: "difficulty", value: "easy", label: "Niveau : facile", display: "FACILE"},
    {name: "difficulty", value: "medium", label: "Niveau : moyen", display: "MOYEN"},
    {name: "difficulty", value: "difficult", label: "Niveau : difficile", display: "DIFFICILE"}
]

export const fieldsDirection = [
    {name: "orientation", value: "0", label: "Position : horizontale", display: <span className="radio-icon horizontal"></span>},
    {name: "orientation", value: "1", label: "Position : verticale", display: <span className="radio-icon vertical"></span>},
    {name: "orientation", value: "2", label: "Position : diagonale (vers le haut)", display: <span className="radio-icon slash"></span>},
    {name: "orientation", value: "3", label: "Position : diagonale (vers le bas)", display: <span className="radio-icon backSlash"></span>},
    {name: "orientation", value: "4", label: "Position: bord gauche", display: <span className="radio-icon"><FontAwesomeIcon icon="arrow-left"/>|</span>},
    {name: "orientation", value: "5", label: "Position: bord droit", display: <span className="radio-icon">|<FontAwesomeIcon icon="arrow-right"/></span>},
    {name: "orientation", value: "6", label: "Position : aléatoire", display: <span className="radio-icon"><FontAwesomeIcon icon="asterisk"/></span>},

]

export const fieldsStopwatch = [
    {name: "stopwatch", value: "true", label: "Avec temps", display: <span className="radio-icon"><FontAwesomeIcon icon="hourglass-half"/></span>},
    {name: "stopwatch", value: "false", label: "Sans temps", display: <span className="radio-icon"><FontAwesomeIcon icon="hourglass"/></span>},
]

export const antiJerkFormData = [fieldsDifficulty, fieldsDirection]

export const fieldsJerkPosition = [
    {name: "position", value: "static", label: "Mire statique", display: <span className="radio-icon"><FontAwesomeIcon icon="person"/></span>},
    {name: "position", value: "movement", label: "Mire en mouvement", display: <span className="radio-icon"><FontAwesomeIcon icon="person-running"/></span>},
]

export const nearlyLetters = {
    A: ['R'],
    B: ['D', 'P', 'E', 'R'],
    C: ['G', 'O', 'S'],
    D: ['B', 'Q', 'O', 'P'],
    E: ['F', 'L', 'B', 'P'],
    F: ['E', 'P', 'R', 'T'],
    G: ['C', 'O'],
    H: ['N', 'K', 'R'],
    I: ['L', 'T', 'J', 'T'],
    J: ['I', 'U', 'Y'],
    K: ['H', 'N'],
    L: ['I', 'E'],
    M: ['N', 'W', 'Y'],
    N: ['M', 'K', 'H'],
    O: ['C', 'G', 'Q', 'D', 'U'],
    P: ['B', 'D', 'R', 'E'],
    Q: ['O', 'D'],
    R: ['A', 'P', 'B', 'F', 'H'],
    S: ['Z', 'C', 'Z'],
    T: ['F', 'I'],
    U: ['V', 'O', 'W', 'J', 'Y'],
    V: ['U', 'W', 'Y', 'J'],
    W: ['V', 'U', 'Y', 'M'],
    X: ['Y'],
    Y: ['U', 'V', 'W', 'X'],
    Z: ['S'],
    a: ['o', 'e'],
    b: ['d', 'p', 'q', 'h', 'd'],
    c: ['o', 'e', 's'],
    d: ['b', 'p', 'q'],
    e: ['c', 'o', 'a'],
    f: ['l', 't'],
    g: ['p', 'q', 'j', 'y'],
    h: ['b', 'k'],
    i: ['j', 'l', 'y'],
    j: ['i', 'g'],
    k: ['h'],
    l: ['t', 'f', 'i'],
    m: ['n'],
    n: ['m', 'u', 'r'],
    o: ['c', 'a', 'e'],
    p: ['q', 'b', 'd', 'g'],
    q: ['p', 'b', 'd', 'g'],
    r: ['n'],
    s: ['c', 'z'],
    t: ['l', 'f'],
    u: ['v', 'w', 'n'],
    v: ['u', 'w'],
    w: ['u', 'v'],
    x: ['x'],
    y: ['g', 'i'],
    z: ['s'],
}

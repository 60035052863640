import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const TextInput = ({input, meta: {touched, error}, label, ...rest}) => {
    return (
        <div className='it-container'>
            <input className={touched && error ? 'it-input it-input-error' : 'it-input'} placeholder={label} {...input} {...rest}/>
            <label className='it-label' htmlFor={input.name}>{label}</label>
            <span className="it-border"></span>

            {touched && error && <div className='it-error'>
                <FontAwesomeIcon icon="exclamation-circle"/>
                {error}
            </div>}
        </div>
    )
}

export default TextInput
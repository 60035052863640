import {Form} from "react-final-form";
import {useSelector} from "react-redux";

//import components
import {fields} from "../commun/Form/fields";

//import selectors
import {userSelector} from "../../reducers/selectors";

function SubscribePageForm({submit, loading}) {
    const user = useSelector(userSelector)

    const initialValues = {
        firstName: user.data.firstName,
        lastName: user.data.lastName,
        email: user.data.email,
    }

    return (
        <Form
            initialValues={initialValues}
            onSubmit={submit}
            render={({handleSubmit, hasValidationErrors, form}) => (
                <form
                    onSubmit={(event) => handleSubmit(event).then(() => form.restart())}
                      className="spf-form">
                    <div className="spf-container">
                        {fields.text("firstName", "Prénom*", true)}
                        {fields.text("lastName", "Nom*", true)}
                    </div>

                    {fields.email(true)}
                    {fields.iban()}

                    <button
                        disabled={hasValidationErrors}
                        className={!loading ? "subscribe-bloc" : "subscribe-bloc loading"}
                        type="submit">
                        Mettre en place ce prélèvement !
                    </button>
                </form>
            )}
        />
    )
}

export default SubscribePageForm
//import components
import InitGame from "../commun/InitGame";
import ComparisonForm from "./ComparisonForm";
import ComparisonGame from "./ComparisonGame";

function Comparison() {
    return (
        <InitGame
            form={<ComparisonForm/>}
            game={<ComparisonGame/>}
        />
    )
}

export default Comparison
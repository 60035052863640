//import services
import {request} from "./request";

export const isExist = values => {
    return request(
        'POST',
        `/api/auth/isExist`,
        response => response.data > 0,
        null,
        null,
        values
    )
}
import {Fragment} from "react";

function Svg () {
    return (
        <Fragment>
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" className="svg-settings">
                <defs>
                    <linearGradient id="linear-gradient" x1="64%" y1="0%" x2="0%" y2="76%" >
                        <stop className="linear-stop1" offset="0%"></stop>
                        <stop className="linear-stop2" offset="100%"></stop>
                    </linearGradient>
                </defs>
            </svg>

            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" className="svg-settings">
                <defs>
                    <linearGradient id="linear-gradient-false" x1="64%" y1="0%" x2="0%" y2="76%" >
                        <stop className="linear-stop1-false" offset="0%"></stop>
                        <stop className="linear-stop2-false" offset="100%"></stop>
                    </linearGradient>
                </defs>
            </svg>

            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" className="svg-settings">
                <defs>
                    <linearGradient id="linear-gradient-true" x1="64%" y1="0%" x2="0%" y2="76%" >
                        <stop className="linear-stop1-true" offset="0%"></stop>
                        <stop className="linear-stop2-true" offset="100%"></stop>
                    </linearGradient>
                </defs>
            </svg>
        </Fragment>
    )
}

export default Svg;
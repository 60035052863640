//import services
import {request} from './request';

//import components
import {notifications} from "../components/commun/Notifications";

export const upgradeServices = {
    submit,
}

function submit(uuid, values) {
    const successCallback = (response) => {
        notifications.success('Le message a bien été envoyé !')
        return response
    }

    return request(
        'POST',
        `/api/upgrade/${uuid}`,
        successCallback,
        null,
        null,
        values
    )
}
//import components
import InitGame from "../commun/InitGame";
import MemoryForm from "./MemoryForm";
import MemoryGame from "./MemoryGame";

function Memory() {
    return (
        <InitGame
            form={<MemoryForm/>}
            game={<MemoryGame/>}
        />
    )
}

export default Memory

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useDispatch, useSelector} from "react-redux";

//import actions
import {navigationActions} from "../../actions/navigationActions";

//import selector
import {userSelector} from "../../reducers/selectors";

//import components
import SwitchGroup from "./SwitchGroup";

function Home() {
    const dispatch = useDispatch()

    const user = useSelector(userSelector)

    return (
        <main>
            <div className="home">
                <div className="home-title">Bonjour {user.data.firstName} !</div>
                <div className="home-subtitle">
                    Commençons l'entraînement...
                    <FontAwesomeIcon onClick={() => navigationActions.open(dispatch)} className="icon" icon="fa-graduation-cap"/>
                </div>
                <SwitchGroup/>
            </div>
        </main>
    )
}

export default Home
import {Field} from "react-final-form";

//import components
import DefaultSliderTime from "./DefaultSliderTime";
import OtherSliderTime from "./OtherSliderTime";

function SliderTimeField ({initialValue, marks, defaultTime, defaultSlider = true}) {
    return (
        <Field
            name='time'
            component={defaultSlider ? DefaultSliderTime : OtherSliderTime}
            type="text"
            default={initialValue}
            marks={marks}
            defaultTime={defaultTime}
        />
    )
}

export default SliderTimeField
import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {Form} from "react-final-form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

//import components
import FirstRadio from "../commun/Form/FirstRadio";
import {
    fieldsPosition,
    readingFieldsTimeMovement,
    readingFieldsTimeStatic,
    readingFormDataDirection
} from "../reading/ReadingRadioData";
import {CustomTextTooltip} from "../commun/customTooltip";
import CommonRadio from "../commun/Form/CommonRadio";
import Submit from "../commun/Form/Submit";
import {fieldsNumberChar} from "../comparison/ComparisonRadioData";

//import constants
import {formMount, submitForm} from "../commun/Form/formFunction";
import SliderTimeField from "../commun/Form/SliderTimeField";
import {memoryTitle} from "../../constants/titleText";
import {fieldsTypeComparison, memoryFormData} from "./MemoryFormData";

//import actions
import {responseActions} from "../../actions/responseActions";

function MemoryForm({setLaunch, initialValues, setInitialValues}) {
    const dispatch = useDispatch()

    const [step, setStep] = useState(0)

    const submit = values => submitForm(values, dispatch, setInitialValues, setLaunch)

    useEffect(() => {
        formMount(dispatch, initialValues, setStep)
        responseActions.raz(dispatch)
    }, [])

    const getConditionSlider = (values) => {
        if (values.type !== "movement") {
            return step > (values.group === "group" ? 4 : 2)
        }

        if (values.type === "movement") {
            return step > (values.group === "group" ? 5 : 3)
        }

        return step > 2
    }

    return (
        <main className="reading">
            <div className="title">
                <CustomTextTooltip title={memoryTitle}>
                    <div><FontAwesomeIcon icon="fa-circle-question"/></div>
                </CustomTextTooltip>
            </div>
            <div className='game-form reading-form'>
                <Form
                    onSubmit={submit}
                    initialValues={initialValues}
                    render={({handleSubmit, values, form, hasValidationErrors}) => (
                        <form onSubmit={handleSubmit}>
                            <FirstRadio
                                setStep={setStep}
                                fields={[...fieldsPosition].splice(0, 2)}
                                values={values}
                                form={form}
                                setInitialValues={setInitialValues}
                            />

                            {values.type === "movement" && <CommonRadio index={1} step={step} setStep={setStep} fields={readingFormDataDirection}/>}

                            {(values.type !== "movement" || values.type === "movement" && step > 1)&& memoryFormData.map((radio, index) => (
                                <CommonRadio key={index} index={index + (values.type === "movement" ? 2 : 1)} step={step} setStep={setStep} fields={radio}/>
                            ))}

                            {values.group === "group" && <CommonRadio index={values.type === "movement" ? 4 : 3} step={step} setStep={setStep} fields={fieldsTypeComparison}/>}

                            {values.group === "group" && values.typeComparison && <CommonRadio index={values.type === "movement" ? 5 : 4} step={step} setStep={setStep} fields={[...fieldsNumberChar().splice(1, 6)]}/>}

                            {getConditionSlider(values) && <SliderTimeField
                                initialValue={initialValues.time}
                                marks={values.type === "static" ? readingFieldsTimeStatic : readingFieldsTimeMovement}
                                defaultTime={values.type === "static" ? 400 : 1000}
                                defaultSlider={false}
                            />}

                            <Submit condition={!getConditionSlider(values)}/>
                        </form>
                    )}
                />
            </div>
        </main>
    )
}

export default MemoryForm

import {useState} from "react";
import {Slider} from "@mui/material";

function DefaultSliderTime ({input, ...rest}) {
    const [defaultValue, setDefaultValue] = useState(rest.default ? rest.default === 250 : true)

    const min = rest.marks[0].value
    const max = rest.marks[rest.marks.length - 1].value

    return (
        <div className={defaultValue ? 'slider-time-' + max + ' defaultValue' : 'slider-time-' + max}>
            <Slider
                defaultValue={rest.default ? rest.default : 250}
                step={null}
                marks={rest.marks}
                min={min}
                max={max}
                valueLabelDisplay="on"
                onChange={event => {
                    input.onChange(event.target.value)
                    setDefaultValue(event.target.value === 250)
                }}
            />
        </div>
    )
}

export default DefaultSliderTime

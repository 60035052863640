import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useDispatch} from "react-redux";

//import actions
import {responseActions} from "../../../actions/responseActions";
import {navigationActions} from "../../../actions/navigationActions";

//import components
import {CustomTooltip} from "../customTooltip";

function Next({setStart, launch, title, correction}) {
    const blackList = ["poursuite", "lecture", "memoire"]
    const dispatch = useDispatch()

    const next = action => {
        navigationActions.hide(dispatch)

        if (action) {
            action(dispatch)
        }

        setStart(false)
    }

    return (
        correction
        && !blackList.includes(title)
        && !(title === "saccade" && launch.position === "movement")
        && !(title === "saccade" && launch.stopwatch === "false")
            ?
                <div className="btn-nav btn-second btn-correction">
                    <CustomTooltip title="Passer la réponse" placement="left">
                        <button onClick={() => next(responseActions.responseMissedIncSerial)}>
                            <FontAwesomeIcon className="icon icon-missed" icon="circle-exclamation"/>
                        </button>
                    </CustomTooltip>

                    <CustomTooltip title="Réponse fausse" placement="left">
                        <button onClick={() => next(responseActions.responseFalse)}>
                            <FontAwesomeIcon className="icon icon-false" icon="circle-xmark"/>
                        </button>
                    </CustomTooltip>

                    <CustomTooltip title="Bonne réponse" placement="left">
                        <button onClick={() => next(responseActions.responseTrue)}>
                            <FontAwesomeIcon className="icon icon-true" icon="circle-check"/>
                        </button>
                    </CustomTooltip>
                </div>
                :
                <CustomTooltip title="Suivant" placement="left">
                    <button className="btn-nav btn-second" onClick={() => next()}>
                        <FontAwesomeIcon className="icon" icon="circle-right"/>
                    </button>
                </CustomTooltip>
    )
}

export default Next

import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";

//import assets
import Logo from "../../assets/logo_white.png";

//import actions
import {navigationActions} from "../../actions/navigationActions";
import {CustomTooltip} from "./customTooltip";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function Mobile() {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    useEffect(() => {
        navigationActions.hide(dispatch)
    }, [])

    return (
        <main className='mobile'>
            <div className="mobile-title">
                <div className="mobile-logo"><img alt="logo Orthotraînement" src={Logo}/></div>
                <div className="mobile-name">Orthotraînement</div>
            </div>

            <div className="mobile-content">
                <p><a href="https://orthotrainement.fr/">www.orthotrainement.fr</a> n'est pas disponible pour les petits écrans (téléphone, petite tablette). Merci de réésayer avec un écran plus grand.</p>

                <a href="https://www.instagram.com/ortho.trainement/" target="_blank" className="mobile-insta">
                    <FontAwesomeIcon className="icon" icon="fa-brands fa-instagram"/>

                    <p>En attendant, visitez notre page Instagram !</p>
                </a>
            </div>

            <div className="mobile-content mobile-content-center" onClick={() => navigate("/empan")}>Recharger la page</div>
        </main>
    )
}

export default Mobile

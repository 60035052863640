import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {NavLink, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

//import assets
import LogoWhite from "../../../assets/logo_white.png"
import LogoDark from "../../../assets/logo_dark.png"

//import components
import NavBloc from "./NavBloc";
import {CustomTooltip} from "../customTooltip";

//import selectors
import {navigationSelector, userSelector} from "../../../reducers/selectors";

//import actions
import razActions from "../../../actions/razActions";

function Navigation() {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const {data} = useSelector(userSelector)
    const {display, shadow} = useSelector(navigationSelector)

    const logout = () => {
        razActions(dispatch)
        navigate("/")
    }

    return (
            <nav className={display ? "navigation" : "navigation-hide"}>
                <div className="navigation-start"><img src={shadow ? LogoDark : LogoWhite} alt="logo Orthotraînement"/></div>

                <div className="navigation-navlink-container">
                    <NavBloc title="empan" link="/empan"/>
                    <NavBloc title="anti-saccade" link="/antiSaccade"/>
                    <NavBloc title="saccade" link="/saccade"/>
                    <NavBloc title="comparaison" link="/comparaison"/>
                    <NavBloc title="lecture" link="/lecture"/>
                    <NavBloc title="balayage" link="/balayage"/>
                    <NavBloc title="poursuite" link="/poursuite"/>
                    <NavBloc title="mémoire" link="/memoire"/>
                </div>

                <div className='navigation-footer'>
                    <CustomTooltip title="Profil">
                        <div className='navigation-footer-profile'>
                            <NavLink className="navigation-profile" to="/profil">{data.firstName && data.firstName[0]}</NavLink>
                            <div className='navigation-footer-status' style={{color: data.tryPeriod ? "#07bc0c" : data.hasPaid ? "#07bc0c" : "#cc3300"}}>
                                <FontAwesomeIcon icon="circle"/>
                                {data.tryPeriod ? "Période d'essai" : data.hasPaid ? "Actif" : "Inactif"}
                            </div>
                        </div>
                    </CustomTooltip>

                    <CustomTooltip title="Déconnexion">
                        <div className='navigation-footer-logout' onClick={logout}><FontAwesomeIcon className="icon" icon="fa-right-from-bracket"/></div>
                    </CustomTooltip>
                </div>
            </nav>
    )
}

export default Navigation

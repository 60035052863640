import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

//import components
import BottomNav from "../commun/BottomNav";
import {jerkDisplay, jerkDisplayStatic} from "./pattern/JerkPatternDisplay";
import BottomNavWithoutTime from "../commun/BottomNavWithoutTime";

//import actions
import {responseActions} from "../../actions/responseActions";

//import selectors
import {responseSelector} from "../../reducers/selectors";

function JerkGame({launch, setLaunch}) {
    const stopwatch = launch.stopwatch === "true"

    const {difficulty, position} = launch
    const orientation = parseInt(launch.orientation)
    const newLaunch = {...launch, stopwatch, jerk: 2}

    const [display, setDisplay] = useState()
    const [start, setStart] = useState(false)

    const dispatch = useDispatch()

    const {trueResponses, falseResponses, serial} = useSelector(responseSelector)

    useEffect(() => {
        if (!start && position === "static") {
            if (serial !== 0 && trueResponses.current === 0 && falseResponses.current === 0) {
                responseActions.responseMissedIncSerial(dispatch)
            }

            jerkDisplay[orientation](setDisplay, setStart, newLaunch)
        }
    }, [start])

    const getGameDisplay = () => {
        responseActions.displayResponse(dispatch, false)

        return position === "movement" ?
            jerkDisplayStatic(setDisplay, setStart, newLaunch)
            :
            jerkDisplay[orientation](setDisplay, setStart, newLaunch)
    }

    return (
        <main className={(!start && position === "static" && stopwatch) ? "cursor-hide" : undefined}>
            <div className="antiJerk-game">
                <div className={`antiJerk-game-display ${difficulty}`}>
                    {display}
                </div>

                {start && <BottomNav
                    title={"saccade"}
                    start={start}
                    setStart={setStart}
                    setLaunch={setLaunch}
                    launch={launch}
                    setDisplay={setDisplay}
                />}

                {(position === "movement" || (!stopwatch && position === "static")) &&
                    <BottomNavWithoutTime
                        title={"saccade"}
                        launch={launch}
                        setLaunch={setLaunch}
                        gameDisplay={getGameDisplay}
                        setDisplay={setDisplay}
                    />
                }
            </div>
        </main>
    )
}

export default JerkGame

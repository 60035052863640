import {Fragment} from "react";
import styled, {keyframes, css} from "styled-components"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

//import constants
import {generateRandomNumber, generateRandomUniqueNumbers} from "../../constants/getRandomNumber";
import {memoryData, memoryDataGroup} from "./series";
import {getStyle} from "../../constants/localStorage";
import {generateRandomUniqueLetters, getRandomLetter} from "../../constants/getRandomLetter";
import {getColor} from "../../constants/constants";

//import actions
import {responseActions} from "../../actions/responseActions";

export const displayNoGroup = (target) => {
    const styleStored = getStyle()
    const style = {...styleStored, paddingLeft: 0, paddingRight: 0}

    const getIconsToDisplay = () => {
        const randomNumber = generateRandomNumber(0, memoryData.length)
        const fake = [...memoryData[randomNumber]].sort(() => 0.5 - Math.random())
        const newFake = fake.map(element => (
            <FontAwesomeIcon
                style={{...style, padding: `0 ${style.letterSpacing}`}}
                className={element.custom}
                icon={element.name}
            />
        ))
        const real = newFake[generateRandomNumber(0, 4)]
        return {fake: newFake, real}
    }

    const getElementsToDisplay = (elements) => {
        const fake = elements().map(element => <span style={style}>{element}</span>)
        const real = fake[generateRandomNumber(0, 4)]
        return {fake, real}
    }

    const getNumbersToDisplay = () => {
        const elements = () => generateRandomUniqueNumbers(4)
        const {fake, real} = getElementsToDisplay(elements)
        return {fake, real}
    }

    const getLettersToDisplay = () => {
        const elements = () => generateRandomUniqueLetters(4)
        const {fake, real} = getElementsToDisplay(elements)
        return {fake, real}
    }

    const getRandomToDisplay = () => {
        const random = generateRandomNumber(0, 3)

        switch (random) {
            case 0: return getIconsToDisplay()
            case 1: return getNumbersToDisplay()
            case 2: return getLettersToDisplay()
        }
    }

    const targetToDisplay = {
        "0": getIconsToDisplay(),
        "1": getNumbersToDisplay(),
        "2": getLettersToDisplay(),
        "3": getRandomToDisplay()
    }

    return targetToDisplay[target]
}

const displayGroup = (launch) => {
    const {target, numberChar, typeComparison} = launch
    const styleStored = getStyle()
    const style = {...styleStored, paddingLeft: 0, paddingRight: 0}

    const countCondition = (n) => {
        if (n === 0 || n === 1) return 1;
        else return n * countCondition(n - 1);
    }

    const countUniqueElement = (iterable) => new Set(iterable).size;

    const getElements = (element) => {
        const fake = Array.from({ length: numberChar === "2" ? 4 : 1 }, () => (
            Array.from({ length: numberChar }, () => element())
        ))

        if (numberChar !== "2") {
            const count = countUniqueElement(fake[0])
            const numberConditions = countCondition(count)

            if (numberConditions < 4) return getElements(element)

            while (fake.length < 4) {
                const shuffledArray = [...fake[0]].sort(() => 0.5 - Math.random());
                const isExist = fake.some(element => JSON.stringify(element) === JSON.stringify(shuffledArray))

                if (!isExist) {
                    fake.push(shuffledArray)
                }
            }
        }

        return fake
    }

    const getElementsToDisplay = (elementToDisplay, target) => {
        const element = () => typeComparison === "0" ? getColor() : elementToDisplay()
        const elements = getElements(element)

        let fake = []

        if (typeComparison === "0") {
            const toDisplay = elementToDisplay()
            fake = elements.map(elements => elements.map((element, index) => (
                <span className={element} className={element} style={{ color: element }} key={index}>
                    {target === "0" ?
                        <FontAwesomeIcon style={{ ...style, padding: `0 ${style.letterSpacing}` }} icon={toDisplay} />
                        :
                        <span className="child" style={{...style, color: typeComparison === "0" ? "inherit" : ""}}>{toDisplay}</span>
                    }
                </span>
            )))
        } else {
            fake = elements.map(elements => elements.map((element, index) => (
                target === "0" ?
                    <FontAwesomeIcon key={index} style={{ ...style, padding: `0 ${style.letterSpacing}` }} icon={element} />
                    :
                    <span className="child" style={{...style, color: typeComparison === "0" ? "inherit" : ""}} key={index}>{element}</span>
            )))
        }

        const real = fake[generateRandomNumber(0, 4)]
        return { fake, real }
    }

    const getIconsToDisplay = (target) => {
        const data = memoryDataGroup[generateRandomNumber(0, 2)]
        const elementToDisplay = () => data[generateRandomNumber(0, data.length)]
        const { fake, real } = getElementsToDisplay(elementToDisplay, target)
        return { fake, real }
    }

    const getNumbersToDisplay = (target) => {
        const elementToDisplay = () => generateRandomNumber(0, 10)
        const { fake, real } = getElementsToDisplay(elementToDisplay, target)
        return { fake, real }
    }

    const getLettersToDisplay = (target) => {
        const elementToDisplay = () => getRandomLetter()
        const { fake, real } = getElementsToDisplay(elementToDisplay, target)
        return { fake, real }
    }

    const getRandomToDisplay = () => {
        const random = generateRandomNumber(0, 3)

        switch (random) {
            case 0: return getIconsToDisplay(random.toString())
            case 1: return getNumbersToDisplay(random.toString())
            case 2: return getLettersToDisplay(random.toString())
        }
    }

    const targetToDisplay = {
        "0": getIconsToDisplay(target),
        "1": getNumbersToDisplay(target),
        "2": getLettersToDisplay(target),
        "3": getRandomToDisplay()
    }

    return targetToDisplay[target]
}

export const memoryDisplay = (setDisplay, setStart, launch, dispatch) => {
    const {direction, type, target, group, typeComparison, time} = launch

    const elementsToDisplay = group === "group" ? displayGroup(launch) : displayNoGroup(target)

    const checkNoGroup = (event) => {
        const target = event.target
        let element = target

        if (target.className === "rgd-fake-word-content ") {
            element = target.firstChild
        }

        const real = elementsToDisplay.real

        if (real.props.children || real.props.children === 0) {
            if (element.textContent === real.props.children.toString()) {
                element.parentNode.classList.add("true")
                responseActions.responseTrue(dispatch)
            } else {
                element.parentNode.classList.add("false")
                responseActions.responseFalse(dispatch)
            }
        } else {
            //icons
            if (element.tagName === "path") {
                element = target.parentNode
            }

            const classNames = Array.from(element.classList).splice(1, 3)
            const realClassNames = real.props.className !== "" ? [`fa-${real.props.icon}`, real.props.className] : [`fa-${real.props.icon}`]

            if (JSON.stringify(classNames) === JSON.stringify(realClassNames)) {
                element.parentNode.classList.add("true")
                responseActions.responseTrue(dispatch)
            } else {
                element.parentNode.classList.add("false")
                responseActions.responseFalse(dispatch)
            }
        }
    }

    const checkGroup = (event) => {
        const real = elementsToDisplay.real
        const target = event.target
        let element = target

        if (target.className === "child" || target.tagName === "svg" || target.tagName === "path") {
            element = target.parentNode

            if (target.tagName === "path" || typeComparison === "0") {
                element = element.parentNode

                if (target.tagName === "path" && typeComparison === "0") {
                    element = element.parentNode
                }
            }
        }

        const children = Array.from(element.childNodes)

        const getValues = () => {
            if (typeComparison === "0") {
                const compare = children.map(child => child.className).join("")
                const newReal = real.map(element => element.props.className).join("")
                return {compare, newReal}
            }

            if (real[0].props.children || real[0].props.children === 0) {
                if (typeComparison === "0") {
                    const compare = children.map(child => child.className).join("")
                    const newReal = real.map(element => element.props.className).join("")
                    return {compare, newReal}
                } else {
                    const compare = children.map(child => child.innerHTML).join("")
                    const newReal = real.map(element => element.props.children).join("")
                    return {compare, newReal}
                }
            } else {
                const compare = children.map(child => child.dataset.icon).join("")
                const newReal = real.map(element => {
                    const icon = element.props.icon
                    return typeof icon === "object" ? icon.iconName : icon
                }).join("")
                return {compare, newReal}
            }
        }

        const {compare, newReal} = getValues()

        if (compare === newReal) {
            element.classList.add("true")
            responseActions.responseTrue(dispatch)
        } else {
            element.classList.add("false")
            responseActions.responseFalse(dispatch)
        }
    }

    const check = group === "group" ? checkGroup : checkNoGroup

    const movementRtL = keyframes`
      0% {transform: translateX(35vw)}
      100% {transform: translateX(-35vw)}
    `;

    const movementLtR = keyframes`
      0% {transform: translateX(-35vw)}
      100% {transform: translateX(35vw)}
    `;

    const DIV = styled.div`
      ${(props) => props.movement && css`
        animation: ${direction === "RtL" ? movementRtL : movementLtR} ${time + 50}ms linear;
      `};
    `

    const animate = (rwClassName) => (
        <div className="reading-animate-container">
            <div className="blank blank-left"></div>
            <DIV movement={type === "movement" && rwClassName !== "hide"} className={`rgd-real-word ${rwClassName}`}>{elementsToDisplay.real}</DIV>
            <div className="blank blank-right"></div>
        </div>
    )

    const divToDisplay = (rwClassName, fwClassName) => {
        return (
            <Fragment>
                {type === "movement" && rwClassName !== "hide" ?
                    animate(rwClassName)
                    :
                    <div className={`rgd-real-word ${rwClassName} reading-noAnimate`}>{elementsToDisplay.real}</div>
                }
                <div className={`rgd-fake-word-container ${fwClassName}`}>
                    {elementsToDisplay.fake.map((element, index) =>
                        <div className={`rgd-fake-word-content ${fwClassName}`} key={index} onClick={check}>
                            {element}
                        </div>
                    )}
                </div>
            </Fragment>
        )
    }

    setDisplay(divToDisplay("", "hide"))
    setTimeout(() => setDisplay(divToDisplay("hide", "")), time)
    setTimeout(() => setStart(true), time)
}

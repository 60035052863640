import {styled} from "@mui/material/styles";
import Tooltip, {tooltipClasses} from "@mui/material/Tooltip";

export const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />))
({[`& .${tooltipClasses.tooltip}`]: {minWidth: "auto"}});

export const CustomTextTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />))
({[`& .${tooltipClasses.tooltip}`]: {minWidth: "50rem"}});

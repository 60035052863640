import {useDispatch, useSelector} from "react-redux";
import {useState, useEffect} from "react";
import {useLocation} from "react-router-dom";

//import selector
import {userSelector} from "../../reducers/selectors";

//import components
import UpdateProfile from "./UpdateProfile";
import UpdatePassword from "./UpdatePassword";
import {notifications} from "../commun/Notifications";

//import actions
import {userActions} from "../../actions/userActions";
import {navigationActions} from "../../actions/navigationActions";

function Profile() {
    const user = useSelector(userSelector)

    const dispatch = useDispatch()
    const location = useLocation()

    const [form, setForm] = useState(true)

    useEffect(() => {
        if (location.search === "?hasPaid=true") {
            userActions.edit(dispatch, {hasPaid: true})
            notifications.success("L'abonnement a été créé.")
        }
        window.history.replaceState({}, document.title, "/profil");
        navigationActions.display(dispatch)
    }, [])

    return (
        <main>
            <div className="profile">
                <div className="profile-picture">{user.data.firstName[0]}</div>

                <div className="profile-form">
                    {form ?
                        <UpdateProfile setForm={setForm}/>
                        :
                        <UpdatePassword setForm={setForm}/>
                    }
                </div>
            </div>
        </main>
    )
}

export default Profile

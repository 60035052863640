import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

//import constants
import BottomNav from "../commun/BottomNav";

//import selector
import {memoryDisplay} from "./MemoryDisplay";

//import actions
import {responseActions} from "../../actions/responseActions";
import {responseSelector} from "../../reducers/selectors";

function MemoryGame({launch, setLaunch}) {
    const [display, setDisplay] = useState()
    const [start, setStart] = useState(false)

    const {trueResponses, serial} = useSelector(responseSelector)

    const dispatch = useDispatch()

    const {group, typeComparison} = launch

    useEffect(() => {
        if (!start) {
            if (serial !== 0 && trueResponses.current === 0) {
                responseActions.responseMissedIncSerial(dispatch)
            }

            responseActions.responseTrueCurrent(dispatch)
            responseActions.responseFalseCurrent(dispatch)
            setDisplay()
            setTimeout(() => memoryDisplay(setDisplay, setStart, launch, dispatch), 1000)
        }
    }, [start])

    return (
        <main className={!start ? "cursor-hide" : undefined}>
            <div className={`reading-game memory-game ${group} typeComparison-${typeComparison}`}>
                <div className="reading-game-display">{display}</div>
                {start && <BottomNav
                    title="memoire"
                    start={start}
                    setStart={setStart}
                    setLaunch={setLaunch}
                    launch={launch}
                    setDisplay={setDisplay}
                />}
            </div>
        </main>
    )
}

export default MemoryGame

import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

//import constants
import {empanDisplay} from "./EmpanDisplay";

//import components
import BottomNav from "../commun/BottomNav";

//import constants
import {getStyle} from "../../constants/localStorage";

//import actions
import {responseActions} from "../../actions/responseActions";

//import selectors
import {responseSelector} from "../../reducers/selectors";

function EmpanGame({launch, setLaunch}) {
    const [display, setDisplay] = useState()
    const [start, setStart] = useState(false)

    const type = parseInt(launch.type)
    const number = parseInt(launch.number)
    const time = launch.time
    const direction = launch.direction

    const {trueResponses, falseResponses, serial} = useSelector(responseSelector)

    const dispatch = useDispatch()

    const style = getStyle()

    useEffect(() => {
        if (!start) {
            if (serial !== 0 && trueResponses.current === 0 && falseResponses.current === 0) {
                responseActions.responseMissedIncSerial(dispatch)
            }

            setDisplay(<FontAwesomeIcon className="point" icon="circle"/>)
            setTimeout(() => empanDisplay[type](setDisplay, setStart, time, number, direction, style), 2000)
        }
    }, [start])

    return (
        <main className={!start ? "cursor-hide" : undefined}>
            <div className="empan-game">
                <div className="empan-game-display">
                    {display}
                </div>
                {start && <BottomNav
                    title="empan"
                    start={start}
                    setStart={setStart}
                    setDisplay={setDisplay}
                    setLaunch={setLaunch}
                    launch={launch}
                />}
            </div>
        </main>
    )
}

export default EmpanGame

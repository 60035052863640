import {library} from '@fortawesome/fontawesome-svg-core'

import {
    faCircleRight, faGraduationCap, faChevronRight, faArrowLeft, faArrowRight, faCircle, faAsterisk, faXmark, faPlus,
    faStarOfLife, faFaceSadTear, faBorderAll, faBorderNone, faCircleQuestion, faExclamationCircle, faRightFromBracket,
    faKey, faPen, faCheck, faLightbulb, faPencil, faAddressCard, faLaptop, faEye, faAt, faCreditCard, faFileArrowDown,
    faPerson, faPersonRunning, faHourglassHalf, faHourglass, faCircleCheck, faCircleXmark, faFloppyDisk, faCircleInfo,
    faBars, faWater, faUpRightAndDownLeftFromCenter, faDownLeftAndUpRightToCenter, faZ, faArrowsLeftRight,
    faMagnifyingGlass, faMagnifyingGlassPlus, faMagnifyingGlassMinus, faMagnifyingGlassLocation, faMagnifyingGlassChart,
    faMagnifyingGlassArrowRight, faEyeSlash, faEyeLowVision, faImage, faPhone, faMusic, faWandMagicSparkles, faBomb,
    faCameraRetro, faFile, faMugHot, faUmbrella, faCircleHalfStroke, faPlane, faMagnet, faHand, faThumbsUp, faBicycle,
    faFish, faFeather, faBug, faMugSaucer, faGauge, faStethoscope, faMountainSun, faClock, faPlay, faBackward, faCopy,
    faHourglassStart, faHandPointUp, faFlag, faRocket, faPowerOff, faMoon, faHammer, faCube, faPlug, faClone, faDice,
    faNotEqual, faOtter, faYinYang, faVolumeXmark, faVenus, faTableTennisPaddleBall, faStarHalfStroke, faSignsPost,
    faShoePrints, faShapes, faPizzaSlice, faPersonArrowUpFromLine, faPersonArrowDownToLine, faPeace, faHouseChimney,
    faHillRockslide, faHillAvalanche, faHeadSideMask, faHeadSideCough, faHeadSideCoughSlash, faHandDots, faHandPointRight,
    faGuitar, faFootball, faCrop, faCropSimple, faCloudSunRain, faCloudSun, faCloudMoon, faCloudMoonRain, faCirclePlay,
    faCarrot, faAngleUp, faHouse, faHouseChimneyWindow, faHouseMedical, faHouseChimneyUser, faHouseChimneyMedical, faFaucet,
    faFaucetDrip, faTemperatureArrowUp, faTemperatureArrowDown, faHorse, faHorseHead, faUser, faUserGroup, faUsers, faUserTie,
    faUsersViewfinder, faUsersRectangle, faUsersLine, faUsersBetweenLines, faUserNurse, faUserDoctor, faUserGraduate,
    faPersonWalking, faPersonWalkingWithCane, faPersonWalkingLuggage, faPersonSkiingNordic, faPersonSkiing, faPersonSkating,
    faPersonSwimming, faPersonDrowning, faPersonDotsFromLine, faRuler, faRulerVertical, faImages, faFileImage, faPhotoFilm,
    faPhoneVolume, faPhoneSlash, faPager, faMobileScreenButton, faMobileButton, faMobileRetro, faMicrophone, faMicrophoneSlash,
    faMicrophoneLines, faMicrophoneLinesSlash, faCirclePlus, faCircleMinus, faCirclePause, faCircleStop, faChevronCircleRight,
    faSchoolCircleXmark, faSchoolCircleExclamation, faSchoolCircleCheck, faSchool, faPlugCircleXmark, faPlugCirclePlus,
    faPlugCircleMinus, faPlugCircleCheck, faAnchor, faAnchorCircleXmark, faAnchorCircleCheck, faAnchorCircleExclamation,
    faCamera, faCameraRotate, faLocationDot, faLocationPin, faMapLocationDot, faMapLocation, faChartSimple, faSquarePollVertical,
    faSquarePollHorizontal, faLandmark, faLandmarkFlag, faLandmarkDome, faCircleNotch, faBullseye, faCrosshairs, faCircleDot,
    faCloud, faCloudShowersHeavy, faCloudRain, faCloudShowersWater, faComment, faComments, faCommentSlash, faCommentDots,
    faTruck, faTruckFast, faTruckMedical, faTruckDroplet, faTruckMonster, faTruckPickup, faCarSide, faVanShuttle,
    faCar, faTaxi, faCarRear, faBusSimple, faGaugeSimple, faMaximize, faMinimize, faArrowsToDot, faArrowsToCircle,
    faShieldHeart, faShieldDog, faShieldVirus, faShieldCat, faShield, faShieldHalved, faFaceSmile, faFaceSmileWink, faFaceMeh,
    faFaceSmileBeam, faFaceLaughBeam, faFaceLaughWink, faFaceLaughSquint, faFaceLaugh, faFaceGrinWide, faFaceGrinStars,
    faFaceGrinBeamSweat, faFaceGrinBeam, faFaceGrinTongue, faFaceGrinTongueWink, faFaceGrinTongueSquint, faFaceGrinSquint,
    faFaceGrinHearts, faFaceGrinTears, faFaceGrinSquintTears, faFaceKiss, faFaceKissBeam, faFaceKissWinkHeart,
    faFaceAngry, faFaceFrown, faFaceFrownOpen, faDiceTwo, faDiceThree, faDiceFour, faDiceFive, faDiceSix, faDiceOne,
    faTable, faTableColumns, faTableList, faTableCells, faDiagramSuccessor, faDiagramPredecessor, faScaleBalanced, faScaleUnbalanced,
    faScaleUnbalancedFlip, faBagShopping, faBriefcase, faSuitcaseRolling, faSuitcase, faCalendarWeek, faCalendar, faCalendarMinus,
    faCalendarDays, faChartGantt, faChartColumn, faChartBar, faChartLine, faUmbrellaBeach, faHandsHolding, faHandsHoldingChild,
    faHandsHoldingCircle, faHandHoldingHand, faList, faListUl, faBarsStaggered, faGear, faGears, faUserGear, faUsersGear,
    faEarthAmericas, faEarthOceania, faEarthAfrica, faEarthAsia, faMartiniGlass, faMartiniGlassEmpty, faMartiniGlassCitrus,
    faHourglassEnd, faLock, faLockOpen, faUnlock, faUnlockKeyhole, faAlignRight, faAlignCenter, faAlignJustify, faAlignLeft,
    faFolder, faFolderOpen, faFolderMinus, faFolderClosed, faPlaneArrival, faPlaneDeparture, faPepperHot, faMoneyBill,
    faMoneyBillWave, faMoneyBill1Wave, faMoneyBill1, faVirus, faViruses, faDisease, faVirusSlash, faVirusCovid, faVirusCovidSlash,
    faSquare, faFlagCheckered, faFlagUsa, faFontAwesome, faMotorcycle, faSlash, faBowlingBall, faVolleyball, faBasketball, faBaseball,
    faBatteryEmpty, faBatteryFull, faBatteryQuarter, faBatteryHalf, faRecordVinyl, faCompactDisc, faCookie, faCookieBite,
    faStroopwafel, faWheelchair, faWheelchairMove, faWandMagic, faWandSparkles, faVolumeOff, faVolumeHigh, faVolumeLow,
    faVenusDouble, faVest, faVestPatches, faBorderTopLeft, faArrowUpWideShort, faArrowUpShortWide, faArrowDownShortWide,
    faArrowDownWideShort, faTowerObservation, faVihara, faMonument, faTowerCell, faTent, faTents, faSprayCan, faSprayCanSparkles,
    faMountain, faVolcano, faPumpSoap, faPumpMedical, faPeopleGroup, faPeopleRoof, faPeopleLine, faArrowsDownToPeople,
    faPrescriptionBottle, faPrescriptionBottleMedical, faTrash, faTrashArrowUp, faPlusMinus, faMinus, faGripLines, faGripLinesVertical,
    faBacterium, faBacteria, faFire, faFireFlameSimple, faFireFlameCurved, faWineGlass, faChampagneGlasses, faWineGlassEmpty,
    faCapsules, faBowlRice, faBowlFood, faBurger, faPlateWheat, faPersonRays, faStar, faIcons, faHeart, faDiamond, faCertificate,
    faHippo, faDog, faCow, faDragon, faSpider, faMosquito, faFrog, faCrow, faCat, faCrown, faBell, faLemon, faSun,
    faSplotch, faStarHalf, faPersonFalling, faUserClock, faUserPlus, faUserXmark, faHouseCrack, faHouseChimneyCrack, faEquals,
    faCircleLeft, faCompass, faHandshake, faFileWord, faJetFighter, faListOl, faListCheck
} from '@fortawesome/free-solid-svg-icons'

import { faInstagram } from '@fortawesome/free-brands-svg-icons'

library.add(
    faCircleRight, faGraduationCap, faChevronRight, faArrowLeft, faArrowRight, faCircle, faAsterisk, faXmark, faPlus,
    faStarOfLife, faFaceSadTear, faBorderAll, faBorderNone, faCircleQuestion, faExclamationCircle, faRightFromBracket,
    faKey, faPen, faCheck, faLightbulb, faPencil, faAddressCard, faLaptop, faEye, faAt, faCreditCard, faFileArrowDown,
    faPerson, faPersonRunning, faHourglassHalf, faHourglass, faCircleCheck, faCircleXmark, faFloppyDisk, faCircleInfo,
    faBars, faWater, faUpRightAndDownLeftFromCenter, faDownLeftAndUpRightToCenter, faZ, faArrowsLeftRight,
    faMagnifyingGlass, faMagnifyingGlassPlus, faMagnifyingGlassMinus, faMagnifyingGlassLocation, faMagnifyingGlassChart,
    faMagnifyingGlassArrowRight, faEyeSlash, faEyeLowVision, faImage, faPhone, faMusic, faWandMagicSparkles, faBomb,
    faCameraRetro, faFile, faMugHot, faUmbrella, faCircleHalfStroke, faPlane, faMagnet, faHand, faThumbsUp, faBicycle,
    faFish, faFeather, faBug, faMugSaucer, faGauge, faStethoscope, faMountainSun, faClock, faPlay, faBackward, faCopy,
    faHourglassStart, faHandPointUp, faFlag, faRocket, faPowerOff, faMoon, faHammer, faCube, faPlug, faClone, faDice,
    faNotEqual, faOtter, faYinYang, faVolumeXmark, faVenus, faTableTennisPaddleBall, faStarHalfStroke, faSignsPost,
    faShoePrints, faShapes, faPizzaSlice, faPersonArrowUpFromLine, faPersonArrowDownToLine, faPeace, faHouseChimney,
    faHillRockslide, faHillAvalanche, faHeadSideMask, faHeadSideCough, faHeadSideCoughSlash, faHandDots, faHandPointRight,
    faGuitar, faFootball, faCrop, faCropSimple, faCloudSunRain, faCloudSun, faCloudMoon, faCloudMoonRain, faCirclePlay,
    faCarrot, faAngleUp, faHouse, faHouseChimneyWindow, faHouseMedical, faHouseChimneyUser, faHouseChimneyMedical, faFaucet,
    faFaucetDrip, faTemperatureArrowUp, faTemperatureArrowDown, faHorse, faHorseHead, faUser, faUserGroup, faUsers, faUserTie,
    faUsersViewfinder, faUsersRectangle, faUsersLine, faUsersBetweenLines, faUserNurse, faUserDoctor, faUserGraduate,
    faPersonWalking, faPersonWalkingWithCane, faPersonWalkingLuggage, faPersonSkiingNordic, faPersonSkiing, faPersonSkating,
    faPersonSwimming, faPersonDrowning, faPersonDotsFromLine, faRuler, faRulerVertical, faImages, faFileImage, faPhotoFilm,
    faPhoneVolume, faPhoneSlash, faPager, faMobileScreenButton, faMobileButton, faMobileRetro, faMicrophone, faMicrophoneSlash,
    faMicrophoneLines, faMicrophoneLinesSlash, faCirclePlus, faCircleMinus, faCirclePause, faCircleStop, faChevronCircleRight,
    faSchoolCircleXmark, faSchoolCircleExclamation, faSchoolCircleCheck, faSchool, faPlugCircleXmark, faPlugCirclePlus,
    faPlugCircleMinus, faPlugCircleCheck, faAnchor, faAnchorCircleXmark, faAnchorCircleCheck, faAnchorCircleExclamation,
    faCamera, faCameraRotate, faLocationDot, faLocationPin, faMapLocationDot, faMapLocation, faChartSimple, faSquarePollVertical,
    faSquarePollHorizontal, faLandmark, faLandmarkFlag, faLandmarkDome, faCircleNotch, faBullseye, faCrosshairs, faCircleDot,
    faCloud, faCloudShowersHeavy, faCloudRain, faCloudShowersWater, faComment, faComments, faCommentSlash, faCommentDots,
    faTruck, faTruckFast, faTruckMedical, faTruckDroplet, faTruckMonster, faTruckPickup, faCarSide, faVanShuttle,
    faCar, faTaxi, faCarRear, faBusSimple, faGaugeSimple, faMaximize, faMinimize, faArrowsToDot, faArrowsToCircle,
    faShieldHeart, faShieldDog, faShieldVirus, faShieldCat, faShield, faShieldHalved, faFaceSmile, faFaceSmileWink, faFaceMeh,
    faFaceSmileBeam, faFaceLaughBeam, faFaceLaughWink, faFaceLaughSquint, faFaceLaugh, faFaceGrinWide, faFaceGrinStars,
    faFaceGrinBeamSweat, faFaceGrinBeam, faFaceGrinTongue, faFaceGrinTongueWink, faFaceGrinTongueSquint, faFaceGrinSquint,
    faFaceGrinHearts, faFaceGrinTears, faFaceGrinSquintTears, faFaceKiss, faFaceKissBeam, faFaceKissWinkHeart,
    faFaceAngry, faFaceFrown, faFaceFrownOpen, faDiceTwo, faDiceThree, faDiceFour, faDiceFive, faDiceSix, faDiceOne,
    faTable, faTableColumns, faTableList, faTableCells, faDiagramSuccessor, faDiagramPredecessor, faScaleBalanced, faScaleUnbalanced,
    faScaleUnbalancedFlip, faBagShopping, faBriefcase, faSuitcaseRolling, faSuitcase, faSuitcase, faCalendarWeek, faCalendar,
    faCalendarMinus, faCalendarDays, faChartGantt, faChartColumn, faChartBar, faChartLine, faUmbrellaBeach, faHandsHolding,
    faHandsHoldingChild, faHandsHoldingCircle, faHandHoldingHand, faList, faListUl, faBarsStaggered, faGear, faGears, faUserGear, faUsersGear,
    faEarthAmericas, faEarthOceania, faEarthAfrica, faEarthAsia, faMartiniGlass, faMartiniGlassEmpty, faMartiniGlassCitrus,
    faHourglassEnd, faLock, faLockOpen, faUnlock, faUnlockKeyhole, faAlignRight, faAlignCenter, faAlignJustify, faAlignLeft,
    faFolder, faFolderOpen, faFolderMinus, faFolderClosed, faPlaneArrival, faPlaneDeparture, faPepperHot, faMoneyBill,
    faMoneyBillWave, faMoneyBill1Wave, faMoneyBill1, faVirus, faViruses, faDisease, faVirusSlash, faVirusCovid, faVirusCovidSlash,
    faSquare, faFlagCheckered, faFlagUsa, faFontAwesome, faMotorcycle, faSlash, faBowlingBall, faVolleyball, faBasketball, faBaseball,
    faBatteryEmpty, faBatteryFull, faBatteryQuarter, faBatteryHalf, faRecordVinyl, faCompactDisc, faCookie, faCookieBite,
    faStroopwafel, faWheelchair, faWheelchairMove, faWandMagic, faWandSparkles, faVolumeOff, faVolumeHigh, faVolumeLow,
    faVenusDouble, faVest, faVestPatches, faBorderTopLeft, faArrowUpWideShort, faArrowUpShortWide, faArrowDownShortWide,
    faArrowDownWideShort, faTowerObservation, faVihara, faMonument, faTowerCell, faTent, faTents, faSprayCan, faSprayCanSparkles,
    faMountain, faVolcano, faPumpSoap, faPumpMedical, faPeopleGroup, faPeopleRoof, faPeopleLine, faArrowsDownToPeople,
    faPrescriptionBottle, faPrescriptionBottleMedical, faTrash, faTrashArrowUp, faPlusMinus, faMinus, faGripLines, faGripLinesVertical,
    faBacterium, faBacteria, faFire, faFireFlameSimple, faFireFlameCurved, faWineGlass, faChampagneGlasses, faWineGlassEmpty,
    faCapsules, faBowlRice, faBowlFood, faBurger, faPlateWheat, faPersonRays, faStar, faIcons, faHeart, faDiamond, faCertificate,
    faHippo, faDog, faCow, faDragon, faSpider, faMosquito, faFrog, faCrow, faCat, faCrown, faBell, faLemon, faSun,
    faSplotch, faStarHalf, faPersonFalling, faUserClock, faUserPlus, faUserXmark, faHouseCrack, faHouseChimneyCrack, faEquals,
    faCircleLeft, faInstagram, faCompass, faHandshake, faFileWord, faJetFighter, faListOl, faListCheck
)

import {Fragment, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

//import components
import UpgradeText from "./UpgradeText";
import UpgradeBlock from "./UpgradeBlock";
import UpgradeCI from "./UpgradeCI";

function Upgrade() {
    const [display, setDisplay] = useState('')

    return (
        <main>
            <div className="upgrade">
                <div className="upgrade-first">
                    <FontAwesomeIcon className="icon" icon="fa-lightbulb"/>
                    <div className="upgrade-first-text">
                        <p>Cette plateforme se veut participative.</p>
                        <p>Ainsi, si vous avez une quelconque <span>remarque</span> sur un jeu existant, sur le fonctionnement de la plateforme, si souhaitez <span>proposer une amélioration</span>, si vous avez une <span>idée de jeu à implémenter</span> ou simplement si vous avez <span>une question</span>, faites-moi le savoir.</p>
                    </div>
                </div>

                <div className={display === "" ? "upgrade-second" : "upgrade-second upgrade-second-display"}>
                    {display === "" && <Fragment>
                        <UpgradeBlock icon="pencil" text="Me contacter via la plateforme" setDisplay={setDisplay}/>
                        <UpgradeBlock icon="address-card" text="Mes coordonnées" setDisplay={setDisplay}/>
                    </Fragment>}

                    {display === "pencil" && <UpgradeText setDisplay={setDisplay}/>}

                    {display === "address-card" && <UpgradeCI setDisplay={setDisplay}/>}
                </div>
            </div>
        </main>
    )
}

export default Upgrade
//import components
import InitGame from "../commun/InitGame";
import ReadingForm from "./ReadingForm";
import ReadingGame from "./ReadingGame";

function Reading() {
    return (
        <InitGame
            form={<ReadingForm/>}
            game={<ReadingGame/>}
        />
    )
}

export default Reading
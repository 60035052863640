import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Form} from "react-final-form";

//import constants
import {formMount, submitForm} from "../commun/Form/formFunction";
import {scanTitle} from "../../constants/titleText";
import {fieldsDensity, fieldsSetup, fieldsTypeScan} from "./ScanRadioData";

//import actions
import {responseActions} from "../../actions/responseActions";

//import components
import {CustomTextTooltip} from "../commun/customTooltip";
import FirstRadio from "../commun/Form/FirstRadio";
import CommonRadio from "../commun/Form/CommonRadio";
import Submit from "../commun/Form/Submit";
import {fieldsGroup, fieldsNumberChar} from "../comparison/ComparisonRadioData";

function ScanForm({setLaunch, initialValues, setInitialValues}) {
    const dispatch = useDispatch()

    const [step, setStep] = useState(0)

    const submit = values => submitForm(values, dispatch, setInitialValues, setLaunch)

    useEffect(() => {
        formMount(dispatch, initialValues, setStep)
        responseActions.raz(dispatch)
    }, [])

    const getCondition = (values) => {
        const {type, setup, group} = values
        if (type === "2" || type !== "2" && setup === "1") return 3 > step
        if (type !== "2" && setup === "0" && group === "noGroup") return 4 > step
        return 5 > step
    }

    return (
        <main className="scan">
            <div className="title">
                <CustomTextTooltip title={scanTitle}>
                    <div><FontAwesomeIcon icon="fa-circle-question"/></div>
                </CustomTextTooltip>
            </div>

            <div className={'game-form scan-form'}>
                <Form
                    onSubmit={submit}
                    initialValues={initialValues}
                    render={({handleSubmit, values, form}) => (
                        <form onSubmit={handleSubmit}>
                            <FirstRadio
                                setStep={setStep}
                                fields={fieldsTypeScan}
                                values={values}
                                form={form}
                                setInitialValues={setInitialValues}
                            />

                            <CommonRadio index={1} step={step} setStep={setStep} fields={fieldsSetup}/>
                            <CommonRadio index={2} step={step} setStep={setStep} fields={fieldsDensity}/>
                            {(values.type !== "2" && values.setup !== "1") && <CommonRadio index={3} step={step} setStep={setStep} fields={fieldsGroup}/>}
                            {(values.type !== "2" && values.group === "group" && values.setup !== "1") && <CommonRadio index={4} step={step} setStep={setStep} fields={fieldsNumberChar().slice(1, 6)}/>}

                            <Submit condition = {getCondition(values)}/>
                        </form>
                    )}
                />
            </div>
        </main>
    )
}

export default ScanForm

//import components
import EmpanForm from "./EmpanForm";
import EmpanGame from "./EmpanGame";
import InitGame from "../commun/InitGame";

function Empan() {
    return (
        <InitGame
            form={<EmpanForm/>}
            game={<EmpanGame/>}
        />
    )
}

export default Empan
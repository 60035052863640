import * as DT from '../config/dispatchTypes';

//import constants
import {getShadow} from "../constants/localStorage";

const initialState = {
    open: false,
    display: false,
    displayLeft: false,
    shadow: getShadow()
}

const navigationReducer = (state = initialState, action) => {
    switch (action.type) {
        case DT.NAVIGATION_OPEN:
            return {
                ...state,
                open: true
            }

        case DT.NAVIGATION_CLOSE:
            return {
                ...state,
                open: false
            }

        case DT.NAVIGATION_HIDE:
            return {
                ...state,
                display: false,
                displayLeft: false
            }

        case DT.NAVIGATION_DISPLAY:
            return {
                ...state,
                display: true,
                displayLeft: true
            }

        case DT.NAVIGATION_DISPLAY_LEFT:
            return {
                ...state,
                displayLeft: true
            }

        case DT.NAVIGATION_SWITCH_SHADOW:
            return {...state, shadow: getShadow()}

        case DT.NAVIGATION_RAZ:
            return {...initialState, shadow: getShadow()}

        default:
            return state;
    }
}

export default navigationReducer;

//import components
import {fields} from "../commun/Form/fields";

const PasswordForm = () => {
    return (
        <div className="profile-form-password">
            {fields.password(false, null, false, "Nouveau mot de passe*", "password", true)}
            {fields.password(false, null,false, "Confirmation nouveau mot de passe*", "confirmPassword", true)}
        </div>
    )
}

export default PasswordForm;
import {combineReducers} from 'redux';

//import reducer
import navigationReducer from "./navigationReducer";
import userReducer from "./userReducer";
import responseReducer from "./responseReducer";

export const rootReducer = combineReducers({
    navigation: navigationReducer,
    user: userReducer,
    response: responseReducer
})
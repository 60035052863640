import {Form} from "react-final-form";
import {useEffect, useState} from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {useDispatch} from "react-redux";

//import components
import {formMount, submitForm} from "../commun/Form/formFunction";
import {extrafieldsTime, fieldsTime} from "../commun/Form/SliderData";
import CommonRadio from "../commun/Form/CommonRadio";
import FirstRadio from "../commun/Form/FirstRadio";
import Submit from "../commun/Form/Submit";
import SliderTimeField from "../commun/Form/SliderTimeField";

//import constants
import {CustomTextTooltip} from "../commun/customTooltip";
import {jerkTitle} from "../../constants/titleText";
import {
    fieldsDifficulty,
    fieldsDirection,
    fieldsJerkPosition, fieldsStopwatch,
    jerkFieldsType,
} from "./pattern/JerkRadioData";

//import actions
import {responseActions} from "../../actions/responseActions";
import {fieldsNumber} from "../commun/Form/RadioData";

function JerkForm({setLaunch, initialValues, setInitialValues}) {
    const dispatch = useDispatch()

    const [step, setStep] = useState(0)
    const time = [...fieldsTime, ...extrafieldsTime].filter(({value}) => value !==  250)

    const submit = values => submitForm(values, dispatch, setInitialValues, setLaunch)

    useEffect(() => {
        formMount(dispatch, initialValues, setStep)
        responseActions.raz(dispatch)
    }, [])

    const getStaticForm = (values) => (
        values.position === "static" ?
            <>
                {step > 1 && values.position && <CommonRadio index={2} step={step} setStep={setStep} fields={fieldsNumber.slice(0, 3)}/>}
                {step > 2 && values.number && <CommonRadio index={3} step={step} setStep={setStep} fields={fieldsDifficulty}/>}
                {step > 3 && values.difficulty && <CommonRadio index={4} step={step} setStep={setStep} fields={fieldsDirection}/>}
                {step > 4 && values.orientation && <CommonRadio index={5} step={step} setStep={setStep} fields={fieldsStopwatch}/>}
            </> : null
    )

    return (
        <main className="jerk">
            <div className="title">
                <CustomTextTooltip title={jerkTitle}>
                    <div><FontAwesomeIcon icon="fa-circle-question"/></div>
                </CustomTextTooltip>
            </div>
            <div className="game-form jerk-form">
                <Form
                    onSubmit={submit}
                    initialValues={initialValues}
                    render={({handleSubmit, values, form}) => (
                        <form onSubmit={handleSubmit}>
                            <FirstRadio
                                step={step}
                                setStep={setStep}
                                fields={jerkFieldsType}
                                form={form}
                                setInitialValues={setInitialValues}
                            />

                            {values.type && <CommonRadio index={1} step={step} setStep={setStep} fields={fieldsJerkPosition}/>}

                            {getStaticForm(values)}

                            {((values.position === "static" && values.stopwatch === "true" && step > 5)
                                || (values.position === "movement" && step > 1)) &&
                                <SliderTimeField
                                    initialValue={initialValues.time}
                                    marks={time}
                                    defaultTime={500}
                                    defaultSlider={false}
                                />
                            }

                            <Submit condition={(values.position === "static" ? 6 : 2) > step}/>
                        </form>
                    )}
                />
            </div>
        </main>
    )
}

export default JerkForm

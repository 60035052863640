import {Fragment} from "react";
import {Form} from "react-final-form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

//import services
import {loginServices} from "../../services/loginServices";

//import components
import {fields} from "../commun/Form/fields";
import {CustomTooltip} from "../commun/customTooltip";

const ForgotForm = ({setForgot}) => {
    const submit = values => {
        loginServices.forgotPassword(values).then(response => (
            response.status === 200 && setForgot(true)
        ))
    }

    return (
        <Fragment>
            <h1>Mot de passe oublié</h1>
            <Form
                onSubmit={submit}
                render={({handleSubmit, hasValidationErrors}) => (
                    <form onSubmit={handleSubmit}>
                        {fields.email()}
                        <CustomTooltip title="Réinitialiser le mot de passe" placement="left">
                            <button disabled={hasValidationErrors} className="btn-nav btn-second" type="submit">
                                <FontAwesomeIcon className="icon" icon="circle-right"/>
                            </button>
                        </CustomTooltip>
                    </form>
                )}
            />
        </Fragment>
    )
}

export default ForgotForm

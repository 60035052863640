import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as htmlToImage from "html-to-image";
import download from "downloadjs";
import {useEffect} from "react";

//import components
import DisplayResults from "./DisplayResults";
import moment from "moment";
import {CustomTooltip} from "./customTooltip";

function Results({setResults, setLaunch, launch, title, setStart, setDisplay, gameDisplay}) {
    const close = () => {
        setResults(false)
        setLaunch({})
    }

    useEffect(() => {
        setDisplay()
    }, [])

    const save = () => {
        const titlePng = moment().format("YYYY-MM-DD-HHmm")

        htmlToImage.toPng(document.body, {skipFonts: true}).then(dataUrl => {
            download(dataUrl, titlePng + "_" + title.replace('-','') + '.png');
        })
    }

    const next = () => {
        setResults(false)
        setStart ? setStart(false) : gameDisplay()
    }

    return (
        <div className="results-container">
            <div className="displayResults-title">{title}</div>

            <DisplayResults launch={launch} title={title}/>

            <CustomTooltip title="Retour" placement="right">
                <button className="btn-nav btn-first" onClick={close}>
                    <FontAwesomeIcon className="icon" icon="circle-left"/>
                </button>
            </CustomTooltip>

            <CustomTooltip title="Enregistrer">
                <button className="btn-nav btn-center" onClick={save}>
                    <FontAwesomeIcon className="icon icon-center" icon="floppy-disk"/>
                </button>
            </CustomTooltip>

            <CustomTooltip title="Continuer" placement="left">
                <button className="btn-nav btn-second" onClick={next}>
                    <FontAwesomeIcon className="icon" icon="circle-right"/>
                </button>
            </CustomTooltip>
        </div>
    )
}

export default Results

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Form} from "react-final-form";

//import components
import ProfileForm from "../profile/ProfileForm";
import {CustomTooltip} from "../commun/customTooltip";

//import services
import {userServices} from "../../services/userServices";

const CreateForm = ({setForm}) => {
    const submit = (values) => {
        userServices.register(values).then(response => response.status === 200 && setForm(true))
    }

    return (
        <Form
            onSubmit={submit}
            render={({handleSubmit, hasValidationErrors}) => (
                <form onSubmit={handleSubmit}>
                    <ProfileForm disabled={false}/>

                    <CustomTooltip title="S'inscrire" placement="left">
                        <button disabled={hasValidationErrors} className="btn-nav btn-second" type="submit">
                            <FontAwesomeIcon className="icon" icon="circle-right"/>
                        </button>
                    </CustomTooltip>
                </form>
            )}
        />
    )
}

export default CreateForm

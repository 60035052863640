import * as DT from '../config/dispatchTypes';

//import constants
import {getCorrection} from "../constants/localStorage";

const initialState = {
    trueResponses: {current: 0, serial: 0},
    falseResponses: {current: 0, serial: 0},
    missedResponse: {current: 0, serial: 0},
    serial: 0,
    game: false,
    correction: getCorrection(),
    display: true
}

const responseReducer = (state = initialState, action) => {
    const {trueResponses, falseResponses, missedResponse, serial} = state

    const substract = (prev) => {
        const newValue = prev - 1
        return newValue < 0 ? 0 : newValue
    }

    switch (action.type) {
        case DT.RESPONSE_TRUE:
            return {
                ...state,
                trueResponses: {current: trueResponses.current + 1, serial: trueResponses.serial + 1},
                game: true
            }

        case DT.RESPONSE_TRUE_CURRENT:
            return {
                ...state,
                trueResponses: {...trueResponses, current: 0},
                game: true
            }

        case DT.RESPONSE_FALSE:
            return {
                ...state,
                falseResponses: {current: falseResponses.current + 1, serial: falseResponses.serial + 1},
                game: true
            }

        case DT.RESPONSE_FALSE_CURRENT:
            return {
                ...state,
                falseResponses: {...falseResponses, current: 0},
                game: true
            }

        case DT.RESPONSE_MISSED:
            return {
                ...state,
                missedResponse: {serial: missedResponse.serial + action.missedResponse, current: action.missedResponse},
                game: true
            }

        case DT.RESPONSE_MISSED_INC_SERIAL:
            return {
                ...state,
                missedResponse: {...missedResponse, serial: missedResponse.serial + 1},
                game: true
            }

        case DT.RESPONSE_MISSED_DEC:
            return {
                ...state,
                missedResponse: {serial: substract(missedResponse.serial), current: substract(missedResponse.current)},
                game: true
            }

        case DT.RESPONSE_MISSED_DEC_SERIAL:
            return {
                ...state,
                missedResponse: {serial: missedResponse.serial - missedResponse.current, current: 0},
            }

        case DT.RESPONSE_SERIAL:
            return {...state, serial: serial + 1, game: true}

        case DT.RESPONSE_SERIAL_DEC:
            return {...state, serial: serial - 1}

        case DT.RESPONSE_SWITCH_CORRECTION:
            return {...initialState, serial: 1, game: state.game, correction: getCorrection(), display: state.display}

        case DT.RESPONSE_DISPLAY:
            return {...state, display: action.display}

        case DT.RESPONSE_RAZ:
            return {...initialState, correction: getCorrection()}

        default:
            return state;
    }
}

export default responseReducer;

//import config
import * as DT from "../config/dispatchTypes";

export const responseActions = {
    responseTrue,
    responseTrueCurrent,
    responseFalse,
    responseFalseCurrent,
    responseMissed,
    responseMissedIncSerial,
    responseMissedDec,
    responseMissedDecSerial,
    serial,
    serialDec,
    switchCorrection,
    displayResponse,
    raz
}

function responseTrue(dispatch) {
    dispatch({type: DT.RESPONSE_TRUE})
}

function responseTrueCurrent(dispatch) {
    dispatch({type: DT.RESPONSE_TRUE_CURRENT})
}

function responseFalse(dispatch) {
    dispatch({type: DT.RESPONSE_FALSE})
}

function responseFalseCurrent(dispatch) {
    dispatch({type: DT.RESPONSE_FALSE_CURRENT})
}

function responseMissed(dispatch, missedResponse) {
    dispatch({type: DT.RESPONSE_MISSED, missedResponse})
}

function responseMissedIncSerial(dispatch) {
    dispatch({type: DT.RESPONSE_MISSED_INC_SERIAL})
}

function responseMissedDec(dispatch) {
    dispatch({type: DT.RESPONSE_MISSED_DEC})
}

function responseMissedDecSerial(dispatch) {
    dispatch({type: DT.RESPONSE_MISSED_DEC_SERIAL})
}

function serial(dispatch) {
    dispatch({type: DT.RESPONSE_SERIAL})
}

function serialDec(dispatch) {
    dispatch({type: DT.RESPONSE_SERIAL_DEC})
}

function switchCorrection(dispatch) {
    dispatch({type: DT.RESPONSE_SWITCH_CORRECTION})
}

function displayResponse(dispatch, display) {
    dispatch({type: DT.RESPONSE_DISPLAY, display})
}

function raz(dispatch) {
    dispatch({type: DT.RESPONSE_RAZ})
}
